import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { app, auth } from '../firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getFirestore } from 'firebase/firestore';
import { getBalance } from '../utils/blockchain';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import getProvider from '../providers';
import getProviderClass from '../providers';

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, loading, errorAuth] = useAuthState(auth);
  const [refreshBalanceValue, setRefreshBalanceValue] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [balance, setBalance] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [dataValue, dataLoading, error] = useDocumentData(currentUser &&
    doc(getFirestore(app), 'users', currentUser.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  console.log('SOADJODJAAD', dataValue, dataLoading, error, currentUser, user)

  useEffect(() => {
    console.log('firsteffect', user, loading, errorAuth)

    if (loading) {
      return;
    }

    console.log(location)

    if (location.pathname.startsWith('/app/') && !user && !loading) {
      setIsLoading(false)
      navigate(routes.login.path)
      return;
    }

    setCurrentUser(user)
  }, [loading, user, navigate, location, errorAuth]);

  useEffect(() => {
    if (dataLoading) {
      return;
    }

    console.log('setDataValue?', dataValue)
    if (dataValue) {
      setCurrentUserData(dataValue)
      setIsSuccess(true)
      setIsLoading(false)
    }
  }, [dataLoading, dataValue])

  useEffect(() => {
    if (dataLoading) {
      return;
    }

    console.log('setDataValue?', dataValue)
    if (dataValue) {
      setCurrentUserData(dataValue)
      setIsSuccess(true)
      setIsLoading(false)
    }
  }, [dataLoading, dataValue])

  useEffect(() => {
    console.log('blockchainprovider', currentUserData, currentUserData?.publicKey)
    if (!currentUserData?.publicKey) {
      return;
    }

    if (!refreshBalanceValue) {
      return;
    }

    const Provider = getProviderClass(process.env.REACT_APP_PROVIDER)

    const providerInstance = new Provider();
    setProvider(providerInstance);

    console.log('loomprovider', providerInstance)

    new Promise((resolve)  => {
      if (process.env.REACT_APP_PROVIDER === 'firebase') {
        console.log('ssfredfsa', currentUserData, currentUserData.balance)
        return resolve(currentUserData.balance.toString())
      } else {
        return resolve(getBalance(providerInstance.web3, process.env.REACT_APP_LAYER2_STABLECOIN_CONTRACT_ADDRESS, currentUserData.publicKey))
      }
    })
      .then((balance) => {
        console.log('balance', balance)
        setBalance(providerInstance.web3.utils.fromWei(balance))
      })

    setRefreshBalanceValue(false);
  }, [currentUserData?.publicKey, refreshBalanceValue]);

  function refreshBalance() {
    setRefreshBalanceValue(true);
  }

  return <AuthContext.Provider value={{ currentUser, user, loading, errorAuth, isLoading, currentUserData, error, isSuccess, provider, balance, refreshBalance }}>{children}</AuthContext.Provider>;
}

export function useAuthValue() {
  return useContext(AuthContext);
}
