import * as React from 'react';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { defaultTextColor, defaultTitleColor, globalThemeOptions } from '../../../styles';
import ContainerWithLine from '../../../components/ContainerWithLine';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Box from '@mui/material/Box';
import image from '../assets/Illustration-02-cropped.svg';
import BoxElement from './BoxElement';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { useCustomRouterContext } from '../../../contexts/CustomRouterContext';
import { Link } from 'react-router-dom';

const theme = createTheme(globalThemeOptions, {
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: defaultTitleColor,
          lineHeight: '5rem',
          fontSize: '4rem',
          fontWeight: 800,
          letterSpacing: '-0.02em',
          paddingBottom: globalThemeOptions.spacing(4),
        },
        h2: {
          color: defaultTextColor,
          fontWeight: 700,
          fontSize: '1.5rem',
          lineHeight: '2.25rem',
        },
      },
    },
  },
});

function WhySection() {
  const routes = useCustomRouterContext();

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <ContainerWithLine maxWidth="lg" sx={{ pb: 4 }}>
          {/* @ts-ignore */}
          <ContainerWithLine position="top" sx={{
            marginLeft: -3,
            paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
          }}>
            <TypographyUnderlined variant="h2" fontWeight={800} fontSize={'3em'} wrapperSX={{
              paddingLeft: (theme) => theme.spacing(2),
            }}>Pourquoi choisir Divyz ?</TypographyUnderlined>
          </ContainerWithLine>
        </ContainerWithLine>
        <BoxWithDiagonaleBackground padding={0} deg={-6}>
          <ContainerWithLine maxWidth="lg" sx={{
            backgroundImage: `url('${image}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: (theme) => theme.spacing(4),
            backgroundSize: 'contain',
            paddingTop: { sm: 30, md: 0 },
          }}>
            {/*<img src={image} alt="Illustration" style={{ width: '100%' }} />*/}
            <Grid2 container
                   // paddingTop={66}
                   spacing={4}
                   sx={{
                     [globalThemeOptions.breakpoints.up('xs')]: {
                       paddingTop: 32
                     },
                     [globalThemeOptions.breakpoints.up('md')]: {
                       paddingTop: 56,
                     },
                     [globalThemeOptions.breakpoints.up('lg')]: {
                       paddingTop: 66,
                     },
                   }}
            >
              <BoxElement mdOffset={4} md={4} sm={6} title="1. Revenus Réguliers:" icon={'euro'}>
                Bénéficiez d'un flux de revenus régulier grâce à votre part des loyers perçus.
              </BoxElement>
              <BoxElement md={4} sm={6} title="2. Investissement Flexible:" icon={'wallet'}>
                Vous pouvez investir selon votre budget et votre préférence, en choisissant le pourcentage de loyers reversés qui vous convient.
              </BoxElement>
              <BoxElement md={4} sm={6} title="3. Diversification des risques:" icon={'bar-chart'}>
                En investissant dans différentes propriétés, vous répartissez les risques et réduisez votre exposition à une seule propriété.
              </BoxElement>
              <BoxElement md={4} sm={6} title="4. Accès au Marché immobilier:" icon={'immo'}>
                Vous pouvez participer au marché immobilier même avec un capital limité, en investissant dans des propriétés générant déjà des revenus.
              </BoxElement>
              <BoxElement md={4} sm={6} title="5. Professionnalisme de la Gestion:" icon={'manage'}>
                Les sociétés de gestion s'occupent de la gestion des biens, vous permettant de bénéficier de leur expertise et de leur expérience.
              </BoxElement>
              <BoxElement md={4} sm={6} title="6. Pas de soucis de location:" icon={'doc'}>
                Vous n'avez pas à vous soucier de trouver des locataires, de la gestion des contrats de location ou des problèmes liés à la maintenance. Cela est pris en charge par les professionnels.
              </BoxElement>
              <BoxElement md={4} sm={6} title="7. Investissement Passif:" icon={'money'}>
                En investissant dans des revenus partagés, vous pouvez profiter des avantages de l'immobilier sans avoir à vous occuper activement de la gestion quotidienne.
              </BoxElement>
              <BoxElement md={4} sm={6} title="8. Potentiel de Plus-Value:" icon={'plus'}>
                En plus des revenus réguliers, vous pouvez également bénéficier de la valorisation de la propriété au fil du temps, ce qui peut augmenter votre rendement global.
              </BoxElement>
            </Grid2>
          </ContainerWithLine>
        </BoxWithDiagonaleBackground>
        <ContainerWithLine maxWidth="lg" sx={{ pb: 4 }}>
        <Grid2
          container
          alignItems="center"
          direction="column"
          paddingY={5}
          sx={{ paddingY: { xs: 2, md: 5 } }}
        >
          <Grid2>
            <Link to={routes.register.path}>
              <CustomButton color="primary" variant="contained">Je crée mon compte</CustomButton>
            </Link>
          </Grid2>
        </Grid2>
        </ContainerWithLine>
      </Box>
    </ThemeProvider>
  )
}

export default WhySection
