import { Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import imageHeartIcon from "../../../assets/images/heart-icon.png";
import { useCustomRouterContext } from '../../../contexts/CustomRouterContext';
import { Link } from 'react-router-dom';

const CommonFooter = () => {
  const routes = useCustomRouterContext();

  const HeartIcon  = styled('img')`
    max-width: 3.6rem;
    object-fit: contain;
    margin: 0 1.5rem;
    @media (max-width: 827px){
      margin-bottom: 1.5rem;
    }
  `

  const ParrainezText = styled('span')`
    text-align: center; 
  `
  return (
    <Link to={routes.referral.path}>

    <Button
      sx={{
        backgroundColor: "var(--purple)",
        borderRadius: "5px",
        boxShadow: "40px 40px 100px rgba(220, 225, 237, 0.5);",
        color: "var(--white)",
        padding: "36px 49px",
        width: "100%",
        height: "100%",
        border: "none",
        outline: "none",
        textTransform: "none",
        fontSize: "2.4rem",
        lineHeight: "3.8rem",
        fontFamily: "var(--font-Manrope)",
        fontWeight: "500",
        letterSpacing: "0.02em",
        marginTop: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        "&:hover, &:focus": {
          backgroundColor: "var(--dark)",
          border: "none",
          outline: "none",
        },
      }}
    >
        <HeartIcon src={imageHeartIcon} alt="" />
        <ParrainezText>
          Parrainez vos proches et gagnez xxx de leur loyers
        </ParrainezText>
    </Button>
      </Link>
  );
};

export default CommonFooter;
