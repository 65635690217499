import * as Yup from 'yup';
import { required } from './messages';

const KYCSchema = Yup.object().shape({
  birth: Yup.object().shape({
    city: Yup.string()
      .max(140)
      .required(required),
    date: Yup
      .string()
      .matches(/^[12]\d{3}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])$/)
      .required(required),
    Country: Yup.string()
      .max(3)
      .required(required),
  }),
})

export default KYCSchema
