import * as React from 'react';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import { Typography } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { defaultSubTextColor, globalThemeOptions, secondaryTitleColor } from '../../../styles';
import ContainerWithLine from '../../../components/ContainerWithLine';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';
import { buttonPrimaryBackgroundColor } from '../../../components/CustomButton/styles';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: '3rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // paddingTop: theme.spacing(4),
  // paddingLeft: theme.spacing(4),
  // paddingRight: theme.spacing(4),
  padding: theme.spacing(4),

  // boxShadow: theme.shadows[24],
  '& .MuiSvgIcon-root': {
    color: buttonPrimaryBackgroundColor,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(135deg)',
  },
  '&.Mui-expanded': {
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(0),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  marginTop: theme.spacing(-4),
  // paddingBottom: theme.spacing(4)
  // marginTop: theme.spacing(-6),
  // marginBottom: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function FAQSection() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg" sx={{ pb: 4 }}>
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Grid2
          container
          spacing={4}
          md={8}
          sx={{
            paddingTop: { xs: 2, md: 4 },
            paddingBottom: { xs: 2, md: 8 },
          }}
        >
          <Grid2>
            <TypographyUnderlined
              variant="h2"
              fontWeight={800}
              fontSize={'3em'}
              lineHeight="1.35em"
              color={secondaryTitleColor}
              wrapperSX={{
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >Questions fréquentes</TypographyUnderlined>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
      <ContainerWithLine maxWidth="lg" sx={{ pb: 4, pl: 4 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color={secondaryTitleColor} fontWeight={700} fontSize="1.15em">Quels sont les frais ?</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Typography fontSize="1em" fontWeight={400} color={defaultSubTextColor}>
              Nos commissions sont de 0€ pour nos investisseurs, un point c'est tout ! Vous n'aurez aucun frais d'entrée, de frais de sortie ou de frais de gestion. Notre rémunération est transparente.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color={secondaryTitleColor} fontWeight={700} fontSize="1.15em">Dans quels secteurs se trouvent les biens à financer ?</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Typography fontSize="1em" fontWeight={400} color={defaultSubTextColor}>
              Nous avons des chasseurs qui sillonnent toute la France à la recherche des meilleurs opportunités pour la communauté.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color={secondaryTitleColor} fontWeight={700} fontSize="1.15em">Comment ça fonctionne ?</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Typography fontSize="1em" fontWeight={400} color={defaultSubTextColor}>
              Lorsqu'un projet vous intéresse, vous pouvez y investir et signez alors un contrat de partage des loyers avec la foncière porteuse de projet.<br/>
              Exemple si vous investissez à hauteur de 10% d'un projet dont 90% des loyers bruts sont reversés, vous toucherez 9% des loyers bruts !
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color={secondaryTitleColor} fontWeight={700} fontSize="1.15em">Qui peut investir ?</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Typography fontSize="1em" fontWeight={400} color={defaultSubTextColor}>
              Les particuliers ainsi que les sociétés peuvent investir dans nos projets. Les étrangers pourront prochainement investir.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color={secondaryTitleColor} fontWeight={700} fontSize="1.15em">Quel sont les moyens de paiements autorisés ?</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Typography fontSize="1em" fontWeight={400} color={defaultSubTextColor}>
              Nous avons 2 moyens de paiements autorisés :<br/>
              Paiement par carte bancaire.<br/>
              Paiement par virement bancaire.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </ContainerWithLine>
    </Box>
  )
}

export default FAQSection
