import { createTheme, ThemeOptions } from '@mui/material/styles';
import { alpha } from '@mui/material';

export const linkColor = '#83899C';
export const secondaryTitleColor = '#423A61';
export const defaultSubTextColor = '#6F7486'
export const defaultSubTextLighterColor = alpha('#6F7486', 0.5)
export const defaultTextColor = '#4A5064'
export const defaultTitleColor = '#5333ED'
export const greyColor = '#C6C8D7'
export const lineColor = '#B8CCE0'

export const globalThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#5233ed',
    },
    secondary: {
      main: '#2cd4d9',
    },
    text: {
      // neutral: defaultTextColor,
      // primary: styles.t,
      // secondary: styles.tt,
      // disabled: styles.ttt,
      // hint: styles.tttt,
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
        color: linkColor,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      }
    }
  }
});
/*

globalThemeOptions.typography.h1 = {
  [globalThemeOptions.breakpoints.up('xs')]: {
    fontSize: "2rem",
    lineHeight: globalThemeOptions.typography.pxToRem(48),
  },
  [globalThemeOptions.breakpoints.up('sm')]: {
    fontSize: "2.75rem",
    lineHeight: '1rem',
  },
  [globalThemeOptions.breakpoints.up('md')]: {
    fontSize: "2.75rem",
  },
  [globalThemeOptions.breakpoints.up('lg')]: {
    fontSize: "2.75rem",
  },
}

globalThemeOptions.typography.h2 = {
  [globalThemeOptions.breakpoints.up('xs')]: {
    fontSize: "1.25rem",
  },
  [globalThemeOptions.breakpoints.up('sm')]: {
    fontSize: "1.5rem",
  },
  [globalThemeOptions.breakpoints.up('md')]: {
    fontSize: "1.5rem",
  },
  [globalThemeOptions.breakpoints.up('lg')]: {
    fontSize: "1.5rem",
  },
}
globalThemeOptions.typography.h3 = {
  [globalThemeOptions.breakpoints.up('xs')]: {
    fontSize: "0.75rem",
  },
  [globalThemeOptions.breakpoints.up('sm')]: {
    fontSize: "1.25rem",
  },
  [globalThemeOptions.breakpoints.up('md')]: {
    fontSize: "1.25rem",
  },
  [globalThemeOptions.breakpoints.up('lg')]: {
    fontSize: "1.25rem",
  },
}
*/

globalThemeOptions.typography.subtitle1 = {
  fontSize: '1.25rem',
  fontWeight: 'lighter'
}

globalThemeOptions.typography.body1 = {
  fontSize: '1.714rem',
}

export default globalThemeOptions;
