import { Typography } from '@mui/material';
import { buttonPrimaryBackgroundColor } from '../../components/CustomButton/styles';
import React from 'react';
import ContainerWithLine from '../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import { defaultSubTextColor, secondaryTitleColor } from '../../styles';
import rbfImage from './assets/rbf.png';

function Hero() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Typography variant="subtitle1" sx={{
          color: buttonPrimaryBackgroundColor,
        }}>Projets</Typography>
        <TypographyUnderlined variant="h1" fontWeight={800} fontSize={'3em'} color={secondaryTitleColor} wrapperSX={{
          paddingY: (theme) => theme.spacing(2),
        }}>Les projets en cours</TypographyUnderlined>
        <Typography variant="body1" color={secondaryTitleColor} fontWeight={500} marginY={4}>Connectez-vous ou créez votre compte pour découvrir les projets en cours et pour être alerté en avant première des nouvelles pépites d’investissement Divyz.</Typography>
      </ContainerWithLine>
    </Box>
  )
}

export default Hero
