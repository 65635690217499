import { Wrapper, Status } from '@googlemaps/react-wrapper';
import React, { useEffect, useRef, useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { createCustomEqual } from 'fast-equals';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { FaGrinStars, FaHandHoldingUsd, FaLightbulb, FaList } from 'react-icons/fa';
import DPE from '../../../../components/Diagnostics/DPE';
import GES from '../../../../components/Diagnostics/GES';
import { MdHomeWork } from 'react-icons/md';
import getIconByState from '../utils/getIconByState';

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

const Map = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName),
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} className="map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

function Infos({ data }) {
  const center = { lat: parseFloat(data.coordinates.latitude), lng: parseFloat(data.coordinates.longitude) };
  const zoom = 13;

  return (
    <div className="infos">
      <h2 className="heading"><span></span> Informations sur le bien</h2>
      <div className="map_wrap">
        <p>Localisation</p>

        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
          <Map center={center} zoom={zoom}>
            <Marker
              position={{ lat: parseFloat(data.coordinates.latitude), lng: parseFloat(data.coordinates.longitude) }} />
          </Map>
        </Wrapper>
      </div>
      <Row className="table_wrap">
        <Col md={6}>
          <div className="table_left_side">
            <h3>Présentation</h3>
            <PrismicRichText field={data.informationsText} />

            <Card
              bg="light"
              text={'dark'}
              className="mb-2 mt-4"
              l
            >
              <Card.Header><span><FaLightbulb /></span> Description du bien actuel</Card.Header>
              <Card.Body>
                <div className="diagnostics_table">
                  <div className="table-responsive">
                    <Table striped bordered className="mb-0">
                      <tbody>
                      {data.project_informations.map((pi, i) => (
                        <tr key={i}>
                          <td>{pi.key}</td>
                          <td>{pi.value}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <Card
            className="mb-2"
          >
            <Card.Header><span><MdHomeWork /></span> La copropriété</Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <Table striped bordered className="mb-0">
                  <tbody>
                  <tr></tr>
                  {data.copro.map((c, i) => (
                    <tr key={i}>
                      <td>{c.key}</td>
                      <td>{c.state}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
          <Card
            className="mb-2"
          >
            <Card.Header><span><FaHandHoldingUsd /></span> Notre projet pour ce bien</Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <Table striped bordered className="mb-0">
                  <tbody>
                  <tr></tr>
                  {data.our_project.map((op, i) => (
                    <tr key={i}>
                      <td>{op.key}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <div className="col-md-6">
          <div className="table_right_side">
            <div className="why_invest">
              <Card
                className="mb-2"
                l
              >
                <Card.Header><span><FaGrinStars /></span> Pourquoi devriez-vous investir ?</Card.Header>
                <Card.Body>
                  <ul>
                    {data.advantages.map((a, i) => (
                      <li key={i}>{a.key}</li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </div>

            <Card
              bg="light"
              text={'dark'}
              className="mb-2"
              l
            >
              <Card.Header><span><FaLightbulb /></span> Performance énergétique DPE <br />Indice d'émission de gaz à
                effet de serre (GES)</Card.Header>
              <Card.Body>
                <div className="diagnostics_table">
                  <div className="table-responsive">
                    <Table striped bordered className="mb-0">
                      <tbody>
                      {data.diagnostics.map((pi, i) => (
                        <tr key={i}>
                          <td>{pi.key}</td>
                          <td>{getIconByState(pi.state)} {pi.state === "1" ? `Conforme` : 'TO UPDATE'}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </div>
                </div>

                <Row className="text-center">
                  <Col md={6}>
                    <DPE value={244} />
                  </Col>
                  <Col md={6}>
                    <GES value={53} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card
              className="mb-2"
            >
              <Card.Header><span><FaList /></span> Rapport d'état</Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Table striped bordered className="mb-0">
                    <tbody>
                    <tr></tr>
                    {data.project_state.map((ps, i) => (
                      <tr key={i}>
                        <td>{ps.key}</td>
                        <td>{getIconByState(ps.state)} {ps.current}</td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Row>
    </div>
  );
}

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      a instanceof window.google.maps.LatLng ||
      b instanceof window.google.maps.LatLng
    ) {
      return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  },
);

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[],
) {
  React.useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback]);
}

// window.addEventListener("DOMContentLoaded", () => {
//   ReactDom.render(<App />, document.getElementById("root"));
// });

export default Infos;
