import * as Yup from 'yup';
import PasswordValidator from './PasswordValidator';

const validationSchema = Yup.object().shape({
  acceptTerms: Yup
    .boolean().oneOf([true],'Must check the CGV'),
  // firstName: Yup.string()
  //   .max(100, '*Prénom must be less than 100 characters')
  //   .required('*Prénom is required'),
  // lastName: Yup.string()
  //   .max(100, '*Nom de famille must be less than 100 characters')
  //   .required('*Nom de famille is required'),
  email: Yup.string()
    .email('*Must be a valid email address')
    .max(100, '*Email must be less than 100 characters')
    .required('*Email is required'),
  hasReferrer: Yup.boolean().optional(),
  referrerCode: Yup.string()
    .when('hasReferrer', {
      is: true,
      then: Yup.string()
        .required('*Code de parrainage is required')
        .max(250, '*Le code parrain doit être inférieur à 10 caractères'),
      otherwise: Yup.string().notRequired()
    }),
})
  .concat(PasswordValidator);

export default validationSchema;
