import { TabPanel } from "@mui/lab";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { styled } from '@mui/system';
import { MontageData } from "../../../utils/Montage";
import { tableHeading } from "../../../utils/MontageTableHeading";
const Montage = () => {
  const PropertyTabContent = styled('div')({
    padding: '5rem 0',
    h2: {
      color: 'var(--primary)',
      marginBottom: '5rem',
      fontWeight: '800',
    },
    iframe: {
      border: 'none'
    },
    p: {
      fontSize: '1.6rem',
      lineHeight: '2.6rem',
      color: '#6f7486',
      letterSpacing: '0.02em',
      '@media (maxWidth: 480px)': {
        fontSize: '1.4rem',
      }
    }
  })

  const MontageTableContent = styled(Table)({
    borderCollapse: 'separate !important',
    borderSpacing: '0 7px !important',
    'tbody tr': {
      background: '#ffffff',
      border: '1px solid rgba(198, 200, 215, 0.5) !important',
      boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
      borderRadius: '5px',
      'lightgreen': {
        background: '#f4fafa',
      },
      ':hover': {
        background: '#f4fafa'
      }
    },
    td:{
      fontSize: '1.6rem !important',
      lineHeight: '2.6rem',
      // color: '#6f7486',
      letterSpacing: '0.02em',
      textAlign: 'center',
      color: 'var(--primary)',
      fontFamily: 'var(--font-Manrope) !important',
      padding: '1.3rem 1rem',
      border: 'none',
      whiteSpace: 'nowrap',
      borderTop: '1px solid rgba(198, 200, 215, 0.5)',
      borderBottom: '1px solid rgba(198, 200, 215, 0.5)',
      borderRight: '1px solid rgba(198, 200, 215, 0.5)',
      ':first-of-type': {
        borderLeft: '1px solid rgba(198, 200, 215, 0.5)',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
      ':last-child': {
        borderRight: '1px solid rgba(198, 200, 215, 0.5)',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      }
    },
    th: {
      fontWeight: 600,
      fontSize: '1.6rem !important',
      lineHeight: '2.6rem',
      // color: '#6f7486',
      letterSpacing: '0.02em',
      textAlign: 'center',
      color: 'var(--primary)',
      fontFamily: 'var(--font-Manrope) !important',
      padding: '1.3rem 1rem',
      border: 'none',
      whiteSpace: 'nowrap',
    }
  })

  return (
    <TabPanel value="4" sx={{ padding: "0px" }}>
      <PropertyTabContent>
        <h2>Montage financier</h2>
        <TableContainer>
          <MontageTableContent
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tableHeading.heading.map((item, i) => {
                  return <TableCell key={i}>{item}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {MontageData.map((item, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{item.Chambre}</TableCell>
                    <TableCell>{item.Profil}</TableCell>
                    <TableCell>{item.Paiment}</TableCell>
                    <TableCell>{item.Loyer}</TableCell>
                    <TableCell>{item.Charges}</TableCell>
                    <TableCell>{item.LoyerCC}</TableCell>
                    <TableCell>{item.TypedeBail}</TableCell>
                    <TableCell>{item.DebitBail}</TableCell>
                    <TableCell>{item.Renouvellement}</TableCell>
                    <TableCell>{item.Garant}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </MontageTableContent>
        </TableContainer>
      </PropertyTabContent>
    </TabPanel>
  );
};

export default Montage;
