import './styles/main.css'
import AbstractDiagnostic from './AbstractDiagnostic';

function DPE({ value }) {
  return (
    <AbstractDiagnostic
      className=""
      title="Diagnostic de performance énergétique (DPE)"
      value={value}
      ranges={[
        50,
        90,
        150,
        230,
        330,
        450,
        999
      ]}
      unit="kWh/m2.an"
      />
  );
}

export default DPE;
