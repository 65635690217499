export const tableHeading = {
  heading: [
    "Chambre",
    "Profil",
    "Paiment",
    "Loyer (HC)",
    "Charges",
    "Loyer (CC)",
    "Type de Bail",
    "Debit Bail",
    "Renouvellement",
    "Garant",
  ],
};
