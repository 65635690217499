import * as React from 'react';
import Container, { ContainerTypeMap } from '@mui/material/Container';

import { createTheme, Theme } from '@mui/material/styles';
import { capitalize } from '../helpers';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import { SxProps } from '@mui/system';
import { globalThemeOptions, lineColor } from '../styles';

// @ts-ignore
function ContainerWithLine({ children, position = 'left', ...props }) {
  return (
    <Container {...props} sx={{
      borderType: 'solid',
      borderColor: lineColor,
      [`border${capitalize(position)}Width`]: 1,
      [`border${capitalize(position)}Style`]: 'dotted',
      [globalThemeOptions.breakpoints.up('xs')]: {
        borderLeftStyle: 'none',
        borderLeftWidth: '0',
      },
      [globalThemeOptions.breakpoints.up('lg')]: {
        [`border${capitalize(position)}Width`]: 1,
        [`border${capitalize(position)}Style`]: 'dotted',
      },
      ...props.sx,
    }}>
      {children}
    </Container>
  )
}

export default ContainerWithLine as OverridableComponent<ContainerTypeMap<{ position?: string }>>;
