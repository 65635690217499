import React from 'react';
import OkIcon from '../components/Icons/OkIcon';
import WarningIcon from '../components/Icons/WarningIcon';
import DangerIcon from '../components/Icons/DangerIcon';
import UndefinedIcon from '../components/Icons/UndefinedIcon';

function getIconByState(state) {
  if (typeof state === 'number') {
    state = state.toString()
  }

  switch(state) {
    case '0':
      return <DangerIcon />
    case '1':
      return <OkIcon />
    case '2':
      return <WarningIcon />
    case '3':
    default:
      return <UndefinedIcon />
  }
}

export default getIconByState
