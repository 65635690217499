import * as React from 'react';
import { Typography } from '@mui/material';
import { defaultSubTextColor, defaultTitleColor, secondaryTitleColor } from '../../styles';
import ContainerWithLine from '../../components/ContainerWithLine';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import Box from '@mui/material/Box';
import CustomList from '../../components/CustomList/CustomList';

function RBFSection() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        <TypographyUnderlined
          variant="h2"
          fontWeight={800}
          fontSize={'3em'}
          lineHeight="1.35em"
          color={secondaryTitleColor}
          wrapperSX={{
            paddingLeft: (theme) => theme.spacing(2),
          }}
        >Le modèle RBF</TypographyUnderlined>
        <Typography variant="h3" color={secondaryTitleColor} fontWeight={500} marginY={4}>Le modèle Divyz est basé sur
          le RBF “Revenue Based Financing” qu’on peut traduire en “partage de revenus”.</Typography>

          <Box>
          <Box>
            <Typography color={defaultSubTextColor} marginTop={5}>Ce modèle de financement, novateur en France, vous permet de percevoir une part des revenus locatifs de Divyz au prorata de votre investissement sur le ou les biens que vous aurez sélectionnés sur notre plateforme.</Typography>
            <Typography color={defaultSubTextColor} marginTop={5}>Dans le système général, le RBF consiste à financer une entreprise qui verse en contrepartie un pourcentage de son chiffre d’affaires à ses financeurs. Le RBF est en quelque sorte une alternative au financement par equity pour les entreprise ne souhaitant pas diluer leur capital.</Typography>
            <Typography color={defaultSubTextColor} marginTop={3}>Votre part de revenus est garantie par un contrat de cession de revenu dit contrat RBF. Ce contrat est enregistré sur une blockchain est vous est partagé juste après votre achat de briques.</Typography>
          </Box>
            <CustomList
              title="Quels sont les risques"
              titleProps={{ color: defaultTitleColor, variant: "h4", fontWeight: 800, sx: { fontSize: '1.5em'} }}
              wrapperProps={{ marginTop: 8 }}
              items={[
                "Divyz s’engage à reverser chaque mois les revenus locatifs au prorata de l'investissement de chacun de ses membres.",
                "Ce partage de revenu est garanti légalement par le contrat RBF délivré après votre participation au projet financé à 100%.",
                " Les seuls risques liés à votre investissement concernent les vacances locatives ou les mauvais payeurs qui peuvent engendrer un retard\n" +
                "de paiement ou la   mise en pause du versement le temps de trouver une solution.",
                "Par ailleurs, Divyz se prémunit en mettant des assurances de loyers impayés (GLI) sur tous les projets tout en accordant une grande importance\n" +
                "à la validation des dossiers des locataires.",
              ]}
            />
          </Box>
        </ContainerWithLine>
      </ContainerWithLine>
    </Box>
  )
}

export default RBFSection
