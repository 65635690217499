import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase';
import UpdatePasswordForm from './UpdatePasswordForm';
import { Col, Row } from 'react-bootstrap';
import Enroll2FaForm from './Enroll2FaForm';

function Security() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <Row>
      <Col md={6} xs={12}>
        <div className="head_box">
        <UpdatePasswordForm />
        </div>
      </Col>
      <Col md={6} xs={12}>
        <div className="head_box">
        <Enroll2FaForm user={user} />
        </div>
      </Col>
    </Row>
  );
}

export default Security;
