import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { defaultSubTextColor, defaultTitleColor, globalThemeOptions, secondaryTitleColor } from '../../../styles';
import ContainerWithLine from '../../../components/ContainerWithLine';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Box from '@mui/material/Box';
import BoxElement from './BoxElement';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomButton from '../../../components/CustomButton/CustomButton';
import imageHowItWorks1 from '../assets/HowItWorks1.svg';
import imageHowItWorks2 from '../assets/HowItWorks2.svg';
import imageHowItWorks3 from '../assets/HowItWorks3.svg';
import imageHowItWorks4 from '../assets/HowItWorks4.svg';
import ProjectCard from '../../../components/ProjectCard/ProjectCard';
import SendIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';
import { useCustomRouterContext } from '../../../contexts/CustomRouterContext';

function ProjectFeatureSection() {
  const routes = useCustomRouterContext();

  return (
    <Box>
      <BoxWithDiagonaleBackground padding={0} deg={-6} offsetTop={170}>
      <ContainerWithLine maxWidth="lg" sx={{
        pb: { xs: 10, md: 20 },
        pl: { xs: 2, md: 4 },
        pt: { xs: 2, md: 4 },
      }}>
          <ProjectCard images={[]} />

        <Stack sx={{
          marginTop: '2rem',
          flexDirection: 'row',
          [globalThemeOptions.breakpoints.up('xs')]: {
            flexDirection: 'column',
            width: '100%',
          },
          [globalThemeOptions.breakpoints.up('sm')]: {
            flexDirection: 'row',
            width: '100%',

          },
          [globalThemeOptions.breakpoints.up('lg')]: {
            flexDirection: 'row',
          },
        }}>
          <Link to={routes.login.path}>
            <CustomButton variant="contained" my={1} color="primary">Je me connecte</CustomButton>
          </Link>
          <Link to={routes.register.path}>
            <CustomButton variant="outlined" color="secondary" my={1} endIcon={<SendIcon />}>Je crée mon compte</CustomButton>
          </Link>
        </Stack>
        </ContainerWithLine>
      </BoxWithDiagonaleBackground>
    </Box>
  )
}

export default ProjectFeatureSection
