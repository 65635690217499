import { auth } from '../firebase';
import { getFileUrl } from './files';

export function deposit(publicKey, amount) {
  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/${publicKey}/deposit`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ amount: amount.toString() })
  }).then(res => res.json())
    .then(res => console.log(res));
}
/*
export function withdraw(publicKey, amount) {
  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/${publicKey}/withdraw`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, *!/!*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ amount: amount.toString() })
  }).then(res => res.json())
    .then(res => console.log(res));
}*/

export async function withdraw(values) {
  const tokenId = await auth.currentUser.getIdToken(true);

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/lemonway/withdraw`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenId}`,
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
    .then((res) => {
      if (res.type === 'error') {
        throw new Error(res.message);
      }

      return res;
    })
    .then(res => console.log(res));
}

export async function addIban(values) {
  const tokenId = await auth.currentUser.getIdToken(true);

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/lemonway/iban`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenId}`,
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
    .then((res) => {
      if (res.type === 'error') {
        throw new Error(res.message);
      }

      return res;
    })
    .then(res => console.log(res));
}

export async function cardPayment(amount)  {
  const tokenId = await auth.currentUser.getIdToken(true);
  try {
    return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/lemonway/payment`, {
      method: 'POST',
      body: JSON.stringify({ amount: parseInt(amount.toString()) }),
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
      },
    }).then(res => {
      return res.json()
    }).then(res => {
      if (res.type === 'success') {
        window.location.href = res.redirectUrl;
      } else {
        throw new Error(res.message);
      }
    })
      .then(res => console.log(res));
  } catch (e) {
    console.error('error', e);
    throw new Error(e.message);
  }
};

export async function payByBankPayment(amount)  {
  const tokenId = await auth.currentUser.getIdToken(true);
  try {
    return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/lemonway/payment/paybybank`, {
      method: 'POST',
      body: JSON.stringify({ amount: parseInt(amount.toString()) }),
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
      },
    }).then(res => {
      return res.json()
    }).then(res => {
      console.log('response', res, process.env, process.env.REACT_APP_PAYMENT_URL)
      if (res.type === 'success') {

        window.location.href = res.redirectUrl;
      } else {
        throw new Error(res.message);
      }
    })
      .then(res => console.log(res));
  } catch (e) {
    console.error('error', e);
    throw new Error(e.message);
  }
};

export async function bridgeApiPayment(amount)  {
  const tokenId = await auth.currentUser.getIdToken(true);
  try {
    return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/bridgeapi/payment`, {
      method: 'POST',
      body: JSON.stringify({ amount: parseInt(amount.toString()) }),
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
      },
    }).then(res => {
      return res.json()
    }).then(res => {
      console.log('response', res, process.env, process.env.REACT_APP_PAYMENT_URL)
      if (res.type === 'success') {

        window.location.href = res.redirectUrl;
      } else {
        throw new Error(res.message);
      }
    })
      .then(res => console.log(res));
  } catch (e) {
    console.error('error', e);
    throw new Error(e.message);
  }
};

export async function bankWirePayment()  {
  const tokenId = await auth.currentUser.getIdToken(true);
  try {
    return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/lemonway/payment/bankwire/generate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
      },
    }).then(res => {
      return res.json()
    }).then(async (res) => {
      const fileUrl = await getFileUrl(`ibans/${auth.currentUser.uid}/iban-in.pdf`);
      return { ...res, fileUrl }
    })
  } catch (e) {
    console.error('error', e);
    throw new Error(e.message);
  }
};

