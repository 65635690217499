import React, { useState } from 'react';
import { Col, Container, Row, Pagination } from 'react-bootstrap';
import { usePrismicDocumentsByType } from '@prismicio/react';
import BreadCrumb from './BreadCrumb';
import { client } from '../../../prismicClient';

import './styles/main.css';
import './styles/propertyBox.css';
import SkeletonPropertyList from './SkeletonPropertyList';
import { useNavigate } from 'react-router-dom';
import PropertyDetails from './PropertyDetails';
import SkeletonPropertyDetails from './SkeletonPropertyDetails';
import ProjectCard from '@hivest/integration/src/components/ProjectCard/ProjectCard';
import Grid2 from '@mui/material/Unstable_Grid2';
import ProjectCardMini from '@hivest/integration/src/components/ProjectCard/ProjectCardMini';

function PropertyList() {
  const navigate = useNavigate()
  console.log(client)
  // if (!params.propertyId) {
  //   return <>Loading...</>
  // }
  const document = usePrismicDocumentsByType('property', {
    client,
  });

  console.log('--', document)

  const properties = document[0]?.results.filter((d) => d.data.active);
/*

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!loomProvider) {
      return;
    }

    const blockchainAddress = data.blockchain_address

    console.log('DOC', document)

    async function fetchPropertyDetails() {
      const propertyPresale = getPropertySaleContract(loomProvider.web3,
        blockchainAddress
      );

      const metadata = await propertyPresale.methods.metadata().call({
        from: currentUserData.publicKey,
      });
      const pricePerToken = await propertyPresale.methods.pricePerToken().call({
        from: currentUserData.publicKey,
      });

      setPropertyDetails({ metadata, pricePerToken })
    }

    fetchPropertyDetails()
  }, [document, loomProvider])

  useEffect(() => {
    console.log('GOT COMPANY DETAILS', propertyDetails)
  }, [propertyDetails])
*/

  if (!properties) {
    return <></>
    // return <SkeletonPropertyList />
  }

  const renderProperties = properties.map((property) => {
    return (
      <Grid2 md={4} key={property.uid}>
        <ProjectCardMini
          uid={property.uid}
          {...property.data}
        />
      </Grid2>
    )
  })

  console.log(renderProperties)
  let paginationItems = []
  for (let number = 1; number <= document[0]?.total_pages; number++) {
    paginationItems.push(
      <Pagination.Item key={number} active>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <>
      <section className="all_properties">
          <div className="head_toppart">
            {/*<div className="heade_right_part">*/}
            {/*  <span>Filtrer par :</span>*/}
            {/*  <div className="dropdown">*/}
            {/*    <a className="nav-link dropdown-toggle" href="#" id="dropdownMenuButton4" data-bs-toggle="dropdown"*/}
            {/*       aria-expanded="false">Date de lancement</a>*/}
            {/*    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton4">*/}
            {/*      <li><a className="dropdown-item" href="#">Action</a></li>*/}
            {/*      <li><a className="dropdown-item" href="#">Another action</a></li>*/}
            {/*      <li><a className="dropdown-item" href="#">Something else here</a></li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
      </section>

        <Grid2 container>
            {renderProperties.length > 0 ? renderProperties :
              <Col md={4} key={142}>
                <SkeletonPropertyDetails />
              </Col>}
        </Grid2>

      <Pagination>
        {paginationItems}
      </Pagination>
    </>
  );
}

export default PropertyList;
