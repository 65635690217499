import {
  Client, LocalAddress, CryptoUtils, LoomProvider
} from 'loom-js'
import Web3 from 'web3'
import { networks } from '../../config/web3Config';

export default class LoomProviderClass {
  constructor(privateKey) {
    if (!privateKey) {
      this.privateKey = CryptoUtils.generatePrivateKey()
    } else {
      this.privateKey = CryptoUtils.B64ToUint8Array(privateKey)
    }

    this.publicKey = CryptoUtils.publicKeyFromPrivateKey(this.privateKey)
    this.extdevNetworkConfig = networks[process.env.REACT_APP_LOOM_NETWORK]

    this._createClient()
    this._createCurrentUserAddress()
    this._createWebInstance()
  }

  _createClient() {
    const writeUrl = this.extdevNetworkConfig['writeUrl']
    const readUrl = this.extdevNetworkConfig['readUrl']
    const networkId = this.extdevNetworkConfig['chainId']

    this.client = new Client(networkId, writeUrl, readUrl)

    this.client.on('error', msg => {
      console.error('Error on connect to client', msg)
      console.warn('Please verify if loom command is running')
    })
  }

  _createCurrentUserAddress() {
    this.currentUserAddress = LocalAddress.fromPublicKey(this.publicKey).toString()
    // console.log('current private key', CryptoUtils.Uint8ArrayToB64(this.privateKey))
    // console.log('current user address', this.currentUserAddress)
  }

  _createWebInstance() {
    this.web3 = new Web3(new LoomProvider(this.client, this.privateKey))
  }
}
