import React from 'react'

import locationImage from '../../images/location.svg'
import mailImage from '../../images/mail.svg'
import f2prLogo from './styles/f2pr.png'
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';

import './styles/footer.css'
import { routes } from '../../routes';
import { FaFacebook, FaPinterest, FaTwitter } from 'react-icons/fa';
import {Col, Row} from 'react-bootstrap';

function Footer() {
  return (
    <footer>
      <div className="footer_top">
        <div className="container">
          <div className="row">
            <div className="col-md-3 offset-md-3">
              <p className="footer_heading">Information</p>
              <ul>
                <li><Link to={routes.howItWorks.path}>Comment ça fonctionne ?</Link></li>
                <li><Link to={routes.advantages.path}>Nos avantages</Link></li>
                <li><Link to={routes.login.path}>Investir</Link></li>
                <li><Link to={routes.login.path}>Les biens immo</Link></li>
                {/*<li><Link to={routes.aboutUs.path}>À propos</Link></li>*/}
              </ul>
            </div>
            <div className="col-md-2">
              <p className="footer_heading">Confidentialité</p>
              <ul className="contact_icon">
                {/*<li className="mb-5"><a href="#"><span><Image src={locationImage} alt="location_icon" /></span>42 rue*/}
                {/*  Carducci 76019 Paris</a></li>*/}
                {/*<li><Link to={routes.homepage.path}><span><Image src={phoneImage} alt="phone_icon" /></span>01.72.63.12.56</Link></li>*/}
                <li><a href="mailto:support@hivest.io"><span><Image src={mailImage} alt="mail_icon" /></span>support@hivest.io</a></li>
                <li><Link to={routes.cgu.path}>CGU Hivest</Link></li>
                <li><Link to={routes.ml.path}>Mentions légales</Link></li>
              </ul>
            </div>
            <div className="col-md-2 partners">
              <p className="footer_heading">Partenaires</p>
              <ul className="contact_icon">
                <li>
                  <a href="https://www.f2pr.org/" rel="noreferrer" target="_blank">
                    <Image src={f2prLogo} alt="f2pr_logo" width={50} />
                  </a>
                </li>
                {/*<li>*/}
                {/*  Agent de <a href="https://lemonway.com" rel="noreferrer" target="_blank">Lemonway</a> (établissement de paiement dont le siège social est situé au 8, rue du Sentier 75002 Paris, agréé par l’ACPR sous le numéro 16568) inscrit au Registre des agents financiers (<a href="https://www.regafi.fr/" rel="noreferrer" target="_blank">Regafi</a>).*/}
                {/*</li>*/}
              </ul>
            </div>
            {/*<div className="col-md-2 offset-md-2">
                  <p className="footer_heading">Social Media</p>
                  <ul className="social_icon">
                    <li><Link to={routes.homepage.path}><FaFacebook size={32} /></Link></li>
                    <li><Link to={routes.homepage.path}><FaTwitter /></Link></li>
                    <li><Link to={routes.homepage.path}><FaTwitter /></Link></li>
                    <li><Link to={routes.homepage.path}><FaPinterest /></Link></li>
                  </ul>
            </div>*/}
          </div>
        </div>
      </div>
      <Row>
        <Col md={{span: 10, offset: 1}} className="my-2 text-center">
          <p style={{fontSize: '12px'}}>
            <b>AVERTISSEMENT:</b> Nos offres comportent certains risques et en particulier le risque de perte totale ou
            partielle des sommes investies. De plus, les performances passées ne préjugent pas des performances futures,
            ce qui signifie que ce n'est pas parce que notre taux de défaut est de 0% que nous n'aurons jamais d'incident
            sur un immeuble. Si vous avez la moindre question sur les risques associés à nos projets contactez-nous et
            nos équipes prendront le temps de répondre à vos interrogations.
          </p>
        </Col>
      </Row>
      <div className="footer_bottom">
        <p>© 2022 All Rights Reserved.</p>
      </div>
    </footer>
  )
}

export default Footer;
