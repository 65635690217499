import * as React from 'react';
import successCheck from '../assets/successCheck.svg';
import AbstractListElement from './AbstractListElement';

function SuccessListElement({ description }: {description: string }) {
  return (
    <AbstractListElement icon={successCheck} description={description} />
  );
}

export default SuccessListElement
