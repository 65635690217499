import styled from 'styled-components';

const BackgroundStyled = styled.div`
  background-color: ${props => props.backgroundColor || '#f7f7fc'};
  padding:  ${props => props.padding || '10px 0'};
`;

function BackgroundedDiv({ children, backgroundColor, padding, className }) {
  return (
    <BackgroundStyled backgroundColor={backgroundColor} className={className}>
      {children}
    </BackgroundStyled>
  );
}

export default BackgroundedDiv;
