// @ts-ignore
import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { defaultSubTextLighterColor } from '../../styles';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type TextInputProps = TextFieldProps & { showHidePasswordField? : boolean };
function TextInput(props: TextInputProps) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isPassword = props.showHidePasswordField && !showPassword;

  return (
    <TextField
      variant="filled"
      color="secondary"
      type={isPassword ? 'password' : 'text'}
      InputProps={{
        endAdornment: props.showHidePasswordField && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {isPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        marginY: 1,
        '& .MuiInputLabel-root': {
          color: defaultSubTextLighterColor,
        },
        '& .MuiFilledInput-root:before': {
          borderBottomColor: defaultSubTextLighterColor,
        },
        '& .MuiFilledInput-root': {
          backgroundColor: '#F6F6F9',
        },
      }}
      {...props}
    />
  )
}

export default TextInput;
