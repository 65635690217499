import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { TabPanel } from "@mui/lab";
import { Box } from "@mui/material";
import { createCustomEqual } from 'fast-equals';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import Grid from "@mui/material/Grid";
import { PrismicRichText } from '@prismicio/react';
import imagePlayIcon from '../../../assets/images/play-icon.png';


const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

const Map = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName),
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} className="map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

const Project = ({ data }) => {
  const PropertyTabContent = styled('div')({
    padding: '5rem 0',
    h2: {
      color: 'var(--primary)',
      marginBottom: '5rem',
      fontWeight: '800',
    },
    iframe: {
      border: 'none'
    },
    p: {
      fontSize: '1.6rem',
      lineHeight: '2.6rem',
      color: '#6f7486',
      letterSpacing: '0.02em',
      '@media (max-width: 480px)': {
        fontSize: '1.4rem',
      }
    }
  })

  const LeftContent = styled('div')({
    h4: {
      fontSize: '2.4rem',
      lineHeight: '3.3rem',
    }
  })

  const UlCheckPoints = styled('ul')({
    li: {
      background: 'var(--white)',
      border: '1px solid rgba(198, 200, 215, 0.5)',
      boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
      borderRadius: '5px',
      margin: '0 0 6px',
      padding: '1rem 2rem',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      color: 'var(--primary)',
      ':nth-of-type(odd)': {
        background: '#f4fafa',
      },
      '@media (max-width: 480px)': {
        fontSize: '1.4rem'      }
    },
    svg: {
      maxWidth: '2.4rem',
      marginRight: '2.5rem'
    }
  })

  const RightContent = styled('div')({
    h4: {
      fontSize: '2.4rem',
      lineHeight: '3.3rem'
    }
  })

  const VideoBox = styled('div')({
    position: 'relative',
    background: 'rgba(198, 200, 215, 0.15)',
    borderRadius: '5px',
    minHeight: '31.6rem',
    margin: '0 0 3rem',
    boxShadow: '40px 40px 100px rgba(220, 225, 237, 0.5)',
  })

  const PlayIcon = styled('img')({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '5.5rem',
  });

  console.log('foidshighgsdds', data)
  const center = { lat: parseFloat(data.coordinates.latitude), lng: parseFloat(data.coordinates.longitude) };
  const zoom = 13;


  return (
    <TabPanel value="1" sx={{ padding: "0px" }}>
      <PropertyTabContent>
        <h2>Le project</h2>
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
          <Map center={center} zoom={zoom} style={{ width: '100%', height: '30rem'}}>
            <Marker
              position={{ lat: parseFloat(data.coordinates.latitude), lng: parseFloat(data.coordinates.longitude) }} />
          </Map>
        </Wrapper>
        <Box sx={{ marginTop: "8rem" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LeftContent>
                <h4>Presentation</h4>
                <PrismicRichText field={data.informationsText} />
              </LeftContent>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <RightContent>
                <h4>Location</h4>
                <PrismicRichText field={data.informationsText} />
              </RightContent>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: "8rem" }}>
          <Grid
            container
            spacing={6}
            sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LeftContent>
                <h4>Pourquoi devriez-vous investir?</h4>
                <UlCheckPoints>
                  {data.advantages.map((advantage, index) => (
                    <li key={index}>
                      <svg
                        width="26"
                        height="19"
                        viewBox="0 0 26 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.31201 18.3406L0.687012 9.71563L2.52451 7.87812L9.31201 14.6656L23.712 0.265625L25.5495 2.10313L9.31201 18.3406Z"
                          fill="#24F012"
                        />
                      </svg>
                      {advantage.key}
                    </li>
                  ))}
                </UlCheckPoints>
              </LeftContent>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <RightContent>
                <VideoBox>
                  <a href="#">
                    <PlayIcon
                      src={imagePlayIcon}
                      alt="Play Icon"
                    />
                  </a>
                </VideoBox>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  tincidunt posuere auctor. Vestibulum vel risus diam. Nam
                  iaculis ut nibh ut tempus.{" "}
                </p>
              </RightContent>
            </Grid>
          </Grid>
        </Box>
      </PropertyTabContent>
    </TabPanel>
  );
};


const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      a instanceof window.google.maps.LatLng ||
      b instanceof window.google.maps.LatLng
    ) {
      return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  },
);

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[],
) {
  React.useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback]);
}

export default Project;
