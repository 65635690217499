import { Alert, Col, Container, Nav, Row, Table } from 'react-bootstrap';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { useAuthValue } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import humanFormat from 'human-format';
import Skeleton from 'react-loading-skeleton';


function Landing() {
  const { currentUserData, balance } = useAuthValue();
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  return (
    <Row>
      <Col md={12} xs={12}>
        Manage all settings here
      </Col>
    </Row>
  );
}

export default Landing;
