import * as React from 'react'
import Box, { BoxTypeMap } from '@mui/material/Box';
import styled from 'styled-components';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const StyledBoxWithDiagonaleBackground = styled('div')<{
  deg: number,
  color: string,
  overrideColor?: string | number,
  padding: string | number,
  offsetTop: number,
  offsetBottom: number,
}>`

  // @ts-ignore
  --diagonal-box-angle: ${props => props.deg}deg;
  /*-----------------
  Magic Number Alert:
  
  the 0.09719 is calculated by this formula:
  tan(11°) / 2
  11° is the current skewY value.
  ------------------*/
  --diagonal-box-magic-number: 0.09719;
  //--diagonal-box-skew-padding: calc(var(--diagonal-box-width) * var(--diagonal-box-magic-number));
  --diagonal-box-clip-padding: calc(var(--diagonal-box-full-width) * var(--diagonal-box-magic-number));
  

  position: relative;
  padding: var(--diagonal-box-skew-padding) 0;
  margin-top: -1px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: ${props => props.offsetTop}px;
    right: 0;
    bottom: ${props => props.offsetBottom}px;
    transform: skewy(var(--diagonal-box-angle));
    transform-origin: 50% 0;
    outline: 0px solid transparent;
    backface-visibility: hidden;
    background-color: ${props => props.color}
    //background: linear-gradient(-135deg, #ff0084, #33001b);
  }

  & .content {
    max-width: var(--diagonal-box-width);
    margin: 0 auto;
    padding: ${props => props.padding};
    position: relative;

    /* -----------
    enable the border to see, that the content
    perfectly fits into the section withou
    bleeding into the adjecting areas:
    ------------ */
    //border: 2px dashed #fff8;
  }
`

function BoxWithDiagonaleBackground({
// @ts-ignore
  children,
  padding= 0,
  deg= -6,
  offsetTop = 0,
  offsetBottom = 0,
  color= 'rgb(198, 200, 215, 0.15)',
  overrideColor = null,
  ...props
}) {
  return (
    <StyledBoxWithDiagonaleBackground deg={deg} color={overrideColor ? overrideColor : color} padding={padding} offsetTop={offsetTop} offsetBottom={offsetBottom}>
      <div className="content">
      {children}
      </div>
    </StyledBoxWithDiagonaleBackground>
  )
}

export default BoxWithDiagonaleBackground as OverridableComponent<BoxTypeMap<{
  deg?: number,
  padding?: string | number,
  color?: string,
  overrideColor?: string | number | null,
  offsetTop?: number,
  offsetBottom?: number,
}, "div">>

