import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase';

export function uploadFile(path, data, documentType) {
  const fileRef = ref(storage, path);

  return uploadBytes(fileRef, data, {
    contentType: data.type,
    customMetadata: {
      documentType,
    },
  })
    .then((snapshot) => {
      console.log('Uploaded a blob or file!', snapshot);
      return snapshot;
    })
    .catch((error) => {
      console.log('error', error);
    });
}

export function getFileUrl(path) {
  const fileRef = ref(storage, path);

  return getDownloadURL(fileRef)
    .catch((error) => {
      console.log('error', error);
    });
}
