import * as React from 'react';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { common } from '@mui/material/colors';
import { buttonPrimaryBackgroundColor } from '../../../components/CustomButton/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import buildingsBackground from '../../../assets/images/buildings-background.svg';

function TalkToSalesSection() {
  return (
    <Box position="relative">
      <BoxWithDiagonaleBackground
        deg={6}
        offsetTop={0}
        offsetBottom={0}
        color={buttonPrimaryBackgroundColor}
      >
        <Box
          sx={{
            backgroundImage: `url(${buildingsBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '0 4em',
            width: '100%',
            height: 827,
          }}
        >
        <Container sx={{
          paddingTop: { xs: 8, md: 16 },
        }}>
          <Grid2 container>
            <Grid2 xs={12} lg={7} md={8}>
              <TypographyUnderlined
                variant="h2"
                overrideDividerColor={common.white}
                fontWeight={800}
                fontSize={'3em'}
                lineHeight="1.35em"
                color={common.white}
                wrapperSX={{
                  paddingLeft: (theme) => theme.spacing(2),
                  paddingRight: (theme) => theme.spacing(4),
                  paddingBottom: theme => theme.spacing(8)
                }}
              >Une question sur Divyz ou sur nos projets?</TypographyUnderlined>
            </Grid2>
          </Grid2>
          <Grid2 container sx={{
            paddingRight: { xs: 2, md: 0 },
          }}>
            <Grid2 lg={3} md={4} xs={12} sx={{
              paddingY: { xs: 2, md: 0 },
              marginRight: { xs: 0, md: 2 },
            }}>
              <CustomButton variant="contained" color="secondary" width={{ xs: '100%', md: 'auto' }}>Contactez-nous</CustomButton>
            </Grid2>
            <Grid2 lg={6} md={6} xs={12}>
              <CustomButton variant="outlined" width={{ xs: '100%', md: 'auto' }}>Prendre RDV avec nos experts</CustomButton>
            </Grid2>
          </Grid2>
        </Container>
        </Box>
      </BoxWithDiagonaleBackground>
    </Box>
  )
}

export default TalkToSalesSection
