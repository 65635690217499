import { Wrapper, Status } from "@googlemaps/react-wrapper";
import React, { useEffect, useRef, useState } from 'react';
import { createCustomEqual } from "fast-equals";

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

const Map = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} className="map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

function Infos({ data }) {
  const center = { lat: parseFloat(data.coordinatesLatitude), lng: parseFloat(data.coordinatesLongitude) };
  const zoom = 13;

  return (
    <>
      <h2 className="heading"><span></span>Infos</h2>
      <div className="map_wrap">
        <p>Localisation</p>

        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
          <Map center={center} zoom={zoom}>
            <Marker position={{ lat: parseFloat(data.coordinatesLatitude), lng: parseFloat(data.coordinatesLongitude) }} />
          </Map>
        </Wrapper>
          {/*<iframe className="responsive-iframe"*/}
          {/*        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d122662.13236036143!2d-0.4530630930974922!3d49.17998345967188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480a43394d191f4d%3A0x48c0ab497251c743!2s166%20Rue%20du%20G%C3%A9n%C3%A9ral%20Moulin%2C%2014000%20Caen%2C%20France!5e0!3m2!1sen!2sin!4v1651142899978!5m2!1sen!2sin"*/}
          {/*        style="border:0;" allowfullscreen="" loading="lazy"*/}
          {/*        referrerpolicy="no-referrer-when-downgrade"></iframe>*/}
      </div>
      <div className="row table_wrap">
        <div className="col-md-6">
          <div className="table_left_side">
            <h3>Informations</h3>
            <p>Extension incluse dans le prix de vente à réaliser (3 chambres supplémentaires) : - gros œuvre en
              Mai-Juin (avec maison occupée par locataire) - second œuvre extension et modifications dans la maison
              existante en juillet-aout (avec maison inoccupée par les locataires) Pas de locataires en juillet et aout
              2022.</p>
            <div className="description_table">
              <h3>Description du bien</h3>
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                  <tr>
                    <td>Type de bien</td>
                    <td>appartement</td>
                  </tr>
                  <tr>
                    <td>Surface au sol</td>
                    <td>275 m²</td>
                  </tr>
                  <tr>
                    <td>Surface carrez</td>
                    <td>275 m²</td>
                  </tr>
                  <tr>
                    <td>Nombre d'étages</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>Pièces</td>
                    <td>16</td>
                  </tr>
                  <tr>
                    <td>Chambres</td>
                    <td>9</td>
                  </tr>
                  <tr>
                    <td>Salles de bains</td>
                    <td>6</td>
                  </tr>
                  <tr>
                    <td>WC</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Sous-sol/Cave</td>
                    <td>Non</td>
                  </tr>
                  <tr>
                    <td>Parking</td>
                    <td>Non</td>
                  </tr>
                  <tr>
                    <td>Terrasse</td>
                    <td>Oui</td>
                  </tr>
                  <tr>
                    <td>Balcon</td>
                    <td>Non</td>
                  </tr>
                  <tr>
                    <td>Chauffage</td>
                    <td>Gaz</td>
                  </tr>
                  <tr>
                    <td>Fenêtres</td>
                    <td>Double vitrage</td>
                  </tr>
                  <tr>
                    <td>Sol(s)</td>
                    <td>Parquet, Carrelage</td>
                  </tr>
                  <tr>
                    <td>Exposition</td>
                    <td>Ouest</td>
                  </tr>
                  <tr>
                    <td>Copropriété</td>
                    <td>Non</td>
                  </tr>
                  <tr>
                    <td>Taxe foncière</td>
                    <td>1300 €/an</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="table_right_side">
            <h3>Informations</h3>
            <div className="rigth_top_box">
              <p>Performance énergétique DPE <br />Indice d'émission de gaz à effet de serre (GES)</p>
              <div className="diagnostics_table">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                    <tr>
                      <td>Installation électrique</td>
                      <td>Conforme</td>
                    </tr>
                    <tr>
                      <td>Installation de gaz</td>
                      <td>Conforme</td>
                    </tr>
                    <tr>
                      <td>Plomb</td>
                      <td>Conforme</td>
                    </tr>
                    <tr>
                      <td>Amiante</td>
                      <td>Conforme</td>
                    </tr>
                    <tr>
                      <td>Mérule</td>
                      <td>Conforme</td>
                    </tr>
                    <tr>
                      <td>ERP</td>
                      <td>Conforme</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <h3>Rapport d’état</h3>
            <div className="rigth_bottom_box">
              <div className="diagnostics_table">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                    <tr>
                      <td>Chambres</td>
                      <td>État neuf</td>
                    </tr>
                    <tr>
                      <td>Cuisine</td>
                      <td>État neuf</td>
                    </tr>
                    <tr>
                      <td>Salles de bain</td>
                      <td>État neuf</td>
                    </tr>
                    <tr>
                      <td>Toilettes</td>
                      <td>État neuf</td>
                    </tr>
                    <tr>
                      <td>Salon</td>
                      <td>État neuf</td>
                    </tr>
                    <tr>
                      <td>Plomberie</td>
                      <td>État neuf</td>
                    </tr>

                    <tr>
                      <td>Extérieur</td>
                      <td>État neuf</td>
                    </tr>
                    <tr>
                      <td>Garage</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>Grenier</td>
                      <td>Bon état</td>
                    </tr>
                    <tr>
                      <td>Sous-sol/Cave</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>Jardin</td>
                      <td>État neuf</td>
                    </tr>
                    <tr>
                      <td>Structure</td>
                      <td>Bon état</td>
                    </tr>
                    <tr>
                      <td>Toiture</td>
                      <td>Bon état</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      a instanceof window.google.maps.LatLng ||
      b instanceof window.google.maps.LatLng
    ) {
      return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback]);
}

// window.addEventListener("DOMContentLoaded", () => {
//   ReactDom.render(<App />, document.getElementById("root"));
// });

export default Infos;
