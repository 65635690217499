import './styles/main.css'
import AbstractDiagnostic from './AbstractDiagnostic';

function GES({ value }) {
  return (
    <AbstractDiagnostic
      className="ges-diag"
      title="Indice d'émission de gaz à effet de serre (GES)"
      value={value}
      ranges={[
        5,
        10,
        20,
        35,
        55,
        80,
        999
      ]}
      unit="kg CO₂/m².an"
      />
  );
}

export default GES;
