export const MontageData = [
  {
    Chambre: "Chambre 1",
    Profil: "Etudiant",
    Paiment: "-",
    Loyer: "410€",
    Charges: "40€",
    LoyerCC: "450€",
    TypedeBail: "Meubie",
    DebitBail: "01/10/2023",
    Renouvellement: "-",
    Garant: "-",
  },
  {
    Chambre: "Chambre 1",
    Profil: "Etudiant",
    Paiment: "-",
    Loyer: "410€",
    Charges: "40€",
    LoyerCC: "450€",
    TypedeBail: "Meubie",
    DebitBail: "01/10/2023",
    Renouvellement: "-",
    Garant: "-",
  },
  {
    Chambre: "Chambre 1",
    Profil: "Etudiant",
    Paiment: "-",
    Loyer: "410€",
    Charges: "40€",
    LoyerCC: "450€",
    TypedeBail: "Meubie",
    DebitBail: "01/10/2023",
    Renouvellement: "-",
    Garant: "-",
  },
  {
    Chambre: "Chambre 1",
    Profil: "Etudiant",
    Paiment: "-",
    Loyer: "410€",
    Charges: "40€",
    LoyerCC: "450€",
    TypedeBail: "Meubie",
    DebitBail: "01/10/2023",
    Renouvellement: "-",
    Garant: "-",
  },
];
