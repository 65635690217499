import { Checkbox, CheckboxProps } from '@mui/material';
import React from 'react';
import { buttonPrimaryBackgroundColor } from '../CustomButton/styles';

type CustomCheckboxProps = CheckboxProps
function CustomCheckbox(props: CustomCheckboxProps) {
  return (
    <Checkbox
      {...props}
      sx={{
        '&.Mui-checked': { color: buttonPrimaryBackgroundColor },
        ...props.sx
      }}
    />
  );
}

export default CustomCheckbox;

