import React from 'react'
import { styled } from '@mui/system';
const SocialCards = ({ cardType, cardImage, socialColor, socialName,width }) => {

  const SocialCard = styled('div')({
    width: '100%',
    height: '10rem',
    borderRadius: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 1.9rem 0px 1.9rem',
    cursor: 'pointer',
    '@media only screen and (max-width: 767px)' : {
        width: '100%'
    }
  });
  
  const SocialCardImg = styled('img')({
    verticalAlign: 'middle',
    marginRight: '1rem',
  });
  
  const Para1 = styled('p')({
    fontSize: '1.6rem',
    lineHeight: '2.6rem',
    fontWeight: 400,
    color: 'var(--white)',
  });
  
  const Para2 = styled('p')({
    fontSize: '2.4rem',
    lineHeight: '3.3rem',
    fontWeight: 800,
    color: 'var(--white)',
  });
  return (
    <SocialCard style={{ backgroundColor: [socialColor] }}>
      <div>
        <Para1>Partager sur</Para1>
        <Para2> {socialName}</Para2>
      </div>
      <div>
        <SocialCardImg src={cardImage} alt="socialCard" style={{width:width}} />
      </div>
    </SocialCard>

  );
};

export default SocialCards;
