import React from 'react';

function AbstractIcon({ size, Wrapper, Icon }) {
  return (
    <Wrapper>
      <Icon size={size || 12} />
    </Wrapper>
  )
}

export default AbstractIcon
