function AbstractDiagnostic({ title, value, unit, className, ranges }) {
  let renderDiagnostic = []

  renderDiagnostic = [];
  ranges.reduce((previousValue, currentValue) => {
    const indexOf = ranges.indexOf(currentValue);
    if (value > previousValue && currentValue >= value) {
      renderDiagnostic.push(
        <div className={`cell new level-${indexOf+1} focus`} key={indexOf+1}>
          <div className="diag-letter">{String.fromCharCode('A'.charCodeAt(0)+indexOf).toUpperCase()}</div>
          <div className="score"><span className="value">{value}</span><span
            className="unit">{unit}</span></div>
        </div>
      )
      return currentValue;
    }

    renderDiagnostic.push(<div className={`cell new level-${indexOf+1}`}></div>)

    return currentValue
  }, 0)

  return (
    <>
      <p className="mt-2 mb-2 fs-sm">{title}</p>
      <div className={`mb-2 diagnostics ${className}`}>
        {renderDiagnostic}
      </div>
    </>
  );
}

export default AbstractDiagnostic;
