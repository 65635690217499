import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper';

const SliderWrapper = styled('div')`
  
--swiper-navigation-color: #ffffff;
--swiper-navigation-size: 15px;
--swiper-pagination-color: #ffffff;

`;

function Slider({ images, featured_image }: { images: string[] | React.ReactElement[]; featured_image: string | React.ReactElement }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const imagesToRender = [
    ...images,
  ]
    .map((imageToRender, i) => {
      return (
        <SwiperSlide key={i}>
          {imageToRender}
        </SwiperSlide>
      );
    });

  return (
    <SliderWrapper>
      <div className="main_left_slider">
        <Swiper
          centeredSlides={true}
          spaceBetween={10}
          navigation={true}
          loop={true}
          thumbs={{
           // @ts-ignore
            swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null
        }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper_main"
        >
          {imagesToRender}
        </Swiper>
        <Swiper
          // @ts-ignore
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={3}
          loop={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper_thumb"

          style={{
            // @ts-ignore
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }}
        >
          {imagesToRender}
        </Swiper>
      </div>
    </SliderWrapper>
  );


}

export default Slider;
