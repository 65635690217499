import React from 'react';
import { Field, Form, Formik } from 'formik';
import PersonalInfosSchema from './schemas/PersonalInfosSchema';
import { Col, FormGroup, FormSelect, Row } from 'react-bootstrap';
import KYCIndividual from '../../../components/KYC/KYCIndividual';
import CountryField from '../../../components/KYC/CountryField';
import DateOfBirthField from '../../../components/KYC/DateOfBirthField';
import { errorMessageRender, onSubmitAdminKYC, validateName, validateUrl } from './helpers';
import { useAuthValue } from '../../../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../routes';
import { adminKycCompanyRegister, kycCompanyRegister } from '../../../actions/user';
import CompanyInfosSchema from './schemas/CompanyInfosSchema';
import { adminKycProjectCompanyRegister } from '../../../actions/projects';

function AdminKYCCompanyInfoPage() {
  const { type, propertyId } = useParams()
  const navigate = useNavigate()
  const { currentUser, currentUserData } = useAuthValue()
  console.log(currentUser, currentUserData)
  return (
    <>
      <Formik
        initialValues={{
          company: {
            name: '',
            description: '',
            websiteUrl: '',
            identificationNumber: '',
          },
          // ...currentUserData.kyc
        }}
        validationSchema={CompanyInfosSchema}
        onSubmit={onSubmitAdminKYC({
          propertyId,
          currentUserData,
          currentUser,
          navigate,
          nextStep: { path: generatePath(routes.adminKycUpload.path, { type, propertyId }), step: 1 }
        }, adminKycProjectCompanyRegister(propertyId))}
      >
        {({
          values,
          errors,
          touched,
          isValidating,
          setFieldValue,
          initialValues,
          handleSubmit
        }) => (
          <div className="container">
            {console.log(errors)}
            <Col sm={12}>
              <Form onSubmit={handleSubmit}>
                <div className="mt-4">
                  <Row className="mb-3">
                    <Col sm={5}>
                      <FormGroup>
                        <label htmlFor="company.name">Nom</label>
                        <Field
                          name="company.name"
                          type="text"
                          placeholder="Hivest SAS"
                          className={`form-control ${
                            touched.company?.name && errors.company?.name ? 'is-invalid' : ''
                          }`}
                        />
                        {touched.company?.name && errors.company?.name && errorMessageRender(errors.company.name)}
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                          <label htmlFor="company.websiteUrl">Url de votre site web</label>
                          <Field
                            name="company.websiteUrl"
                            type="text"
                            placeholder="https://hivest.immmo"
                            className={`form-control ${
                              touched.company?.websiteUrl && errors.company?.websiteUrl ? 'is-invalid' : ''
                            }`}
                            validate={validateUrl}
                          />
                          {touched.company?.websiteUrl && errors.company?.websiteUrl && errorMessageRender(errors.company.websiteUrl)}
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup>
                          <label htmlFor="company.identificationNumber">Numeéro d'identification</label>
                          <Field
                            name="company.identificationNumber"
                            type="text"
                            placeholder="336.."
                            className={`form-control ${
                              touched.company?.identificationNumber && errors.company?.identificationNumber ? 'is-invalid' : ''
                            }`}
                            validate={validateName}
                          />
                          {touched.company?.identificationNumber && errors.company?.identificationNumber && errorMessageRender(errors.company.identificationNumber)}
                        </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <FormGroup>
                        <label htmlFor="company.description">Description de société</label>
                        <Field
                          name="company.description"
                          type="text"
                          placeholder="Ecommerce, vente de produits..."
                          className={`form-control ${
                            touched.company?.description && errors.company?.description ? 'is-invalid' : ''
                          }`}
                        />
                        {touched.company?.description && errors.company?.description && errorMessageRender(errors.company.description)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="form-group float-end">
                    <button className="btn btn-primary" type="submit">
                      Suivant
                    </button>
                  </div>
                </div>
              </Form>
            </Col>
          </div>
        )}
      </Formik>
    </>
  );
}

export default AdminKYCCompanyInfoPage;
