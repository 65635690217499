import * as React from 'react';
import Hero from './Hero';
import RBFSection from './RBFSection';
import TalkToSalesSection from './TalkToSalesSection/TalkToSalesSection';
import WeDoAllForYou from '../../components/WeDoAllForYou/WeDoAllForYou';

function FeesPage() {
  console.log('otrihdsoighsdoih')
  return (
    <>
      <Hero />
      <RBFSection />
      <WeDoAllForYou />
      <TalkToSalesSection />
    </>
  );
}

export default FeesPage;
