import { Typography } from '@mui/material';
import { buttonPrimaryBackgroundColor } from '../../components/CustomButton/styles';
import React from 'react';
import ContainerWithLine from '../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import { defaultSubTextColor, secondaryTitleColor } from '../../styles';
import rbfImage from './assets/rbf.png';

function Hero() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Typography variant="subtitle1" sx={{
          color: buttonPrimaryBackgroundColor,
        }}>Les frais Divyz</Typography>
        <TypographyUnderlined variant="h1" fontWeight={800} fontSize={'3em'} color={secondaryTitleColor} wrapperSX={{
          paddingY: (theme) => theme.spacing(2),
        }}>Les frais de Divyz</TypographyUnderlined>
        <Typography variant="h3" color={secondaryTitleColor} fontWeight={500} marginY={4}>Le modèle Divyz est basé sur le RBF “Revenue Based Financing” qu’on peut traduire en “partage de revenus”.</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box component="img" src={rbfImage} alt="spread" sx={{ marginY: 4, width: '70%' }} />
        </Box>
        {/*<Typography color={defaultSubTextColor} fontSize="0.95em">Post emensos insuperabilis expeditionis eventus languentibus partium animis, quas periculorum varietas fregerat et laborum, nondum tubarum cessante clangore vel milite locato per stationes hibernas, fortunae saevientis procellae tempestates alias rebus infudere communibus per multa illa et dira facinora Caesaris Galli, qui ex squalore imo miseriarum in aetatis adultae primitiis ad principale culmen insperato saltu provectus ultra terminos potestatis delatae procurrens asperitate nimia cuncta foedabat. propinquitate enim regiae stirpis gentilitateque etiam tum Constantini nominis efferebatur in fastus, si plus valuisset, ausurus hostilia in auctorem suae felicitatis, ut videbatur.</Typography>*/}

      </ContainerWithLine>
    </Box>
  )
}

export default Hero
