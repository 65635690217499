import { Tab } from 'react-bootstrap';
import chartImage from '../images/chart.png';

function Simulator(data) {
  return (
    <>
      <h2 className="heading"><span></span> Simulation sur 25 ans</h2>
      <div className="chart">
        <div className="chart_wrap">
          <img src={chartImage} alt="simulation chart" />
        </div>
      </div>
    </>
  )
}

export default Simulator
