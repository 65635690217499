import * as React from 'react';
import { Typography } from '@mui/material';
import { defaultSubTextColor, defaultTitleColor, globalThemeOptions, secondaryTitleColor } from '../../../styles';
import ContainerWithLine from '../../../components/ContainerWithLine';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Box from '@mui/material/Box';
import BoxElement from './BoxElement';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomButton from '../../../components/CustomButton/CustomButton';
import imageHowItWorks1 from '../assets/HowItWorks1.svg';
import imageHowItWorks2 from '../assets/HowItWorks2.svg';
import imageHowItWorks3 from '../assets/HowItWorks3.svg';
import imageHowItWorks4 from '../assets/HowItWorks4.svg';
import ProjectCard from '../../../components/ProjectCard/ProjectCard';

function ProjectFeatureSection() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,

          [globalThemeOptions.breakpoints.up('sm')]: {
            paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(1),
          },
          [globalThemeOptions.breakpoints.up('md')]: {
            paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
          },
        }}>
        </ContainerWithLine>
        <Grid2 container paddingTop={4} spacing={4} md={10} paddingBottom={8}>
          <Grid2>
            <TypographyUnderlined
              variant="h2"
              fontWeight={800}
              fontSize={'3em'}
              lineHeight="1.35em"
              color={secondaryTitleColor}
              wrapperSX={{
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >Soyez informé en avant première des pépites sur Divyz</TypographyUnderlined>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
      <BoxWithDiagonaleBackground padding={0} deg={6} offsetTop={70}>
      <ContainerWithLine maxWidth="lg" sx={{
        pb: { xs: 10, md: 20 },
        pl: { xs: 2, md: 4 }
      }}>
          <ProjectCard images={[]} />
        </ContainerWithLine>
      </BoxWithDiagonaleBackground>
    </Box>
  )
}

export default ProjectFeatureSection
