import * as React from 'react';
import { Stack, ThemeProvider, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { defaultTextColor, defaultTitleColor, globalThemeOptions } from '../../styles';
import CustomButton from '../../components/CustomButton/CustomButton';
import SendIcon from '@mui/icons-material/ArrowRightAlt';
import heroBuildingImage from './assets/hero-building.svg';
import heroBuildingMobileImage from './assets/hero-building-mobile.png';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import ContainerWithLine from '../../components/ContainerWithLine';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';

const theme = createTheme(globalThemeOptions, {
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: defaultTitleColor,
          fontSize: '5.5rem',
          fontWeight: 800,
          letterSpacing: '-0.02em',
          marginBottom: globalThemeOptions.spacing(4),

          [globalThemeOptions.breakpoints.up('sm')]: {
            lineHeight: '1.25em',
          },
        },
        h2: {
          color: defaultTextColor,
          fontWeight: 500,
          fontSize: '1.5rem',
          lineHeight: '2.25rem',
        },
      },
    },
  },
});

function HeroSection() {
  const routes = useCustomRouterContext();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        backgroundImage: `url('${heroBuildingImage}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundSize: 'cover',
        display: { sx: 'none', sm: 'flex' },

        [globalThemeOptions.breakpoints.down('sm')]: {
          backgroundImage: 'none',
        },
        [globalThemeOptions.breakpoints.up('sm')]: {
          minHeight: '110vh',
        },
      }}>
      <ContainerWithLine disableGutters sx={{

        [globalThemeOptions.breakpoints.up('xs')]: {
          paddingLeft: 3,
          paddingBottom: 4,
        },
        [globalThemeOptions.breakpoints.up('md')]: {
          paddingLeft: 6,
          paddingBottom: 12,
          minHeight: '110vh',
        },
        [globalThemeOptions.breakpoints.up('xl')]: {
          paddingTop: 8,
          paddingBottom: 80,
        },
        position: 'left',
      }}>
        <Stack direction="column" sx={{
          [globalThemeOptions.breakpoints.up('xs')]: {
            paddingX: 1
          },
          [globalThemeOptions.breakpoints.up('sm')]: {
            width: '60%',
          },
          [globalThemeOptions.breakpoints.up('md')]: {
            width: '55%',
          },
          [globalThemeOptions.breakpoints.up('lg')]: {
            width: '60%',
          }
        }}>
          <Typography variant="h1">Lancez-vous dans l'immobilier de partage de loyer</Typography>
          <Typography variant="h2" fontSize="1.25em"><b>Recevez des loyers</b> des projets immobiliers dans lesquels vous investissez dès 1000€ .</Typography>
        </Stack>
        <Box sx={{
          display: { xs: 'flex', sm: 'none' },
          backgroundImage: `url('${heroBuildingMobileImage}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          backgroundSize: 'cover',
          width: '430px',
          height: '395px',
          position: 'relative',
          right: 0,
        }} />
        <Stack spacing={2} sx={{
          marginTop: '5rem',
          flexDirection: 'row',
          [globalThemeOptions.breakpoints.up('xs')]: {
            flexDirection: 'column',
            width: '100%',
          },
          [globalThemeOptions.breakpoints.up('sm')]: {
            flexDirection: 'column',
            width: '55%'
          },
          [globalThemeOptions.breakpoints.up('lg')]: {
            flexDirection: 'row',
          },
        }}>
          <Link to={routes.login.path}>
            <CustomButton variant="contained" color="primary">J'investis dès 1000€</CustomButton>
          </Link>
          <Link to={routes.howItWorks.path}>
            <CustomButton variant="text" endIcon={<SendIcon />}>En savoir plus</CustomButton>
          </Link>
        </Stack>
      </ContainerWithLine>
      </Box>
    </ThemeProvider>
  )
}

export default HeroSection
