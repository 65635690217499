import { createClient } from '@prismicio/client'
const endPoint = process.env.REACT_APP_PRISMIC_URL;

export const client = createClient(endPoint);

export const linkResolver = (doc) => {
  switch (doc.type) {
    case 'property':
      return '/property' + doc.uid;
    default:
      return '/';
  }
}
