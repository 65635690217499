import { TabPanel } from "@mui/lab";
import { Box } from "@mui/material";
import React from "react";
import { styled } from '@mui/system';
import Grid from "@mui/material/Grid";
import { revenusData } from "../../../utils/Revenus";
const Revenus = ({ data }) => {

  const PropertyTabContent = styled('div')({
    padding: '5rem 0',
    h2: {
      color: 'var(--primary)',
      marginBottom: '5rem',
      fontWeight: '800',
    },
    iframe: {
      border: 'none'
    },
    p: {
      fontSize: '1.6rem',
      lineHeight: '2.6rem',
      color: '#6f7486',
      letterSpacing: '0.02em',
      '@media (maxWidth: 480px)': {
        fontSize: '1.4rem',
      }
    }
  })

  const HeadingContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    paddingBottom: '3rem',
    '::before': {
      content: '""',
      position: 'absolute',
      left: '-3.8rem',
      bottom: 0,
      borderBottom: '1px dashed #b8cce0',
      width: '100vw',
    },
    h2: {
      margin: 0,
    }
  })

  const Title = styled('div')({
    p: {
      margin: '5px 0 0',
    }
  })

  const AcquisitionContent = styled('div')({
    marginTop: '8rem',
    '@media (maxWidth: 767px)': {
      marginTop: '3rem',
    },
    h4: {
      fontSize: '2.4rem',
      lineHeight: '3.2rem',
      color: 'var(--text-color)',
      marginBottom: '3.4rem',
    },
    p: {
      '@media (max-width: 360px)': {
        fontSize: '1.1rem',
      }
    }
  })

  const AcquisitionBox = styled(Box)({
    background: '#ffffff',
    border: '1px solid rgba(198, 200, 215, 0.5)',
    boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
    borderRadius: '5px',
    marginBottom: '6px',
    ':hover': {
      background: '#f4fafa',
    },
    'p:hover' : {
        fontWeight:'bold',
        color: '#4A5064'
    }
  })

  const PriceGrid = styled(Grid)({
    p: {
      color: 'var(--secondary)',
      textAlign: 'center',
    }
  })

    const StatusGrid = styled(Grid)({
    p: {
      color: '#24f012',
      textAlign: 'center',
    }
  })

  const TableValue = styled('div')({
    fontSize: '1.6rem',
    lineHeight: '2rem',
    fontWeight: 600,
    color: 'var(--primary)',
    padding: '1.3rem 2rem',
    borderRight: '1px solid rgba(198, 200, 215, 0.5)',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (maxWidth: 480px)': {
      padding: '1.3rem 1rem',
    },
    svg: {
      maxWidth: '2.4rem',
      marginRight: '1.3rem',
      verticalAlign: 'top',
    }
  })

  const Revenu = styled('div')`
    h2 {
    font-size: 9rem;
    line-height: 10.2rem;
    color: var(--secondary);
    font-weight: 500;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 5rem;
      line-height: 6.2rem;
    }
}
  `
  return (
    <TabPanel value="3" sx={{ padding: "0px" }}>
      <PropertyTabContent>
        <HeadingContent>
          <Title>
            <h2>Revenus</h2>
            <p>Estimes/an</p>
          </Title>
          <Revenu>
            <h2 style={{color: '#2cd4d9'}}>{data.cashflow_net}%</h2>
          </Revenu>
        </HeadingContent>
        <AcquisitionContent>
          <h4>Acquisition</h4>
          {revenusData.acquisition.map((item, i) => (
            <AcquisitionBox key={i}>
              {
                <Grid container>
                  <Grid item xs={6} sm={6} md={8} lg={8}>
                    <TableValue>
                      <p>{item.label}</p>
                    </TableValue>
                  </Grid>
                  <StatusGrid
                    xs={3}
                    sm={3}
                    md={2}
                    lg={2}
                  >
                    <TableValue>
                      <p>{item.value}</p>
                    </TableValue>
                  </StatusGrid>

                  <PriceGrid item xs={3} sm={3} md={2} lg={2}>
                    <TableValue style={{border: 'none'}}>
                      <p>{item.price}</p>
                    </TableValue>
                  </PriceGrid>
                </Grid>
              }
            </AcquisitionBox>
          ))}
        </AcquisitionContent>
      </PropertyTabContent>
    </TabPanel>
  );
};

export default Revenus;
