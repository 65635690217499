import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { defaultSubTextColor } from '../../../styles';

function AbstractListElement({ icon, description }: {icon: string, description: string }) {
  return (
    <Box sx={{
      '& img': {
        verticalAlign: 'sub'
      }
    }}>
      <img src={icon} alt="success" />
      <Typography ml={1} component="span" color={defaultSubTextColor} fontWeight={500} fontSize="1em" lineHeight={3.5}>{description}</Typography>
    </Box>
  );
}

export default AbstractListElement
