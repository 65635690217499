import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { common } from '@mui/material/colors';
import { Paper, Stack, Typography } from '@mui/material';
import ContainerWithLine from '../../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import { defaultSubTextColor, defaultTitleColor } from '../../../styles';
import Card from '@mui/material/Card';

// @ts-ignore
function BasicCard({ title, icon, children, ...props }) {
  return (
    <Grid2
      // alignContent="center"
      md={6}
      sx={{
        borderRadius: 0,
        color: common.white,
        // backgroundColor: whire,
      }}
      {...props}
    >
      <Card sx={{
        borderRadius: 0,
        padding: (theme) => theme.spacing(4),
        margin: (theme) => theme.spacing(2),
      }}>
      <Box component="img" src={icon} sx={{ width: '50px', height: '50px' }} />
        <Typography color={defaultTitleColor} fontWeight={800} fontSize={'1.5em'} sx={{ paddingY: 2 }}>{title}</Typography>
        <Typography color={defaultSubTextColor}>{children}</Typography>
      </Card>
    </Grid2>
  );
}

export default BasicCard;
