export const RoomData = [
  {
    imageName: " Document-no-01.pdf",
    value: "Label",
  },
  {
    imageName: " Document-no-01.pdf",
    value: "Label",
  },
  {
    imageName: " Document-no-01.pdf",
    value: "Label",
  },
  {
    imageName: " Document-no-01.pdf",
    value: "Label",
  },
];
