import React, { useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { routes } from '../../../routes';
import { TdDanger, TdSuccess, TdWarning } from './styled';
import { fromWei, getPropertySaleContract } from '../../../utils/blockchain';
import { useAuthValue } from '../../../contexts/AuthContext';
import CustomCountdown from '../../../components/CustomCountdown/CustomCountdown';
import { doc, getDoc, addDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

function ProjectDetail({ document }) {
  const { loomProvider, currentUserData } = useAuthValue();
  const [openingTime, setOpeningTime] = useState(null);
  const [kycStatus, setKYCStatus] = useState(null);
  const [blockchainStatus, setBlockchainStatus] = useState(null);
  const [pricePerToken, setPricePerToken] = useState(null);
  const { data } = document;

  console.log(document);

  function renderStatus(status, extraMessage = null) {
    switch (status) {
      case -1:
        return <TdDanger>Not Verified {extraMessage}</TdDanger>;
      case 0:
        return <TdWarning>Partially verified {extraMessage}</TdWarning>;
      case 1:
        return <TdSuccess>Verified</TdSuccess>;
      default:
        return <TdDanger>Loading...</TdDanger>;
    }
  }

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!loomProvider) {
      return;
    }

    if (!data.blockchain_address) {
      setKYCStatus(-1);
      return;
    }

    async function fetchPropertyDetails() {
      try {
        const propertyPresale = getPropertySaleContract(loomProvider.web3,
          data.blockchain_address,
        );

        const metadata = await propertyPresale.methods.metadata().call({
          from: currentUserData.publicKey,
        });
        console.log(metadata);
        const pricePerToken = await propertyPresale.methods.pricePerToken().call({
          from: currentUserData.publicKey,
        });

        setOpeningTime(new Date(metadata.openingTime * 1000));
        setPricePerToken(fromWei(pricePerToken));
        setBlockchainStatus(metadata ? 1 : -1);
      } catch (e) {
        console.log(e);
        setBlockchainStatus(-1);
      }
    }

    async function fetchKycStatus() {
      console.log('---fetchkyc', document.uid)
      const docRef = doc(db, 'projects', document.uid)
      const docFromDb = await getDoc(docRef);
      console.log('---fetchkyc2')

      if (!docFromDb.exists()) {
        await setDoc(docRef, {
          totalInvestors: 0,
          blockchainAddress: data.blockchain_address,
        });
      }

      const docData = docFromDb.data();
      console.log('---fetchkyc3')
      // setData(docData)
      console.log('PULLEDDATA', docData);

      setKYCStatus(docData && docData.status?.KYCApproved ? 1 : docData.status?.kycFilesSubmitted || docData.status?.kycSubmitted ? 0 : -1);
    }

    fetchKycStatus();
    fetchPropertyDetails();
  }, [document, loomProvider, data, data?.blockchain_address, currentUserData.publicKey]);


  return (
    <tr>
      <td><Link to={generatePath(routes.property.path, { propertyId: document.uid })}><img src={data.featured_image.url}
                                                                                           alt="" width={100} /></Link>
      </td>
      <td>{data.address}</td>
      <td>{pricePerToken} €</td>
      {renderStatus(blockchainStatus, <CustomCountdown date={openingTime} />)}
      {renderStatus(kycStatus, <Link to={generatePath(routes.adminProjectKYC.path, { propertyId: document.uid, type: 'company' })}>kyc</Link>)}
    </tr>
  );
}

export default ProjectDetail;
