import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import KYCCompanyStep1 from './KYCCompanyStep1';
import KYCIndividualStep1 from './KYCIndividualStep1';

function KYCStep1Page() {
  const navigate = useNavigate()
  const { type } = useParams()

    return <KYCIndividualStep1 />
}

export default KYCStep1Page;
