import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, FormGroup, Row } from 'react-bootstrap';
import CountryField from '../../../components/KYC/CountryField';
import { errorMessage, onSubmitKYC } from './helpers';
import MoreInfosSchema from './schemas/MoreInfosSchema';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../../contexts/AuthContext';

function KYCStep4Page() {
  const navigate = useNavigate();

  return (
    <>
      <div>
        MERCI D'AVOIR SOUMIS VOTRE KYC
      </div>
    </>
  )
}

export default KYCStep4Page;
