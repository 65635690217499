import React from "react";
import { TabPanel } from "@mui/lab";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/system';
import BlockSelector from '../BlockSelector/BlockSelector';
import GesSelector from '../BlockSelector/GesSelector';
import getIconByState from '@hivest/frontend/src/utils/getIconByState';
import DPE from '@hivest/frontend/src/components/Diagnostics/DPE';
import GES from '@hivest/frontend/src/components/Diagnostics/GES';
const LeBien = ({ data }) => {
  const PropertyTabContent = styled('div')({
    padding: '5rem 0',
    h2: {
      color: 'var(--primary)',
      marginBottom: '5rem',
      fontWeight: '800',
    },
    iframe: {
      border: 'none'
    },
    p: {
      fontSize: '1.6rem',
      lineHeight: '2.6rem',
      color: '#6f7486',
      letterSpacing: '0.02em',
      '@media (maxWidth: 480px)': {
        fontSize: '1.4rem',
      }
    }
  })

  const LebianContent = styled('div')({
    h4: {
      fontSize: '2.4rem',
      lineHeight: '3.2rem',
      fontWeight: 800,
      margin: 0,
      letterSpacing: '-0.02em',
      '@media (maxWidth: 480px)': {
        marginBottom: '1.6rem'
      }
    },
    p: {
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
      fontWeight: 500,
      letterSpacing: '0.02em',
      miHeight: '2.2rem',
      '@media (maxWidth: 480px)': {
        minHeight: 'auto',
      }
    }
  })

  const PropertyTable = styled('div')({
    marginTop: '5rem',
    '@media (maxWidth: 480px)': {
      marginTop: '2rem',
    }
  })

  const PropertyTableBox = styled(Box)({
    background: '#ffffff',
    border: '1px solid rgba(198, 200, 215, 0.5)',
    boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
    borderRadius: '5px',
    marginBottom: '6px',
    ':nth-of-type(odd)':{
      background: '#f4fafa',
    }
  })

  const PerformanceTable = styled('div')({
    marginTop: '5rem',
    '@media (maxWidth: 480px)': {
      marginTop: '2rem',
    }
  })

  const PerformanceTableBox = styled(Box)({
    background: '#ffffff',
    border: '1px solid rgba(198, 200, 215, 0.5)',
    boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
    borderRadius: '5px',
    marginBottom: '6px',
    ':nth-of-type(odd)': {
      background: '#f4fafa'
    }
  })

  const TableValue = styled('div')({
    fontSize: '1.6rem',
    lineHeight: '2rem',
    fontWeight: 600,
    color: 'var(--primary)',
    padding: '1.3rem 2rem',
    borderRight: '1px solid rgba(198, 200, 215, 0.5)',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (maxWidth: 480px)': {
      padding: '1.3rem 1rem',
    },
    svg: {
      maxWidth: '2.4rem',
      marginRight: '1.3rem',
      verticalAlign: 'top',
    }
  })

  const DiagnosicBox = styled(Box)({
    background: '#ffffff',
    border: '1px solid rgba(198, 200, 215, 0.5)',
    boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
    borderRadius: '5px',
    marginTop: '3.4rem',
    padding: '3rem',
    minHeight: '19.2rem',
  })

  return (
    <TabPanel value="2" sx={{ padding: "0px" }}>
      <PropertyTabContent>
        <h2>Le bien</h2>
        <Box sx={{ marginTop: "5rem" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LebianContent>
                <h4>Description du bien actuel</h4>
                <p></p>
                <PropertyTable>
                  {data.project_informations.map((p, i) => {
                      return (
                        <PropertyTableBox key={i}>
                          <Grid container>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                              <TableValue>
                                <p>{p.key}</p>
                              </TableValue>
                            </Grid>
                            <Grid item xs={6} sm={6} md={8} lg={8}>
                              <TableValue style={{ border: "none" }}>
                                <p>{p.value}</p>
                              </TableValue>
                            </Grid>
                          </Grid>
                        </PropertyTableBox>
                      )
                    })}
                </PropertyTable>
              </LebianContent>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LebianContent>
                <h4>Performance energetique DPE</h4>
                <p>Indice d’emission de gaz a effet de serre (GES)</p>
                <PerformanceTable>
                  {data.diagnostics.map((p, i) => {
                    return (
                    <PerformanceTableBox key={i}>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <TableValue>
                          <p>{p.key}</p>
                        </TableValue>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        sx={{ textAlign: "center" }}
                      >
                        <TableValue style={{border: 'none'}}>
                          <p>
                            <svg
                              width="26"
                              height="19"
                              viewBox="0 0 26 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.31201 18.3406L0.687012 9.71563L2.52451 7.87812L9.31201 14.6656L23.712 0.265625L25.5495 2.10313L9.31201 18.3406Z"
                                fill="#24F012"
                              />
                            </svg>
                            {getIconByState(p.state)} {p.state === "1" ? `Conforme` : 'TO UPDATE'}
                          </p>
                        </TableValue>
                      </Grid>
                    </Grid>
                  </PerformanceTableBox>
                    )})}
                </PerformanceTable>
                <DiagnosicBox>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <DPE value={data.dpe} />

                    </Grid>
                  </Grid>
                </DiagnosicBox>
                <DiagnosicBox>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <GES value={data.ges} />

                    </Grid>
                  </Grid>
                </DiagnosicBox>
              </LebianContent>
            </Grid>
          </Grid>
        </Box>
      </PropertyTabContent>
    </TabPanel>
  );
};

export default LeBien;
