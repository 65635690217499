import { useNavigate, useParams } from 'react-router-dom';
import PropertyDetails from '@hivest/integration/src/pages/PropertyDetails/PropertyDetails';
import { useCustomRouterContext } from '@hivest/integration/src/contexts/CustomRouterContext';
import { useAuthValue } from '../../contexts/AuthContext';
import React, { useEffect, useState } from 'react';
import { client } from '../../prismicClient';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { getPropertySaleContract } from '../../utils/blockchain';
import SkeletonPropertyDetails from '../Authenticated/PropertyDetailsOld/SkeletonPropertyDetails';
import { routes } from '../../routes';
import { Popover, Table } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

function PropertyDetailsWrapper() {
  const navigate = useNavigate();
  const routes = useCustomRouterContext();
  const params = useParams()
  const { loomProvider, currentUserData } = useAuthValue()

  console.log(params)
  const [hasAlreadyInvestedIn, setHasAlreadyInvestedIn] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [contractAddress, setContractAddress] = useState(null);

  console.log(client)
  // if (!params.propertyId) {
  //   return <>Loading...</>
  // }
  const document = usePrismicDocumentByUID('property', params.propertyId, {
    client,
  });

  const data = document[0]?.data;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  useEffect(() => {
    if (!data) {
      return;
    }

/*    if (!loomProvider) {
      return;
    }*/

    setContractAddress(data.blockchain_address)

    if (!contractAddress) {
      return;
    }

    setHasAlreadyInvestedIn(currentUserData.investedIn.includes(contractAddress))
    console.log('DOC', document, document, contractAddress, data?.blockchain_address, currentUserData.publicKey)

    async function fetchPropertyDetails() {
      // const propertyPresale = getPropertySaleContract(loomProvider.web3,
      //   contractAddress
      // );
      //
      // const metadata = await propertyPresale.methods.metadata().call({
      //   from: currentUserData.publicKey,
      // });
      // const pricePerToken = await propertyPresale.methods.pricePerToken().call({
      //   from: currentUserData.publicKey,
      // });


      const docRef = await getDoc(doc(db, 'projects', params.propertyId));

      const docData = docRef.data()

      console.log('DATAPULLEEE', docData)

      setPropertyDetails({ metadata: docData.metadata, pricePerToken: docData.metadata.rate * docData.metadata.minInvestment })
    }

    fetchPropertyDetails()
  }, [document, contractAddress, data?.blockchain_address, currentUserData.publicKey])

  useEffect(() => {
    console.log('GOT COMPANY DETAILS', propertyDetails)
  }, [propertyDetails])

  if (!data) {
    return <SkeletonPropertyDetails />
  }

  function handleInvest() {
    navigate(routes.checkout.path, {
      state: {
        uid: params.propertyId,
        data,
        propertyDetails,
      }
    })
  }

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: 800 }}>
      <Popover.Header as="h3">Details</Popover.Header>
      <Popover.Body>
        <Table striped bordered>
          <thead>
          <tr>
            <th>Montant par mois</th>
            <th>Commentaire</th>
          </tr>
          </thead>
          <tbody>
          {document[0]?.data.cashflow_breakdown.map((cb, i) => {
            return (
              <tr key={i}>
                <td>{cb.amount} €</td>
                <td>{cb.comment}</td>
              </tr>
            )
          })}
          </tbody>
        </Table>
      </Popover.Body>
    </Popover>
  );

  console.log('-----', propertyDetails)

  return (
    <PropertyDetails
      navigate={navigate}
      routes={routes}
      handleInvest={handleInvest}
      data={data}
      propertyDetails={propertyDetails}
      contractAddress={contractAddress}
      displayCreateAccountButton={false}
      uid={params.propertyId}
    />
  )
}

export default PropertyDetailsWrapper;
