import React from 'react';
import ReactCountdown from 'react-countdown';

function CustomCountdown({ date, children }) {
  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <>{children}</>;
    }

    return (
      <div className="timer">
      <ul>
        <li>{days} <span>jour</span></li>
        <li>{hours} <span>heures</span></li>
        <li>{minutes} <span>minutes</span></li>
        <li>{seconds} <span>secondes</span></li>
      </ul>
      </div>
    );
  };

  return (
      <ReactCountdown
        date={date}
        renderer={countdownRenderer}
      />
  );
}

export default CustomCountdown;
