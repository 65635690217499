import * as React from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../pages/Homepage/Footer/Footer';

function DefaultLayout() {
  const location = useLocation();
  const referrerKeyName = 'referrer';
  const [, setValue] = useLocalStorage(referrerKeyName);

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (query.get(referrerKeyName)) {
      setValue(query.get(referrerKeyName));
    }
  }, [location, setValue]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

export default DefaultLayout;
