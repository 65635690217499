import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import image from './assets/image.png';
import Button from '@mui/material/Button';
import CustomButton from '../../components/CustomButton/CustomButton';
import { lineColor, secondaryTitleColor } from '../../styles';
import { common } from '@mui/material/colors';
import LoginSocialButton from '../../components/LoginSocialButton/LoginSocialButton';
import { buttonTextColor } from '../../components/CustomButton/styles';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';

type RegisterProps = {
  children: React.ReactNode,
  googleOnClick: (navigate: NavigateFunction) => void,
}
function Register({ children, googleOnClick }: RegisterProps) {
  const navigate = useNavigate();
  const routes = useCustomRouterContext();

  return (
    <Grid2 container>
      <Grid2 mdOffset={4} md={4}>
        <Box
          sx={{
            background: common.white,
            padding: { xs: 2, md: 4 },
            boxShadow: '40px 40px 100px rgba(220, 225, 237, 0.5)',
          }}>
          <Typography variant="h3" color={secondaryTitleColor} fontWeight={500} sx={{
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}>Créez votre compte sur Divyz <Box component="img" width={37} src={image} alt="building" /></Typography>
          <Box id="sso-login" marginTop={3}>
            <LoginSocialButton onClick={() => googleOnClick(navigate)} description="Inscription via Gmail" />
          </Box>
          <Typography textAlign="center" marginY={2}>ou</Typography>
          {children}
          <Box id="register" sx={{
            borderTopStyle: 'dashed',
            borderTopWidth: 1,
            borderTopColor: lineColor,
            paddingTop: 4,
          }}>
            <Typography
              variant="h4"
              fontWeight={800}
              fontSize={{ xs: '1.5rem', md: '1.5rem' }}
              color={buttonTextColor}
            >Vous avez déjà un compte?</Typography>
            <Box
              display="flex"
              flexDirection="column"
              marginTop={4}
            >
              <CustomButton
                component={Link}
                to={routes.login.path}
                variant="outlined"
                color="secondary"
              >Connectez-vous</CustomButton>
            </Box>
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  )
}

export default Register
