import { Card, Tab, Table } from 'react-bootstrap';

function Renting({ data }) {
  return (
    <div className="renting">
      <h2 className="heading"><span></span> État locatif</h2>
      <div className="rental_status_table">
        <div className="table-responsive main_table">
          <table className="table table-borderless ">
            <thead>
            <tr>
              <td>CHAMBRE</td>
              <td>PROFIL</td>
              <td>PAIEMENT</td>
              <td>LOYER (HC)</td>
              <td>CHARGES</td>
              <td>LOYER (CC)</td>
              <td>TYPE DE BAIL</td>
              <td>DÉBUT BAIL</td>
              <td>RENOUVELLEMENT</td>
              <td>GARANT</td>
            </tr>
            </thead>
            <tbody>
            {data.renting_state.map((item, index) => {
              return (
                <tr key={index}  bgcolor={index > data.nb_current_renting - 1 && '#ededed'}>
                  <td>{item.name}</td>
                  <td>{item.profile}</td>
                  <td>{item.payment}</td>
                  <td>{item.rent_hc} €</td>
                  <td>{item.charges} €</td>
                  <td>{item.rent_cc} €</td>
                  <td>{item.bail_type}</td>
                  <td>{item.bail_start}</td>
                  <td>{item.renewal}</td>
                  <td>{item.guarantor}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>

        <Card
          bg="light"
          text={'dark'}
          className="mb-2"
          l
        >
          <Card.Header>Détail locatif</Card.Header>
          <Card.Body>
            <div className="diagnostics_table">
              <div className="table-responsive">
                <Table striped bordered>
                  <tbody>
                  <tr>
                    <td>Nb de locataires</td>
                    <td>{data.nb_current_renting} (futur: {data.nb_future_renting})</td>
                  </tr>
                  <tr>
                    <td>Type de baux</td>
                    <td>{data.general_bail_type}</td>
                  </tr>

                  <tr>
                    <td>Loyer total précédent CC</td>
                    <td>{data.previous_total_rent_cc} € /mois</td>
                  </tr>
                  <tr>
                    <td>Loyer total estimé CC</td>
                    <td>{data.rentAverage} € /mois</td>
                  </tr>
                  <tr>
                    <td><b>Cashflow mensuel</b></td>
                    <td><b>+{data.cashflow_redistributed} €</b></td>
                  </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Renting
