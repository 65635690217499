import ForgotPasswordForm from '@hivest/integration/src/pages/ForgotPassword/ForgotPasswordForm';
import forgotPasswordValidator from '../../validators/ForgotPasswordValidator';
import { sendPasswordReset } from '../../firebase';

function ForgotPasswordFormLogic() {
  const onSubmit = ({ setSuccessMessage, setErrorMessage }) => ({ email }, { setSubmitting, resetForm, setFieldError }) => {
    // When button submits form and form is in the process of submitting, submit button is disabled
    setSuccessMessage(null);
    setErrorMessage(null);
    setSubmitting(true);

    sendPasswordReset(email)
      .then(async () => {
        console.log('reset password onsubmit');
        setSuccessMessage('Mot de passe réinitialisé avec succès, merci de cliquez sur lien présent dans l\'email que vous venez de recevoir')
      })
      .catch((err) => {
        console.log(err.code);

        switch (err.code) {
          case 'auth/user-disabled':
            setErrorMessage('Votre nom d\'utilisateur a été désactivé. Merci de nous contacter pour de plus amples informations');
            break;
          case 'auth/wrong-password':
            setFieldError('password', 'Mot de passe invalide.');
            break;
          case 'auth/user-not-found':
            setErrorMessage('User inconnu.');
            break;
          default:
            setErrorMessage(err.message);
            break;
        }
      })
      .finally(() => {
        setSubmitting(false);
        // resetForm();
      });
  };

  return (
    <ForgotPasswordForm
      validationSchema={forgotPasswordValidator}
      onSubmit={onSubmit}
      />
  )
}

export default ForgotPasswordFormLogic;
