import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import hivestLogo from '../images/Logo.png';
import { routes } from '../routes';
// material
// import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <div className="logo">
      <RouterLink to={routes.homepage.path}>
        <Image src={hivestLogo} />
      </RouterLink>
    </div>
  );
}
