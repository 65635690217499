import { Formik } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from 'firebase/auth';
import { useState } from 'react';
import { Alert, Box, Link, Modal, Typography } from '@mui/material';
import CustomButton from '@hivest/integration/src/components/CustomButton/CustomButton';
import TextInput from '@hivest/integration/src/components/Forms/TextInput';
import { buttonPrimaryBackgroundColor } from '@hivest/integration/src/components/CustomButton/styles';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';

type ForgotPasswordFormProps = {
  onSubmit: any,
  validationSchema: any,
};

function ResetPasswordForm({  onSubmit, validationSchema }: ForgotPasswordFormProps) {
  const navigate = useNavigate();
  const routes = useCustomRouterContext();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  return (
    <>
      <Formik
        initialValues={{ password: '', passwordConfirmation: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit({ setSuccessMessage, setErrorMessage })}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <>
            {console.log('values', values)}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {successMessage && <Alert severity="success">{successMessage}</Alert>}

              <TextInput
                fullWidth
                label="Mot de passe"
                showHidePasswordField
                name="password"
                disabled={isSubmitting}
                value={values.password}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                placeholder="******"
              />
              <TextInput
                fullWidth
                label="Confirmation de votre Mot de passe"
                showHidePasswordField
                disabled={isSubmitting}
                name="passwordConfirmation"
                value={values.passwordConfirmation}
                onChange={handleChange}
                error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                placeholder="******"
              />
              <CustomButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                mx={0}
                boxShadow="none"
                disabled={isSubmitting}
                my={4}
              >Enregistrer votre nouveau mot de passe</CustomButton>
            </>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ResetPasswordForm;
