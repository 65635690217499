import * as Yup from 'yup';
import { required } from '../KYC/schemas/messages';

const AddIbanSchema = Yup.object().shape({
  holder: Yup.string()
    .min(2)
    .max(100)
    .required(required),
  bic: Yup.string()
    .min(11)
    .max(11)
    .required(required),
  iban: Yup.string()
    .min(15)
    .max(34)
    .required(required),
  domiciliation1: Yup.string()
    .min(15)
    .max(256)
    .required(required),
  domiciliation2: Yup.string()
    .min(15)
    .max(256)
});

export default AddIbanSchema;
