import { Formik } from 'formik';
import { auth, logout, signInWithEmailAndPassword } from '../../firebase';
import { routes } from '../../routes';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from 'firebase/auth';
import validationSchema from '../../validators/LoginValidator';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Alert, Box, Link, Modal, Typography } from '@mui/material';
import CustomButton from '@hivest/integration/src/components/CustomButton/CustomButton';
import TextInput from '@hivest/integration/src/components/Forms/TextInput';
import { buttonPrimaryBackgroundColor } from '@hivest/integration/src/components/CustomButton/styles';
import LoginForm from '@hivest/integration/src/pages/Login/LoginForm';

function LoginFormLogic() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const confirmVerificationCode = async (resolver, verificationId, confirmationCode) => {
    // Ask user for the SMS verification code.
    const cred = PhoneAuthProvider.credential(
      verificationId, confirmationCode, auth);
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
    console.log('userCredential', userCredential);
  };

  const onSubmit = ({ resolver, setResolver, setErrorMessage, setVerifCode, setOpen2FAPopin }) => ({ email, password }, { setSubmitting, resetForm, setFieldError }) => {
    // When button submits form and form is in the process of submitting, submit button is disabled
    setErrorMessage(null);
    setSubmitting(true);
    console.log('---, , onsubmit')

    signInWithEmailAndPassword(email, password)
      .then(async () => {
        if (!auth.currentUser.emailVerified) {
          setErrorMessage('Merci de valider votre adresse e-mail.');
          await logout();
          return;
        }

        console.log('login onsubmit');
        navigate(routes.propertyList.path, { replace: true });
      })
      .catch((e) => {
        console.log("ERROR LOGIN", e.code);

        switch (e.code) {
          case 'auth/user-disabled':
            setErrorMessage('Votre nom d\'utilisateur a été désactivé. Merci de nous contacter pour de plus amples informations');
            break;
          case 'auth/wrong-password':
            setFieldError('password', 'Mot de passe invalide.');
            break;
          case 'auth/user-not-found':
            setErrorMessage('User inconnu.');
            break;
          case 'auth/multi-factor-auth-required': {
            const resolver = getMultiFactorResolver(auth, e);
            setResolver(resolver);
            console.log('---', e, resolver);
            console.error(e);
            if (resolver.hints[0].factorId ===
              PhoneMultiFactorGenerator.FACTOR_ID) {
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              };
              const phoneAuthProvider = new PhoneAuthProvider(auth);
              // Send SMS verification code
              const recaptchaVerifier = new RecaptchaVerifier(
                'otp-confirmation', { size: 'invisible' }, auth);
              return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                .then(function (verificationId) {
                  console.log('PLEASE VERIF 2F', verificationId);
                  setVerifCode(verificationId);
                  setOpen2FAPopin(true);
                });
            } else {
              // Unsupported second factor.
              console.log('Unsupported second factor');
            }
          }
            break;
          default:
            setErrorMessage(e.message);
            break;
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <LoginForm
      validationSchema={validationSchema}
      confirmVerificationCode={confirmVerificationCode}
      onSubmit={onSubmit}
    />
  );
}

export default LoginFormLogic;
