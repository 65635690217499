import React from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from '@mui/system';
import { useCustomRouterContext } from '../../../contexts/CustomRouterContext';

const Navigation = () => {
  const location = useLocation();
  const routes = useCustomRouterContext();

  const SideBar = styled('div')`
    padding-top: 5rem;
    @media only screen and (max-width: 899px) {
      position: relative;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px dashed var(--light-blue);
      margin: 0 -3.4rem;
    }

    @media only screen and (max-width: 767px) {
      &::before,
      &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 3.4rem;
      height: 100%;
      pointer-events: none;
      z-index: 1;
      }
    ::before {
      left: 0;
      background: linear-gradient(
        89.91deg,
        #ffffff 22.75%,
        rgba(255, 255, 255, 0) 87.31%
      );
      }
    ::after {
      right: 0;
      background: linear-gradient(
        -89.91deg,
        #ffffff 22.75%,
        rgba(255, 255, 255, 0) 87.31%
      );
      width: 4.4rem;
      }
    }
  `

  const NavigationList = styled('ul')`
  list-style: none;
  li {
    color: var(--secondary);
    padding: 1rem 1.4rem;
    cursor: pointer;
    max-width: 14.2rem;
    &:hover,
    &:focus {
      background: #f4fafa;
      font-weight: 800;
    }
  }

  .activeLink {
    background: #f4fafa;
    font-weight: 800;
    li {
    font-weight: 800;
    background: #f4fafa;
  }
  }

  @media only screen and (max-width: 899px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
    li {
    color: var(--secondary);
    padding: 1rem 1.4rem;
    cursor: pointer;
    max-width: 14.2rem;
    &:focus {
      background: #f4fafa;
      font-weight: 800;
    }
  }

  @media only screen and (max-width: 767px) {
    overflow-x: auto;
    margin-right: 2rem;
    a:first-child {
      margin-left: 2rem;
    }
  }
  `

  const NavigationLink = styled(Link)`
   color: var(--secondary);
  `

  const isActive = (path) => {
    return location.pathname === path ? 'activeLink' : "";
  };

  return (
    <SideBar>
      <NavigationList>
        <NavigationLink to={routes.dashboard.path} className={`${isActive(routes.dashboard.path)}`}>
          <li>Accueil</li>
        </NavigationLink>
        <NavigationLink
          to={routes.propertyList.path}
          className={`${isActive(routes.propertyList.path)}`}
        >
          <li>Propriétés</li>
        </NavigationLink>
        <NavigationLink
          to={routes.wallet.path}
          className={`${isActive(routes.wallet.path)}`}
        >
          <li>Portefeuille</li>
        </NavigationLink>
        <NavigationLink
          to="/marketplace"
          className={`${isActive("/marketplace")}`}
        >
          <li>Marketplace</li>
        </NavigationLink>
        <NavigationLink to={routes.profile.path} className={`${isActive(routes.profile.path)}`}>
          <li>Profil</li>
        </NavigationLink>
        <NavigationLink
          to={routes.referral.path}
          className={`${isActive(routes.referral.path)}`}
        >
          <li>Parrainage</li>
        </NavigationLink>
      </NavigationList>
    </SideBar>
  );
};

export default Navigation;
