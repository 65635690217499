import { Link } from 'react-router-dom';
import { routes } from '../../routes';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { client } from '../../prismicClient';

export default function DashboardApp() {
  const documents = useAllPrismicDocumentsByType('property', { client })

  console.log(documents)
  const documentRender = documents.length > 0 && documents[0] && documents[0].map((document) => {
    return <div>
      <Link to={`/app/properties/${document.uid}`}>{document.data.address}</Link>
    </div>
  })

  return (
    <div>
      <div>
        <Link to={routes.wallet.path}>Portefeuille</Link>
      </div>
      <div>
        <Link to={routes.propertyExample.path}>Property example</Link>
      </div>
      <div>
        <Link to={routes.propertyList.path}>Property list</Link>
      </div>
      <div>
        <Link to="/app/properties/evreux-appartement-0x07059c212234abfdb40323596fb23af8e51b9cef">Property evreux
          dynamic</Link>
        <hr />
        {documentRender}
      </div>
    </div>
  );
}
