import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ShowHidePassword({ field, form, placeholder }) {
  const [showHidePassword, setShowHidePassword] = useState(false);

  return (
    <Form.Group controlId={field.name}>
      <div className="mb-3">
        <InputGroup>
          <Form.Control
            type={showHidePassword ? 'text' : 'password'}
            /* This name property is used to access the value of the form element via values.nameOfElement */
            name={field.name || 'password'}
            placeholder={placeholder || "Mot de passe"}
            /* Set onChange to handleChange */
            onChange={field.onChange}
            /* Set onBlur to handleBlur */
            onBlur={field.onBlur}
            /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
            defaultValue={form.values[field.name]}
            /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
            className={`${form.touched[field.name] && form.errors[field.name] ? 'error' : null} form-control`}
            aria-describedby="passwordHelp"
          />
          <InputGroup.Text onClick={() => setShowHidePassword(!showHidePassword)}>
            {showHidePassword ? <FaEyeSlash /> : <FaEye />}
          </InputGroup.Text>
        </InputGroup>
        {form.touched[field.name] && form.errors[field.name] ? (
          <div className="text-danger">{form.errors[field.name]}</div>
        ) : null}
      </div>
    </Form.Group>
  )
}

export default ShowHidePassword
