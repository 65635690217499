import React, { useState } from 'react';
import { useAuthValue } from '../../../contexts/AuthContext';
import Button from '../../../components/Button/Button';
import { Link, generatePath } from 'react-router-dom';
import { routes } from '../../../routes';
import { Container, FormCheck } from 'react-bootstrap';

function KYCLanding() {
  const { currentUserData } = useAuthValue();
  const [kycType, setKycType] = useState('individual');

  const isKYCApproved = currentUserData.status?.KYCApproved
  const isKYCFilesSubmitted = currentUserData.status?.kycFilesSubmitted
  const isKYCSubmitted = currentUserData.status?.kycSubmitted

  const kycStatusRender = isKYCApproved ? (
    <span className="link-success fw-bold">Validé</span>
  ) : (isKYCFilesSubmitted && isKYCSubmitted) ? (
    <span className="link-warning fw-bold">En attente de validation</span>
  ) : (
    <span className="link-danger fw-bold">Non soumis</span>
  );

  const onChange = (type) => () => {
    setKycType(type);
  };

  return (
    <div className="head_box">
      <p className="box_heading">Vérification d'identité</p>
      <Container>
        <p>Votre status KYC sur notre site est: {kycStatusRender} </p>
      </Container>
      {!isKYCApproved && !currentUserData.kycStatus && <div className="mt-5 d-flex justify-content-center">
        <div className="form-group">
          Êtes-vous un particulier ou une entreprise ?
          <div className="mb-3">
            <FormCheck
              inline
              name="isIndividualAccount"
              type="radio"
              id="individual"
              label="Particulier"
              defaultChecked={true}
              onChange={onChange('individual')}
            />
            <FormCheck
              inline
              name="isIndividualAccount"
              type="radio"
              id="company"
              label="Entreprise"
              onChange={onChange('company')}
            />
          </div>
          <div className="d-flex justify-content-center">
            <Link to={generatePath(routes.kycStep1.path, { type: kycType })}>
              <Button>Commencer votre vérification d'identité</Button>
            </Link>
          </div>
        </div>
      </div>}
      {currentUserData.kycStatus && (
        <div className="mt-5">
          Si certaines informations doivent être modifié, merci de nous envoyer un e-mail a <a href="support@divyz.com">support@divyz.com</a>
        </div>
      )}
    </div>
  );
}

export default KYCLanding;
