import { Col, Nav } from 'react-bootstrap';
import { routes } from '../../../routes';
import { NavLink } from 'react-router-dom';
import dashboardIcon from './images/dashboardIcon.svg';
import dashboardProfileIcon from './images/dashboardProfileIcon.svg';
import dashboardWalletIcon from './images/dashboardWalletIcon.svg';
import dashboardReferralIcon from './images/dashboardReferralIcon.svg';
import dashboardMessagesIcon from './images/dashboardMessagesIcon.svg';
import { FaLock } from 'react-icons/fa';
import { FaWallet } from 'react-icons/fa';
import { useAuthValue } from '../../../contexts/AuthContext';
import { isAdmin } from '../../../utils/helpers';

function Sidebar() {
  const { currentUser } = useAuthValue();

  return (
    <Col className="left_tab">
      <div className="profile_control">
        <Nav defaultActiveKey={routes.dashboard.path} className="navbar-nav" as="ul">
          <Nav.Item className="mx-0 p-0" as="li">
            <Nav.Link as={NavLink} to={routes.dashboard.path}>
              <span><img src={dashboardIcon} alt="dashboard" /></span>
              Dashboard
            </Nav.Link>
          </Nav.Item>
          {isAdmin(currentUser) &&
          <Nav.Item className="mx-0 p-0" as="li">
            <Nav.Link as={NavLink} to={routes.admin.path} active={true}>
              <span><img src={dashboardProfileIcon} alt="profile" /></span>
              Admin
            </Nav.Link>
          </Nav.Item>}
          <Nav.Item className="mx-0 p-0" as="li">
            <Nav.Link as={NavLink} to={routes.profile.path}>
              <span><img src={dashboardProfileIcon} alt="profile" /></span>
              Profil
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="mx-0 p-0" as="li">
            <Nav.Link as={NavLink} to={routes.security.path}>
              <span><FaLock /></span>
              Sécurité
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="mx-0 p-0" as="li">
            <Nav.Link as={NavLink} to={routes.wallet.path}>
              <span><FaWallet /></span>
              Portfeuille
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="mx-0 p-0" as="li">
            <Nav.Link as={NavLink} to={routes.referral.path}>
              <span><img src={dashboardReferralIcon} alt="parrainage" /></span>
              Parrainage
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="mx-0 p-0" as="li">
            <Nav.Link as={NavLink} to={routes.kyc.path}>
                <span><img src={dashboardReferralIcon} alt="kyc" /></span>
                Identité
              </Nav.Link>
          </Nav.Item>
          <Nav.Item className="mx-0 p-0" as="li">
            <Nav.Link as={NavLink} to={routes.messages.path}>
              <span><img src={dashboardMessagesIcon} alt="messages" /></span>
              Messages
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </Col>
  )
}

export default Sidebar
