import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { fromWei } from '../../utils/blockchain';
import CustomCountdown from '../../components/CustomCountdown/CustomCountdown';
import {
  doc,
  getDoc,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuthValue } from '../../contexts/AuthContext';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { useCustomRouterContext } from '@hivest/integration/src/contexts/CustomRouterContext';

function InvestmentBox({ uid, contractAddress, metadata, handleInvest }) {
  const [data, setData] = useState({})
  const routes = useCustomRouterContext();
  const { currentUserData } = useAuthValue();

  const ApplyBtn = styled('div')({
    cursor: 'pointer',
    background: 'var(--secondary)',
    borderRadius: '5rem',
    padding: '1rem',
    height: '5.8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '24.4rem',
    maxWidth: '24.4rem',
    color: 'var(--white)',
    fontSize: '1.8rem',
    lineHeight: '2.5rem',
    fontWeight: 700,
    ':hover' : {
      background: 'var(--dark)',
    },
    ':visited': {
      color: 'var(--white)',
    },
    ':focus': {
      background: 'var(--dark)'
    },
    '@media (max-width: 899px)': {
      marginTop: '3rem'
    },
    '@media (max-width: 480px)': {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
    }
  })
  const CreateAccountButtonBig = styled(Grid)({
    '@media (max-width: 480px)': {
      display: 'none !important'
    },
    '@media (max-width: 430px)': {
      display: 'none !important',
    }
  })

  useEffect(() => {
    console.log('EFFECTIVE')
    async function fetch() {
      console.log('projectoijoijojos', uid, 'projects', uid)
      const docRef = await getDoc(doc(db, 'projects', uid));

      const docData = docRef.data()
      setData(docData)
      console.log('PULLEDDATA', docData, uid)
    }

    fetch()
  }, [uid])

  console.log('goisdhsgrd', metadata, uid)

  if (!metadata) {
    return <Skeleton width={`150px`} height={`40px`} />;
  }

  const hasAlreadyInvested = currentUserData.investedIn.includes(contractAddress)

  const alreadyRaised = data?.amount / fromWei(metadata.hardCap) * 100;

  return (
    <Grid item
          xs={0}
          sm={0}
          md={3}
          lg={3}
          sx={{ display: "flex", justifyContent: "flex-end" }}>
      <CustomCountdown
        date={metadata.openingTime.toDate()}
      >
        <CreateAccountButtonBig>
          <ApplyBtn onClick={handleInvest}>
            J'investis!
          </ApplyBtn>
        </CreateAccountButtonBig>
      </CustomCountdown>
    </Grid>
  );
}

export default InvestmentBox;
