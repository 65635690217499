import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getAuth,
  applyActionCode,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  confirmPasswordReset,
  sendEmailVerification as sendEmailVerificationFirebase,
  sendPasswordResetEmail,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  signInWithCustomToken as signInWithCustomTokenFirebase,
  signInWithPopup,
  signOut,
  updateProfile,
  connectAuthEmulator,
} from 'firebase/auth';
import {
  getStorage,
  connectStorageEmulator,
} from "firebase/storage";
import {
  getFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { postRegister } from './actions/user';

const firebaseConfig = {
  apiKey: 'AIzaSyDU3m5fbc4fiH1dn7Mgh-QTUF33lkf1XN4',
  authDomain: 'hivest.firebaseapp.com',
  databaseURL: 'https://hivest-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'hivest',
  storageBucket: 'hivest.appspot.com',
  messagingSenderId: '217659716806',
  appId: '1:217659716806:web:ede839389b6939368893c7',
  measurementId: 'G-C9KJG535H1',
};

export const app = initializeApp(firebaseConfig);
if (process.env.NODE_ENV === 'production') {
  getAnalytics(app);
}
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
}

function buildUrl(path) {
  return `${window.location.protocol}//${window.location.host}${path}`;
}

function handleProviderPostRegister(signInRespone, referrerCode) {
  const additionalUserInfo = getAdditionalUserInfo(signInRespone)

  if (additionalUserInfo.isNewUser) {
    return postRegister({ toggleMailingList: true, referrerCode })
  }
}

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async (referrerCode) => {
  auth.useDeviceLanguage();
  const res = await signInWithPopup(auth, googleProvider);

  return handleProviderPostRegister(res, referrerCode);
};

export const signInWithCustomProvider = (provider) => {
  const oauthProvider = new OAuthProvider(provider);

  return async (referrerCode) => {
    const res = await signInWithPopup(auth, oauthProvider);
    await handleProviderPostRegister(res, referrerCode);
  }
};
const facebookProvider = new FacebookAuthProvider();
export const signInWithFacebook = async (referrerCode) => {
  auth.useDeviceLanguage();
  const res = await signInWithPopup(auth, facebookProvider);

  return handleProviderPostRegister(res, referrerCode);
};

export const signInWithEmailCustomProvider = async (provider, authorizationCode, redirectUri) => {
  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/${provider}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer ${tokenId}`
    },
    body: JSON.stringify({ authorizationCode, redirectUri }),
  }).then(res => res.json())
    .then(res => console.log(res));

  // return signInWithEmailAndPasswordFirebase(auth, email, password);
};

export const signInWithEmailAndPassword = async (email, password) => {
  return signInWithEmailAndPasswordFirebase(auth, email, password);
};

export const sendEmailVerification = async (currentUser) => {
  await sendEmailVerificationFirebase(currentUser);
}

export const registerWithEmailAndPassword = async (email, password, toggleMailingList, referrerCode) => {
  await createUserWithEmailAndPassword(auth, email, password);
  await sendEmailVerification(auth.currentUser);

  // await updateProfile(auth.currentUser, {
    // displayName: `${firstName} ${lastName}`,
  // });

  await postRegister({ toggleMailingList, referrerCode })
};

export const signInWithCustomToken = async (token) => {
  await signInWithCustomTokenFirebase(auth, token);
};

export const sendPasswordReset = async (email) => {
  await sendPasswordResetEmail(auth, email, {
    url: buildUrl('/auth/login'),
  });
};

export const handleVerifyEmail = async (oobCode) => {
  await applyActionCode(auth, oobCode);
};

export const handleResetPassword = async (oobCode, newPassword) => {
  await confirmPasswordReset(auth, oobCode, newPassword);
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    console.error(err);

    throw err;
  }
};


