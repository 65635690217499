import * as React from 'react';
import { Typography } from '@mui/material';
import ContainerWithLine from '../../components/ContainerWithLine';

function CGU() {
  return (
    <>
      <ContainerWithLine maxWidth="lg" sx={{ marginBottom: 38 }}>
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
          <Typography variant="h2" sx={{ marginBottom: 5 }}>AVERTISSEMENT ET PRÉAMBULE</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les présentes conditions générales d’utilisation (ci-après,
            les « Conditions
            générales ») formalisent les conditions juridiques auxquelles sont proposés les services (ci-après, les «
            Services »), notamment d’utilisation de la plateforme exploitée sous le nom DIVYZ.COM
            (https://www.DIVYZ.COM/,
            le « Site » ou la « Plateforme »), par la société HIVEST SAS, société par actions simplifiée au capital
            social
            de 100 €, dont le siège social est 18 rue de Penthièvre – 75008 PARIS, immatriculée au Registre du Commerce
            et
            des Société de Vienne sous le numéro 914 754 791 (« DIVYZ »).</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Elles forment, avec l’ensemble des documents auxquels elles
            font référence, un
            contrat (ci-après, le « Contrat »), conclu entre DIVYZ et toute personne physique ou morale, professionnel
            ou
            non (ci-après, «l’Utilisateur »), individuellement dénommée la « Partie », ou collectivement les « Parties
            »,
            utilisant tout ou partie des Services.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Toute utilisation des Services sur la Plateforme par
            l’Utilisateur entraîne
            l’acceptation, sans réserve, des présentes Conditions générales, que l’Utilisateur est réputé avoir
            étudiées.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Il est précisé que la Plateforme, membre de la Fédération
            des Plateformes de
            Partage de Revenus – F2PR, est signataire de la Charte de bonne conduite F2PR dont le texte est disponible
            sur
            le site www.f2pr.org . En particulier, dans l’optique de la protection effective de l’Utilisateur
            investisseur
            face aux risques spécifiques associés au financement par partage de revenus, la Plateforme s’engage
            notamment
            à respecter certaines règles en matière de gouvernance, de transparence et de communication.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}>
            L’attention de l’Utilisateur est attirée sur le fait les investissements proposés sur la Plateforme
            n’entrent
            pas dans le cadre du Règlement (UE) 2020/1503 du Parlement européen et du Conseil du 7 octobre 2020 relatif
            aux prestataires européens de services de financement participatif pour les entrepreneurs. Dès lors, le
            Contributeur reconnaît que seules les dispositions impératives du code civil et, dans la mesure où il a la
            qualité de consommateur, celles du code de la consommation, régissent le présent Contrat de Partage de
            Revenus
            Futurs.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Afin d'assurer une pratique homogène, responsable et
            transparente des pratiques au
            sein des adhérents de F2PR, mais aussi et surtout une protection effective des investisseurs, la Plateforme
            entend utiliser des Conditions générales standardisées. Il est rappelé que seuls les adhérents de la
            Fédération ont le droit d'utiliser les documents types du collectif ou de communiquer des éléments
            standardisés, y compris les présentes Conditions générales. Toute retranscription ou utilisation par un
            tiers
            à la Fédération sans le consentement de cette dernière engage la responsabilité dudit tiers.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le Site est hébergé par</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Google Cloud Platform<br />
            1600 Amphitheatre Parkway<br />
            Mountain View, CA 94043<br />
            United States</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}></Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 1. Définitions<br />
            Les termes ci-dessous, utilisés au singulier ou au pluriel avec une majuscule , et sauf stipulations
            contraires, ont la signification suivante :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Collecte » : désigne l’opération de collecte des fonds
            nécessaires au financement<br />
            du Projet du Porteur de Projet à financer.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Compte Personnel de l’Utilisateur » ou « Compte
            Utilisateur » : Désigne l’espace<br />
            personnel d’un Utilisateur sur le Site. Cet espace est accessible uniquement par la saisine des identifiants
            de connexion, et permet l’accès aux Services fournis par la Société. Il permet également à l’Utilisateur de
            consulter et de modifier ses propres données personnelles.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Contrat de Partage de Revenus Futurs » : Désigne les
            contrats signés entre les<br />
            Utilisateurs et les Porteurs de Projet désignés comme tel sur la Plateforme.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Contributeur » : Désigne l’Utilisateur, qui souhaite
            soutenir financièrement le<br />
            Porteur de Projet sous la forme d’une contribution dans le cadre d’une opportunité offerte par la Plateforme
            et qui a conclu un Contrat de Partage de Revenus Futurs avec le Porteur de Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Contribution » : Désigne toute somme en numéraire versée
            par un Contributeur en<br />
            vue de l’obtention d’une Redevance.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Droits de Redevance » ou « Redevances » : désigne le
            total des droits de créance<br />
            acquis par l’ensemble des Contributeurs à la Collecte sur le Porteur de Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Opération de Financement » : Désigne l’opération de
            financement par voie de<br />
            partage de revenus du Projet faisant l’objet du Contrat de Partage de Revenus Futurs.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Page Projet » : Désigne la page créée sur le Site par la
            Société sur la base des<br />
            informations et documents fournis par le Porteur de Projet dans le but de présenter son Projet lors d’une
            Phase de Contribution.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Phase de Confirmation » : Désigne les quinze (15) jours
            calendaires qui suivent<br />
            la Phase de Contribution, en vue de purger l’éventuel droit de rétractation des Contributeurs, et au terme
            de<br />
            laquelle l’atteinte du Seuil de Succès est confirmée.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Phase de Contribution » : Désigne la période pendant
            laquelle les Utilisateurs<br />
            peuvent participer à l’Opération de Financement. La période est d’une durée déterminée par le Porteur de
            Projet dans le Contrat de Partage de Revenus Futurs.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Porteur de Projet » : Désigne toute personne morale qui
            cherche à obtenir un<br />
            financement par le biais de la Plateforme.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Procédure KYC » : Désigne l’ensemble des mesures
            d’identification des<br />
            Utilisateurs menées par DIVYZ et pour lesquelles l’Utilisateur s’engage à fournir tout justificatif
            nécessaire<br />
            à la vérification de ses déclarations sur première demande de cette dernière.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Projet », « Projet à financer » : Désigne toute projet
            d’acquisition ou de<br />
            refinancement d’un bien immobilier à usage locatif porté par un Porteur de Projet et publiée sur le
            Site.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Réclamation » : Désigne toute déclaration actant du
            mécontentement d’un<br />
            Utilisateur envers DIVYZ et ses Services. Une demande d’information, d’avis, de clarification, de service ou
            de prestation n’est pas une Réclamation.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Services » : Désigne les prestations objet des présentes
            Conditions générales<br />
            disponibles à tout Utilisateur sur le Site et fournis par la société DIVYZ.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Seuil de Succès » : Désigne le montant minimal de
            l’ensemble des Contributions<br />
            reçues de la part des Contributeurs dans le cadre de l’Opération de Financement envisagée par un Porteur de
            Projet et conditionnant la réalisation définitive de l’Opération de Financement. Ce montant varie selon
            l’Opération de Financement.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Site », « Plateforme » : Désigne le site Web par lequel
            DIVYZ met ses Services à<br />
            disposition de tout Utilisateur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Utilisateur » : Désigne tout utilisateur des Services
            proposés par DIVYZ sur le<br />
            Site, dans le respect des stipulations des présentes Conditions générales, majeur et capable juridiquement
            et<br />
            qui fait l’objet de la Procédure KYC.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Versement Individuel » : Désigne la somme versée par un
            Contributeur dans le<br />
            cadre de l’Opération de Financement.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Versement Individuel Maximal » : Désigne la somme
            maximale pouvant être acceptée,<br />
            à titre de Contribution, par le Porteur de Projet de la part du Contributeur dans le cadre du Contrat de
            Partage de revenus futurs.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Versement Individuel Minimal » : Désigne la somme
            minimale pouvant être acceptée,<br />
            à titre de Contribution, par le Porteur de Projet de la part du Contributeur dans le cadre du Contrat de
            Partage de Revenus Futurs.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> « Visiteur » : Désigne l’internaute personne physique ou
            morale accédant au Site ou<br />
            le consultant mais ne s’inscrivant pas en qualité d’Utilisateur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}>
            Article 2 . Objet<br />
            Par les présentes Conditions générales, DIVYZ s’engage à fournir, à tout Utilisateur, tout ou partie des
            Services proposés sur le Site et notamment les Services suivants, aux conditions stipulées ci-après
            :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Ø Mise à disposition d’une application sur la Plateforme
            permettant au Contributeur
            de bénéficier des services suivants :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> o conclusion du Contrat de Partage de Revenus Futurs entre
            l’Utilisateur et un
            Porteur de Projet ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> o Vérification d’identité (Procédure « KYC »</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Ø Service support auprès du Contributeur pour le suivi de
            sa
            situation.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les Services pourront, à tout moment, faire l’objet de
            modifications, changements
            et/ou mises en conformité par DIVYZ, tant techniques que fonctionnels, ce que l’Utilisateur accepte d’ores
            et
            déjà. Ces adaptations pourront être réalisées sans préavis, demande d’accord ou information préalable du
            Contributeur si elles ne modifient pas de manière substantielle le service concerné et assurent à
            l’Utilisateur un niveau de prestation au moins équivalent.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En cas de modification conséquente impactant les
            fonctionnalités essentielles,
            DIVYZ s’engage à en informer l’Utilisateur au préalable.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Nonobstant ce qui précède, DIVYZ pourra mettre en œuvre
            directement toute
            modification d’urgence rendue nécessaire pour des raisons de sécurité, de sûreté, de bon fonctionnement ou
            de
            confidentialité.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Il appartient à l’Utilisateur de définir ses besoins et
            rechercher si les Services
            et l’étendue des Services proposés sont adaptés, compte-tenu des objectifs qu’il poursuit et de
            l’infrastructure qu’il utilise.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les conditions d’accès et d’utilisation sont limitativement
            spécifiées sur le Site
            et/ou dans les présentes Conditions générales. Toute utilisation des Services, non expressément prévue par
            les
            présentes Conditions générales, est interdite. En ce sens l’Utilisateur ne pourra en aucun cas faciliter
            l’accès à la Plateforme à un tiers, et s’interdit strictement toute autre utilisation que celle visée
            ci-dessus et notamment toute adaptation, modification, traduction, arrangement, diffusion, etc., de la
            Plateforme.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> DIVYZ ne fournit aucun engagement d’accessibilité, de
            fonctionnalité ou de
            performance ni ne garantit aucun niveau de service qui ne seraient pas expressément spécifiés dans les
            présentes Conditions générales.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 3 . Accès et conditions d’utilisation des Services
            L’accès au Site est gratuit et permet aux Visiteurs et Utilisateurs de consulter son contenu éditorial et
            les
            Projets à financer.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En revanche, l’accessibilité à certaines informations,
            fonctionnalités et certains
            Services du Site est conditionnée à l’inscription sur le Site via la création d’un compte Utilisateur dans
            les
            conditions fixées ci-après.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Pour accéder aux Services, l’Utilisateur doit disposer
            d’une connexion internet
            fiable et de haut débit qu’il lui appartient de mettre en place et de paramétrer à ses frais.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur garantit disposer des infrastructures,
            matériels, programmes et
            compétences nécessaires pour une bonne accessibilité et utilisation de la Plateforme.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur s’engage à connaître et à maîtriser les
            caractéristiques et le
            fonctionnement de ses installations et réseaux ainsi que du réseau Internet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Conditions d’accès
            Toute personne physique souhaitant s’inscrire doit être majeure et doit avoir la capacité de
            contracter.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Dans le cas de l’inscription d’une personne morale certifie
            détenir l’intégralité
            des droits nécessaires à la conclusion des présentes au nom de la personne morale qu’il
            représente.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Chaque Compte Utilisateur est personnel et un compte doit
            être créé pour chaque
            Utilisateur. L’Utilisateur s’engage à ne pas créer ou utiliser d’autre compte que celui initialement créé,
            que
            ce soit sous sa propre identité ou celle d’un tiers. Toute dérogation à cette règle devra faire l’objet
            d’une
            demande de la part de l’Utilisateur et d’une autorisation expresse et spécifique de DIVYZ. Le fait de créer
            ou
            utiliser de nouveaux comptes sous sa propre identité ou celle de tiers sans avoir demandé et obtenu
            l’autorisation de DIVYZ pourra entraîner la suspension immédiate du Compte Personnel de l’Utilisateur et de
            l’accessibilité à tous les Services associés.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’ouverture d’un Compte Utilisateur suppose que
            l’Utilisateur soit éligible à
            accéder au Site en fonction de ses Conditions générales d’utilisation.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur qui souhaite accéder à la Plateforme doit
            créer un compte (ci-après
            le « Compte ») : www.DIVYZ.COM/signup</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur s’engage à fournir et maintenir les
            informations le concernant
            exactes, à jour et complètes. L’Utilisateur peut à tout moment compléter et/ou modifier les informations
            personnelles le concernant sur son Compte.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les identifiants de connexion (identifiant et mot de passe)
            sont personnels. Le mot
            de passe du Compte est, en outre, strictement confidentiel. L’Utilisateur s’engage à le conserver secret et
            à
            ne pas le divulguer avec son identifiant, pour quelque raison que ce soit, de quelque manière et sous
            quelque
            forme que ce soit, à des tiers.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En cas d’oubli de mot de passe, l’Utilisateur peut en
            demander un nouveau depuis la
            page de connexion www.DIVYZ.COM/login, une procédure de réinitialisation sera alors envoyée à son adresse
            email.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur peut activer un mécanisme de
            double-authentification pour renforcer
            la protection de son compte.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur garantit strictement DIVYZ, dans le cas d’une
            utilisation à des fins
            non professionnelles :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - qu’il est une personne physique majeure, capable et
            disposant d'une pièce
            d'identité en cours de validité (carte nationale d'identité, passeport ou titre de séjour) ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - qu’il est domicilié dans l’un des États membres de
            l’Union européenne (UE) ou
            partie à l’Espace Economique Européen (EEE) ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - qu’il est titulaire d’un compte bancaire ouvert auprès
            d’un établissement de
            crédit situé dans l’UE ou l’EEE, et le cas échéant détenteur d’une carte de paiement permettant les
            transferts
            de fonds dotée du dispositif 3D Secure, adossée à son compte bancaire ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - que les éléments d’identification qu’il communique à
            DIVYZ sont exacts et
            complets.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Dans le cas d’une utilisation pour le compte d’une personne
            morale :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - qu’il est une personne physique majeure, capable et
            disposant d'une pièce
            d'identité en cours de validité (carte nationale d'identité, passeport ou titre de séjour) ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - qu’il est domicilié dans l’un des États membres de
            l’Union européenne (UE) ou
            partie à l’Espace Economique Européen (EEE) ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - qu’il est titulaire d’un compte bancaire professionnel
            ouvert auprès d’un
            établissement de crédit situé dans l’UE ou l’EEE, et le cas échéant détenteur d’une carte de paiement
            permettant les transferts de fonds dotée du dispositif 3D Secure, adossée à son compte bancaire
            ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - qu’il dispose de toutes les habilitations nécessaires
            pour utiliser le Site
            ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - que la personne morale qu’il représente est dûment
            immatriculée au registre du
            commerce et des sociétés et ne fait pas l’objet d’une procédure prévue par le Livre VI du Code de commerce
            ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - que les éléments d’identification qu’il communique à
            DIVYZ sont exacts et
            complets.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}>
            DIVYZ recueille par l’intermédiaire du Site :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Document d’identité pour la procédure KYC.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Pendant toute la durée du Contrat, l’Utilisateur doit
            informer spontanément DIVYZ
            de tout changement intervenant dans sa situation et pouvant avoir une incidence sur les
            Services.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur accepte que DIVYZ puisse lui demander, par
            l’intermédiaire du Site, à
            tout moment, des éléments d’identification supplémentaires pour se conformer à la réglementation applicable,
            notamment en matière de lutte contre le blanchiment des capitaux et le financement du terrorisme.
            L’Utilisateur s’engage à fournir plus généralement tout justificatif nécessaire à la vérification de ses
            déclarations sur première demande de DIVYZ.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> DIVYZ confirmera l’inscription de l’Utilisateur. Toutefois,
            DIVYZ se réserve le
            droit de refuser l’inscription à toute personne qui notamment ne remplirait pas les conditions de bonne
            moralité, ou qui contreviendrait aux valeurs et à l’éthique que le Site s’efforce de promouvoir, sans que
            cette dernière ne soit tenue de motiver ce refus.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> DIVYZ est seule décisionnaire des inscriptions qu’elle
            retient, sans recours
            possible, ni indemnité de quelque nature que ce soit.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur accepte et reconnaît qu’il est responsable de
            la conservation des
            identifiants et mots de passe ainsi que du respect de leur caractère confidentiel et personnel. En
            conséquence, l’Utilisateur est le seul et unique responsable de leur utilisation et supportera donc seul
            toutes conséquences liées à une utilisation ou à un accès par un Utilisateur ou un tiers non autorisé mais
            disposant des identifiants de l’Utilisateur (notamment perte ou altération des données) et de tout acte
            effectué à partir d’un de ses comptes/accès utilisateurs.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Si l’Utilisateur a connaissance d’une utilisation non
            autorisée d’un identifiant,
            d’un mot de passe et/ou d’un Compte Utilisateur, il s’engage à en informer DIVYZ sans délai et par e-mail à
            l’adresse support@divyz.com et confirmera par courrier recommandé cette information à l’adresse du siège
            social de DIVYZ indiqué dans les mentions légales au début du présent document.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur pourra accéder à la Plateforme à tout moment
            à l’exception des
            périodes d’indisponibilité notamment pour maintenance. L’accès à la Plateforme pourra effectivement être
            suspendu en raison notamment de toute opération de maintenance nécessaire au bon
            fonctionnement.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> DIVYZ ne pourra pas être tenu responsable de l’impact
            éventuel de cette
            interruption sur l’activité de l’Utilisateur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Conditions d’utilisation
            L’Utilisateur s’engage à utiliser la Plateforme conformément à sa destination et dans le respect des règles
            légales et réglementaires ainsi que des présentes Conditions générales.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur est seul responsable de ses données, de leur
            qualité et absence de
            vice, de leur utilisation ainsi que de l’utilisation des Services et des données afférentes, qui y
            transitent
            ou qui résultent de son utilisation.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur s’engage à n’entreprendre aucune action
            d’aucune sorte, susceptible
            d’avoir une incidence néfaste sur les Services.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur garantit et s’engage également à
            :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Disposer de toutes les autorisations nécessaires pour
            l’utilisation, le stockage
            et la diffusion des données ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Respecter les droits, notamment de propriété
            intellectuelle afférents à la
            Plateforme et aux contenus de celle-ci ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Ne pas utiliser la Plateforme à toute fin autre que celle
            prévue, ou de manière
            non conforme à sa destination ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Ne poster, générer, indiquer ou diffuser, sous quelque
            forme que ce soit, que des
            informations ou contenus conformes à la réalité, ne portant pas atteinte aux droits des tiers, et conformes
            à
            la réglementation en vigueur ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Renoncer expressément à utiliser des programmes ou
            dispositifs susceptibles de
            perturber le bon fonctionnement de la Plateforme, et à engager toute action de nature à imposer une charge
            disproportionnée pour les infrastructures d’hébergement ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Ne pas reproduire de façon permanente ou provisoire la
            Plateforme, en tout ou
            partie, par tout moyen et sous toute forme ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - N’utiliser la Plateforme qu’à des fins licites dans les
            conditions et limites des
            présentes Conditions générales ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Ne pas procéder à toute adaptation, modification,
            traduction, transcription,
            arrangement, compilation, décompilation, assemblage, désassemblage, transcodage, ni appliquer la
            rétro-ingénierie (ou « reverse engineering »), de tout ou partie de la Plateforme et/ou de son contenu
            ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Ne pas exporter la Plateforme, ou fusionner tout ou
            partie de celle-ci avec
            d’autres programmes ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Ne pas extraire ou réutiliser, y compris à des fins
            privées, sans autorisation
            préalable une partie substantielle ou non du contenu de la Plateforme ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Ne pas mettre en place de système susceptible de pirater
            ou d’entrer en conflit
            avec la Plateforme et/ou ses composantes en tout ou partie, de nature à violer les présentes Conditions
            générales.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le non-respect des dispositions ci-dessus pourra entraîner
            la suspension des
            Services et/ou la fin de toute autorisation d’accès et d’utilisation des Services.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le manquement aux obligations définies au présent article
            constitue un manquement
            grave.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}>
            L’Utilisateur garantit DIVYZ contre toute action intentée par un tiers possédant ou prétendant posséder un
            quelconque droit, notamment droit de propriété intellectuelle, en lien avec les données émanant de
            l’Utilisateur ou générées par l’Utilisateur dans le cadre des Services. L’Utilisateur fera son affaire des
            revendications de ces tiers pour que DIVYZ ne soit pas inquiétée et puisse, le cas échéant, exploiter les
            données ou éléments aux conditions des présentes Conditions générales.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}>
            En toute hypothèse, l’Utilisateur remboursera à DIVYZ les dommages et intérêts et les frais de défense qu’il
            aura eu à supporter dans ce cadre.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}>
            DIVYZ s’engage à faire ses meilleurs efforts pour maintenir la Plateforme accessible le plus souvent
            possible
            dans les conditions d’une fréquentation raisonnable.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur est néanmoins averti des aléas techniques
            inhérents à l’Internet et
            aux réseaux de communication pouvant entraîner notamment des interruptions d’accès ou ralentissements. Dans
            un
            tel cas, DIVYZ ne pourra pas être tenu responsable d’un défaut d’accès ou d’une diminution des
            performances.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur est averti de ce que l’accès à la Plateforme
            n’est pas garanti comme
            étant ininterrompue, sécurisée, sans erreur, et exempt de malwares ou d’autres éléments
            nuisibles.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Suspension et suppression du Compte Utilisateur
            DIVYZ sera en droit de suspendre les Services ou de supprimer le Compte Personnel de l’Utilisateur dans les
            cas suivants :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - L’Utilisateur a manqué à l’une de ses obligations
            contractuelles et notamment n’a
            pas renseigné les informations et communiqué les documents nécessaires à la Procédure KYC ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - DIVYZ suspecte de l’Utilisateur qu’il effectue une
            utilisation non-conforme ou
            frauduleuse des Services ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - L’Utilisateur empêche DIVYZ d’exécuter les obligations
            qui lui incombent au titre
            des présentes Conditions générales ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - L’Utilisateur utilise plusieurs Comptes
            Utilisateurs.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> DIVYZ informe l’Utilisateur de sa décision de suspension ou
            de suppression, par
            courrier électronique, en indiquant le motif de suspension/suppression, sa portée, et la durée en cas de
            suspension.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur peut également décider de procéder à la
            suppression de son compte, à
            tout moment.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> La suppression du Compte utilisateur, que celle-ci soit à
            l’initiative de
            l’Utilisateur ou de DIVYZ, entraîne la suppression des données stockées sur la Plateforme et l’accès aux
            services proposés par les différents prestataires.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> La suppression du Compte Utilisateur sera effective
            :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Dans les quinze (15) jours suivant la notification de
            suppression par DIVYZ
            ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - A compter de la confirmation de suppression du compte par
            l’Utilisateur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 4 . Services
            Participation à des Opérations de Financement via des Contributions
            L’Utilisateur, investisseur non professionnel mais actif, en recherche d’un revenu lisible tiré du marché
            immobilier locatif avec un alignement d’intérêts avec le Porteur de Projet proposé sur la Plateforme, mais
            sans lien capitalistique avec ce dernier ou ses associés ni droit sur sa gouvernance, a sélectionné une
            Opération de Financement sur la Plateforme après analyse des données disponibles sur les perspectives
            d’encaissement de loyers. Souhaitant soutenir financièrement le Porteur de Projet pour la réalisation du
            Projet, en contrepartie d’une quote-part des loyers encaissés par le Porteur de Projet, l’Utilisateur
            déclare
            disposer à cette fin de ressources suffisantes et avoir eu la possibilité de saisir les caractéristiques et
            les risques inhérents à la participation à l’Opération de Financement du Porteur de Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur peut contribuer à une ou plusieurs Opérations
            de Financement de biens
            immobiliers à vocation locative, portées par ou plusieurs Porteurs de Projets, via la signature respective
            d’un Contrat de Partage de Revenus Futurs avec le Porteur de Projet en question.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Opération de Financement débute par l’ouverture d’une
            Phase de Contribution
            durant laquelle l’Utilisateur peut participer à l’Opération de Financement. La durée de la présente Phase de
            Contribution est expressément indiquée sur la Page Projet de l’Opération de Financement en
            question.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur est libre du montant de son Versement
            Individuel lors de sa
            Contribution, étant précisé que celui-ci devra en tout état de cause être supérieur ou égal au Versement
            Individuel Minimal et inférieur ou égal au Versement Individuel Maximal. Toutes les informations nécessaires
            à
            ces montants figurent sur la Page Projet ainsi que dans la documentation contractuelle liée au Projet à
            financer.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> La Contribution emporte l’acceptation de l’ensemble des
            conditions et modalités de
            l’Opération de Financement qui seront détaillées dans le Projet à financer et dans le Contrat de Partage de
            Revenus Futurs afférant à l’Opération de Financement.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> La Contribution se concrétisera par la signature
            électronique du Contrat de Partage
            de Revenus Futurs reprenant les principales conditions et modalités de l’Opération de Financement et/ou de
            tous actes y attachés.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Il est précisé que ni DIVYZ, ni le Porteur de Projet ne
            perçoivent de commissions
            des Contributeurs sur les Contributions. DIVYZ sera rémunérée par le Porteur de Projet et ses commissions
            seront prélevées sur le montant total des Contribution.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Conditions de paiement</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Si au terme de la Phase de Contribution, le Seuil de Succès
            n’a pas été atteint, la
            Contribution est alors reversée du compte du Porteur de Projet au compte de l’Utilisateur ayant réalisé la
            Contribution.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Si au terme de la Phase de Contribution et de la Phase de
            Confirmation, soit 15
            jours après la première Phase de Contribution, le Seuil de Succès a été atteint ou dépassé, la somme ne
            pourra
            plus, dès lors, être remboursé au Contributeur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Annulation d’une Opération de Financement</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En cas de résiliation des Projets en cours de Phase de
            Contribution, l’Opération de
            Financement devra alors être arrêtée et les Contributions afférentes donneront lieu à remboursement des
            Contributeurs concernés.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Droit de Redevance</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Il est notamment rappelé que le droit de créance sur le
            Porteur de Projet ne
            garantit pas au Contributeur la perception de revenus ni ne fait de ce dernier un associé ou un créancier
            inconditionnel du Porteur de Projet et que l’investissement correspondant n’entre pas dans le cadre du
            Règlement (UE) 2020/1503 du Parlement européen et du Conseil du 7 octobre 2020 relatif aux prestataires
            européens de services de financement participatif pour les entrepreneurs. Dès lors, le Contributeur
            reconnaît
            que seules les dispositions impératives du code civil et, dans la mesure où il a la qualité de consommateur,
            celles du code de la consommation, régissent le Contrat de Partage de Revenus Futurs qu’il signera avec le
            Porteur de Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Au terme de l’Opération de Financement réussie, le
            Contributeur aura acquis un
            droit de créance sur le Porteur de Projet, lequel sera alors tenu de verser une Redevance à ses
            Contributeurs
            dans les conditions précisées dans le Contrat de Partage de Revenus Futurs conclus avec eux.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Cette Redevance est payée en ayant recours aux services de
            paiement proposés sur le
            Site et est reversée sur le compte bancaire du Contributeur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Reliquat</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Dans le cadre du versement d’une Redevance, si lors de la
            répartition de celle-ci
            entre les Contributeurs, il venait à rester, par le mécanisme des arrondis, un reliquat de Redevance qui ne
            pourrait - en raison de son faible montant - être réparti équitablement entre les Contributeurs à due
            proportion de leur Contribution, celui-ci serait définitivement acquis par le Porteur de
            Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Cession du Contrat de Partage de Revenus Futurs
            La cession par un Contributeur du contrat l’unissant à la Société et peut intervenir au profit de tout
            Utilisateur enregistré sur la Plateforme à condition d’être accompagné de la cession du contrat unissant le
            Contributeur au Porteur de Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Dans le cas d’un contrat de cession établi par écrit entre
            les parties à cette
            opération, l’Utilisateur acheteur aura pour devoir de notifier la présente cession à la Société et au
            Porteur
            de Projet à leur siège social. La cession leur sera opposable au jour de la confirmation par chacun d’eux de
            la prise en compte de cette opération.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> A compter de la date d’opposabilité ci-dessus et
            l’acceptation par le cessionnaire
            des Conditions générales, du contrat de partage de revenus avec le Porteur de Projet et des conditions du
            Prestataire de Services de Paiement, la cession sera définitive et la Société versera les Redevances au
            cessionnaire uniquement, sans recours du Contributeur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> La Société n’a aucun mandat pour la recherche d’un
            acquéreur du contrat ou des
            Droits à Redevance pour le compte du Contributeur. L’attention du Contributeur est attirée sur le risque de
            liquidité limitée de son investissement au titre des Conditions générales.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Obligations fiscales
            Il appartient au Porteur de Projet et aux Contributeurs de respectivement s’acquitter des droits et taxes
            applicables à l’octroi des Redevances. Le Porteur de Projet ne saurait en aucun cas se substituer aux
            Contributeurs, ni même les conseiller sur la nature et le détail de leurs obligations.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Il appartient à chaque Contributeur de vérifier son
            éligibilité à bénéficier de
            toute déduction ou réduction d’impôt sur tout ou partie des Contributions effectuées dans le cadre d’un
            Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 5 . Conditions particulières applicables au Porteur
            de Projet</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Financement contre Redevance sur les revenus du
            Projet</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les acquisitions d’immeubles à usage locatif prévus par le
            Porteur de Projet sont
            en tout ou partie financées par les Contributeurs via un Contrat de Partage de Revenus Futurs du Projet,
            calculés selon les cas sur référentiel convenu.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le Porteur de Projet s’engage à verser aux Contributeurs
            une partie de ces revenus,
            soit les loyers hors charges collectés par le Porteur de Projet (les « Revenus »), exprimée en pourcentage
            de
            ces derniers et définie par le Porteur de Projet avant le début de l’Opération de Financement et aux
            conditions définies dans le Contrat de Partage de Revenus Futurs utilisé dans le cadre de l’Opération de
            Financement. Les Droits de Redevance détenus par le Contributeur ouvrent à ce dernier le droit de percevoir,
            sous réserve de trésorerie suffisante, une quote-part individuelle des revenus du Porteur de Projet dont le
            montant est déterminé discrétionnairement par le Porteur de Projet selon le Projet à financer et, le cas
            échéant, d’une quote-part exceptionnelle en fin de Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> è Engagement du Porteur de Projet en cas de réussite de son
            Opération de
            Financement</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le Porteur de Projet s’engage à remplir toutes ses
            obligations vis-à-vis des
            Contributeurs, notamment à renseigner des informations claires, sincères, objectives et exactes concernant
            le
            Projet et accomplir ce dernier tel que défini dans le Projet à financer et à les rétribuer des Redevances
            décrites dans le Projet à financer.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le Porteur de Projet s’engage à ne pas proposer ni fournir
            de contenu illicite et
            notamment à caractère raciste, discriminatoire, diffamatoire, injurieux, xénophobe, incitant à la violence,
            violant l’image de tiers, portant atteinte à l’ordre public, contraire aux lois, violant le secret
            professionnel, la propriété et le droit des marques, des brevets ou de toute autre création intellectuelle
            ou
            industrielle appartenant à des tiers, dangereux ou falsifié.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> D’une manière générale, le Porteur de Projet s’engage à
            agir d’une manière prudente
            et à mener son activité et ses Opérations de Financement diligemment, efficacement et en conformité avec la
            présentation communiquée sur la Plateforme.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Pour l’accomplissement de son activité et des diligences et
            prestations ci-dessous
            énoncées, le Porteur de Projet s’engage à donner ses meilleurs soins, conformément aux règles de
            l’art.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur ne pourra pas en outre rechercher la
            responsabilité de la Plateforme
            en cas de défaillance du Porteur de Projet ou de manquement par celui-ci à ses obligations.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En cas de manquement par le Porteur de Projet à l’une
            quelconque de ses
            obligations, celui-ci devra indemniser la Plateforme et l’Utilisateur pour l’ensemble des dommages, directs
            et
            indirects, qui en découleront.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 6 . Droit de rétractation et remboursement
            En application de l’article L. 221-18 du Code de la consommation, le Contributeur dispose d’un délai de
            quatorze (14) jours calendaires pour exercer son droit de rétractation, sans avoir à justifier de motif ni à
            supporter de pénalités.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le délai pendant lequel peut s’exercer le droit de
            rétractation court à compter du
            jour où l’Utilisateur participe à l’Opération de Financement.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Pour exercer ce droit de rétractation, le Contributeur doit
            renvoyer par lettre
            recommandée avec avis de réception à l’adresse du siège du Porteur de Projet le bordereau unique de
            rétractation joint en annexe du Contrat de Partage de Revenus Futurs après l’avoir rempli, daté et signé
            (ou,
            en cas de signature du Contrat de Partage de Revenus Futurs par voie électronique, par l’envoi par le même
            mode du bordereau de rétractation et la conservation de son accusé de réception électronique).</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> La rétractation prend effet au jour de la réception par le
            Porteur de Projet du
            formulaire de rétractation dûment rempli par le Contributeur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le compte bancaire du Contributeur sera alors crédité de la
            somme versée lors de la
            Contribution, déduction faite des éventuels frais (bancaires et liés au Services, en ce compris toute
            commission versée au Prestataire de Services de Paiement) plafonnés à un total de 5 % du montant, dans un
            délai de trente (30) jours. Cette opération se fera dans les meilleures conditions et au plus tard dans les
            trente (30) jours à compter du jour où le Contributeur communique au Porteur de Projet sa volonté de
            rétracter, soit à compter de la réception par le Porteur de Projet du formulaire de rétractation dûment
            rempli
            par le Contributeur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le Contributeur a toutefois la possibilité, lors de la
            confirmation de sa
            Contribution, de renoncer expressément à son droit de rétractation, dans le respect des dispositions de
            l'article L.221-25 du Code de la consommation, pour valider immédiatement sa Contribution en renvoyant, dans
            les mêmes conditions, le bordereau de renonciation joint en annexe du Contrat conclu avec le Porteur de
            Projet
            après l’avoir rempli, daté et signé.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 7 . Absence de garanties légales</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> DIVYZ n’a pas la qualité de vendeur d’un bien et se
            contente de mettre à
            disposition du Contributeur un accès à un droit à partage des revenus générés par un Porteur de Projet – tel
            que contractuellement prévu au sein du Contrat de Partage de Revenus Futurs - au travers d’une Redevance. Le
            Contributeur reconnaît dès lors l’inapplicabilité du régime légal du droit de la vente et des dispositions
            du
            code de la consommation y afférentes ainsi que l’absence d’application du régime de l’intermédiation en
            biens
            divers visé à l’article L. 551-1 du Code monétaire et financier.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Il est notamment rappelé que le droit de créance sur le
            Porteur de Projet ne
            garantit pas au Contributeur la perception de revenus ni ne fait de ce dernier un associé ou un créancier
            inconditionnel du Porteur de Projet et que l’investissement correspondant n’entre pas dans le cadre du
            Règlement (UE) 2020/1503 du Parlement européen et du Conseil du 7 octobre 2020 relatif aux prestataires
            européens de services de financement participatif pour les entrepreneurs. Dès lors, le Contributeur
            reconnaît
            que seules les dispositions impératives du code civil et, dans la mesure où il a la qualité de consommateur,
            celles du code de la consommation, régissent le Contrat de Partage de Revenus Futurs qu’il signera avec le
            Porteur de Projet.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 8 Droit à l’information</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> À tout moment au cours de la relation contractuelle,
            l’Utilisateur a le droit, s’il
            en fait la demande, de recevoir les conditions contractuelles sur un support papier. En outre, l’Utilisateur
            a
            le droit de changer les techniques de communication à distance utilisées.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 9 . Droit de Réclamation
            Tout Utilisateur mécontent ou insatisfait par les Services proposés par DIVYZ et inscrit sur la Plateforme
            peut contacter DIVYZ pour déposer une Réclamation à l’adresse électronique suivante : support@DIVYZ.COM ou à
            l’adresse postale suivante : Hivest Service réclamation, 18 rue de Penthièvre – 75008 PARIS</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> DIVYZ fera tout son possible pour résoudre et répondre à
            l’Utilisateur dans un
            délai raisonnable. Exceptionnellement, DIVYZ informera l’Utilisateur par courriel des causes d’un éventuel
            retard.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 10 . Hébergement
            DIVYZ assure l’hébergement de la Plateforme par l’intermédiaire de Google Cloud Platform, Inc.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur s’engage à prendre connaissance de ces
            conditions et notamment les
            garanties et responsabilités, et à les respecter.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> DIVYZ assure la sauvegarde des données de
            l’Utilisateur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 11 . Responsabilité
            L’Utilisateur est averti des aléas techniques inhérents à l’Internet et aux réseaux de communication pouvant
            entraîner des interruptions d’accès ou ralentissements. Dans un tel cas, DIVYZ ne pourra pas être tenu
            responsable d’un défaut d’accès ou d’une diminution des performances.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur reconnaît notamment que :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Les transmissions de données, incluant les données, sur
            des réseaux de
            télécommunications, ne bénéficient que d’une fiabilité technique relative, celles-ci circulant sur des
            réseaux
            hétérogènes aux caractéristiques et capacités techniques diverses, qui sont parfois saturés à certaines
            périodes de la journée et/ou peuvent connaître des dysfonctionnements ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - DIVYZ ne peut être en aucun cas responsable de la
            fiabilité des transmissions des
            données, des temps d’accès, des éventuelles restrictions d’accès sur des réseaux et/ou serveurs spécifiques
            connectés au réseau utilisé par la Plateforme ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Les données circulant sur des réseaux de
            télécommunication, malgré les modes de
            protection existants et mis en œuvre par DIVYZ, peuvent faire l’objet de détournements éventuels, et
            qu’ainsi
            la communication des données, des codes d’accès, et plus généralement de toute information, est effectuée
            par
            l’Utilisateur à ses risques et périls.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En tout état de cause, l’Utilisateur reconnaît et accepte
            que DIVYZ ne soit pas
            tenu par une obligation de contrôle des données hébergées, l’Utilisateur étant seul responsable des données
            communiquées.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En particulier, l’Utilisateur sera seul responsable en cas
            de :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Saisie ou transmission incorrecte de données
            ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Altération ou suppression de données à la suite d’erreurs
            imputables à
            l’Utilisateur ;</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Autorisation d’accès délivrée par l’Utilisateur à des
            tiers,</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Non-respect des recommandations de DIVYZ, et de manière
            plus générale, de faits
            générateurs de dommages dont cette dernière n’a pas la maîtrise, tels que l’utilisation irrégulière et/ou le
            détournement de données et/ou des codes d’accès, faute ou négligence de l’Utilisateur, force majeure,
            perturbations des réseaux de télécommunication, de l’Internet et tout fait de tiers,</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Non-respect par l’Utilisateur des prescriptions légales
            ou
            réglementaires.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> De manière générale, DIVYZ est soumis à une obligation de
            moyen au titre des
            présentes Conditions générales.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En cas de mise en jeu de la responsabilité de DIVYZ, il est
            convenu que seuls les
            dommages directs seront susceptibles d’être indemnisés, à l’exception de tous dommages indirects tels que
            gain
            manqué, préjudice commercial, perte de chiffre d’affaires ou de bénéfices, trouble commercial quelconque,
            perte d’image de marque, perte de données, perte d’une chance, coût de l’obtention d’un produit, d’un
            service
            ou technologie de substitution.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Quoiqu’il en soit, DIVYZ ne pourra en aucun cas être tenue
            pour responsable de tout
            dommage causé par une interruption ou une baisse de service de l’opérateur de télécommunications, du
            fournisseur d’électricité, ou en raison d’un fait en relation ou provenant de l’inexécution fautive de ses
            obligations par l’Utilisateur, et/ou par un tiers.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Il est rappelé que DIVYZ n’est ni propriétaire ni
            gestionnaire du bien immobilier
            mis en location par le Porteur de Projet. Dès lors, DIVYZ ne saurait être tenue pour responsable des pertes
            liées à des insuffisances dans la performance de la gestion locative du bien (difficultés d’encaissement des
            loyers, insolvabilité des locataires).</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En toute hypothèse, la responsabilité de DIVYZ dans le
            cadre des présentes
            Conditions générales, pour quelque raison que ce soit, dans les limites des dispositions légales d’ordre
            public, est plafonnée à une somme maximale correspondant à 100% de la somme versée par le
            Contributeur.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}>
            Article 12 . Force majeure
            La responsabilité de l’Utilisateur et de DIVYZ ne peut être engagée lorsqu’elle résulte d’un cas de force
            majeure.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Au sens du Contrat, on entend par cas de force majeure tout
            événement irrésistible
            tant dans sa survenance que dans ses effets, imprévisible lors de la signature du Contrat et échappant au
            contrôle des Parties conformément aux dispositions de l’article 1218 du Code civil. Sont également
            considérés
            par les Parties comme cas de force majeure : acte de sabotage, attentat, défaillance d’Internet ou des
            réseaux
            de communication ou réseaux électriques, incendie ou autre accident hors du contrôle des
            Parties.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Dans ce cadre, la Partie empêchée le notifie à l’autre dans
            les plus brefs délais à
            compter de sa connaissance de la survenance de l’évènement.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Le cas de force majeure suspend les obligations empêchées
            ainsi que les obligations
            corrélatives de l’autre Partie. La suspension s’applique tant que dure l’évènement, sans que la durée de
            suspension ne puisse excéder trente (30) jours calendaires consécutifs à compter de la date de survenance.
            Au
            terme de cette période, chacune des Partie peut notifier à l’autre Partie sa décision de mettre fin à la
            relation contractuelle, à compter de la notification.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 13 . Propriété intellectuelle
            DIVYZ est et demeure titulaire de tous droits de propriété intellectuelle relatifs à tout élément de la
            Plateforme et de ses contenus mis à disposition de l’Utilisateur dans le cadre des présentes Conditions
            générales.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur n’acquière au titre des présentes aucun droit
            autre que la concession
            d’un droit d’accéder à la Plateforme et de l’utiliser dans les limites prévues aux présentes. La mise à
            disposition temporaire de la Plateforme dans les conditions prévues aux présentes ne saurait être analysée
            comme la cession d’un quelconque droit de propriété intellectuelle au bénéfice de l’Utilisateur, au sens du
            Code de la propriété intellectuelle.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En conséquence, le contenu de la Plateforme ne peut,
            conformément aux dispositions
            du Code de la propriété intellectuelle, faire l’objet d’une quelconque représentation ou reproduction,
            intégrale ou partielle, sur quelque support que ce soit, sans l’autorisation expresse et préalable de
            DIVYZ.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 14 . Politique de gestion des données personnelles
            et de cookies
            DIVYZ informe l’Utilisateur qu’il collecte, enregistre et traite certaines données que l’Utilisateur a
            fourni
            dans le cadre de l’utilisation de la Plateforme. Il s’agit des données suivantes :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> · État civil</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> · Document d’identité</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> · Numéro de compte bancaire</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> · Adresse postale et email</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> · Numéro de téléphone</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Ces données sont traitées uniquement dans le cadre des
            finalités suivantes
            :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Permettre à l’Utilisateur de créer un
            compte,</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> - Permettre à l’Utilisateur l’utilisation des
            Services.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> La base légale de ces traitements est l’exécution
            contractuelle et
            précontractuelle.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les données sont conservées uniquement durant le temps de
            l’utilisation des
            Services et, au plus tard, jusqu’à cinq (5) ans suivant la fin de la relation avec l’Utilisateur. En cas de
            cessation de la relation, elles peuvent faire l’objet d’un archivage intermédiaire pour satisfaire aux
            obligations légales de DIVYZ.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Conformément à la réglementation applicable, l’Utilisateur
            dispose de droits sur
            ses données : droit d’accès, droit de rectification, droit de suppression, droit à la portabilité, et droit
            de
            de ne pas faire l’objet d’une décision automatisée (profiling). L’utilisateur peut exercer ces doits en
            envoyant une requête à l’adresse email suivante support@DIVYZ.COM ou à l’adresse postale suivante : Hivest,
            18
            rue de Penthièvre – 75008 PARIS</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Si l’Utilisateur considère que ses droits ont été
            enfreints, il est invité à
            contacter DIVYZ aux coordonnées ci-dessus. L’Utilisateur est informé qu’il peut également déposer une
            plainte
            auprès de l’autorité compétente, la CNIL.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En outre, lors de l’utilisation de la Plateforme, des
            cookies sont déposés sur le
            terminal utilisé par l’Utilisateur (Ordinateur, tablette, smartphone, etc.), il s’agit des cookies suivants
            :</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Google Analytics : Suivi des visites</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Facebook : Suivi des visites et retargeting</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Un cookie est un fichier texte déposé lors de la visite
            d’un site ou de la
            consultation d’une page internet pour stocker diverses données techniques, et améliorer le parcours de
            l’Utilisateur sur un site.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 15 . Liens externes
            Les Services proposés sur la Plateforme peuvent contenir des liens vers des pages Internet externes,
            exploitées par des tiers, pour lesquelles DIVYZ décline toute responsabilité quant à l’accès, au contenu, et
            à
            l’utilisation.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 16 . Évolution
            Les présentes Conditions générales sont susceptibles d’évoluer à la suite d’une modification apportée par
            DIVYZ. Elles sont communiquées de manière automatique à l’Utilisateur lors de cette mise à
            jour.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> La nouvelle version des Conditions générales d’utilisation
            entre en vigueur dès sa
            publication.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les Parties conviennent que les Conditions générales
            applicables sont celles en
            vigueur au jour de l’utilisation des Services.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 17 . Application des conditions générales –
            Opposabilité et
            Preuve</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les présentes Conditions générales sont mises à la
            disposition de l’Utilisateur sur
            le Site Internet conformément à l’article 1127-1 du Code civil. Elles peuvent également être communiquées
            via
            email aux Utilisateurs sur simple demande, par courrier électronique ou courrier postal, en plus de la
            communication automatique et de leur validation à la création du compte Utilisateur et lors de la
            Contribution
            à un Projet de Financement ce qui vaut acceptation de leurs termes.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’archivage des communications, des services réalisés, des
            documents Utilisateurs
            et des factures est effectué sur un support fiable et durable conformément à l’article 1379 du Code civil.
            Tous ces éléments ont valeur probante entre les Parties. De manière générale, tout document informatique de
            DIVYZ fait preuve entre les Parties.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 18 . Langue des Conditions générales</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les présentes Conditions générales pourront être traduites
            en anglais afin de
            faciliter leur compréhension auprès d’Utilisateurs. Nonobstant cette traduction, il est expressément convenu
            entre les Parties qu’en cas de différend, seule la version française des présentes Conditions générales fera
            foi entre les Parties.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 19 . Intégralité
            Dans l’hypothèse où une ou plusieurs stipulations seraient frappées de nullité ou étaient déclarées
            contraires
            à l’ordre public ou de nature à créer un déséquilibre significatif, au regard d’une règle de droit en
            vigueur
            ou d’une décision judiciaire devenue définitive, les autres stipulations conserveraient toute leur force et
            leur portée.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Les Parties devraient alors remplacer la stipulation
            litigieuse par une stipulation
            correspondant à l’esprit et à l’objet du Contrat. Toutefois, l’absence d’accord sur cette nouvelle
            stipulation
            pourrait remettre en cause les termes de l’alinéa précédent.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Article 20. Droit applicable - Résolution des litiges
            La loi applicable aux présentes Conditions générales est la loi française.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> En cas de litige lié aux présentes Conditions générales,
            les Parties s’engagent à
            tenter une démarche amiable préalablement à la saisine du tribunal compétent.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> Conformément à l'ordonnance n°2015-1033 du 20 août 2015 et
            au décret d’application
            n°2015-1382 du 30 octobre 2015, tout différend ou litige dit de consommation, sous réserve de l’article L.
            612-1 du Code de la consommation, peut faire l’objet d’un règlement amiable par médiation.</Typography>

          <Typography variant="body1" sx={{ marginTop: 2 }}> L’Utilisateur peut également présenter ses réclamations
            éventuelles sur la
            plateforme de résolution des litiges mise en ligne par la Commission Européenne à l'adresse suivante :
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR</Typography>

        </ContainerWithLine>
      </ContainerWithLine>
    </>
  );
}

export default CGU;
