import { useNavigate } from 'react-router-dom';
import { registerWithEmailAndPassword } from '../../firebase';
import RegisterForm from '@hivest/integration/src/pages/Register/RegisterForm';
import registerValidator from '../../validators/RegisterValidator';
import { useCustomRouterContext } from '@hivest/integration/src/contexts/CustomRouterContext';

function RegisterFormLogic({ referrerCode }) {
  const navigate = useNavigate();
  const routes = useCustomRouterContext();

  const onSubmit = ({ setErrorMessage }) => ({ email, password, referrerCode, toggleMailingList }, { setSubmitting, setFieldError }) => {
    // When button submits form and form is in the process of submitting, submit button is disabled
    setErrorMessage(null);
    setSubmitting(true);

    registerWithEmailAndPassword(email, password, toggleMailingList, referrerCode)
      .then(() => {
        navigate(routes.registerSuccess.path);
      })
      .catch((err) => {
        console.log('-----', err)
        console.log('-----', err.code)
        if (err.code === 'auth/email-already-in-use') {
          setErrorMessage('Un compte existe déjà avec cette adresse e-mail.');
          return;
        }

        if (err.code === 'auth/invalid-email') {
          setFieldError('email', 'Adresse e-mail invalide.');
          return;
        }

        if (err.code === 'auth/weak-password') {
          setFieldError('password', 'Le mot de passe doit contenir au moins 6 caractères.');
          return;
        }

        if (err.code === 'auth/internal-error') {
          setErrorMessage('L\'inscription est temporairement désactivée.');
          return;
        }

        setErrorMessage(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <RegisterForm
      referrerCode={referrerCode}
      onSubmit={onSubmit}
      validationSchema={registerValidator}
    />
  );
}

export default RegisterFormLogic;
