import * as React from 'react';
import { Typography } from '@mui/material';
import { defaultTitleColor, globalThemeOptions, secondaryTitleColor } from '../../../styles';
import ContainerWithLine from '../../../components/ContainerWithLine';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Box from '@mui/material/Box';
import BoxElement from '../HowToJoinSection/BoxElement';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomButton from '../../../components/CustomButton/CustomButton';
import imageBlock1 from '../assets/block1.svg';
import imageBlock2 from '../assets/block2.svg';
import imageBlock3 from '../assets/block3.svg';
import { buttonPrimaryBackgroundColor, buttonTextColor } from '../../../components/CustomButton/styles';
import { Link } from 'react-router-dom';
import { useCustomRouterContext } from '../../../contexts/CustomRouterContext';
import { common } from '@mui/material/colors';

function HowToJoinSection() {
  const routes = useCustomRouterContext();

  return (
    <Box>
      <ContainerWithLine maxWidth="lg" sx={{ pb: { xs: 0, md: 4 } }}>
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          [globalThemeOptions.breakpoints.up('xs')]: {
            paddingTop: 2,
          },
          [globalThemeOptions.breakpoints.up('md')]: {
            paddingTop: 4,
          },
        }}>
        </ContainerWithLine>
        <Grid2
          container
          paddingTop={4}
          spacing={4}
          md={12}
          paddingBottom={8}
          sx={{
            paddingTop: { xs: 4, md: 4 },
            paddingBottom: { xs: 2, md: 4 },
          }}
        >
          <Grid2>
            <TypographyUnderlined
              variant="h2"
              fontWeight={800}
              lineHeight="1.35em"
              color={secondaryTitleColor}
              wrapperSX={{
                paddingLeft: (theme) => theme.spacing(2),
              }}
              extraElements={
                <Typography
                  variant="body1"
                  fontWeight="300"
                  lineHeight="1.6em"
                  paddingTop={4}
                >En 3 étapes simples et rapides vous pouvez accéder à des projets d’investissement
                  rémunérateurs</Typography>
              }
            >Comment rejoindre la communauté des investisseurs de Divyz?</TypographyUnderlined>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
      <BoxWithDiagonaleBackground padding={0} deg={6} offsetTop={200} offsetBottom={-800}>
        <ContainerWithLine maxWidth="lg" sx={{
          pb: { xs: 2, md: 4 },
          pl: { xs: 2, md: 4 }
        }}>
          {/*<img src={image} alt="Illustration" style={{ width: '100%' }} />*/}
          <Grid2
            container
            alignContent="center"
            alignItems="stretch"
            justifyContent="center"
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            <BoxElement
              md={4}
              bgColor={defaultTitleColor}
              image={imageBlock1}
            >
              <Typography variant="body1" fontSize="1.5em" component="h3" fontWeight={800} textAlign="center" color={common.white}>
                Inscription en quelques clics
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="center">
                Lors de la création de votre compte Divyz, certains documents requis par notre partenaire de paiement
                bancaire vous seront demandés (photo de votre pièce d’identité ou passeport)
              </Typography>
            </BoxElement>
            <BoxElement
              md={4}
              bgColor={buttonPrimaryBackgroundColor}
              image={imageBlock2}
            >
              <Typography variant="body1" fontSize="1.5em" component="h3" fontWeight={800} textAlign="center" color={common.white}>
                Validation de votre compte
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="center">
                Notre partenaire de paiement bancaire vérifie votre compte et le valide dans un délai de 3
                jours
              </Typography>
            </BoxElement>
            <BoxElement
              md={4}
              bgColor={buttonTextColor}
              image={imageBlock3}
            >
              <Typography variant="body1" fontSize="1.5em" component="h3" fontWeight={800} textAlign="center" color={common.white}>
                Sélection du projet d’investissement
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="center">
                Choisissez le projet immobilier et le montant que vous souhaitez investir pour l’acquisition de ce bien.
                Vous percevrez ensuite des loyers tous les mois en plus d’une plus value à la revente (si plus value constatée).
              </Typography>
            </BoxElement>
          </Grid2>
        </ContainerWithLine>
      </BoxWithDiagonaleBackground>
      <ContainerWithLine maxWidth="lg" sx={{ pb: 4 }}>
        <Grid2 container alignItems="center" direction="column"
               sx={{
                 paddingY: { xs: 2, md: 5 },
               }}
               paddingY={5}>
          <Grid2>
            <Link to={routes.register.path}>
              <CustomButton color="primary" variant="contained">Je crée mon compte</CustomButton>
            </Link>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
    </Box>
  )
}

export default HowToJoinSection
