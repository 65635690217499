import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActionCodeOperation } from '@firebase/auth';
import VerifyEmail from './VerifyEmail';
import ResetPassword from '@hivest/integration/src/pages/ResetPassword/ResetPassword';
import ResetPasswordLogic from '../ResetPassword/ResetPasswordLogic';

function FirebaseAccountManagementLogic() {
  const [searchParams] = useSearchParams();

  const oobCode = searchParams.get('oobCode');
  const mode = searchParams.get('mode');

  let renderComponent = null;

  console.log('---', searchParams, mode, oobCode, ActionCodeOperation.VERIFY_EMAIL);
  switch (mode) {
    case 'verifyEmail':
      renderComponent = <VerifyEmail oobCode={oobCode} />;
      break;

    case 'resetPassword':
      renderComponent = (
        <ResetPassword>
          <ResetPasswordLogic oobCode={oobCode} />
        </ResetPassword>
      );
      break;

    default:
      renderComponent = <div>Loading...</div>;
      break;
  }

  return (
    <>
      {renderComponent}
    </>
  );
}

export default FirebaseAccountManagementLogic;
