import React from 'react';
import { Alert, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './styles/error.css';
import Button from '../../../components/Button/Button';
import { routes } from '../../../routes';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

function CheckoutSuccess() {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="error_wrap">
      <Confetti width={width} height={height} recycle={false} />
      <Container fluid>
        <h2 className="heading"><span></span> Félicitation pour votre investissement!</h2>
        <Row>
          {/*{message && <Alert variant="danger">{message}</Alert>}*/}
          {/*{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}*/}

          <div className="error_text_box">
            <div className="error_text_wrap">
              <p>Merci d'avoir investis via Divyz! Vous allez recevoir un e-mail de confirmation sous peu afin d'avoir de plus amples informations sur les prochaines étapes. </p>
                <Button variant="primary" onClick={() => navigate(routes.propertyList.path)} className="error_primary">
                  Retour à la liste des biens
                </Button>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default CheckoutSuccess;
