import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useAuthState, useUpdatePassword } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase';

// Schema for yup
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    )
    .required('*Password required'),
  confirmPassword: Yup.string()
    .required('Please Enter your confirm password')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
});

const UpdatePasswordForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [updatePassword, updating, updatePasswordError] = useUpdatePassword(auth);

  return (
    <Container>
      <p className="box_heading">Mettre à jour votre mot de passe</p>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={validationSchema}
        onSubmit={async ({ password }, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          await updatePassword(password);

          setSubmitting(false);
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <Form.Group>
              <div className="mb-3">
                <Form.Label htmlFor="password">Mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  /* This name property is used to access the value of the form element via values.nameOfElement */
                  name="password"
                  placeholder="Mot de passe"
                  /* Set onChange to handleChange */
                  onChange={handleChange}
                  /* Set onBlur to handleBlur */
                  onBlur={handleBlur}
                  /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                  value={values.password}
                  /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
                  className={`${touched.password && errors.password ? 'error' : null} form-control`}
                  aria-describedby="passwordHelp"
                />
                {touched.password && errors.password ? (
                  <div className="text-danger">{errors.password}</div>
                ) : null}
              </div>
            </Form.Group>
            <Form.Group>
              <div className="mb-3">
                <Form.Label htmlFor="confirmPassword">Confirmation du mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  /* This name property is used to access the value of the form element via values.nameOfElement */
                  name="confirmPassword"
                  placeholder="Mot de passe confirmation"
                  /* Set onChange to handleChange */
                  onChange={handleChange}
                  /* Set onBlur to handleBlur */
                  onBlur={handleBlur}
                  /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                  value={values.confirmPassword}
                  /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
                  className={`${touched.confirmPassword && errors.confirmPassword ? 'error' : null} form-control`}
                  aria-describedby="passwordHelp"
                />
                {touched.confirmPassword && errors.confirmPassword ? (
                  <div className="text-danger">{errors.confirmPassword}</div>
                ) : null}
              </div>
            </Form.Group>

            <div className="d-grid gap-2">
              <Button variant="primary" type="submit" disabled={isSubmitting} className="mt-4">
                Update
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};


export default UpdatePasswordForm;
