import React from "react";
import { styled } from '@mui/system';

import imageHandIcon from "../../../assets/images/hand-icon.png";
import imageUserIcon from "../../../assets/images/user-icon.png";
import imageWalletIcon from "../../../assets/images/wallet-icon.png";
import { useAuthValue } from '@hivest/frontend/src/contexts/AuthContext';
import { getUserName } from '@hivest/frontend/src/utils/helpers';

const Heading = () => {
  const HandIcon = styled('img')({
    display: 'inline',
    width: '4.5rem',
    height: '4.5rem',
    objectFit: 'contain',
    verticalAlign: 'sub',
    marginBottom: '0.9rem',
  });

  const Info = styled('div')(({ theme }) => ({
    width: '13rem',
    height: '4.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(198, 200, 215, 0.5)',
    boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
    borderRadius: '5px',
    marginLeft: '4rem',
  }));

  const InfoImg = styled('img')({
    width: '2.3rem',
    height: '2.3rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
  });

  const UserContent = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'none',
    marginBottom: '5rem',
    '@media only screen and (max-width: 1023px)': {
        display: 'block',
    }
  }));

  const UserImg = styled('img')({
  position: 'absolute',
  left: 0,
  top: '-8px',
  width: '5.8rem',
  height: '5.8rem',
  borderRadius: '100%',
});

const Heading = styled('h1')({
  margin: '0 0 5rem',
  '@media (max-width: 429px)': {
    fontSize: '3.2rem',
    lineHeight: '4.8rem',
  },
  '@media only screen and (max-width: 767px)': {
      width: '100%',
  },
  '@media only screen and (max-width: 1023px)' : {
    'margin': '0 0 3rem',
  }
});

  const { currentUser, currentUserData } = useAuthValue()

  return (
    <>
      <Heading>
        Hello {getUserName(currentUser, currentUserData)}{" "}{" "}
        <HandIcon src={imageHandIcon} alt="" />
      </Heading>
      <UserContent>
        <UserImg src={imageUserIcon}  alt="" />
        <Info>
          <InfoImg src={imageWalletIcon} alt="" />
          <p>122€ </p>
        </Info>
      </UserContent>
    </>
  );
};

export default Heading;
