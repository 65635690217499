export const revenusData = {
  title: "Revenus",
  estimatedAnnualIncome: "4,1%",
  acquisition: [
    {
      label: "Prix d’acquisition",
      value: "-",
      price: "90000€",
    },
    {
      label: "Frais Brik Club",
      value: "5,0%",
      price: "4500€",
    },
    {
      label: "Frais de notaire",
      value: "7,1%",
      price: "6400€",
    }
  ],
};
