import * as React from 'react';
import { Stack, ThemeProvider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { defaultSubTextColor, defaultTextColor, defaultTitleColor, globalThemeOptions, greyColor } from '../../styles';
import Box from '@mui/material/Box';
import ContainerWithLine from '../ContainerWithLine';
import Grid2 from '@mui/material/Unstable_Grid2';
import { common } from '@mui/material/colors';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';
import imageCreativeIcon from './assets/creativeIcon.png';
import imageEmailIcon from './assets/emailIcon.png';
import imageCallCenter from './assets/callCenterIcon.png';
import { buttonPrimaryBackgroundColor } from '../CustomButton/styles';

function WeDoAllForYou() {
  const routes = useCustomRouterContext();

  return (
    <Box marginTop={10}>
      <ContainerWithLine disableGutters maxWidth="lg" sx={{
      }}>
        <Grid2 container bgcolor={defaultTextColor}
               sx={{
                  padding: { xs: 2, md: 8 },
               }}
               >
          <Grid2 md={12} color={common.white} sx={{ marginBottom: 2 }}>
            <Typography fontWeight={800} fontSize={"2.5em"}>Une question sur Divyz ou sur nos projets?</Typography>
          </Grid2>
          <Grid2 md={3} color={common.white} display="flex" flexDirection="row">
              <Box component="img" src={imageCreativeIcon} width={76} />
              <Box component={Link}
                   to={routes.register.path}
                   fontWeight={400}
                   lineHeight="1.5em"
                   color={buttonPrimaryBackgroundColor}
                   fontSize={"1.5em"}
                   sx={{ textDecoration: 'none'}}
              >Découvrez notre
                rubrique FAQ</Box>
          </Grid2>
          <Grid2 md={3} mdOffset={1} color={common.white} display="flex" flexDirection="row">
              <Box component="img" src={imageEmailIcon} width={76} />
              <Box component={Link}
                   to={routes.register.path}
                   fontWeight={400}
                   lineHeight="1.5em"
                   color={buttonPrimaryBackgroundColor}
                   fontSize={"1.5em"}
                   sx={{ textDecoration: 'none'}}
              >Contactez nous
                par email</Box>
          </Grid2>
          <Grid2 md={3} mdOffset={1} color={common.white} display="flex" flexDirection="row">
              <Box component="img" src={imageCallCenter} width={76} />
              <Box component={Link}
                   to={routes.register.path}
                   fontWeight={400}
                   lineHeight="1.5em"
                   color={buttonPrimaryBackgroundColor}
                   fontSize={"1.5em"}
                   sx={{ textDecoration: 'none'}}
              >Prendre RDV
                avec nos experts</Box>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
    </Box>
  )
}

export default WeDoAllForYou
