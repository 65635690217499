import { auth } from '../firebase';

export async function invest(contractAddress, amount) {
  const tokenId = await auth.currentUser.getIdToken(true)

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/${contractAddress}/invest`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenId}`
    },
    body: JSON.stringify({ amount: amount.toString() })
  }).then(res => res.json())
    .then(res => console.log(res));
}

export async function preInvest(propertyUID, amount) {
  const tokenId = await auth.currentUser.getIdToken(true)

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/${propertyUID}/preinvest`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenId}`
    },
    body: JSON.stringify({ amount: parseInt(amount.toString()) })
  }).then(res => res.json())
    .then(res => console.log(res));
}
