import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { common } from '@mui/material/colors';
import { Stack } from '@mui/material';

// @ts-ignore
function BasicCard({ bgColor, image, children, ...props }) {
  return (
    <Grid2
      alignContent="center"
      margin={2}
      padding={5}
      sx={{
        borderRadius: 0,
        color: common.white,
        backgroundColor: bgColor,
      }}
      {...props}
    >
      <Stack direction="column" alignItems="center" spacing={4} justifyContent="center">
        <img src={image} alt="image1" />
        {children}
      </Stack>
    </Grid2>
  );
}

export default BasicCard;
