import './styles/loginbutton.css';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2';
import { routes } from '../../routes';
import { secondaryTitleColor } from '@hivest/integration/src/styles';
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { common } from '@mui/material/colors';

export default function RegisterSuccessProvider({ children }) {
  return (
    <Grid2 container>
      <Grid2 mdOffset={4} md={4}>
        <Box
          sx={{
            background: common.white,
            padding: { xs: 2, md: 4 },
            boxShadow: '40px 40px 100px rgba(220, 225, 237, 0.5)',
          }}>
          <Typography variant="h3" color={secondaryTitleColor} fontWeight={500} mb={4} sx={{
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}>Welcome!</Typography>
          <div className="social_connection">
            <Alert variant="success">Bienvenue sur Hivest! Vous pouvez maintenant vous connecter en cliquant ci-dessous.</Alert>
            <Box className="form_footer" mt={4}>
              <p><Link to={routes.login.path}>Connectez-vous</Link></p>
            </Box>
          </div>
          {children}
        </Box>
      </Grid2>
    </Grid2>
  );
}
