import React from 'react';
import { useAuthValue } from '../../../contexts/AuthContext';
import Profile from '@hivest/integration/src/pages/Profile/Profile'

function ProfileWrapper() {
  const { currentUser } = useAuthValue()
  return (
    <div className="profile_wrapper">
      <Profile user={currentUser} />
    </div>
  );
}

export default ProfileWrapper;
