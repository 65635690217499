import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/system';
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import imageWhiteLogo from "../../assets/images/logo-white.svg";
import imageF2pr from "../../assets/images/logo-f2pr.svg";

const Footer = () => {
  const FooterContainer = styled('div')`
  position: relative;
  padding: 28.4rem 0 0;
  margin-top: -32rem;
  width: 100%;
  height: 100%;
  background: var(--dark-rock-blue);
  clip-path: polygon(0% 0%, 100% 37.5%, 100% 100%, 0% 100%);

  .container {
  width: 100%;
  max-width: 120rem;
  margin: auto;
  padding: 0 3.8rem;
}

  .logo {
    max-width: 16.5rem;
  }

  .footerColumn {
    h4 {
      color: var(--white);
      margin-bottom: 2.4rem;
      font-weight: 700;
    }

    ul.footerNav {
      li {
        font-size: 1.6rem;
        line-height: 2.4rem;
        letter-spacing: 0.02em;
        color: var(--light-grey);
        margin-bottom: 1.7rem;
        cursor: pointer;

        a {
          color: var(--light-grey);
          text-decoration: none;

          &:hover,
          &:focus {
            color: var(--secondary);
          }
        }
      }

      li:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 1.4rem;
      list-style: 2rem;
      font-weight: 400;
      color: var(--white);
      margin-bottom: 1.6rem;
    }

    .lemonway {
      max-width: 14.2rem;
      margin-bottom: 1.6rem;
    }

    .f2pr {
      max-width: 5.8rem;
    }
  }

  .coyprightContent {
    position: relative;
    padding: 3.6rem 0;
    margin-top: 8.6rem;
    border-top: 1px solid var(--off-white);

    .privacyContent {
      a {
        color: var(--grey);
        cursor: pointer;
        text-decoration: none;

        &:hover,
        &:focus {
          color: var(--secondary);
        }
      }
    }

    p {
      color: var(--grey);
    }
  }

  .socialIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      width: 3.6rem;
      height: 3.6rem;
      background: var(--dark-green);
      border-radius: 8px;
      margin-left: 1.6rem;
      color: var(--white) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      a {
        color: var(--white);
        display: inherit;
        text-decoration: none;
      }

      &:hover {
        background: var(--secondary);
      }

      svg {
        width: 2.2rem;
        height: 2.2rem;
      }
    }

    &.mobileOnly {
      display: none;

      @media (max-width: 767px) {
        display: flex;
        justify-content: flex-start;
        .container {
          padding: 0 3.4rem;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 16.6rem 0 0;
    margin-top: -18rem;
    clip-path: polygon(0% 0%, 100% 6.3%, 100% 100%, 0% 100%);
  }

  @media (max-width: 767px) {
    .socialIcons.mobileOnly {
      display: flex;
      justify-content: flex-start;
    }

    .coyprightContent .innerContent.last {
      display: none;
    }

    .footerColumn ul.socialIcons {
      margin-top: 4rem;
    }

    .footerColumn ul.socialIcons li {
      margin: 0 1.6rem 0 0;
    }
  }

  @media (max-width: 599px) {
    .coyprightContent p.text-center {
      text-align: left;
    }

    .coyprightContent {
      margin-top: 4rem;
      padding-bottom: 10px;
    }

    .socialIcons li:hover {
      background: none;
    }
  }
`;
  return (
      <FooterContainer>
      <div className="container">
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="footerColumn">
                <img src={imageWhiteLogo} className="logo" alt="" />
                <ul className="socialIcons mobileOnly">
                  <li>
                    <InstagramIcon />
                  </li>
                  <li>
                    <LinkedInIcon />
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="footerColumn">
                <h4>Information</h4>
                <ul className="footerNav">
                  <li>
                    <a href="#">A propos</a>
                  </li>
                  <li>
                    <a href="#">Fonctionnement</a>
                  </li>
                  <li>
                    <a href="#">Projets</a>
                  </li>
                  <li>
                    <a href="#">Connexion</a>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="footerColumn">
                <h4>Confidentialité</h4>
                <ul className="footerNav">
                  <li>
                    <a href="/cgu">CGU Hivest</a>
                  </li>
                  <li>
                    <a href="/ml">Mention légales</a>
                  </li>
                  <li>
                    <a href="#">FAQ</a>
                  </li>
                  <li>
                    <a href="#">Support</a>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="footerColumn">
                <h4>Partenaires</h4>
                <p>
                  Hivest est membre de <br /> la fédération
                </p>
                <img src={imageF2pr} className="f2pr" alt="" />
              </div>
            </Grid>
          </Grid>
        </Box>
        <div className="coyprightContent">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} lg={4}>
                <div className="innerContent">
                  <p className="privacyContent">
                    <a href="#">Politique de confidentialité</a>
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <div className="innerContent">
                  <p className="text-center">© Hivest 2022 - 2023 </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <div className="innerContent last">
                  <ul className="socialIcons">
                    <li>
                      <a href="#">
                        <InstagramIcon />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <LinkedInIcon />
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
