import googleAuthImage from './assets/google-auth.svg';
import Button from '@mui/material/Button';
import * as React from 'react';
import { common } from '@mui/material/colors';
import { greyColor } from '../../styles';
import { alpha, decomposeColor } from '@mui/material';

type LoginSocialButtonProps = {
  description: string;
  onClick: () => void;
}

function LoginSocialButton({ description, onClick }: LoginSocialButtonProps) {
  console.log(alpha(greyColor, 0.5))
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      startIcon={<img src={googleAuthImage as string} alt="Google auth" />}
      sx={{
        width: '100%',
        background: common.white,
        borderRadius: 1,
        boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
        borderStyle: 'solid',
        borderColor: alpha(greyColor, 0.5),
        borderWidth: 1,
        textTransform: 'none',
        fontWeight: 700,
        padding: 1.5,

        '&:hover': {
          background: '#F4F4F8',
          boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
        },
        '&:active': {
          background: common.white,
          boxShadow: 0,
        },
    }}
    >{description}</Button>
  )
}

export default LoginSocialButton;
