import JsPDF from 'jspdf';

import { CustomImage } from './CustomImage';
import { kycProjectUploadDocuments } from '../../../../actions/projects';

const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

export const fileToImageURL = (file) => {
  return new Promise((resolve, reject) => {
    const image = new CustomImage(file.type);

    image.onload = () => {
      resolve(image);
    };

    image.onerror = () => {
      reject(new Error('Failed to convert File to Image'));
    };

    image.src = URL.createObjectURL(file);
  });
};

export const normalizeImage = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image;
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0, this.width, this.height);

      return resolve({
        imageData: canvas.toDataURL("image/jpeg"),
        width: this.width,
        height: this.height
      });
    }
    img.crossOrigin = "";  // for demo as we are at different origin than image
    img.src = URL.createObjectURL(file);

    img.onerror = () => {
      reject(new Error('Failed to convert File to Image'));
    };
  })
};

export const generatePdfFromImages = async (files) => {
  const doc = new JsPDF();

  console.log(files, 'ff');

  const images = await Promise.all(files.map(normalizeImage));

  doc.deletePage(1);

  images.forEach((image) => {
    const isLandscapeImage = image.width >= image.height;

    if (isLandscapeImage) {
      doc.addPage(null, 'l');
      doc.addImage({
        imageData: image.imageData,
        format: 'image/jpeg',
        x: 0,
        y: 0,
        width: 297,
        height: 210,
        compression: 'FAST',
      });
    } else {
      doc.addPage(null, 'p');
      doc.addImage({
        imageData: image.imageData,
        format: 'image/jpeg',
        x: 0,
        y: 0,
        width: 210,
        height: 297,
        compression: 'FAST',
      });
    }
  });

  return doc.output('blob');
};

export const pdfGeneratorHandler = async (values, projectId, prefix = 'kyc') => {
  for await (const document of values.documents) {
    document.file = await generatePdfFromImages(document.files);
  }

  await kycProjectUploadDocuments(values.documents, projectId, prefix)();
};
