import React from 'react';
import { useNavigate } from 'react-router-dom';

function AdminKYCStep4Page() {
  const navigate = useNavigate();

  return (
    <>
      <div>
        MERCI D'AVOIR SOUMIS VOTRE KYC
      </div>
    </>
  )
}

export default AdminKYCStep4Page;
