import * as React from 'react';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { common } from '@mui/material/colors';
import { buttonPrimaryBackgroundColor } from '../../../components/CustomButton/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import buildingsBackground from '../../../assets/images/buildings-background.svg';

function TalkToSalesSection() {
  return (
    <Box position="relative">
      <BoxWithDiagonaleBackground
        deg={6}
        offsetTop={0}
        offsetBottom={0}
        color="transparent"
      >
        <Box
          sx={{
            backgroundImage: `url(${buildingsBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '0 4em',
            width: '100%',
            height: 827,
          }}
        >
        </Box>
      </BoxWithDiagonaleBackground>
    </Box>
  )
}

export default TalkToSalesSection
