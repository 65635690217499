import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import f2prLogo from '../../../assets/images/logo-f2pr.svg';
import { Divider, Link, Typography } from '@mui/material';
import { buttonTextHoverColor } from '../../../components/CustomButton/styles';
import Box from '@mui/material/Box';
import { common } from '@mui/material/colors';
import Container from '@mui/material/Container';
import logoWhite from '../../../assets/images/logo-white.svg';
import styled from 'styled-components';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useCustomRouterContext } from '../../../contexts/CustomRouterContext';
import CustomLink from '../../../components/CustomLink/CustomLink';

const FooterWrapper = styled('div')`
  ul {
    list-style: none;
    padding: 0;
    li {
      padding: 1em 0;
    }
  }
`

function Footer() {
  const routes = useCustomRouterContext()

  return (
    <FooterWrapper>
      <Box
        sx={{
          backgroundColor: buttonTextHoverColor,
        }}
      >
        <BoxWithDiagonaleBackground
          deg={6}
          color={buttonTextHoverColor}
          position="relative"
          offsetTop={-160}
          offsetBottom={100}
          sx={{
            background: buttonTextHoverColor,
          }}>
          <Container>
            <Grid2 container md={12}>
              <Grid2 xs={12} sm={12} md={3} lg={3} paddingBottom={{ sm: 4 }}>
                <img src={logoWhite} alt="logo" />
              </Grid2>
              <Grid2 xs={12} sm={4} md={3}>
                <Typography variant="h4" color={common.white} fontWeight={700}
                            fontSize={'1.125em'}>Information</Typography>
                <ul>
                  <li><CustomLink
                    to={routes.aboutUs.path}
                  >A propos</CustomLink></li>
                  <li><CustomLink to={routes.howItWorks.path}>Fonctionnement</CustomLink></li>
                  <li><CustomLink to={routes.projects.path}>Projets</CustomLink></li>
                  <li><CustomLink to={routes.login.path}>Connexion</CustomLink></li>
                </ul>
              </Grid2>
              <Grid2 xs={12} sm={4} md={3} lg={3}>
                <Typography color={common.white} fontWeight={700} fontSize={'1.25em'}>Confidentialité</Typography>
                <ul>
                  <li><CustomLink to={routes.cgu.path}>CGU Divyz</CustomLink></li>
                  <li><Link href="#">Mentions legales</Link></li>
                  <li><Link href="#">FAQ</Link></li>
                  <li><Link href="#">Support</Link></li>
                </ul>
              </Grid2>
              <Grid2 xs={12} sm={4} md={3} lg={3}>
                <Typography color={common.white} fontWeight={700} fontSize={'1.25em'}>Partenaires</Typography>
                <ul>
                  <li>
                   <Typography fontWeight={400} fontSize="0.9em" color={common.white}>Divyz est membre de la fédération</Typography>
                  </li>
                  <li>
                    <img src={f2prLogo} alt={'f2pr logo'} />
                  </li>

                  <li>
                    <Link color="secondary" href="https://subscribepage.io/divyz" target="_blank"><b>S'inscrire à la newsletter</b></Link>
                  </li>
                </ul>
              </Grid2>
            </Grid2>
            <Grid2 container sx={{
              borderTopStyle: 'solid',
              borderTopWidth: 1,
              borderTopColor: 'rgba(255, 255, 255, 0.25)',
              paddingY: theme => theme.spacing(4)
            }}>
              <Grid2 xs={12} sm={4} md={4}>
                <CustomLink to="#">Politique de confidentialité</CustomLink>
              </Grid2>
              <Grid2
                xs={12}
                 sm={4}
                 md={4}
                 sx={{
                   paddingY: { xs: 2, md: 0 },
                   textAlign: { xs: 'left', md: 'center' }
                }}
              >
                <span>© Divyz 2022 - 2024</span>
              </Grid2>
              <Grid2
                xs={12}
                sm={4}
                md={4}
                sx={{
                  textAlign: { xs: 'left', md: 'right' }
                }}
                >
                {/*<Link to="#"><InstagramIcon /></Link>*/}
                <Link href="https://www.linkedin.com/company/divyz-immo/" target="_blank"><LinkedInIcon /></Link>
              </Grid2>
            </Grid2>
          </Container>
        </BoxWithDiagonaleBackground>
      </Box>
    </FooterWrapper>
  )
}

export default Footer
