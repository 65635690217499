import React from 'react';
import { useAuthValue } from '../../contexts/AuthContext';
import { Image, NavDropdown } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import avatarImg from '../../images/avatar.png';
import { routes } from '../../routes';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logout } from '../../firebase';
import { FaUser, FaWallet, FaGift, FaQuestion } from 'react-icons/fa'
import MiddleCropString from './MiddleCropString';
import { isAdmin } from '../../utils/helpers';

const NavBorderedButtonGroup = styled(ButtonGroup)`
  border-radius: 99999px;
  border: 1px solid rgba(0,0,0,.15);
  margin-left: 1rem;
  
  .dropdown-toggle {
    padding: 0.52rem;
  }
  
  .username {
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
  
  .dropdown-toggle:hover + .dropdown-menu,
  .dropdown-menu, .dropdown-menu:hover {
    border: 1px solid #000;
  }
  
  .dropdown-item {
    line-height: 2rem!important;
    padding: 0 2rem;
  }
  
  .logout {
    text-align: center;
    border: 1px solid #0d6efd;
    border-radius: 99999px;
    margin: 0 4rem;
    width: 60%;
   
  }
`;

const EmailField = styled.span`
  font-size: .875rem;
`;

const UsernameField = styled.span`
  padding: 0;
  font-weight: bold;
  font-size: 1rem;
`;

function DropdownProfile() {
  const navigate = useNavigate();
  const { currentUser } = useAuthValue();

  const handleLogOut = () => {
    return logout().then(() => navigate(routes.login.path, { replace: true }));
  };

  return (
    <NavDropdown
      as={NavBorderedButtonGroup}
      title={
        <>
          <Image style={{ width: '2rem', height: '2rem', objectFit: 'cover' }}
                 src={currentUser?.photoURL || avatarImg}
                 roundedCircle
          />
          <span className="username">{currentUser?.displayName.split(' ')[0]}</span>
        </>
      }
      align="end"
      className="d-md-block d-none"
    >
      <NavDropdown.ItemText
        style={{ minWidth: '20rem' }}
      >
        <div className="flex-row d-flex">
          <Image style={{ width: '3rem', height: '3rem', objectFit: 'cover', marginRight: '0.5rem' }}
                 src={currentUser?.photoURL || avatarImg}
                 roundedCircle
          />
          <div className="flex-column d-flex">
            <UsernameField>{currentUser?.displayName.split(' ')[0]}</UsernameField>
            <EmailField className="email"><MiddleCropString maxLength={18}>{currentUser?.email}</MiddleCropString></EmailField>
            </div>
          </div>
      </NavDropdown.ItemText>
      {isAdmin(currentUser) && <NavDropdown.Item active={true} onClick={() => navigate(routes.admin.path)}><FaUser /><span className="mx-4">Admin</span></NavDropdown.Item>}
      <NavDropdown.Item onClick={() => navigate(routes.profile.path)}><FaUser /><span className="mx-4">Profil</span></NavDropdown.Item>
      <NavDropdown.Item onClick={() => navigate(routes.ibans.path)}><FaWallet /><span className="mx-4">Comptes bancaires</span></NavDropdown.Item>
      <NavDropdown.Item onClick={() => navigate(routes.referral.path)}><FaGift /><span className="mx-4">Parrainage</span></NavDropdown.Item>
      <NavDropdown.Divider></NavDropdown.Divider>
      <NavDropdown.Item href={'https://hivest.freshdesk.com'} target="_blank"><FaQuestion /><span className="mx-4">Support</span></NavDropdown.Item>
      <NavDropdown.Item onClick={() => navigate(routes.dashboard.path)}><span className="mx-4">Dashboard</span></NavDropdown.Item>
      <NavDropdown.Divider></NavDropdown.Divider>
      <NavDropdown.Item onClick={() => handleLogOut()} className="logout"><span>Se déconnecter</span></NavDropdown.Item>
    </NavDropdown>
  );
}

export default DropdownProfile;
