import styled from 'styled-components';

export const TdDanger = styled.td`
  background: red!important;
`;

export const TdSuccess = styled.td`
  background: lightgreen!important;
`;

export const TdWarning = styled.td`
  background: orange!important;
`;
