import React, { useEffect, useState } from 'react';
import { Alert, Container, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { auth, db, logout } from '../../../firebase';
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  updateProfile,
} from 'firebase/auth';
import { routes } from '../../../routes';
import { doc, updateDoc } from 'firebase/firestore';
import { useAuthValue } from '../../../contexts/AuthContext';

// Schema for yup
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Please Enter your phoneNumber')
    .min(8),
});
const validationConfirmationCodeSchema = Yup.object().shape({
  confirmationCode: Yup.string()
    .required('Merci d\'entrer le code de confirmation')
    .min(6)
    .max(6),
});

const UpdateDisplayNameForm = ({ user }) => {
  const { currentUserData } = useAuthValue()
  console.log('Update', user, currentUserData)

  const navigate = useNavigate();
  const [open2FAPopin, setOpen2FAPopin] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [verifId, setVerifId] = useState();
  const [showLoginAgain, setShowLoginAgain] = useState(false);
  const [hasMultifactorEnabled, setHasMultiFactorEnabled] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [applicationVerifier, setApplicationVerifier] = useState(null)

  useEffect(() => {
    setApplicationVerifier(new RecaptchaVerifier(
      'otp-confirmation', { size: 'invisible' }, auth));
  }, [])

  useEffect(() => {
    setHasMultiFactorEnabled(multiFactor(auth.currentUser))
  }, [])

  const handleBackToLogin = () => {
    return logout().then(() => navigate(routes.login.path, { replace: true }));
  };

  const handleClose2FAPopin = () => {
    setOpen2FAPopin(false);
  };

  const handleEnroll2FA = async (phoneNumber) => {
    if (!applicationVerifier) {
    setApplicationVerifier(new RecaptchaVerifier(
      'otp-confirmation', { size: 'invisible' }, auth));
    }
    console.log(auth.currentUser, auth.currentUser.multiFactor);
    const multiFactorSession = await multiFactor(auth.currentUser).getSession();
    const phoneInfoOptions = {
      phoneNumber,
      session: multiFactorSession,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    setPhoneNumber(phoneNumber);
    // Send SMS verification code.
    console.log('IFHOHF', applicationVerifier)
    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, applicationVerifier)
      .then(function (verificationId) {
        setVerifId(verificationId);
        setOpen2FAPopin(true);

        const userDoc = doc(db, 'users', auth.currentUser.uid);
        updateDoc(userDoc, {
          phoneNumber,
        });

        // verificationId will be needed for enrollment completion.
        console.log('verificationId', verificationId);
      })
      .catch(error => {
        console.log('error occured');
        console.log(error);
        console.log(error.code);
        setErrorMessage(error.message)
        switch (error.code) {
          case 'auth/requires-recent-login':
            // we need to ask user credentials again.
            setShowLoginAgain(true);
            break;
          default:
            console.log('an error occured', error.code);
        }
      })
  };

  const handleFinalize2Fa = async (confirmationCode) => {
      const cred = PhoneAuthProvider.credential(verifId, confirmationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      // Complete enrollment.
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion, phoneNumber);
      console.log('2 Factor authentication enabled, you can now login');
      // dispatch({type: alertActions.SHOW_MSG, message: '2 Factor authentication enabled, you can now log back in to test', severity: 'success'});
      // setOpen(false);
  };

  function disable2FA(enrolledMFA) {
    return multiFactor(auth.currentUser)
      .unenroll(enrolledMFA)
      .then(() => {
        setSuccessMessage('2FA désactivé avec succès')
      })
      .catch(error => {

        console.log('error occured');
        console.log(error);
        console.log(error.code);
        setErrorMessage(error.message)
        switch (error.code) {
          case 'auth/requires-recent-login':
            // we need to ask user credentials again.
            setShowLoginAgain(true);
            break;
          default:
            console.log('an error occured', error.code);
        }
      })
  }

  console.log('froihdoihgd', hasMultifactorEnabled)

  let hasMultifactorEnabledRender = <></>

  if (hasMultifactorEnabled?.enrolledFactors?.length > 0) {
    hasMultifactorEnabledRender = (
      <Alert severity="warning">
        2FA déjà activé, voulez-vous le désactiver?
        <Button variant="primary" className="mx-auto" onClick={() => disable2FA(hasMultifactorEnabled.enrolledFactors[hasMultifactorEnabled.enrolledFactors.length-1])}>Désactiver</Button>
      </Alert>
    )
  }

  console.log('foiheohg', user)

  return (
    <Container>
      <p className="box_heading">Activer le 2FA</p>
      {hasMultifactorEnabledRender}
      {showLoginAgain && (
        <Alert severity="warning">
          Merci de vous reconnecter afin de pouvoir configurer le 2FA.
          <Button variant="contained" onClick={handleBackToLogin}>Retourner à la page de login</Button>
        </Alert>
      )}
      <Formik
        initialValues={{ phoneNumber: user.phoneNumber || currentUserData.phoneNumber || '+33' }}
        validationSchema={validationSchema}
        onSubmit={async ({ phoneNumber }, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          console.log('grsfdhsgd', phoneNumber);

          await handleEnroll2FA(phoneNumber)
            .catch((e) => {
              setErrorMessage(e.message)
              console.log('ERROR2FA', e);
            });

          setSubmitting(false);
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form.Group>
              <div className="mb-3">
                <Form.Label htmlFor="phoneNumber">Numéro de téléphone</Form.Label>
                <Form.Control
                  type="text"
                  /* This name property is used to access the value of the form element via values.nameOfElement */
                  name="phoneNumber"
                  placeholder="Numéro de téléphone"
                  /* Set onChange to handleChange */
                  onChange={handleChange}
                  /* Set onBlur to handleBlur */
                  onBlur={handleBlur}
                  disabled={isSubmitting || currentUserData.phoneNumber}
                  /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                  value={values.phoneNumber}
                  /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
                  className={`${touched.phoneNumber && errors.phoneNumber ? 'error' : null} form-control`}
                  aria-describedby="phoneNumberHelp"
                />
                {touched.phoneNumber && errors.phoneNumber ? (
                  <div className="text-danger">{errors.phoneNumber}</div>
                ) : null}
              </div>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button variant="primary" type="submit" disabled={isSubmitting || currentUserData.phoneNumber} className="mt-4">
                Mettre à jour
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div id="otp-confirmation"></div>
      <Modal show={open2FAPopin} onClose={handleClose2FAPopin} aria-labelledby="form-dialog-title">
        <Modal.Header id="form-dialog-title">2-Factor Authentication</Modal.Header>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ confirmationCode: '' }}
          validationSchema={validationConfirmationCodeSchema}
          onSubmit={async ({ confirmationCode }, { setSubmitting, validateForm, setFieldError }) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            await validateForm({ confirmationCode });

            await handleFinalize2Fa(confirmationCode)
              .then(() => {
                setOpen2FAPopin(false)
                setSuccessMessage('2FA activé avec succès.')
              })
              .catch((e) => {
                setFieldError('confirmationCode', e.message)
                console.log('ERROR2CONFIRMATIONCODE', e);
              });

            setSubmitting(false);
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto mt-3">
              <Modal.Body>
                Merci d'entrer les 6 chiffres reçu par SMS sur votre téléphone pour finir de configurer le 2FA.
                <Form.Group>
                  <div className="mb-3">
                    <Form.Label htmlFor="confirmationCode">Code de confirmation:</Form.Label>
                    <Form.Control
                      type="text"
                      /* This name property is used to access the value of the form element via values.nameOfElement */
                      name="confirmationCode"
                      placeholder="Code de confirmation"
                      /* Set onChange to handleChange */
                      onChange={handleChange}
                      /* Set onBlur to handleBlur */
                      onBlur={handleBlur}
                      /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                      value={values.confirmationCode}
                      /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
                      className={`${touched.confirmationCodse && errors.confirmationCode ? 'error' : null} form-control`}
                      aria-describedby="confirmationCodeHelp"
                    />
                    {touched.confirmationCode && errors.confirmationCode ? (
                      <div className="text-danger">{errors.confirmationCode}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting || !verifId}
                >
                  Activer le 2FA
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};


export default UpdateDisplayNameForm;
