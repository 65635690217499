import Deposit from './Deposit';
import Withdraw from './Withdraw';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { useAuthValue } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import humanFormat from 'human-format';
import Skeleton from 'react-loading-skeleton';
import Heading from '@hivest/integration/src/components/common/Heading/Heading';
import { Doughnut } from 'react-chartjs-2';
import imageMoney from '@hivest/integration/src/assets/images/Money.png';
import imageRealEstate from '@hivest/integration/src/assets/images/Real_estate.png';
import imageBriefCase from '@hivest/integration/src/assets/images/Briefcase.png';
import CommonFooter from '@hivest/integration/src/components/common/CommonFooter/CommonFooter';
import { Alert, Box, Grid, Table } from '@mui/material';
import { styled } from '@mui/system';
import { getUserName } from '../../../utils/helpers';
import imageHandIcon from '@hivest/integration/src/assets/images/hand-icon.png';

function Wallet() {
  const { currentUserData, balance } = useAuthValue();
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const Heading = styled('h1')({
    margin: '0 0 5rem',
    '@media (max-width: 429px)': {
      fontSize: '3.2rem',
      lineHeight: '4.8rem',
    },
    '@media only screen and (max-width: 767px)': {
      width: '100%',
    },
    '@media only screen and (max-width: 1023px)' : {
      'margin': '0 0 3rem',
    }
  });
  const AccueilContainer = styled('div')`
  .accueil {
    padding-top: 5rem;
  }

  .accueilCard {
    background: var(--white);
    box-shadow: 40px 40px 100px rgba(220, 225, 237, 0.5);
    padding: 4rem 4rem 3rem;
    height: 100%;
    transition: 0.4s;

    &:hover,
    &:focus {
      transform: translateY(-10px);
    }
  }

  .accueilCard img {
    max-width: 4.8rem;
    max-height: 4rem;
    object-fit: contain;
    margin-bottom: 3rem;
  }

  .accueilCard p {
    color: var(--purple);
    font-weight: 800;
    letter-spacing: -0.02em;
    margin-bottom: 2rem;
  }

  .accueilCard h2 {
    font-size: 3.6rem;
    line-height: 4.6rem;
    color: var(--labelColor);
    letter-spacing: 0.02em;
    margin: 0;
    font-weight: 400;
  }

  .chartContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10rem;
    margin-top: 8rem;
  }

  .chart {
    width: 350px;
    height: 350px;
  }

  .projectDetails {
    display: none;
  }

  @media (max-width: 827px) {
    .chart {
      width: 316px;
      height: 316px;
    }
  }

  @media (max-width: 429px) {
    .chart {
      margin-top: 1rem;
      line-height: 4.8rem;
    }

    .chartContainer {
      padding-bottom: 1.6rem;
    }

    .projectContainer {
      margin-top: 5.6rem;
      padding-bottom: 4.6rem;
    }

    .projectDetails {
      margin-top: 1.2rem;
      display: flex;
      justify-content: space-between;
      padding: 1.2rem;
      background: #ffffff;
      border: 1px solid rgba(198, 200, 215, 0.5);
      box-shadow: 5px 5px 40px rgba(220, 225, 237, 0.4);
      border-radius: 0.5rem;
      height: 50px;
      align-items: center;
    }

    .projectPara {
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 2rem;
      color: #4a5064;
      line-height: 2rem;
    }

    .projectPrice {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 2rem;
      color: var(--secondary);
    }
  }
`;

  function humanPaymentType(type) {
    switch (type) {
      case 'deposit':
        return 'Dépôt';
      case 'withdrawal':
        return 'Retrait';
      case 'investment':
        return 'Investissement';
      default:
        return 'Inconnu';
    }
  }

  function humanPaymentStatus(type) {
    switch (type) {
      case 0:
        return 'Succès';
      case 4:
        return 'En attente';
      case 6:
        return 'Erreur';
      case 7:
        return 'Annulée';
      default:
        return 'Inconnu';
    }
  }

  const balanceFormatted = balance ? humanFormat(parseInt(balance), {
      maxDecimals: 2,
    }) :
    <Skeleton width={`50px`} style={{ marginLeft: '5px', marginRight: '5px' }} />

  return (
    <AccueilContainer>
      <div className="accueil">
          <Heading>
            Portefeuille
          </Heading>
        <Heading />
          <Box>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            Montant actuel du portefeuille: <b>{balanceFormatted}</b> €

            <Grid container spacing={'20px'}>
                {!currentUserData?.status?.KYCApproved ?
                  <Alert variant="danger">Votre compte n'a pas encore été validé, merci de remplir <Link
                    to={routes.kyc.path}>votre vérification d'identité</Link> avant de pouvoir effectuer un dépot sur votre portfeuille Divyz</Alert> :
                  <>
                    <Grid item md={6} xs={12}>
                      <Deposit setErrorMessage={setErrorMessage} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Withdraw setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} />
                    </Grid>
                  </>
                }
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <h2 className="box_heading">Historique des transactions</h2>
                    {currentUserData?.payments?.length > 0 ? (
                      <Table bordered>
                        <thead>
                        <tr>
                          <th>Date</th>
                          <th>ID</th>
                          <th>montant</th>
                          <th>Status</th>
                          <th>Objet</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentUserData?.payments?.map((payment, index) => (
                          <tr key={index}>
                            <td>{format(payment.date.toDate(), 'dd/MM/yyyy')}</td>
                            <td>{payment.reference}</td>
                            <td>{payment.amount} €</td>
                            <td>{humanPaymentStatus(payment.status)}</td>
                            <td>{humanPaymentType(payment.type)}</td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    ) : <div>Aucune transaction enregistrée pour le moment.</div>
                    }
              </Grid>
            </Grid>
          </Box>
          <CommonFooter />
        </div>
    </AccueilContainer>
  )
/*
  return (
    <Row>
      <Col md={12} xs={12}>
        <div className="head_box">
          <p className="box_heading">Portefeuille</p>
          <Container>
            <Row>
              <Col md={12} xs={12}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                Montant actuel du portefeuille: <b>{balanceFormatted}</b> €
              </Col>
            </Row>
            <Row>
              {!currentUserData?.status?.KYCApproved ?
                <Alert variant="danger">Votre compte n'a pas encore été validé, merci de remplir <Link
                  to={routes.kyc.path}>votre vérification d'identité</Link> avant de pouvoir effectuer un dépot sur votre portfeuille Divyz</Alert> :
                <>
                  <Col md={6} xs={12}>
                    <Deposit setErrorMessage={setErrorMessage} />
                  </Col>
                  <Col md={6} xs={12}>
                    <Withdraw setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} />
                  </Col>
                </>
              }
            </Row>
          </Container>
        </div>
        <div className="head_box mt-4">
          <p className="box_heading">Historique des transactions</p>
          <Container>
            <Row>
              {currentUserData?.payments?.length > 0 ? (
                <Table striped bordered>
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>ID</th>
                    <th>montant</th>
                    <th>Status</th>
                    <th>Objet</th>
                  </tr>
                  </thead>
                  <tbody>
                  {currentUserData?.payments?.map((payment, index) => (
                    <tr key={index}>
                      <td>{format(payment.date.toDate(), 'dd/MM/yyyy')}</td>
                      <td>{payment.reference}</td>
                      <td>{payment.amount} €</td>
                      <td>{humanPaymentStatus(payment.status)}</td>
                      <td>{humanPaymentType(payment.type)}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
                ) : <div>Aucune transaction enregistrée pour le moment.</div>
              }
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  );*/
}

export default Wallet;
