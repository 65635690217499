import React, { useState } from "react";

import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import imageUserIcon from '../../assets/images/user-icon.png';
import imageWalletIcon from '../../assets/images/wallet-icon.png';
import imageLogo from '../../assets/images/logo.png';

import {UserContainer, SiteNavContainer, DashBoardContainer, InnerHeaderContainer, MobileMenu, HeaderContainer } from '../../layouts/AuthenticatedLayout/style'
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';
import CustomButton from '../CustomButton/CustomButton';
import Box from '@mui/material/Box';
import { getUserName } from '@hivest/frontend/src/utils/helpers';

const Header = ({ isMinimalMenu = false, currentUser = false, currentUserData = false, logout = () => {} }) => {
  const [hamburger, setHamburger] = useState(false);
  const routes = useCustomRouterContext();

  console.log('header-------,', currentUser, currentUserData)

  const openMenu = (event) => {
    document.body.classList.add("mobileMenuActive");
    setHamburger(true);
  };
  const hideMenu = (event) => {
    document.body.classList.remove("mobileMenuActive");
    setHamburger(false);
  };

  return (
    <Box>
    <HeaderContainer>
      <div className={`header ${isMinimalMenu && 'no-border'}`}>
        <DashBoardContainer>
          <div className={`container ${!isMinimalMenu && 'dashedBorder bottom'}`}>
          <InnerHeaderContainer>
          <div className="innerHeader">
              <div className="siteLogo">
                <Link to="/">
                  <img src={imageLogo} alt="" />
                </Link>
                <MobileMenu
                    className={hamburger ? 'mobileMenuActive' : 'mobileMenu'}
                    onClick={hamburger ? hideMenu : openMenu}
                  >
                    {
                hamburger ? (
                  <GrClose size={30} />
                ) : (
                  <GiHamburgerMenu size={35} />
                )
              }
                </MobileMenu>
              </div>
              <SiteNavContainer>
              <div className="siteNav" style={{ transform: hamburger && 'translateX(0)'}}>
                {!isMinimalMenu &&
                <ul className="mainMneu">
                  <Link to={routes.aboutUs.path}><li>A propos</li></Link>
                  <li className="menuItemHasChildren">
                    <Link to={routes.howItWorks.path}>Fonctionnement</Link>
                    <ul className="subMenu" >
                      <Link to={routes.fees.path}><li>Les frais Diviz</li></Link>
                    </ul>
                  </li>
                  <Link to={routes.projects.path}><li>Projets</li></Link>
                </ul>
                }

                {!currentUser ? <CustomButton
                  component={Link}
                  variant="contained"
                  color="primary"
                  maxWidth={182}
                  to={routes.login.path}
                >
                  Connexion
                </CustomButton>:
                <UserContainer>
                  <div className="userContent menuItemHasChildren">
                    <img src={imageUserIcon} className="userImg" alt="" />
                    <div className="info">
                      <img src={imageWalletIcon} alt="" />
                      <p>
                        <strong>{getUserName(currentUser, currentUserData)}:</strong> {currentUserData.balance}€
                      </p>
                    </div>
                    <ul className="subMenu" style={{ width: "20.6rem" }}>
                      <Link to={routes.dashboard.path}><li>Mon compte</li></Link>
                      <Link to={routes.profile.path}><li>Profil</li></Link>
                      <a href="#" onClick={logout}><li>Deconnexion</li></a>
                    </ul>
                  </div>
                </UserContainer>}
              </div>
              </SiteNavContainer>

            </div>
          </InnerHeaderContainer>
          </div>
        </DashBoardContainer>
      </div>
    </HeaderContainer>
    </Box>
  );
};

export default Header;
