import React from 'react';
import styled from 'styled-components';
import { FaDotCircle } from 'react-icons/fa';
import AbstractIcon from './AbstractIcon';

const UndefinedIcon = styled.span`
  svg {
    --bs-blue-light-rgb: 178,198,226;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-blue-light-rgb),var(--bs-text-opacity))!important;
  }
`;

function Icon({ size }) {
  return (
    <AbstractIcon
      size={size}
      Icon={FaDotCircle}
      Wrapper={UndefinedIcon}
    />
  )
}

export default Icon
