import { Typography } from '@mui/material';
import { buttonPrimaryBackgroundColor } from '../../components/CustomButton/styles';
import React from 'react';
import ContainerWithLine from '../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import { secondaryTitleColor } from '../../styles';
import aboutUsImage from './assets/aboutUsImage.png';
import Grid2 from '@mui/material/Unstable_Grid2';

function Hero() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: { sm: 0, md: -3 },
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Grid2 container>
          <Grid2 md={4}>
            <Typography variant="subtitle1" sx={{
              color: buttonPrimaryBackgroundColor,
            }}>A propos</Typography>
            <Typography variant="h1" fontWeight={800} fontSize={'3em'} color={secondaryTitleColor} sx={{
              paddingY: (theme) => theme.spacing(2),
            }}>Divyz</Typography>

            {/* @ts-ignore */}
            <ContainerWithLine position="top" sx={{
              marginLeft: -3,
              marginTop: 4,
              paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}>
            </ContainerWithLine>

            <TypographyUnderlined variant="h2" fontSize="2em!important" fontWeight={800} color={secondaryTitleColor}
                                  wrapperSX={{
                                    paddingY: (theme) => theme.spacing(2),
                                    display: { xs: 'none', sm: 'none', md: 'block' },
                                  }}>Notre mission</TypographyUnderlined>
            <Typography variant="h5" color={secondaryTitleColor} fontWeight={500} marginY={4}>Rendre accessible
              l'investissement immobilier aux personnes qui souhaitent investir à partir de 1000€ dans l'immobilier locatif.</Typography>
          </Grid2>
          <Grid2 md={8}>
            <Box component="img" src={aboutUsImage} sx={{ width: '125%' }} />
          </Grid2>
        </Grid2>
      </ContainerWithLine>
    </Box>
  )
}

export default Hero
