import * as React from 'react';
import { defaultTitleColor } from '../../styles';
import ContainerWithLine from '../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import CustomList from '../../components/CustomList/CustomList';

function AdvantagesSection() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
          <Box>
            <CustomList
              title="Les avantages Divyz"
              titleProps={{ color: defaultTitleColor, variant: "h4", fontWeight: 800, sx: { fontSize: '1.5em'} }}
              wrapperProps={{ marginTop: 8 }}
              items={[
                "Des propriétés off-market chassées et sélectionnées minutieusement par nos experts pour leur potentiel de rendement locatif.",
                "Une transparence des frais liés à la gestion des biens immobiliers.",
                "Une plateforme sécurisée est fiable grâce à notre technologie blockchain et au partenariat avec notre prestataire de paiement agréé.",
                "Aucun engagement, vous récupérez votre mise initial à tout moment grâce à notre marché secondaire.",
            ]}
              />
          </Box>
        </ContainerWithLine>
      </ContainerWithLine>
    </Box>
  )
}

export default AdvantagesSection
