import { NavLink, Outlet } from 'react-router-dom';
import Header from '../DashboardLayout/Header';
import Footer from '../Common/Footer';
import Sidebar from '../../pages/Authenticated/DashboardOld/Sidebar';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import React from 'react';
import { routes } from '../../routes';

export default function DashboardAdminLayout() {
  return (
    <>
      <Header />
      <section className="dashboard">
        <Container fluid>
          <Row className="dashboar_main">
            <Sidebar />

            <Col className="right_content">
              <div className="right_full_width">

                <Row>
                  <Col md={12} xs={12}>
                    <div className="head_box">
                      <p className="box_heading">Admin</p>
                      <Container>
                        <Row>
                          <Col md={12}>
                            <Nav
                              variant="tabs"
                              className="justify-content-center"
                              activeKey={routes.admin.path}
                              // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                            >
                              <Nav.Item>
                                <Nav.Link as={NavLink} to={routes.admin.path}>Admin</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link as={NavLink} to={routes.adminProjects.path}>Projects</Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Outlet />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
