import { auth } from '../firebase';
import { uploadFile } from './files';
export function adminKycProjectCompanyRegister(projectId) {
  return async () => {
    const tokenId = await auth.currentUser.getIdToken(true);

    return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/lemonway/kyc/company/projects/${projectId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
      },
      // body: JSON.stringify(data)
    }).then(res => res.json())

      .then((res) => {
        if (res.type === 'error') {
          throw new Error(res.message);
        }

        return res;
      })
      .then(res => console.log(res));
  }
}


export const kycProjectUploadDocuments = (documents, projectId, prefix = 'kyc') => async () => {
  console.log('data', documents);
  const tokenId = await auth.currentUser.getIdToken(true);
  try {
    for await (const document of documents) {
      console.log('docawaited', document);
      await uploadFile(`${prefix}/${projectId}/doc-${document.documentType}.pdf`, document.file, document.documentType);
    }
//                                                            /api/admin/lemonway/kyc      /company/projects/:projectId /documents
    return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/lemonway/${prefix}/company/projects/${projectId}/documents`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
      },
    }).then(res => res.json())

      .then((res) => {
        if (res.type === 'error') {
          throw new Error(res.message);
        }

        return res;
      })
      .then(res => console.log(res));
  } catch (e) {
    console.error('error', e);
    throw new Error(e.message)
  }
};
