import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { common } from '@mui/material/colors';
import { Divider, Stack, Typography } from '@mui/material';
import ContainerWithLine from '../../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import { greyColor, secondaryTitleColor } from '../../../styles';
import { buttonPrimaryBackgroundColor, buttonTextColor } from '../../../components/CustomButton/styles';
import { OverridableComponent } from '@mui/types';
import { Grid2TypeMap } from '@mui/material/Unstable_Grid2/Grid2Props';

type BoxElementType = {
  rentability: number | string,
  title: string | React.ReactElement,
  children: React.ReactNode,
  highlight?: boolean,
  md?: number,
  sm?: number,
  xs?: number,
};

function BoxElement({ rentability, children, title, highlight, ...props }: BoxElementType) {
  return (
    <Grid2
      alignContent="center"
      margin={2}
      padding={0}
      marginY={{ md: highlight ? -3 : 0, xs: 2 }}
      sx={{
        border: `1px solid ${highlight ? buttonPrimaryBackgroundColor : common.white}`,
        borderTop: `10px solid ${highlight ? buttonPrimaryBackgroundColor : greyColor}`,
        borderRadius: 0,
        backgroundColor: highlight ? '#F6FFFF' : common.white,
        boxShadow: '40px 40px 100px rgba(220, 225, 237, 0.50)',
        textAlign: 'center',
        marginBottom: theme => theme.spacing(4)
      }}
      {...props}
    >
      <Typography
        variant="h4"
        color={secondaryTitleColor}
        fontWeight={800}
        fontSize="1.5em"
        my={3}
      >{title}</Typography>
      <ContainerWithLine position="top">
        <Box my={2}>
          <Typography color={secondaryTitleColor} fontWeight={800} fontSize="2.75em">{rentability} %</Typography>
          <Typography color={buttonTextColor} fontWeight={500} fontSize="1.25em" mt={2}>de rendement</Typography>
        </Box>
        <Box mt={6} mb={3} textAlign="left" sx={{
          marginLeft: { xs: 2, md: 0 },
        }}>
        {children}
        </Box>
      </ContainerWithLine>
    </Grid2>
  );
}

export default BoxElement;
