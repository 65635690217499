export const ChartData = [
  {
    id: 1,
    year: 2016,
    userGain: 80,
    userLost: 823,
    projectName: "Name of the Project 1",
    price: "1200€",
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
    projectName: "Name of the Project 2",
    price: "800€",
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
    projectName: "Name of the Project 3",
    price: "850€",
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
    projectName: "Name of the Project 4",
    price: "300€",
  },
];

export const propertyChart = [
  {
    id: 1,
    year: 2016,
    userGain: 80,
    userLost: 823,
    projectName: "Name of the Project 1",
    price: "1200€",
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
    projectName: "Name of the Project 2",
    price: "800€",
  },
];
