import React from 'react';
import Router, { routes } from './routes';
import { ThemeProvider } from '@mui/material';
import { AuthProvider } from './contexts/AuthContext';
import { CustomRouterProvider } from '@hivest/integration/src/contexts/CustomRouterContext';
import globalThemeOptions from '@hivest/integration/src/styles';

import '@hivest/integration/src/App.css';

function App() {
  return (
    <ThemeProvider theme={globalThemeOptions}>
      <CustomRouterProvider routes={routes}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </CustomRouterProvider>
    </ThemeProvider>
  );
}

export default App;
