import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// import Home from './pages/Home';
import Login from '@hivest/integration/src/pages/Login/Login';
import LoginFormLogic from './pages/Login/LoginFormLogic';
import ForgotPassword from '@hivest/integration/src/pages/ForgotPassword/ForgotPassword';
import ForgotPasswordFormLogic from './pages/ForgotPassword/ForgotPasswordFormLogic';
import AuthLayout from '@hivest/integration/src/layouts/LoginLayout/LoginLayout';
import Register from '@hivest/integration/src/pages/Register/Register';
import RegisterFormLogic from './pages/Register/RegisterFormLogic';
import RegisterSuccess from './pages/RegisterSuccess/RegisterSuccess';
import PublicLayout from './layouts/PublicLayout/PublicLayout';
import Homepage from '@hivest/integration/src/pages/Homepage/Homepage';
import RequireAuth from './components/RequireAuth';
// import DashboardLayout from './layouts/DashboardLayout/DashboardLayout';
import DashboardApp from './pages/Authenticated/DashboardApp';
import AdminLanding from './pages/Authenticated/Admin/Landing';
import AdminProjects from './pages/Authenticated/Admin/Projects';
import AdminProjectKYC from './pages/Authenticated/Admin/ProjectKYC';
import Wallet from './pages/Authenticated/Wallet/Wallet';
import Ibans from './pages/Authenticated/Ibans/Ibans';
import Profile from '@hivest/integration/src/pages/Profile/Profile';
import CGU from '@hivest/integration/src/pages/Cgu/CGU';
import Security from './pages/Authenticated/Security/Security';
import PropertyDetails from './pages/PropertyDetails/PropertyDetailsWrapper';
import PropertyDetailsExample from './pages/Authenticated/PropertyDetailsOld/PropertyDetailsExample';
import Checkout from './pages/Authenticated/Checkout/Checkout';
import Error from './pages/Error/Error';
import ResetPassword from './pages/ResetPasswordOld/ResetPassword';
import Advantages from './pages/Advantages/Advantages';
import AboutUsPage from '@hivest/integration/src/pages/AboutUs/AboutUsPage';
import HowItWorksPage from '@hivest/integration/src/pages/HowItWorks/HowItWorksPage';
import FeesPage from '@hivest/integration/src/pages/Fees/FeesPage';
import ProjectsPage from '@hivest/integration/src/pages/Projects/ProjectsPage';
import PropertyList from './pages/Authenticated/PropertyList/PropertyList';
import CheckoutSuccess from './pages/Authenticated/CheckoutSuccess/CheckoutSuccess';
import CheckoutError from './pages/Authenticated/CheckoutError/CheckoutError';
import CheckoutCancel from './pages/Authenticated/CheckoutCancel/CheckoutCancel';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import Dashboard from '@hivest/integration/src/pages/Dashboard/Dashboard';
import Referral from '@hivest/integration/src/pages/Referral/Referral';
import Messages from './pages/Authenticated/Messages/Messages';
import RegisterSuccessProvider from './pages/RegisterSuccessProvider/RegisterSuccessProvider';
import FirebaseAccountManagement from './pages/FirebaseAccountManagement/FirebaseAccountManagementLogic';
import ML from '@hivest/integration/src/pages/ML/ML';
import KYCLanding from './pages/Authenticated/KYC/KYCLanding';
import AdminKYCLayout from './layouts/AdminKYCLayout/AdminKYCLayout';
import KYCLayout from './layouts/KYCLayout/KYCLayout';
import KYCCompanyInfo from './pages/Authenticated/KYC/KYCCompanyInfo';
import KYCStep1 from './pages/Authenticated/KYC/KYCStep1';
import KYCStep2 from './pages/Authenticated/KYC/KYCStep2';
import KYCStep3 from './pages/Authenticated/KYC/KYCStep3';
import KYCStepUpload from './pages/Authenticated/KYC/KYCStepUpload';
import KYCConfirmation from './pages/Authenticated/KYC/KYCConfirmation';
import DefaultLayout from '@hivest/integration/src/layouts/DefaultLayout/DefaultLayout';
import AuthenticatedLayout from '@hivest/integration/src/layouts/AuthenticatedLayout/AuthenticatedLayout';
import DashboardLayout from '@hivest/integration/src/layouts/DashboardLayout/DashboardLayout';
import DashboardAdminLayout from './layouts/DashboardAdminLayout/DashboardAdminLayout';
import AdminKYCConfirmation from './pages/Authenticated/KYCAdmin/AdminKYCConfirmation';
import AdminKYCStepUpload from './pages/Authenticated/KYCAdmin/AdminKYCStepUpload';
import AdminKYCCompanyInfo from './pages/Authenticated/KYCAdmin/AdminKYCCompanyInfoPage';
import AdminKYCStep1 from './pages/Authenticated/KYCAdmin/AdminKYCStep1';
import React from 'react';
import { handleError, handleRegister } from './helpers/social';
import { signInWithGoogle } from './firebase';
import ReferralWrapper from './components/ReferralWrapper';
import ReferralWrapperPage from './pages/Authenticated/Referral/ReferralWrapper';
import ProfileWrapper from './pages/Authenticated/Profile/ProfileWrapper';

const layouts = {
  default: {
    path: '/',
    name: 'defaultLayout',
    element: (
      <DefaultLayout />
    ),
  },
  dashboard: {
    path: '/app',
    name: 'dashboardLayout',
    element: (
      <RequireAuth>
        <DashboardLayout />
      </RequireAuth>
    ),
  },
  dashboardAdmin: {
    path: '/app/admin',
    name: 'dashboardAdminLayout',
    element: (
      <RequireAuth adminOnly={true}>
        <DashboardAdminLayout />
      </RequireAuth>
    ),
  },
  kyc: {
    path: '/app/kyc',
    name: 'KYCLayout',
    element: (
      <RequireAuth>
        <KYCLayout />
      </RequireAuth>
    ),
  },
  kycAdmin: {
    path: '/app/admin',
    name: 'KYCAdminLayout',
    element: (
      <RequireAuth adminOnly={true}>
        <AdminKYCLayout />
      </RequireAuth>
    ),
  },
  authenticated: {
    path: '/app',
    name: 'authenticatedLayout',
    element: (
      <RequireAuth>
        <AuthenticatedLayout />
      </RequireAuth>
    ),
  },
  public: {
    path: '/',
    name: 'publicLayout',
    element: (
      <PublicLayout />
    ),
  },
  auth: {
    path: '/auth',
    name: 'authLayout',
    element: (
      <AuthLayout />
    ),
  },
};

export const routes = {
  dashboardAdmin: {
    path: '/app/dashboardAdmin',
    element: <DashboardApp />,
    layout: layouts.dashboard,
  },
  dashboard: {
    path: '/app/dashboard',
    element: <Dashboard />,
    layout: layouts.dashboard,
  },
  advantages: {
    path: '/avantages',
    element: <Advantages />,
    layout: layouts.public,
  },
  propertyList: {
    path: '/app/properties',
    element: <PropertyList />,
    layout: layouts.authenticated,
  },
  propertyExample: {
    path: '/app/property',
    element: <PropertyDetailsExample />,
    layout: layouts.authenticated,
  },
  property: {
    path: '/app/properties/:propertyId',
    element: <PropertyDetails />,
    layout: layouts.authenticated,
  },
  checkout: {
    path: '/app/checkout',
    element: <Checkout />,
    layout: layouts.authenticated,
  },
  checkoutSuccess: {
    path: '/app/checkout/success',
    element: <CheckoutSuccess />,
    layout: layouts.authenticated,
  },
  checkoutCancel: {
    path: '/app/checkout/cancel',
    element: <CheckoutCancel />,
    layout: layouts.authenticated,
  },
  checkoutError: {
    path: '/app/checkout/error',
    element: <CheckoutError />,
    layout: layouts.authenticated,
  },
  wallet: {
    path: '/app/wallet',
    element: <Wallet />,
    layout: layouts.dashboard,
  },
  admin: {
    path: '/app/admin',
    element: <AdminLanding />,
    layout: layouts.dashboardAdmin,
  },
  adminProjects: {
    path: '/app/admin/projects',
    element: <AdminProjects />,
    layout: layouts.dashboardAdmin,
  },
  adminProjectKYC: {
    path: '/app/admin/projects/:type/:propertyId/kyc',
    element: <AdminProjectKYC />,
    layout: layouts.kycAdmin,
  },


  adminKycStep1: {
    path: '/app/admin/projects/:type/:propertyId/kyc/step1',
    element: <AdminKYCStep1 />,
    layout: layouts.kycAdmin,
  },
  adminKycCompanyInfo: {
    path: '/app/admin/projects/:type/:propertyId/kyc/infos',
    element: <AdminKYCCompanyInfo />,
    layout: layouts.kycAdmin,
  },
  adminKycUpload: {
    path: '/app/admin/projects/:type/:propertyId/kyc/upload',
    element: <AdminKYCStepUpload />,
    layout: layouts.kycAdmin,
  },
  adminKycCommonConfirmation: {
    path: '/app/admin/projects/:type/:propertyId/kyc/confirmation',
    element: <AdminKYCConfirmation />,
    layout: layouts.kycAdmin,
  },



  ibans: {
    path: '/app/ibans',
    element: <Ibans />,
    layout: layouts.dashboard,
  },
  profile: {
    path: '/app/profile',
    element: <ProfileWrapper />,
    layout: layouts.dashboard,
  },
  security: {
    path: '/app/security',
    element: <Security />,
    layout: layouts.dashboard,
  },
  messages: {
    path: '/app/messages',
    element: <Messages />,
    layout: layouts.dashboard,
  },
  referral: {
    path: '/app/referral',
    element: <ReferralWrapperPage />,
    layout: layouts.dashboard,
  },
  kyc: {
    path: '/app/kyc',
    element: <KYCLanding />,
    layout: layouts.dashboard,
  },
  kycStep1: {
    path: '/app/kyc/:type/step1',
    element: <KYCStep1 />,
    layout: layouts.kyc,
  },
  kycCompanyInfo: {
    path: '/app/kyc/:type/infos',
    element: <KYCCompanyInfo />,
    layout: layouts.kyc,
  },
  kycStep2: {
    path: '/app/kyc/:type/step2',
    element: <KYCStep2 />,
    layout: layouts.kyc,
  },
  kycStep3: {
    path: '/app/kyc/:type/step3',
    element: <KYCStep3 />,
    layout: layouts.kyc,
  },
  kycUpload: {
    path: '/app/kyc/:type/upload',
    element: <KYCStepUpload />,
    layout: layouts.kyc,
  },
  kycCommonConfirmation: {
    path: '/app/kyc/:type/confirmation',
    element: <KYCConfirmation />,
    layout: layouts.kyc,
  },
  homepage: {
    path: '/',
    element: <Homepage />,
    layout: layouts.default,
  },
  howItWorks: {
    path: '/how-it-works',
    element: <HowItWorksPage />,
    layout: layouts.default,
  },
  fees: {
    path: '/frais',
    element: <FeesPage />,
    layout: layouts.default,
  },
  aboutUs: {
    path: '/about-us',
    element: <AboutUsPage />,
    layout: layouts.default,
  },
  projects: {
    path: '/projets',
    element: <ProjectsPage />,
    layout: layouts.default,
  },
  login: {
    path: '/auth/login',
    element: (
      <Login
        googleOnClick={(navigate) => {
          return handleError(signInWithGoogle())
            .then(() => {
              navigate(routes.propertyList.path, { replace: true });
            })
        }}
      >
        <LoginFormLogic />
      </Login>
    ),
    layout: layouts.auth,
  },
  forgotPassword: {
    path: '/auth/forgotPassword',
    element: (
      <ForgotPassword>
        <ForgotPasswordFormLogic />
      </ForgotPassword>
    ),
    layout: layouts.auth,
  },
  resetPassword: {
    path: '/auth/account-management',
    element: (
      <FirebaseAccountManagement />
      // <ForgotPassword>
      //   <ForgotPasswordFormLogic />
      // </ForgotPassword>
    ),
    layout: layouts.auth,
  },
  // verifyEmail: {
  //   path: '/auth/account-management',
  //   element: <FirebaseAccountManagement />,
  //   layout: layouts.public,
  // },
  register: {
    path: '/auth/register',
    element: (
      <ReferralWrapper>
        {(referrerCode) => (
          <Register
            googleOnClick={(navigate) => handleRegister(referrerCode, navigate, signInWithGoogle)}
          >
            <RegisterFormLogic referrerCode={referrerCode} />
          </Register>
        )}
      </ReferralWrapper>
    ),
    layout: layouts.auth,
  },
  registerSuccess: {
    path: '/auth/register/success',
    element: <RegisterSuccess />,
    layout: layouts.auth,
  },
  registerSuccessProvider: {
    path: '/auth/register/successProvider',
    element: <RegisterSuccessProvider />,
    layout: layouts.auth,
  },
  cgu: {
    path: '/cgu',
    element: <CGU />,
    layout: layouts.public,
  },
  ml: {
    path: '/ml',
    element: <ML />,
    layout: layouts.public,
  },
  error404: {
    path: '/404',
    element: <Error />,
    layout: layouts.public,
  },
  catchall: {
    path: '*',
    element: <Navigate to="/404" replace />,
    layout: layouts.public,
  },
};

function buildRoutes() {
  for (const route of Object.values(routes)) {
    if (!Array.isArray(route.layout.children)) {
      route.layout.children = [];
    }

    if (route.layout.children.find((c) => c.path === route.path)) {
      continue;
    }

    route.layout.children.push(route);
  }

  return Object.values(layouts);
}

export default function Router() {
  return useRoutes(buildRoutes());
}
