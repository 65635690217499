import React from 'react'
import styled from 'styled-components'

const StyledText = styled.span`
span::before, span::after {
  display: inline-block;
  // max-width: 10%;
  overflow: hidden;
  white-space: pre;
}

span::before {
  content: attr(data-content-start);
  text-overflow: ellipsis;
}

span::after {
  content: attr(data-content-end);
  text-overflow: clip;
  direction: rtl;
}
`

function MiddleCropString({ maxLength = 20, children }) {
  const start = children.substring(0, maxLength/2)
  const end = children.substring(children.length-maxLength)

  // sebastienOOOOOOOO@hivest.immo
  console.log('middlecrop', children, start, end)

  if (children.length <= maxLength) {
    return <StyledText>{children}</StyledText>
  }

  return (
    <StyledText>
      <span data-content-start={start}></span>...
      <span data-content-end={end}></span>
    </StyledText>
  )
}

export default MiddleCropString
