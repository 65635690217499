import * as Yup from 'yup';
import { required } from './messages';

const KYCSchema = Yup.object().shape({
  company: Yup.object().shape({
    name: Yup.string()
      .min(2)
      .max(100)
      .required(required),
    identificationNumber: Yup.string()
      .min(2)
      .max(100)
      .required(required),
    websiteUrl: Yup.string()
      .url(),
    description: Yup.string()
      .required(required)
      .max(256)
  })
});

export default KYCSchema;
