import Add from './Add';
import { Alert, Col, Container, Row, Table } from 'react-bootstrap';
import React from 'react';
import { format } from 'date-fns';
import { useAuthValue } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import humanFormat from 'human-format';
import Skeleton from 'react-loading-skeleton';


function Ibans() {
  const { currentUserData, balance } = useAuthValue();

  function humanPaymentType(type) {
    switch (type) {
      case 'deposit':
        return 'Dépôt';
      case 'withdraw':
        return 'Retrait';
      case 'invest':
        return 'Investissement';
      default:
        return 'Inconnu';
    }
  }

  function humanPaymentStatus(type) {
    switch (type) {
      case 0:
        return 'Succès';
      case 4:
        return 'En attente';
      case 6:
        return 'Erreur';
      case 7:
        return 'Annulée';
      default:
        return 'Inconnu';
    }
  }

  const balanceFormatted = balance ? humanFormat(parseInt(balance), {
      maxDecimals: 2,
    }) :
    <Skeleton width={`50px`} style={{ marginLeft: '5px', marginRight: '5px' }} />

  return (
    <Row>
      <Col md={12} xs={12}>
        <div className="head_box">
          <p className="box_heading">Gestion des IBANs</p>
          <Container>
            <Row>
              {!currentUserData?.status?.KYCApproved ?
                <Alert variant="danger">Votre compte n'a pas encore été validé, merci de remplir <Link
                  to={routes.kyc.path}>votre vérification d'identité</Link> avant de pouvoir effectuer un dépot sur votre portfeuille Divyz</Alert> :
                <Add />
              }
            </Row>
          </Container>
        </div>
        <div className="head_box mt-4">
          <p className="box_heading">Historique des transactions</p>
          <Container>
            <Row>
              {currentUserData?.payments?.length > 0 ? (
                <Table striped bordered>
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>ID</th>
                    <th>montant</th>
                    <th>Status</th>
                    <th>Objet</th>
                  </tr>
                  </thead>
                  <tbody>
                  {currentUserData?.payments?.map((payment, index) => (
                    <tr key={index}>
                      <td>{format(payment.date.toDate(), 'dd/MM/yyyy')}</td>
                      <td>{payment.reference}</td>
                      <td>{payment.amount} €</td>
                      <td>{humanPaymentStatus(payment.status)}</td>
                      <td>{humanPaymentType(payment.type)}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
                ) : <div>Aucune transaction enregistrée pour le moment.</div>
              }
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  );
}

export default Ibans;
