import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Navigation from "./Navigation/Navigation";
import { Box, Grid } from "@mui/material";
import { Outlet, useLocation, useMatch } from 'react-router-dom';
import {Site, SiteContent } from './style'
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';
import { matchPath } from 'react-router';
import { useAuthValue } from '@hivest/frontend/src/contexts/AuthContext';
import { logout } from '@hivest/frontend/src/firebase';

const AuthenticatedLayout = () => {
  const location = useLocation();
  const routes = useCustomRouterContext();
  const { currentUser, currentUserData } = useAuthValue()
  const match = useMatch(routes.property.path);

  // Define the routes where you want to hide the navigation
  const hiddenNavigationRoutes = [routes.checkout.path];

  console.log('hiddem', hiddenNavigationRoutes, location, match)
  // Check if the current route is in the hiddenNavigationRoutes array
  const shouldDisplayNavigation = !match && !hiddenNavigationRoutes.includes(
    location.pathname
  );

  return (
    <Site>
      <Header currentUser={currentUser} currentUserData={currentUserData} logout={() => logout()} />
      <main className="mainContent">
        <div className="container dashedBorder">
          <SiteContent>
            <Box>
              <Outlet />
            </Box>
          </SiteContent>
        </div>
      </main>
      <Footer />
    </Site>
  );
};

export default AuthenticatedLayout;
