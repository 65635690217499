import Box from '@mui/material/Box';
import * as React from 'react';
import ContainerWithLine from '../../components/ContainerWithLine';
import { defaultSubTextColor, secondaryTitleColor } from '../../styles';
import Grid2 from '@mui/material/Unstable_Grid2';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../components/BoxWithDiagonaleBackground';
import imageInvestFrom1000 from './assets/InvestFrom1000.png';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

type InvestFromXProps = { amount: number };
function InvestFromX({ amount= 200 }: InvestFromXProps) {
  const routes = useCustomRouterContext()
  const navigate = useNavigate();

  return (
    <Box>
      <ContainerWithLine maxWidth="lg" sx={{ pb: 0 }}>
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(2),
        }}>
        </ContainerWithLine>
        <Grid2 container paddingTop={4} spacing={4} md={10} paddingBottom={8}>
          <Grid2>
            <TypographyUnderlined
              variant="h2"
              fontWeight={800}
              fontSize={'3em'}
              lineHeight="1.35em"
              color={secondaryTitleColor}
              wrapperSX={{
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >L’investissement Divyz est accessible dès {amount}€!</TypographyUnderlined>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
      <BoxWithDiagonaleBackground padding={0} deg={6} offsetTop={150} offsetBottom={0}>
        <ContainerWithLine maxWidth="lg" sx={{
          pb: { xs: 8, md: 14 },
          pl: { xs: 2, md: 4 }
        }}>
          <Grid2
            container
            alignContent="center"
            alignItems="stretch"
            justifyContent="center"
            paddingTop={{ sx: 8, md: 12 }}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            <Grid2 md={8} sx={{ marginRight: { md: 5 } }}>
              <Typography color={defaultSubTextColor} sx={{ marginBottom: 2 }}>Divyz divise la moitié du montant de chaque projet d’investissement en parts de 1000€. Ces parts sont accessibles aux membres Divyz qui peuvent acquérir une ou de nombreuses parts selon leur portefeuille. Dans certains cas, l’autre moitié du bien est financé par un emprunt bancaire pour permettre à tous les investisseurs de bénéficier de l’effet de levier à la revente.</Typography>

              <Typography color={defaultSubTextColor}>Les contrats de partage de loyers achetées vous donnent droit à des revenus locatifs chaque mois au prorata du montant investi dans le projet. Ainsi vous pouvez accéder en quelques clics aux nombreux avantages de l’investissement immobilier en locatif, selon votre budget et sans vous soucier des contraintes liées à l’investissement immobilier (démarches et gestion locative au quotidien).</Typography>
            </Grid2>
            <Grid2 md={4}>
              <Box component="img" src={imageInvestFrom1000} alt="contribuez des 1000 euros" width={330} />
            </Grid2>
          </Grid2>
          <Grid2 container sx={{ marginTop: 4 }}>
            <Grid2 md={12} sx={{ textAlign: 'center' }}>
              <CustomButton color="primary" variant="contained" onClick={() => navigate(routes.register.path)}>Créez votre compte</CustomButton>
            </Grid2>
          </Grid2>
        </ContainerWithLine>
      </BoxWithDiagonaleBackground>
    </Box>
  )
}

export default InvestFromX
