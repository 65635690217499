import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'; // the locale you want
import { format, subYears } from 'date-fns'; // the locale you want
import 'react-datepicker/dist/react-datepicker.css';
import { useField, useFormikContext } from 'formik';
import countries from 'i18n-iso-countries';

registerLocale('fr', fr); // register it with the name you want

function DateOfBirthField({
  className,
  placeholder,
  field,
  form,
  disabled,
}) {
  const onChange = (value) => {
    console.log('option', value ? format(value, 'yyyy/MM/dd') : null);
    form.setFieldValue(
      field.name,
      value ? format(value, 'yyyy/MM/dd') : null
    );
  };

  return (
    <DatePicker
      name={field.name}
      className={className}
      disabled={disabled}
      dateFormat="dd/MM/yyyy"
      includeDateIntervals={[
        { start: subYears(new Date(), 100), end: subYears(new Date(), 18) },
      ]}
      locale="fr"
      showMonthDropdown
      showYearDropdown
      maxDate={subYears(new Date(), 18)}
      dropdownMode="select"
      placeholderText={placeholder}
      selected={(field.value && new Date(field.value)) || undefined}
      onChange={onChange}
    />
  );
}

export default DateOfBirthField;
