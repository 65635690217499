import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminKYCIndividualStep1 from './AdminKYCStep1IndividualPage';

function AdminKYCStep1Page() {
  const navigate = useNavigate()
  const { type } = useParams()

    return <AdminKYCIndividualStep1 />
}

export default AdminKYCStep1Page;
