import React, { useContext } from 'react';

const CustomRouterContext = React.createContext();

export function CustomRouterProvider({ routes, children }) {
  return <CustomRouterContext.Provider value={{ ...routes }}>{children}</CustomRouterContext.Provider>;
}

export function useCustomRouterContext() {
  return useContext(CustomRouterContext);
}
