import Web3 from 'web3';
import { CryptoUtils, LocalAddress } from 'loom-js';
import ERC20Json from '../contracts/ABI/ERC20.json';
import PropertySaleInstantTransferJSON from '../contracts/ABI/PropertySaleInstantTransfer.json';

export function getContract(jsonABI, provider, contractAddress) {
  return new provider.eth.Contract(
    jsonABI,
    contractAddress
  );
}

export function getERC20Contract(provider, contractAddress) {
  return getContract(ERC20Json, provider, contractAddress)
}

export function getPropertySaleContract(provider, contractAddress) {
  return getContract(PropertySaleInstantTransferJSON, provider, contractAddress)
}

export function getAllowance(provider, contractAddress, ethAddress, spender) {
  return getERC20Contract(provider, contractAddress).methods.allowance(ethAddress, spender).call({ from: ethAddress });
}

export function getBalance(provider, contractAddress, ethAddress) {
  return getERC20Contract(provider, contractAddress).methods.balanceOf(ethAddress).call({ from: ethAddress });
}

export function toWei(amount) {
  return Web3.utils.toWei((amount ? amount : 0).toString());
}

export function fromWei(amount) {
  return Web3.utils.fromWei((amount ? amount : 0).toString());
}

export function generateWallet() {
  const privateKey = CryptoUtils.generatePrivateKey()
  const privateKeyString = CryptoUtils.Uint8ArrayToB64(privateKey)

  const publicKey = CryptoUtils.publicKeyFromPrivateKey(privateKey)
  const dummyAccount = LocalAddress.fromPublicKey(publicKey).toString()

  return {
    privateKey: privateKeyString,
    address: dummyAccount,
  };
}

export function isWalletUnlocked({ auth }) {
  return !!auth.privateKey;
}
