import countries from "i18n-iso-countries";

import React from 'react'
import Select from 'react-select'

// TODO: Make country selection dynamic based on user language
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

export const CountryField = ({
  className,
  placeholder,
  field,
  form,
  defaultValue,
  disabled
}) => {
  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      countries.alpha2ToAlpha3(option.value)
    );
  };

  const getValue = () => {
    if (options) {
      return options.find(option => option.value === field.value);
    }

    return "";
  };

  const options = Object.keys(countries.getNames("fr")).map((key) => {
    return {
      value: key,
      label: countries.getName(key, "fr")
    }
  })

  return (
    <Select
      options={options}
      name={field.name}
      className={className}
      isDisabled={disabled}
      value={getValue()}
      defaultValue={options.find(option => option.value === countries.alpha3ToAlpha2(defaultValue))}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={field.onBlur}
    />
  )
}

export default CountryField;
