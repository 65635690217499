import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';

function BreadCrumb({ city, type }) {
  return (
    <Breadcrumb style={{ marginLeft: 'calc(-.5 * var(--bs-gutter-x))' }} className="mb-3">
      <Breadcrumb.Item href="https://divyz.com">Divyz</Breadcrumb.Item>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: routes.propertyList.path }}>
        Tous les biens
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{city} - {type}</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default BreadCrumb
