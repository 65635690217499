// import { FakeUSDT, FSToken } from 'contracts';

export const networks = {
  eth: {
    rinkeby: {
      networkId: '4',
      chainId: '0x4',
      httpHost: 'https://rinkeby.infura.io/v3/40cf4405fe7f476792e3abf47a4b3ed2',
    },
    mainnet: {
      networkId: '1',
      chainId: '0x1',
      httpHost: 'https://mainnet.infura.io/v3/40cf4405fe7f476792e3abf47a4b3ed2',
    },
  },
  asia1: {
    chainId: 'asia1',
    networkId: '5101040124304',
    writeUrl: 'wss://test-z-asia1.dappchains.com/websocket',
    readUrl: 'wss://test-z-asia1.dappchains.com/queryws',
    bnbCoinAddress: '0xb6f5558ed8fd604dcda28514c4fb39af935c48e9',
    binanceTransferGatewayAddress: '0x166e1C5Bf627DFCbDDbcF1cc3b848813a9FC1446',
    hotWalletAddress: 'tbnb14sa7gnlalxd0e336clc0ltgke6e6hdanyl6pqq',
  },
  extdev: {
    chainId: 'extdev-plasma-us1',
    networkId: '9545242630824',
    writeUrl: 'wss://extdev-plasma-us1.dappchains.com/websocket',
    readUrl: 'wss://extdev-plasma-us1.dappchains.com/queryws',
    loom2EthGatewayAddress: '0xe754d9518bf4a9c63476891ef9AA7d91C8236A5D',
    eth2LoomGatewayAddress: '0x9c67fD4eAF0497f9820A3FBf782f81D6b6dC4Baa',
  },
  'zkloom-t1': {
    chainId: 'zkloom-t1',
    networkId: '36191',
    writeUrl: 'wss://testnet.era.zksync.dev/ws',
    readUrl: 'wss://testnet.era.zksync.dev/ws',
    loom2EthGatewayAddress: '0xe754d9518bf4a9c63476891ef9AA7d91C8236A5D',
    eth2LoomGatewayAddress: '0x9c67fD4eAF0497f9820A3FBf782f81D6b6dC4Baa',
  },
  mainnet: {
    chainId: 'default',
    networkId: '*',
    writeUrl: 'wss://basechain.dappchains.com/websocket',
    readUrl: 'wss://basechain.dappchains.com/queryws',
    loom2EthGatewayAddress: '0xfcF1E3fA575A313fd81feA2caA06269B49F1A528',
    eth2LoomGatewayAddress: '0xe080079ac12521d57573f39543e1725ea3e16dcc',
  },
};

export const web3Configuration = {
  // contracts: [
  //   FSToken,
  //   FakeUSDT,
  // ],
  fallback: 'ws://localhost:9545',
};
