import * as React from 'react';
import { Typography } from '@mui/material';
import { defaultSubTextColor, defaultTitleColor, secondaryTitleColor } from '../../../styles';
import ContainerWithLine from '../../../components/ContainerWithLine';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Box from '@mui/material/Box';
import BoxElement from './BoxElement';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomButton from '../../../components/CustomButton/CustomButton';
import imageHowItWorks1 from '../assets/HowItWorks1.svg';
import imageHowItWorks2 from '../assets/HowItWorks2.svg';
import imageHowItWorks3 from '../assets/HowItWorks3.svg';
import imageHowItWorks4 from '../assets/HowItWorks4.svg';

function HowItWorksSection() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Grid2
          container
          md={8}
          sx={{
            paddingTop: { xs: 1, md: 2 },
            paddingBottom: { xs: 0, md: 2 },
          }}
        >
          <Grid2>
            <TypographyUnderlined
              variant="h2"
              fontWeight={800}
              fontSize={'3em'}
              lineHeight="1.35em"
              color={secondaryTitleColor}
              wrapperSX={{
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >Comment ça marche?</TypographyUnderlined>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
        <ContainerWithLine maxWidth="lg" sx={{ pb: 4, pl: { xs: 2, md: 4 } }}>
          <Grid2
            container
            alignContent="center"
            alignItems="stretch"
            justifyContent="center"
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            <BoxElement
              md={3}
              bgColor="transparent"
              heading={<Typography color={secondaryTitleColor} fontWeight={800} fontSize="2.25em" marginY={2}>3</Typography>}
              image={imageHowItWorks3}
            >
              <Typography variant="body1" fontSize="1.5em" component="h4" fontWeight={800} textAlign="left" color={defaultTitleColor}>
                Créez votre compte
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="left" mt={6} color={defaultSubTextColor}>
               En quelques clics, lancez vous dans l'investissement immobilier pour toucher des loyers
              </Typography>
            </BoxElement>
            <BoxElement
              md={3}
              bgColor="transparent"
              heading={<Typography color={secondaryTitleColor} fontWeight={800} fontSize="2.25em" marginY={2}>1</Typography>}
              image={imageHowItWorks1}
            >
              <Typography variant="body1" fontSize="1.5em" component="h4" fontWeight={800} textAlign="left" color={defaultTitleColor}>
                Choisissez un projet immobilier
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="left" mt={6} color={defaultSubTextColor}>
                Participer au financement du projet immobilier selon votre budget, à partir de 1000€
              </Typography>
            </BoxElement>
            <BoxElement
              md={3}
              bgColor="transparent"
              heading={<Typography color={secondaryTitleColor} fontWeight={800} fontSize="2.25em" marginY={2}>2</Typography>}
              image={imageHowItWorks2}
            >
              <Typography variant="body1" fontSize="1.5em" component="h4" fontWeight={800} textAlign="left" color={defaultTitleColor}>
                Encaissez des revenus locatifs
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="left" mt={6} color={defaultSubTextColor}>
                Tous les mois vous recevrez une part des revenus locatifs au prorata de votre investissement sur le projet
              </Typography>
            </BoxElement>
            <BoxElement
              md={3}
              bgColor="transparent"
              heading={<Typography color={secondaryTitleColor} fontWeight={800} fontSize="2.25em" marginY={2}>4</Typography>}
              image={imageHowItWorks4}
            >
              <Typography variant="body1" fontSize="1.5em" component="h4" fontWeight={800} textAlign="left" color={defaultTitleColor}>
                Profitez de la plus value immobilière
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="left" mt={6} color={defaultSubTextColor}>
                Sans garanti, si le projet est revendu par la foncière et dégage une plue-value vous en bénéficiez également
              </Typography>
            </BoxElement>
          </Grid2>
        </ContainerWithLine>
    </Box>
  )
}

export default HowItWorksSection
