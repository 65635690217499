import { Box, Typography } from '@mui/material';
import { defaultSubTextColor, defaultTextColor, defaultTitleColor } from '../../../styles';
import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';

type BoxElementProps = { image: string, prefix: string, title: string, description: string, gridProps?: any, textWrapperProps?: any }

function BoxElement({ image, prefix, title, description, gridProps, textWrapperProps }: BoxElementProps) {
  return (
    <Grid2
      sx={{
        padding: 2,
      }}
      {...gridProps}>
      <Box sx={{
        border: '4px solid #B8E3E8',
        borderRadius: '50%',
        padding: 4,
        width: 110,
        height: 110,
        backgroundColor: '#fff',
      }}
           justifyContent="center"
           display="inline-flex"
           alignItems="center"
           marginBottom={4}
      >
        <Box component="img" src={image} sx={{ height: 90 }} alt={title} />
      </Box>
      <Box {...textWrapperProps}>
        <Typography variant="h4" fontWeight={800} color={defaultTitleColor} fontSize={'1.5em'} marginBottom={3}>
          {prefix && <Typography component="span" color={defaultTextColor} fontWeight={800}
                                 fontSize={'1em'}>{prefix}</Typography>}
          {title}
        </Typography>
        <Typography variant="body1" color={defaultSubTextColor}>{description}</Typography>
      </Box>
    </Grid2>
  )
}

export default BoxElement;
