import React from 'react';

export const get = (selector, from) =>
  selector
      .replace(/\[([^[\]]*)\]/g, '.$1.')
      .split('.')
      .filter(t => t !== '')
      .reduce((prev, cur) => prev && prev[cur], from)
  ;

// Error Component
export const errorMessage = (error) => {
  return <div className="invalid-feedback">{error}</div>;
};

export function getStepInvestment() {
  return 1000;
}

export function calculateCashflow(price, objectivePrice, cashflowRedistributedPerMonth, amountInvested) {
  if (amountInvested > objectivePrice) {
    return {
      perMonth: 0,
      perYear: 0,
    };
  }
  const totalBricks = price / getStepInvestment();
  const cashflowPerBrickPerMonth = cashflowRedistributedPerMonth / totalBricks;
  const totalBricksBought = amountInvested / getStepInvestment();
  const cashflowPerInvestmentPerMonth = cashflowPerBrickPerMonth * totalBricksBought;
  const cashflowPerInvestmentPerYear = cashflowPerInvestmentPerMonth * 12;

  return {
    perMonth: cashflowPerInvestmentPerMonth,
    perYear: cashflowPerInvestmentPerYear,
  };
}

export function isAdmin(user) {
  return user.email.endsWith('@hivest.immo')
}

export function getUserName(currentUser, currentUserData) {
  return currentUserData.firstName || currentUser?.displayName.split(' ')[0]
}
