import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Alert, Col } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAuthValue } from '../../../contexts/AuthContext';
import { pdfGeneratorHandler } from './helpers/pdfGenerator';
import { onSubmitKYC } from './helpers';
import { routes } from '../../../routes';
import KYCUploadField from './KYCUploadField';

const documents = [
  {
    type: 'company',
    id: 7,
    name: 'KBIS',
    bothSides: false,
    primary: true,
  },
  {
    type: 'individual',
    id: 0,
    name: 'Carte d\'identité',
    bothSides: true,
    primary: true,
  },
  {
    type: 'individual',
    id: 1,
    name: 'Preuve de domicile',
  },
  {
    type: 'individual',
    id: 14,
    name: 'Livret de famille',
  },
  {
    id: 2,
    name: 'Scan de votre IBAN',
  },
  {
    type: 'individual',
    id: 3,
    name: 'Passeport (Union européenne)',
    primary: true,
  },
  {
    type: 'individual',
    id: 4,
    name: 'Passeport (Hors union européenne)',
    primary: true,
  },
  {
    type: 'individual',
    id: 5,
    name: 'Titre de séjour',
    bothSides: true,
    primary: true,
  },
  {
    type: 'individual',
    id: 11,
    name: 'Permis de conduire',
    bothSides: true,
    primary: true,
  },
  {
    type: 'company',
    id: 12,
    primary: true,
    name: 'Status de société',
  },
  {
    type: 'company',
    id: 21,
    primary: true,
    name: 'Registre des bénéficiaires effectifs type DBE-S1',
  },
  {
    type: 'individual',
    id: 13,
    name: 'Selfie',
  },
];
const numberOfDocumentsToSubmit = {
  individual: { primary: 1, total: 2 },
  company: { primary: 3, individual: 2, total: 3 }
};

function getDocumentById(id) {
  return documents.find((doc) => doc.id.toString() === id);
}

function KYCStepUploadPage() {
  const navigate = useNavigate();
  const { type } = useParams();
  const [errorMessage, setErrorMessage] = useState(null)

  // let type = 'individual'

  const { currentUser, currentUserData } = useAuthValue();

  let documentsToRender = [];
  for(let i = 0; i < numberOfDocumentsToSubmit[type].total; i++) {
    documentsToRender.push(<KYCUploadField
      namePrefix="documents"
      index={i}
      key={i}
      type={type}
      primary={numberOfDocumentsToSubmit[type].primary > i}
    />);
  }
  // needs individual as well?
  if (numberOfDocumentsToSubmit[type].individual) {
    for(let x = 0; x < numberOfDocumentsToSubmit[type].individual; x++) {
      documentsToRender.push(<KYCUploadField
        namePrefix="documents"
        index={numberOfDocumentsToSubmit[type].total + x}
        key={numberOfDocumentsToSubmit[type].total + x}
        type={'individual'}
        primary={numberOfDocumentsToSubmit[type].primary > x}
      />);
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          documents: [],
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await pdfGeneratorHandler(values)
            .catch((error) => {
              setErrorMessage(error.message);
              setSubmitting(false);
              return;
            })

          const valuesToSubmit = Object.assign({}, values)
          delete valuesToSubmit.documents

          await onSubmitKYC({
            currentUserData,
            currentUser,
            navigate,
            setErrorMessage,
            nextStep: { path: generatePath(routes.kycCommonConfirmation.path, { type: 'success' }), step: 2 },
          })(valuesToSubmit, { setSubmitting })
        }}
      >
        {({
          values,
          errors,
          touched,
          isValidating,
          isSubmitting,
          setFieldValue,
          initialValues,
        }) => (
          <div className="container">
            {console.log('value', values, errors, errorMessage)}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Col sm={12}>
              <Form>
                {documentsToRender}
                <div className="form-group float-end">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Suivant
                  </button>
                </div>
              </Form>
            </Col>
          </div>
        )}
      </Formik>
    </>
  );
}

export default KYCStepUploadPage;
