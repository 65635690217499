import { Formik } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Alert, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Link } from '@mui/material';
import CustomButton from '@hivest/integration/src/components/CustomButton/CustomButton';
import TextInput from '@hivest/integration/src/components/Forms/TextInput';
import { buttonPrimaryBackgroundColor } from '@hivest/integration/src/components/CustomButton/styles';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';
import Box from '@mui/material/Box';
import CustomLink from '../../components/CustomLink/CustomLink';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';

type RegisterFormProps = {
  confirmVerificationCode: any,
  onSubmit: any,
  validationSchema: any,
  referrerCode: string,
};

function RegisterForm({ onSubmit, validationSchema, referrerCode }: RegisterFormProps) {
  const navigate = useNavigate();
  const routes = useCustomRouterContext();
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        passwordConfirmation: '',
        hasReferer: !!referrerCode,
        referrerCode,
        acceptTerms: false,
        toggleMailingList: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit({ setErrorMessage })}
    >
      {/* Callback function containing Formik state and helpers that handle common form actions */}
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

            <TextInput
              fullWidth
              name="email"
              value={values.email}
              disabled={isSubmitting}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              label="Adresse email"
              placeholder=""
            />

            <TextInput
              fullWidth
              label="Mot de passe"
              showHidePasswordField
              disabled={isSubmitting}
              name="password"
              value={values.password}
              onChange={handleChange}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              placeholder="******"
            />
            <TextInput
              fullWidth
              label="Confirmation du mot de passe"
              showHidePasswordField
              disabled={isSubmitting}
              name="passwordConfirmation"
              value={values.passwordConfirmation}
              onChange={handleChange}
              error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
              helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              placeholder="******"
            />
            <FormGroup>
            <FormControlLabel
              control={<CustomCheckbox
                name="hasReferer"
                disabled={isSubmitting}
                checked={values.hasReferer}
                onChange={handleChange}
              />}
              label="Je suis parrainé(e)"
            />
              {values.hasReferer && <TextInput
                fullWidth
                label="Code parrain"
                disabled={isSubmitting}
                name="referrerCode"
                value={values.referrerCode}
                onChange={handleChange}
                error={touched.referrerCode && Boolean(errors.referrerCode)}
                helperText={touched.referrerCode && errors.referrerCode}
              />}
            </FormGroup>
            <FormGroup>
              <FormControl error={!!errors.acceptTerms}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={<CustomCheckbox
                  sx={{
                    marginTop: -1,
                  }}
                  name="acceptTerms"
                  disabled={isSubmitting}
                  checked={values.acceptTerms}
                  onChange={handleChange}
                   />} label={<Box component="span">
                J’ai lu et j’accepte les <CustomLink
                  // to={routes.cgu.path}
                  to="#"
                >conditions générales d'utilisation</CustomLink> et la <CustomLink
                  // to={routes.ml.path}
                  to="#"
                >politique de confidentialité</CustomLink></Box>} />
                {touched.acceptTerms && errors.acceptTerms && <FormHelperText>{errors.acceptTerms}</FormHelperText>}
              </FormControl>
            </FormGroup>

            <CustomButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              mx={0}
              boxShadow="none"
              disabled={isSubmitting}
              my={4}
              padding="0.75em 2em"
            >Valider mon inscription</CustomButton>
          </>
        </form>
      )}
    </Formik>
  );
}

export default RegisterForm;
