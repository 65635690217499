import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
// import 'bootstrap/dist/css/bootstrap.min.css';

import '@hivest/integration/src/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import TagManager from 'react-gtm-module';

let ErrorBoundary = null;
if (process.env.NODE_ENV !== 'development') {
  const LogRocket = require('logrocket');
  const setupLogRocketReact = require('logrocket-react');
  LogRocket.init('fxfoss/hivest');
  setupLogRocketReact(LogRocket);

  Bugsnag.start({
    apiKey: '037286bce5b55cd82e0dd2e17c965340',
    plugins: [new BugsnagPluginReact()],
  });

  // @ts-ignore
  Bugsnag.beforeNotify = function (data) {
    data.metaData.sessionURL = LogRocket.sessionURL;
    return data;
  };

  // @ts-ignore
  ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);
}

TagManager.initialize({
  gtmId: 'GTM-MNGW63C',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const render = (
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
)

root.render(
  <React.StrictMode>
    {process.env.NODE_ENV !== 'development' ?
// @ts-ignore
      <ErrorBoundary>{render}</ErrorBoundary> :
      [render]}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
