import Advantages from '../Homepage/AdvantagesSection/Advantages';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import * as React from 'react';
import ContainerWithLine from '../../components/ContainerWithLine';
import { secondaryTitleColor } from '../../styles';

function AdvantagesSection() {
  return (
    <>
      <Advantages needsDiagonaleBackground={false} />
      <Box>
        <ContainerWithLine maxWidth="lg" sx={{ pb: 4 }}>
          <Typography fontSize={"2em"} fontWeight={500} color={secondaryTitleColor}>Sans oublier les nombreux avantages de l’investissement immobilier en fractionné </Typography>
        </ContainerWithLine>
      </Box>
    </>
  )
}

export default AdvantagesSection
