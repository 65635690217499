import * as React from 'react';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { buttonPrimaryBackgroundColor } from './CustomButton/styles';

// @ts-ignore
function TypographyUnderlined({ children, wrapperSX, extraElements, overrideDividerColor, ...props }) {
  return (
    <Box {...wrapperSX}>
      <Typography {...props}>{children}</Typography>
      {extraElements}
      <Divider sx={{
        pt: 4,
        borderBottomWidth: 5,
        borderColor: overrideDividerColor ? overrideDividerColor : buttonPrimaryBackgroundColor,
        width: '2.5em',
      }} />
    </Box>
  )
}

export default TypographyUnderlined as OverridableComponent<TypographyTypeMap<{ wrapperSX?: SxProps<Theme>, extraElements?: React.ReactNode, overrideDividerColor?: string }>>;
