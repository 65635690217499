import { common } from '@mui/material/colors';

export const buttonTextColor = '#4A5064';
export const buttonTextHoverColor = '#242831';
export const buttonTextBackgroundColor = 'transparent';

export const buttonPrimaryColor = common.white;
export const buttonPrimaryBackgroundColor = '#2CD4D9';
export const buttonPrimaryBackgroundHoverColor = '#04C4CA';
export const buttonPrimaryDisabledColor = '#C2ECEE';
export const buttonPrimaryDisabledBackgroundColor = '#F4FAFA';

export const buttonSecondaryBackgroundColor = common.white;
export const buttonSecondaryColor = '#2CD4D9';
export const buttonSecondaryHoverBackgroundColor = common.white;
export const buttonSecondaryHoverColor = '#03C4CA';
export const buttonSecondaryDisabledColor = '#C2ECEE';
export const buttonSecondaryDisabledBackgroundColor = '#F4FAFA';

export const buttonOutlinedBackgroundColor = '#F3F3F5';
export const buttonOutlinedColor = common.white;
