import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from '@mui/system';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import InputText from "../../components/common/InputText";
import Heading from "../../components/common/Heading/Heading";
import CommonFooter from "../../components/common/CommonFooter/CommonFooter";
import FormControl from "@mui/material/FormControl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import * as Yup from "yup";
import { Formik } from "formik";
import { ErrorMessage } from "formik";
import imageProfile from "../../assets/images/profile.png";
import imagePhone from "../../assets/images/phone-icon.png";
import imageEnvelope from "../../assets/images/envelope-icon.png";



export const validationSchema = Yup.object({
  gender: Yup.string().required("Please select your gender"),
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  date: Yup.string().required("Please select date"),
  he: Yup.string().required("Please enter you name"),
  city: Yup.string().required("Please enter your city"),
  country: Yup.string().required("Please enter your Country"),
  address: Yup.string().required("Please enter your address"),
  pincode: Yup.string().required("Please enter your pin Code"),
  phoneNumber: Yup.number().required("please enter phone number"),
});
const Profile = ({ currentUser }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const Profile = styled('div')({
    paddingTop: '5rem',
  });

  const ProfileContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  });

  const ProfilePic = styled('img')({
    maxWidth: '280px',
    objectFit: 'contain',
    borderRadius: '100%',
    marginBottom: '2rem',
  });

  const ContactDetails = styled('div')(({ theme }) => ({
    marginTop: '7rem',
    paddingTop: '5rem',
    borderTop: '1px dashed #b8cce0',
    '& p, & p a': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '& p img': {
      maxWidth: '2.8rem',
      objectFit: 'contain',
      marginRight: '1rem',
    },
    '& p span': {
      margin: '0 1.5rem',
    },
    '& p a': {
      color: 'var(--secondary)',
      '&:hover': {
        color: 'var(--dark)',
      },
    },
    '& .phoneIcon': {
      maxWidth: '2rem',
      marginRight: '1.8rem',
    },
  }));

  const RemoveImage = styled('div')({
    cursor: 'pointer',
    color: 'var(--secondary)',
    fontSize: '1.6rem',
    fontWeight: 400,
    paddingTop: '1.4rem',
  });

  const ProfileForm = styled('div')({
    paddingTop: '8rem',
  });

  const PhoneInputTag = styled('div')({
    height: '70px',
  });

  const TakeButton = styled('div')({
    display: 'none !important',
    '@media (max-width: 429px)' : {
      display: 'block !important'
    }
  });

  const ButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  });

  const PhoneIcon = styled('img')({
    maxWidth: '2rem',
    marginRight: '1.8rem',
  });

  const SaveButtonContainer = styled('div')`
    @media (max-width: 429px) {
    .saveButton button {
      width: 100%;
    }
  }
  `

  const BorderRemoveContainer = styled('div')`
  .borderRemove div {
    padding-top: 3px;
  }
  .borderRemove fieldset {
    border: none !important;
  }
  `

  const EmailInput = styled('div')(({ theme }) => ({
    maxWidth: '65.8rem',
    '@media only screen and (max-width: 767px)': {
      width: '100%',
    }
  }));

  const DemoContainer = styled('div')`
  #demo-simple-select svg {
    font-size: 30px !important;
  }
  `

  const Error = styled(ErrorMessage)({
    color: 'red',
  })

  return (
    <Profile>
      <Heading />
      <ProfileContainer>
        <ProfilePic
          src={currentUser?.photoURL ? currentUser.photoURL : imageProfile}
          alt="profile"
        />

        <input
          accept="image/*"
          id="image-input"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageSelect}
        />
        <ButtonContainer htmlFor="image-input">
          <TakeButton>
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: "white",
              border: "1px solid rgba(198, 200, 215, 0.5)",
              boxShadow: "5px 5px 40px rgba(220, 225, 237, 0.4)",
              textTransform: "none",
              borderRadius: "5px",
              minWidth: "15.8rem",
              fontSize: "1.6rem",
              lineHeight: "3.8rem",
              letterSpacing: "0.02em",
              color: "var(--primary)",
              padding: "0.2rem 1rem",
              textAlign: "center",
              margin: "0.5rem",
              "&:hover, &:focus": {
                backgroundColor: "var(--purple)",
                color: "var(--white)",
                boxShadow: "5px 5px 40px rgba(220, 225, 237, 0.4)",
              },
            }}

          >
            Take photo
          </Button>
          </TakeButton>
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: "white",
              border: "1px solid rgba(198, 200, 215, 0.5)",
              boxShadow: "5px 5px 40px rgba(220, 225, 237, 0.4)",
              textTransform: "none",
              borderRadius: "5px",
              minWidth: "15.8rem",
              fontSize: "1.6rem",
              lineHeight: "3.8rem",
              letterSpacing: "0.02em",
              color: "var(--primary)",
              padding: "0.2rem 1rem",
              margin: "0.5rem",
              "&:hover, &:focus": {
                backgroundColor: "var(--purple)",
                color: "var(--white)",
                boxShadow: "5px 5px 40px rgba(220, 225, 237, 0.4)",
              },
            }}
          >
            Upload new
          </Button>
        </ButtonContainer>
        <RemoveImage>Remove photo</RemoveImage>
      </ProfileContainer>
      <Formik
        initialValues={{
          name: "",
          he: "",
          city: "",
          country: "",
          address: "",
          phoneNumber: "",
          countryy: "",
          pincode: "",
          gender: "",
          date: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {}}
      >
        {({
          errors,
          handleChange,
          values,
          setFieldValue,
          handleSubmit,
          handleBlur,
          touched,
        }) => (
          <ProfileForm
            onSubmit={(e) => e.preventDefault()}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <div>

                    <FormControl fullWidth id="demo-simple-select">
                      <InputLabel
                        id="demo-simple-select-label"
                        error={errors?.gender && touched?.gender}
                        onBlur={handleBlur("gender")}
                        touched={touched.gender}
                        sx={{
                          fontSize: "1.6rem",
                          lineHeight: "2.8rem",
                          fontWeight: "500",
                          color: "var(--labelColor)",
                          opacity: "0.5",
                          fontFamily: "var(--font-Manrope)",
                          padding: "0 !important",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&.Mui-focused": {
                            color: "var(--secondary)",
                            opacity: "1",
                          },
                        }}
                      >
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="gender"
                        onChange={(e) => {
                          setFieldValue("gender", e.target.value);
                        }}
                        value={values.gender}
                        onBlur={() => {
                          handleBlur("gender");
                        }}
                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                        IconComponent={ArrowDropDownIcon}
                        style={{
                          fontSize: "15px", // Adjust this value to increase or decrease the size of the icon
                        }}
                        sx={{
                          backgroundColor: "var(--inputBck)",
                          border: "none !important",
                          borderRadius: "5px !important",
                          padding: "15px !important",
                          height: "58px !important",
                          boxShadow: "none !important",
                          color: "var(--dark)", // Apply secondary color
                          label: {
                            fontSize: "1.6rem",
                            lineHeight: "2.8rem",
                            fontWeight: "500",
                            color: "var(--labelColor)",
                            opacity: "0.5",
                            fontFamily: "var(--font-Manrope)",
                            padding: "0 !important",
                          },
                          fieldset: {
                            border: "none !important",
                            borderRadius: "5px !important",
                            padding: "15px !important",
                            height: "58px !important",
                          },
                          "&:hover fieldset": {
                            border: "none !important",
                            borderRadius: "5px",
                          },
                          "&:focus-within fieldset, &:focus-visible fieldset": {
                            border: "none !important",
                          },
                          "&:focus label, &:focus-within label, &:focus-visible label":
                            {
                              color: "var(--secondary)",
                              opacity: "1",
                            },
                        }}
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>


                    <Error
                      name="gender"
                      component="div"
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div >
                    <InputText
                      label={"He/Him"}
                      value={values?.he}
                      error={errors?.he && touched?.he}
                      onBlur={handleBlur("he")}
                      touched={touched.he}
                      name="he"
                      onChange={(e) => {
                        setFieldValue("he", e?.target?.value);
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>
                    <InputText
                      value={values?.name}
                      label={"Name"}
                      onBlur={handleBlur("name")}
                      error={errors?.name && touched?.name}
                      name="name"
                      touched={touched?.name}
                      onChange={(e) => {
                        setFieldValue("name", e?.target?.value);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, paddingTop: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                      border: "1px solid #ccc",
                      backgroundColor: "var(--inputBck)",
                      border: "none !important",
                      borderRadius: "5px !important",
                      padding: "15px !important",
                      height: "58px !important",
                      label: {
                        fontSize: "1.6rem",
                        lineHeight: "2.8rem",
                        fontWeight: "500",
                        color: "var(--labelColor)",
                        opacity: "0.5",
                        fontFamily: "var(--font-Manrope)",
                        padding: "0 !important",
                      },
                      input: {
                        backgroundColor: "var(--inputBck)",
                        padding: "0 !important",
                        height: "auto !important",
                        fontSize: "1.6rem",
                        lineHeight: "2.8rem",
                        fontWeight: "500",
                      },
                      fieldset: {
                        border: "none !important",
                        borderRadius: "5px !important",
                        padding: "15px !important",
                        height: "58px !important",
                      },
                      "&:hover fieldset": {
                        border: "none !important",
                        borderRadius: "5px",
                      },
                      "&:focus-within fieldset, &:focus-visible fieldset": {
                        border: "none !important",
                      },
                      "&:focus label, &:focus-within label, &:focus-visible label":
                        {
                          color: "var(--secondary)",
                          opacity: "1",
                        },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        fontFamily: "var(--font-Manrope)",
                        color: touched.date && errors.date ? "red" : "",
                        fontSize: "1.6rem",
                        paddingTop: "5px",
                      }}
                    >
                      Date de naissance:
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="en"
                    >
                      <BorderRemoveContainer>
                        <div className="borderRemove">
                          <DatePicker
                            onChange={(e) => {
                              setFieldValue("date", e);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                name="date"
                                fullWidth
                                value={values.date}
                              />
                            )}
                          />
                        </div>
                      </BorderRemoveContainer>

                    </LocalizationProvider>
                  </Box>
                  <Error
                    name="date"
                    component="div"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div>
                    <InputText
                      value={values?.city}
                      error={errors?.city && touched.country}
                      label={"City"}
                      // onBlur={handleBlur("city")}
                      name="city"
                      touched={touched?.city}
                      onChange={(e) => {
                        setFieldValue("city", e?.target?.value);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, paddingTop: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div>
                    <InputText
                      value={values?.country}
                      error={errors?.country && touched.country}
                      label={"Country"}
                      name="country"
                      touched={touched?.country}
                      onChange={(e) => {
                        setFieldValue("country", e?.target?.value);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, paddingTop: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    <InputText
                      label={"Address"}
                      value={values?.address}
                      error={errors?.address && touched.address}
                      name="address"
                      touched={touched?.address}
                      onChange={(e) => {
                        setFieldValue("address", e?.target?.value);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, paddingTop: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <EmailInput>
                    <InputText
                      label={"Pin Code"}
                      name="pincode"
                      value={values?.pincode}
                      error={errors?.pincode && touched.pincode}
                      touched={touched.pincode}
                      onChange={(e) => {
                        setFieldValue("pincode", e?.target?.value);
                      }}
                    />
                  </EmailInput>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <EmailInput>
                    <InputText
                      label={"Country"}
                      value={values?.countryy}
                      error={errors?.countryy && touched.countryy}
                      touched={errors?.countryy}
                      name="countryy"
                      onChange={(e) => {
                        setFieldValue("countryy", e?.target?.value);
                      }}
                    />
                  </EmailInput>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, paddingTop: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <PhoneInputTag>
                    <PhoneInput
                      country="fr" // Set the default country
                      name="phoneNumber"
                      enableSearch={true}
                      value={values.phoneNumber}
                      onChange={(e) => {
                        setFieldValue("phoneNumber", e);
                      }}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      containerStyle={{
                        height: "70px",
                      }}
                      inputStyle={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "var(--inputBck)",
                        border: "none !important",
                        borderRadius: "5px !important",
                        padding: "15px !important",
                        height: "58px !important",
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                    />
                    <Error
                      name="phoneNumber"
                      component="div"
                    />
                  </PhoneInputTag>
                </Grid>
              </Grid>
            </Box>
            <SaveButtonContainer>
              <div className="saveButton">
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    backgroundColor: "#F4FAFA",
                    width: "289px",
                    boxShadow: "none",
                    marginTop: "96px",
                    height: "70px",
                    color: "#C2ECEE",
                    fontSize: "16px",
                    fontWeight: 700,
                    borderRadius: "50px",
                    "&:hover": {
                      backgroundColor: "#F4FAFA",
                      boxShadow: "none",
                    },
                  }}
                >
                  Save
                </Button>
              </div>
            </SaveButtonContainer>

          </ProfileForm>
        )}
      </Formik>

      <ContactDetails>
        <p>
          <a href="mailto:support@hivest.io">
            <img
              src={imageEnvelope}
              alt=""
            />
            Contactez-nous par email
          </a>
          <span style={{ color: "black", paddingLeft: "5px" }}>ou</span>
          <a href="tel:xxxxxxxxxxxx">
            <PhoneIcon
              src={imagePhone}
              alt=""
            />
            Prendre un rdv
          </a>
        </p>
      </ContactDetails>
      <CommonFooter />
    </Profile>
  );
};

export default Profile;
