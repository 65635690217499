import { Field, Formik } from 'formik';
import { addIban, withdraw } from '../../../actions/wallet';
import { useAuthValue } from '../../../contexts/AuthContext';
import { Alert, Col, FormGroup, FormSelect, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import AddIbanSchema from './AddIbanSchema';
import { errorMessage } from '../../../utils/helpers';
import KYCUploadField from '../KYC/KYCUploadField';
import { pdfGeneratorHandler } from '../KYC/helpers/pdfGenerator';

function Add() {
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const { currentUserData, currentUser } = useAuthValue();

  return (
    <div className="mt-5">
      <h5>Nouvel IBAN</h5>
      {/*{currentUserData.ibanSetup && <Alert variant="warning">Vous avez déjà un IBAN enregistré. Vous ne pouvez en ajouter qu'un seul.</Alert>}*/}
      <Formik
        validationSchema={AddIbanSchema}
        initialValues={{
          holder: currentUser.displayName,
          bic: '',
          iban: '',
          domiciliation1: '',
          domiciliation2: '',
          documents: [],
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await pdfGeneratorHandler(values, 'ibans')
            .catch((error) => {
              setAPIErrorMessage(error.message);
              setSubmitting(false);
              return;
            })

          const valuesToSubmit = Object.assign({}, values)
          delete valuesToSubmit.documents

          const iban = valuesToSubmit.iban.replace(' ', '')
          addIban({ ...valuesToSubmit, iban })
            .then(() => {
              setMessage('IBAN ajouté avec succès');
              setAPIErrorMessage('')
              setSubmitting(false);
            })
            .catch((error) => {
              console.log(error.message)
              setAPIErrorMessage(error.message);
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            {console.log(errors)}
            {message && <Alert variant="success">{message}</Alert>}
            {APIErrorMessage && <Alert variant="danger">{APIErrorMessage}</Alert>}
            <Row className="mb-3">
              <Col sm={6}>
                <FormGroup>
                  <label htmlFor="holder">Nom du titulaire</label>
                  <Field
                    name="holder"
                    type="text"
                    disabled={isSubmitting}
                    className={`form-control ${
                      touched.holder && errors.holder ? 'is-invalid' : ''
                    }`}
                  />
                  {touched.holder && errors.holder && errorMessage(errors.holder)}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={8}>
                <FormGroup>
                  <label htmlFor="iban">IBAN</label>
                  <Field
                    name="iban"
                    type="text"
                    disabled={isSubmitting}
                    className={`form-control ${
                      touched.iban && errors.iban ? 'is-invalid' : ''
                    }`}
                  />
                  {touched.iban && errors.iban && errorMessage(errors.iban)}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <label htmlFor="bic">BIC</label>
                  <Field
                    name="bic"
                    type="text"
                    disabled={isSubmitting}
                    className={`form-control ${
                      touched.bic && errors.bic ? 'is-invalid' : ''
                    }`}
                  />
                  {touched.bic && errors.bic && errorMessage(errors.bic)}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6}>
                <FormGroup>
                  <label htmlFor="domiciliation1">Adresse de domiciliation 1</label>
                  <Field
                    name="domiciliation1"
                    type="text"
                    disabled={isSubmitting}
                    className={`form-control ${
                      touched.domiciliation1 && errors.domiciliation1 ? 'is-invalid' : ''
                    }`}
                  />
                  {touched.domiciliation1 && errors.domiciliation1 && errorMessage(errors.domiciliation1)}
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <label htmlFor="domiciliation2">Adresse de domiciliation 2</label>
                  <Field
                    name="domiciliation2"
                    type="text"
                    disabled={isSubmitting}
                    className={`form-control ${
                      touched.domiciliation2 && errors.domiciliation2 ? 'is-invalid' : ''
                    }`}
                  />
                  {touched.domiciliation2 && errors.domiciliation2 && errorMessage(errors.domiciliation2)}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <label htmlFor="domiciliation2">Preuve d'IBAN</label>
                <KYCUploadField
                  namePrefix="documents"
                  index={0}
                  type={'individual'}
                  overrideOptions={[2]}
                />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <button
                className="btn btn-primary"
                disabled={isSubmitting}
                type="submit">
                Ajouter le destinataire
              </button>
            </FormGroup>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default Add;
