import styled from 'styled-components';
import { buttonPrimaryBackgroundColor } from '../CustomButton/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { defaultSubTextColor, defaultTitleColor } from '../../styles';
import * as React from 'react';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import { BoxTypeMap } from '@mui/material/Box/Box';


const listCustomStyle = styled.ul`
  list-style: none;
  li::before {
    content: "\\2022"; /* Add content: \\2022 is the CSS Code/unicode for a bullet */
    color: ${buttonPrimaryBackgroundColor}; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    font-size: 2em;
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
  
  li {
    margin: 10px 0;
  }
`

type CustomListProps = {
  title: string;
  titleProps: TypographyTypeMap['props'];
  wrapperProps: BoxTypeMap['props'];
  items: string[]
}

function CustomList({ items, wrapperProps, titleProps, title }: CustomListProps) {
  return (
    <Box {...wrapperProps}>
      <Typography {...titleProps}>{title}</Typography>
      <Box component={listCustomStyle} color={defaultSubTextColor}>
        {items.map((item: string, index: number) => <Box component="li" key={index}>{item}</Box>)}
      </Box>
    </Box>
  );
}

export default CustomList;
