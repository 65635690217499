import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';
import Avatar from '@mui/material/Avatar';
import EuroIcon from '@mui/icons-material/Euro';
import WalletIcon from '@mui/icons-material/Wallet';
import BarChartIcon from '@mui/icons-material/BarChart';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import PaymentsIcon from '@mui/icons-material/Payments';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { grey } from '@mui/material/colors';
import { defaultSubTextColor, defaultTitleColor } from '../../../styles';

const BoxIcon = ({ icon }: any) => {
  switch (icon) {
    case 'euro':
      return <EuroIcon fontSize={'large'} />;
    case 'wallet':
      return <WalletIcon fontSize={'large'} />;
    case 'immo':
      return <ApartmentIcon fontSize={'large'} />;
    case 'manage':
      return <ManageAccountsIcon fontSize={'large'} />;
    case 'doc':
      return <FilePresentIcon fontSize={'large'} />;
    case 'money':
      return <PaymentsIcon fontSize={'large'} />;
    case 'plus':
      return <DomainAddIcon fontSize={'large'} />;
    case 'bar-chart':
    default:
      return <BarChartIcon fontSize={'large'} />;
  }
}

// @ts-ignore
export default function BasicCard({ title, children, icon, ...props}) {
  return (
    <Grid2 display="flex" {...props}>
      <Card sx={{
        borderRadius: 0,
        padding: (theme) => theme.spacing(2),
        // margin: (theme) => theme.spacing(2),
      }}>
        <CardContent>
          <Avatar sx={{ bgcolor: grey[500], width: 50, height: 50 }} variant="square">
            <BoxIcon icon={icon} />
          </Avatar>
          <Typography sx={{ fontSize: '1.25em' }} fontWeight={800} color={defaultTitleColor} paddingY={4}>
            {title}
          </Typography>
          <Typography fontSize="1em" color={defaultSubTextColor} component="p">
            {children}
          </Typography>
        </CardContent>
      </Card>
    </Grid2>
  );
}
