import React from 'react';
import humanFormat from 'human-format';
import Button from '../../../components/Button/Button';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import CustomCountdown from '../../../components/CustomCountdown/CustomCountdown';
import { useAuthValue } from '../../../contexts/AuthContext';
import { useEffect, useState } from 'react';
import { getPropertySaleContract } from '../../../utils/blockchain';
import Skeleton from 'react-loading-skeleton';

function PropertyDetails() {
  return (
    <div className="propertis_box">
      <div className="propertis_img">
        <Skeleton height={'240px'} width={'100%'} />
      </div>
      <div className="propertis_text_wrap">
        <h3><Skeleton width={`40%`} /></h3>
        <h4><Skeleton width={`20%`} /></h4>
        <p>Lancement le <b><Skeleton width={`10%`} /></b></p>
        <br/>
        <p>Rentabilité :<b><Skeleton width={`10%`} /></b></p>
        <p>Montant recherché vs montant déjà levé</p>
        <p><Skeleton width={`50%`} /></p>

        <Button variant="tertiary" disabled>
          J'investis
        </Button>
        <p className="box_footer"><Skeleton width={`50px`} /></p>
      </div>
    </div>
  );
}

export default PropertyDetails;
