import { Outlet, useLocation } from 'react-router-dom';
import Header from '@hivest/integration/src/components/Header/Header';
import Footer from '@hivest/integration/src/components/Footer/Footer';
// import Footer from '../Common/Footer';
import { useLocalStorage } from 'react-use';
import { useEffect } from 'react';
import { useAuthValue } from '../../contexts/AuthContext';
import { logout } from '../../firebase';

export default function PublicLayout() {
  const location = useLocation();
  const { currentUser, currentUserData } = useAuthValue()

  const referrerKeyName = 'referrer';
  const [, setValue] = useLocalStorage(referrerKeyName);


  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (query.get(referrerKeyName)) {
      setValue(query.get(referrerKeyName));
    }
  }, [location, setValue]);

  return (
    <>
      <Header currentUser={currentUser} currentUserData={currentUserData} logout={() => logout()} />
      <Outlet />
      <Footer />
    </>
  );
}
