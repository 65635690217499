import React, { useRef, useState } from "react";
import { styled } from '@mui/system';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Paper, Table } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css";
import "swiper/css/pagination";
import { propertyChart } from "../../utils/ChartData";
import { Doughnut } from "react-chartjs-2";
import Project from "./Project/Project";
import LeBien from "./Le_bien/LeBien";
import Revenus from "./Revenus/Revenus";
import Montage from "./Montage/Montage";
import Actu from "./Actu/Actu";
import DataRoom from "./DataRoom/DataRoom";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import imageEmailIcon from "../../assets/images/email.png";
import imagePropertyIcon from "../../assets/images/property-icon.png";
import imageCreativeIdeaIcon from "../../assets/images/creative-idea.png";
import imageHelpIcon from '../../assets/images/helpIcon.png';
import imageCallCenterIcon from '../../assets/images/call-center.png';
import { Link } from 'react-router-dom';
import InvestmentBox from '@hivest/frontend/src/pages/PropertyDetails/InvestmentBox';

const PropertyDetails = ({ navigate, routes, handleInvest, data, uid, contractAddress, propertyDetails, displayCreateAccountButton = false }) => {
  const [value, setValue] = React.useState("1");
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);

  const swiperRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chartData, setChartData] = useState({
    datasets: [
      {
        label: "Name of the Project",
        data: propertyChart.map((data) => {
          return data.id;
        }),
        backgroundColor: ["#423a61", "#2CD4D9"],
        cutout: "80%",
      },
    ],
  });

  const textCenter = {
    id: "textcenter",
    beforeDraw(chart) {
      const { ctx, chartArea } = chart;
      const fontSize = 30;
      const fontWeight = 700;
      ctx.font = `${fontWeight} ${fontSize}px Manrope`;
      const text = "8%";

      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;

      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#2CD4D9"; // Set the text color

      ctx.fillText(text, centerX, centerY);
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const images = [data.featured_image.url].concat(data.images.map((p: any) => p.image?.url));

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    setActiveThumbnailIndex(index);
    swiperRef.current.swiper.slideTo(index);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const PageBreadcrumbs = styled('div')({
    p: {
      padding: '5rem 0',
    },
    span: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      fontWeight: 400,
      letterSpacing: '0.02em',
      color: 'var(--secondary)',
    },
    a: {
      color: '#6f7486',
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      fontWeight: 400,
      letterSpacing: '0.02em',
      ':hover': {
        color: 'var(--secondary)'
      }
    }
  })

  const ApartmentName = styled('h6')({
    'fontSize': '1.8rem',
    'lineHeight': '2.5rem',
    'fontWeight': 500,
    'color': 'var(--purple)',
    'margin': '0 0 1rem',
    'letterSpacing': '0.02em',
});

const ApartmentTitle = styled('h2')({
  'fontSize': '3.5rem',
  'lineHeight': '4.8rem',
  'fontWeight': 500,
  'color': 'var(--dark)',
  'margin': '0 0 1rem',
  'letterSpacing': 'normal',
});

const ApartmentPrice = styled('p')({
  'fontSize': '1.6rem',
  'lineHeight': '2.2rem',
  'fontWeight': 500,
  'letterSpacing': '0.02em',
  'color': '#6f7486',
  'margin': '0',
});


const ApplyBtn = styled(Link)({
  background: 'var(--secondary)',
  borderRadius: '5rem',
  padding: '1rem',
  height: '5.8rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '24.4rem',
  maxWidth: '24.4rem',
  color: 'var(--white)',
  fontSize: '1.8rem',
  lineHeight: '2.5rem',
  fontWeight: 700,
  ':hover' : {
    background: 'var(--dark)',
  },
  ':visited': {
    color: 'var(--white)',
  },
  ':focus': {
  background: 'var(--dark)'
  },
  '@media (maxWidth: 899px)': {
    marginTop: '3rem'
  },
  '@media (maxWidth: 480px)': {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
  }
})

const ProjectDetailsContainer = styled('div')`
  .projectDetails {
    position: relative;
  }
  .projectDetails::before {
    content: "";
    position: absolute;
    left: -3.8rem;
    top: 4.8rem;
    border-bottom: 1px dashed #b8cce0;
    width: 100vw;
  }
    .TableList {
    position: relative;
  }
  .TableList::before,
  .TableList::after {
    content: "";
    position: absolute;
    top: 0;
    width: 3.4rem;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }
   .TableList::before {
    left: 0;
    background: linear-gradient(
      89.91deg,
      #ffffff 22.75%,
      rgba(255, 255, 255, 0) 87.31%
    );
  }
  
  .TableList::after {
    right: 0;
    background: linear-gradient(
      -89.91deg,
      #ffffff 22.75%,
      rgba(255, 255, 255, 0) 87.31%
    );
    width: 4.4rem;
  }
   @media (max-width: 430px) {
    .TableList {
      margin: 0 -3.4rem;
    }
   }
    @media (max-width: 767px) {
    .projectDetails::before {
      top: -2rem;
    }
    .projectDetails::after {
      content: "";
      position: absolute;
      left: -3.8rem;
      top: 6.6rem;
      border-bottom: 1px dashed #b8cce0;
      width: 100vw;
    }
    }
`

const ProjectAmount = styled('p')({
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '25px',
  color: '#5333ed',
  paddingTop: '2rem',
  '@media (maxWidth: 430px)': {
    width: '100%',
  }
})

const Amount = styled('p')({
  fontWeight: 500,
  fontSize: '60px',
  color: 'var(--secondary)',
  paddingTop: '1rem',
  lineHeight: '82px',
  '@media (maxWidth: 767px)': {
    width: '100%',
  },
  '@media (maxWidth: 430px)': {
    fontSize: '40px',
    width: '100%',
  },
})

const FundingBox = styled('div')({
  width: '226.67px',
  height: '109.09px',
  background: 'rgba(198, 200, 215, 0.15)',
  borderRadius: '10px',
  padding: '1.5rem 2rem',
  marginTop: '0.5rem',
  marginBottom: '2.5rem',
  '@media (maxWidth: 900px)': {
      width: '100%',
  },
  '@media (maxWidth: 767px)': {
    width: '100%',
  },
  '@media (maxWidth: 430px)': {
    width: '100%'
  }
})

const Para1 = styled('p')({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: '#6f7486',
})

const Para2 = styled('p')({
  fontWeight: 500,
  fontSize: '35px',
  color: '#423a61',
  paddingTop: '1.5rem',
})

const InvestmentBoxWrapper = styled('div')({
  width: '14.5rem',
  height: '5.2rem',
  fontSize: '1.6rem',
  paddingTop: '2.3rem',
  paddingLeft: '1.5rem'
})

const InvestmentPara1 = styled('p')({
  paddingLeft: '2rem'
})

const ListPara = styled('p')({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  color: '#4a5064',
  paddingBottom: '1.8rem',
})

const UnOrderList = styled('ul')({
  listStyleType: 'none',
  padding: 0,
  li: {
    position: 'relative',
    paddingLeft: '2rem',
    marginBottom: '8px',
    '::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '7px',
      background: 'var(--secondary)',
      width: '8px',
      height: '8px',
      borderRadius: '100%',
    },
    '@media (maxWidth: 430px)': {
      fontSize: '1.4rem',
    }
  }
})

const CreateAccountButtonBig = styled(Grid)({
  '@media (maxWidth: 480px)': {
    display: 'none !important'
   },
   '@media (maxWidth: 430px)': {
    display: 'none !important',
   }
})

const PricePara1 = styled('p')({
  color: '#5333ed',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '22px',
  '@media (maxWidth: 1050px)': {
    textAlign: 'center',
    fontSize: '13px',
  },
  '@media (maxWidth: 900px)': {
    textAlign: 'center',
  },
  '@media (maxWidth: 767px)': {
    whiteSpace: 'nowrap',
  },
  '@media (maxWidth: 480px)': {
    'fontSize': '13px',
    'lineHeight': '18px',
    'textAlign': 'center',
  },
  '@media (maxWidth: 430px)': {
    color: '#5333ed',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    textAlign: 'center',
    padding: '1rem',
  },
  '@media (maxWidth: 429px)': {
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'center',
    minHeight: '38px',
    maxWidth: '100%',
    margin: 'auto',
    whiteSpace: 'normal',
  }
})


const PricePara2 = styled('p')({
  fontWeight: 500,
  fontSize: '40px',
  lineHeight: '55px',
  color: '#4a5064',
  '@media (maxWidth: 900px)': {
    textAlign: 'center',
  },
  '@media (maxWidth: 480px)': {
    fontSize: '32px',
    lineHeight: '58px',
    textAlign: 'center',
  },
  '@media (maxWidth: 430px)': {
    fontSize: '35px',
    textAlign: 'center',
    padding: '1rem',
  },
  '@media (maxWidth: 429px)': {
    fontSize: '22px',
    lineHeight: '40px',
    textAlign: 'center'
  }
})

const PriceBox = styled('div')`
  margin-top: 4rem;
  border-top: 1px dashed #b8cce0;
  padding: 2rem 2rem;
  border-right: 1px dashed #b8cce0;
  @media (max-width: 899px) {
    border-right: none;
  }
  @media (max-width: 430px) {
    padding: 0%;
    border-right: none;
    padding: 0rem 0.1rem;
    padding-top: 2rem;
    margin-top: 2rem;
  }
`

const CreateAccountButtonSmall = styled(Grid)({
  display: 'none !important',
  '@media (maxWidth: 480px)': {
    display: 'block !important',
  },
  '@media (maxWidth: 430px)': {
    display: 'block'
  },
})

const QuestionAboutProject = styled('div')({
  position: 'relative',
  padding: '6rem 4rem',
  background: '#4a5064',
  margin: '10rem 0',
  '::before': {
    content: '""',
    position: 'absolute',
    left: '-3.8rem',
    top: 0,
    width: '100vw',
    height: '100%',
    background: '#4a5064',
    pointerEvents: 'none',
    zIndex: '-1',
  },
  '@media (maxWidth: 767px)': {
    margin: '5rem 0',
    padding: '4rem 0',
  },
  h2: {
    fontSize: '4rem',
    lineHeight: '5.6rem',
    color: 'var(--white)',
    '@media (maxWidth: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.6rem',
      marginBottom: '5rem',
    },
  }
})

const QuestionItem = styled('div')({
  a: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '7.4rem',
    height: '7.4rem',
    marginRight: '1rem',
  },
  h3: {
    fontSize: '2.2rem',
    lineHeight: '3.4rem',
    color: 'var(--secondary)',
    fontWeight: 500,
    margin: 0,
    '@media (maxWidth: 480px)': {
      fontSize: '2rem',
      lineHeight: '3rem',
    }
  }
})


const ChartGridOutsideContainer = styled(Grid)`
  padding-left: 4rem;
  @media (max-width: 429px) {
    padding-left: 0;
  }
  @media (max-width: 899px) {
    padding-left: 0;
  }
`

const ChartGridContainer = styled(Grid)({
  borderBottom: "1px dashed #B8CCE0",
  '@media (maxWidth: 429px)': {
    minHeight: '550px',
  },
  '@media (maxWidth: 768px)': {
    minHeight: '550px',
  },
  '@media (maxWidth: 899px)': {
    minHeight: '550px',
  }
});

  return (
    <>
    <PageBreadcrumbs>
      <p>
        <Link to={routes.homepage.path}>Hivest</Link>/<Link to={routes.propertyList.path}>Tous les biens</Link>/
        <span>{data.address}</span>
      </p>
    </PageBreadcrumbs>
    <div className="apartmentDetails">
        <Box>
          <Grid
            container
            sx={{ alignItems: "flex-end", justifyContent: "space-between" }}
          >
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <ApartmentName>{data.type}</ApartmentName>
              <ApartmentTitle>
                {data.address}
              </ApartmentTitle>
              <ApartmentPrice>{data.postalCode} {data.city}</ApartmentPrice>
            </Grid>
            <InvestmentBox
              uid={uid}
              contractAddress={contractAddress}
              metadata={propertyDetails.metadata}
              handleInvest={handleInvest}
            />
          </Grid>
        </Box>
        <div style={{
          position: 'relative',
          background: 'var(--white)',
          boxShadow: '40px 40px 100px rgba(220, 225, 237, 0.5)',
          margin: '7.2rem 0 0',
          padding: '2rem',
          minHeight: '72.8rem',
          '@media (maxWidth: 430px)': {
            padding: '2rem',
          },
        }}>
          <img
            src={imagePropertyIcon}
            alt="property"
            style={{
              position: "absolute",
              right: "0",
              width: "43px",
              height: "27px",
              marginRight: "20px",
            }}
          />
          <Box style={{ marginTop: "4rem" }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper style={{
                    boxShadow: 'none !important',
                    border: '0 !important',
                  }}>
                    <Swiper
                      ref={swiperRef}
                      navigation={true}
                      pagination={{ clickable: true }}
                      spaceBetween={0}
                      slidesPerView={1}
                      modules={[Navigation, Thumbs, Pagination]}
                      onSlideChange={(swiper) =>
                        setActiveIndex(swiper.realIndex)
                      }
                    >
                      {images.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={image}
                            alt={`Image ${index + 1}`}
                            style={{ height: "300px", width: "100%",objectFit: 'cover' }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Paper>
                </Grid>
                {/* Thumbnails */}
                <Grid container spacing={1}>
                  {images.map((image, index) => (
                    <Grid item xs={4} md={4} lg={4} key={index}>
                      <img
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          height: '82px',
                          maxHeight: '82px',
                          objectFit: 'cover',
                          border:
                            index === activeIndex
                              ? "2px solid #2CD4D9"
                              : "none",
                        }}
                        onClick={() => handleThumbnailClick(index)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <ChartGridOutsideContainer item xs={12} md={7} lg={7}>
                <ChartGridContainer
                  item
                  md={12}
                  lg={12}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <ProjectAmount>Montant du projet</ProjectAmount>
                      <Amount>{data.price} €</Amount>
                      <FundingBox>
                        <Para1>Objectif de financement</Para1>
                        <Para2>{data.objective_price} €</Para2>
                      </FundingBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '2rem',
                      }}>
                        <div style={{
                           width: '159.73px',
                           height: '159.73px',
                        }}>
                          {/* <Grid sm={12} xs={12} md={12}lg={12}> */}
                          <Doughnut
                            data={chartData}
                            options={{
                              elements: {
                                arc: {
                                  borderWidth: 0,
                                },
                              },
                              plugins: {
                                cutoutPercentage: 0,

                                tooltip: {
                                  backgroundColor: "#ffffff",
                                  bodyColor: "#4A5064",
                                  position: "nearest",
                                  borderColor: "rgba(198, 200, 215, 0.5)",
                                  boxShadow:
                                    "5px 5px 40px rgba(220, 225, 237, 0.4)",
                                  borderWidth: 1,
                                  bodyFontSize: "1.6rem",
                                  bodyLineHeight: "2.2rem",
                                  bodyFontWeight: "700",
                                },
                              },
                            }}
                            plugins={[textCenter]}
                          />

                          <InvestmentBoxWrapper>
                            <InvestmentPara1>Financé par</InvestmentPara1>
                            <p>
                              <span style={{ color: "#5333ED" }}>25</span>{" "}
                              investisseurs
                            </p>
                          </InvestmentBoxWrapper>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </ChartGridContainer>
                <Grid item sm={12} xs={12} md={12} lg={12} style={{
                  paddingTop: '2rem'
                }}>
                  <ListPara>A propos</ListPara>
                  <UnOrderList>
                    {data.advantages.map((advantage, index) => (
                      <li key={index}>{advantage.key}</li>
                    ))}
                  </UnOrderList>
                </Grid>
              </ChartGridOutsideContainer>
            </Grid>

            <Grid container alignItems="center">
            <Grid item xs={6} sm={6} md={3} lg={3}>
                <PriceBox>
                  <PricePara1>Valeur de la pierre</PricePara1>
                  <PricePara2>{data.pricePerStone} €</PricePara2>
                </PriceBox>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <PriceBox>
                  <PricePara1>Loyer</PricePara1>
                  <PricePara2>{data.rentAverage} €</PricePara2>
                </PriceBox>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <PriceBox>
                  <PricePara1>Taux de rentabilité Brut</PricePara1>
                  <PricePara2>{data.cashflowPercentage} %</PricePara2>
                </PriceBox>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
              <div className="lastPriceBox">
                <PricePara1>Taux de rentabilité Net</PricePara1>
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      justifyContent: "center",
                    }}
                  >
                    <PricePara2>{data.cashflow_net} %</PricePara2>
                    <div className="helpIcon">
                      <Typography
                        aria-owns={open ? "mouse-over-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        <img src={imageHelpIcon} alt="help" />
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                          disableScrollLock={true}
                        >
                          <Typography variant="h3">Details</Typography>
                          {/*<Popover.Header as="h3">Details</Popover.Header>*/}
                          {/*<Popover.Body>*/}
                            <Table striped bordered>
                              <thead>
                              <tr>
                                <th>Montant par mois</th>
                                <th>Commentaire</th>
                              </tr>
                              </thead>
                              <tbody>
                              {data?.cashflow_breakdown.map((cb, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{cb.amount} €</td>
                                    <td>{cb.comment}</td>
                                  </tr>
                                )
                              })}
                              </tbody>
                            </Table>
                          {/*</Popover.Body>*/}
                        </Popover>
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <CreateAccountButtonSmall
              item
              xs={12}
              sm={12}
              md={0}
              lg={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <ApplyBtn href="#">
                Je crée mon compte
              </ApplyBtn>
            </CreateAccountButtonSmall>
          </Box>
        </div>
      </div>
       <ProjectDetailsContainer>
       <div
        className="projectDetails"
        style={{ scrollSnapType: "x mandatory", scrollBehavior: "smooth" }}
      >
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: "none" }} className="TableList">
              <TabList
                style={{
                  overflowX: 'auto',
                }}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
                indicatorColor="transparent"
                sx={{
                  marginRight: "2rem",
                  "& button": {
                    color: "var(--primary)",
                    fontSize: "1.6rem",
                    lineHeight: "2.2rem",
                    fontFamily: "var(--font-Manrope)",
                    fontWeight: "400",
                    textTransform: "none",
                    minWidth: "16.5%",
                    letterSpacing: "0.02em",
                  },
                  "& button:first-of-type": {
                    marginLeft: "2rem",
                  },
                  "& button:last-child": {
                    marginRight: "2rem",
                  },
                  // "& button:hover": {
                  //   background: "#F4FAFA",
                  //   color: "var(--secondary)",
                  //   fontWeight: "700",
                  // },
                  "& button.Mui-selected": {
                    background: "#F4FAFA",
                    color: "var(--secondary)",
                    fontWeight: "700",
                  },
                  // "& .MuiTabs-flexContainer": {
                  //   overflowX: 'auto',
                  // },
                }}
              >
                <Tab label="Le project" value="1" />
                <Tab label="Le bien" value="2" />
                <Tab label="Revenus" value="3" />
                <Tab label="Montage financier" value="4" />
                <Tab label="Actu" value="5" />
                <Tab label="Data room" value="6" />
              </TabList>
            </Box>
            <Project data={data} />
            <LeBien data={data} />
            <Revenus data={data} />
            <Montage data={data} />
            <Actu data={data} />
            <DataRoom data={data} />
          </TabContext>
        </Box>
      </div>
       </ProjectDetailsContainer>

    <QuestionAboutProject>
      <h2>Une question sur Hivest ou sur nos projets?</h2>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <QuestionItem>
              <a href="#">
                <img src={imageCreativeIdeaIcon} alt="" />
                <h3>Découvrez notre rubrique FAQ</h3>
              </a>
            </QuestionItem>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <QuestionItem>
              <a href="mailto:support@hivest.com">
                <img src={imageEmailIcon} alt="" />
                <h3>Contactez nous par email</h3>
              </a>
            </QuestionItem>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <QuestionItem>
              <a href="#">
                <img src={imageCallCenterIcon} alt="" />
                <h3>Prendre RDV avec nos experts</h3>
              </a>
            </QuestionItem>
          </Grid>
        </Grid>
      </Box>
    </QuestionAboutProject>
  </>
  );
};

export default PropertyDetails;
