import React from 'react';
import { Alert, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './styles/error.css';
import Button from '../../../components/Button/Button';
import { routes } from '../../../routes';
import { useEffect, useState } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';

function CheckoutCancel() {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="error_wrap">
      <Container fluid>
        <h2 className="heading"><span></span> Votre investissement à bien été annulé!</h2>
        <Row>
          {/*{message && <Alert variant="danger">{message}</Alert>}*/}
          {/*{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}*/}

          <div className="error_text_box">
            <div className="error_text_wrap">
              <p>Votre transaction a bien été annulée. Vous pouvez-vous à nouveau sélectionner un bien sur lequel investir, vous ne serez bien entendu pas prélevé. </p>
                <Button variant="primary" onClick={() => navigate(routes.propertyList.path)} className="error_primary">
                  Retour à la liste des biens
                </Button>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default CheckoutCancel;
