import Grid from '@mui/material/Grid';
import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

function ReferralCard() {
  const ReferralTableValue = styled('div')({
    fontSize: '1.6rem',
    fontWeight: 400,
    color: 'var(--primary)',
    padding: '2.5rem',
    borderRight: '1px solid rgba(198, 200, 215, 0.5)',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media only screen and (max-width: 767px)': {
      padding: '2.5rem 1.5rem',
      fontSize: '1.4rem'
    }
  });

  const ReferralSatusGrid = styled(Grid)({
    '@media only screen and (max-width: 767px)': {
      display: 'none'
    },
    '@media only screen and (max-width: 1200px)': {
      display: 'none'
    }
  })


  const Price = styled('p')({
    color: '#2cd4d9',
    textAlign: 'center',
  });

  const Status = styled('p')({
    color: '#24f012',
    textAlign: 'center',
    '@media only screen and (max-width: 767px)': {
      textAlign: 'left',
      fontSize: '1.2rem',
      lineHeight: '2rem'
    }
  });

  const HideBorder = styled('div')({
    border: 'none',
  });


  const HideStatus = styled('p')({
    color: '#24f012',
    textAlign: 'center',
    display: 'none',
    '@media only screen and (max-width: 767px)': {
      display: 'block',
      textAlign: 'left',
    },
    '@media only screen and (max-width: 1200px)': {
      display: 'block',
      textAlign: 'left',
    }
  });


  return (
    <Box
      sx={{
        border: "1px solid rgba(198, 200, 215, 0.5);",
        boxShadow: "5px 5px 40px rgba(220, 225, 237, 0.4)",
        borderRadius: "5px",
      }}
    >
      <Grid container>
        <Grid xs={3.5} sm={3.5} md={3.5} lg={2}>
          <ReferralTableValue>
            <p>01/01/2023</p>
          </ReferralTableValue>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6}>
          <ReferralTableValue>
            <p>Meryem Bessieres</p>
            <HideStatus>Actif</HideStatus>
          </ReferralTableValue>
        </Grid>

        <ReferralSatusGrid
          xs={0}
          sm={0}
          md={0}
          lg={2}
        >
          <ReferralTableValue>
            <Status>Actif</Status>
          </ReferralTableValue>
        </ReferralSatusGrid>
        <Grid xs={2.5} sm={2.5} md={2.5} lg={2}>
          <ReferralTableValue
            className={HideBorder}
          >
            <Price>2500€</Price>
          </ReferralTableValue>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReferralCard
