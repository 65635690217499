import styled from 'styled-components';
import { generatePath, Link, Outlet, useLocation, useParams } from 'react-router-dom';
import Header from '../AuthenticatedLayout/Header';
import Footer from '../Common/Footer';
import { Col, Container, Nav, ProgressBar, Row } from 'react-bootstrap';
import { routes } from '../../routes';
import React from 'react';
import { useAuthValue } from '../../contexts/AuthContext';

const KYCWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 6px;
  padding: 20px 0;
  min-height: 288px;
  min-width: 336px;
`;

export default function AdminKYCLayout() {
  const { pathname } = useLocation();
  const { type, propertyId } = useParams();
  const { currentUserData } = useAuthValue();

  const maxKYCStep = 2 + (type === 'company' && + 1);
  const currentKYCStep = currentUserData.kycLastStep + 1 || 0;
  const currentKYCProgress = Math.floor(currentKYCStep / maxKYCStep * 100)
  const currentKYCProgressRender = currentKYCProgress > 100 ? 100 : currentKYCProgress;
  console.log('CURRENT', currentKYCProgressRender, currentKYCProgress, currentKYCStep, pathname, type, maxKYCStep);

  return (
    <>
      <Header />
      <section className="dashboard">
        <Container fluid>
          <Row className="dashboar_main">
            <Col className="right_content">
              <div className="right_full_width">
                <Row>
                  <Col md={12} xs={12}>
                    <span className="float-start">Retourner au <Link to={routes.admin.path}>Admin</Link></span>
                    <KYCWrapper className="mt-5">
                      <p className="box_heading text-center">Vérification d'identité</p>
                      <ProgressBar now={currentKYCProgressRender} className="mx-3 my-2" animated
                                   label={`${currentKYCProgressRender}%`} />
                      {pathname !== generatePath(routes.adminKycCommonConfirmation.path, { type: 'company', propertyId }) &&
                        <Nav className="justify-content-center mb-2" activeKey={pathname} variant="tabs">
                          <Nav.Item>
                            <Nav.Link
                              as={Link}
                              href={generatePath(routes.adminKycStep1.path, { type: 'company', propertyId })}
                              to={generatePath(routes.adminKycStep1.path, { type: 'company', propertyId })}
                            >Informations personnelles</Nav.Link>
                          </Nav.Item>
                          {type === 'company' &&
                            <Nav.Item>
                              <Nav.Link
                                as={Link}
                                href={generatePath(routes.adminKycCompanyInfo.path, { type: 'company', propertyId })}
                                to={generatePath(routes.adminKycCompanyInfo.path, { type: 'company', propertyId })}
                                disabled={currentKYCStep < 1}
                              >Informations de société</Nav.Link>
                            </Nav.Item>
                          }
                          <Nav.Item>
                            <Nav.Link
                              as={Link}
                              to={generatePath(routes.adminKycUpload.path, { type: 'company', propertyId })}
                              href={generatePath(routes.adminKycUpload.path, { type: 'company', propertyId })}
                              disabled={currentKYCStep < 2}>Upload</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      }
                      <Container>
                        <Outlet />
                      </Container>
                    </KYCWrapper>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
