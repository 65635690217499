import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import logoMini from '../../assets/images/logo-mini.svg';
import image1 from './assets/949d5b6a-c7ed-4cf5-81d1-dffc639dde73_IMG_4935.png';
import image2 from './assets/b32f669a-7a64-4ea6-84eb-590ac02e7492_IMG_4951.png';
import image3 from './assets/ff00c55a-076f-432f-8486-b9ec5c68e1ea_IMG_4953.png';
import Slider from './Slider';
import { Typography } from '@mui/material';
import CustomButton from '../CustomButton/CustomButton';
import {
  defaultSubTextColor,
  defaultTitleColor,
  globalThemeOptions,
  lineColor,
  secondaryTitleColor,
} from '../../styles';
import { buttonPrimaryBackgroundColor } from '../CustomButton/styles';
import Box from '@mui/material/Box';
import { common } from '@mui/material/colors';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';
import { generatePath, Link } from 'react-router-dom';

// type ProjectDetailsProps = { images: string[] };

function ProjectCardMini(props: any) {
  const routes = useCustomRouterContext();

  console.log('project props', props)

  const images = [props.featured_image.url].concat(props.images.map((p: any) => p.image?.url));

  const imagesToRender = images.map((image) => <img src={image} width="100%" alt="ss" />);
  // images.map((image) => <PrismicImage field={image} />);

  return (
    <Box
      sx={{
        background: common.white,
        padding: { xs: 2, md: 4 },
        boxShadow: '40px 40px 100px rgba(220, 225, 237, 0.5)',
      }}>
      <Grid2 container md={12} position="relative"  direction={'column'}>
        <Grid2 md={12}>
          <Slider images={imagesToRender} featured_image={imagesToRender[0]} />
        </Grid2>
        <Grid2
          sm={12}
          display="flex"
          justifyContent="space-between"
          flexWrap="nowrap"
          flexDirection="column"
          sx={{
            '&:before': {
              content: '" "',
              background: `url(${logoMini})`,
              width: 45,
              height: 25,
              top: 0,
              right: 0,
              position: 'absolute',
            },
          }}
        >
          <Typography color={defaultTitleColor} fontWeight={500} fontSize="1.125em">{props.type} <Box sx={{
            float: 'right',
            display: { xs: 'inline-block', md: 'none' },
          }}> <img src={logoMini} /></Box></Typography>
          <Typography
            variant="h3"
            color={secondaryTitleColor}
            fontSize="2.2rem"
            fontWeight={500}
            paddingTop={1}
            paddingBottom={1}
          >{props.address}</Typography>
          <Grid2
            container
            sx={{
              borderStyle: 'dotted',
              background: 'none',
              borderColor: lineColor,
              lineHeight: 'initial',
              borderWidth: 0,
              borderTopWidth: 1,
              borderBottomWidth: 1,
            }}
          >
            <Grid2
              xs={6}
              sx={{
                paddingTop: theme => theme.spacing(1),
                paddingBottom: theme => theme.spacing(1),
              }}
            >
              <Typography color={defaultTitleColor} fontWeight={500} fontSize="1.5rem">Montant du projet</Typography>
              <Typography
                color={secondaryTitleColor}
                fontSize="2.75rem"
                fontWeight={500}
                padding={0}
              >{props.objective_price}€</Typography>
            </Grid2>
            <Grid2
              xs={6}
              sx={{
                borderStyle: 'dotted',
                borderColor: lineColor,
                lineHeight: 'initial',
                borderWidth: 0,
                borderLeftWidth: 1,
                background: 'none',
                paddingTop: theme => theme.spacing(1),
                paddingLeft: theme => theme.spacing(1),
                paddingBottom: theme => theme.spacing(1),
              }}
            >
              <Typography color={defaultTitleColor} fontWeight={500} fontSize="1.5rem">Rentabilité cible</Typography>
              <Typography color={buttonPrimaryBackgroundColor} fontWeight={500} fontSize="2.75rem">{props.cashflowPercentage}%</Typography>
            </Grid2>
          </Grid2>
            <Grid2 xs={12} display="flex" justifyContent="center" pt={1}>
              <Link to={generatePath(routes.property.path, { propertyId: props.uid })}>
                <CustomButton variant="contained" color="primary">En savoir plus</CustomButton>
              </Link>
            </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default ProjectCardMini
