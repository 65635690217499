import * as React from 'react';
import Hero from './Hero';
import OurValueSection from './OurValueSection/OurValueSection';
import TeamSection from './TeamSection';
import OurVisionSection from './OurVisionSection';
import TalkToSalesSection from '../Fees/TalkToSalesSection/TalkToSalesSection';
import WeDoAllForYou from '../../components/WeDoAllForYou/WeDoAllForYou';

function ProjectsPage() {
  return (
    <>
      <Hero />
      <OurValueSection />
      <TeamSection />
      <OurVisionSection />
      <WeDoAllForYou />
      <TalkToSalesSection />
    </>
  );
}

export default ProjectsPage;
