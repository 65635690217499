import './styles/loginbutton.css';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routes } from '../../routes';

export default function RegisterSuccess() {
  return (
    <>
      <div className="social_connection">
        <Alert variant="success">Bienvenue sur Hivest! Merci de verifier votre adresse e-mail.</Alert>
        <div className="form_footer">
          <p>Vous avez déjà validé votre compte ? <Link to={routes.login.path}>Connectez-vous</Link></p>
        </div>
      </div>
    </>
  );
}
