import * as React from 'react';
import { Stack, ThemeProvider, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { defaultSubTextColor, defaultTextColor, defaultTitleColor, globalThemeOptions, greyColor } from '../../styles';
import CustomButton from '../../components/CustomButton/CustomButton';
import SendIcon from '@mui/icons-material/ArrowRightAlt';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import ContainerWithLine from '../../components/ContainerWithLine';
import Grid2 from '@mui/material/Unstable_Grid2';
import { common } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';

function WeDoAllForYouSection() {
  const routes = useCustomRouterContext();

  return (
    <Box>
      <ContainerWithLine disableGutters maxWidth="lg" sx={{
      }}>
        <Grid2 container bgcolor={defaultTextColor}
               sx={{
                  padding: { xs: 4, md: 10 },
               }}
               >
          <Grid2 md={8} color={common.white}>
            <Typography color={greyColor} fontWeight={500} fontSize={"1.25em"}>Zéro gestion, zéro contrainte:</Typography>
            <Typography fontWeight={800} fontSize={"2.5em"}>Divyz s’occupe de tout!</Typography>
          </Grid2>
          <Grid2 xs={12} md={4} paddingTop={{ xs: 4 }}>
            <Link to={routes.login.path}>
              <CustomButton color="primary" variant="contained" width={{ xs: '100%', sm: 'auto' }} mx={0}>J’investis dès 1000€</CustomButton>
            </Link>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
    </Box>
  )
}

export default WeDoAllForYouSection
