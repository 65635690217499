import { getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../firebase';
import { routes } from '../routes';

export function handleError(func) {
  return func
    .catch((e) => {
      console.log(e.code);

      switch (e.code) {
        case 'auth/user-disabled':
          throw new Error('Votre nom d\'utilisateur a été désactivé. Merci de nous contacter pour de plus amples informations');
          break;
        case 'auth/wrong-password':
          throw new Error('Mot de passe invalide.');
          break;
        case 'auth/user-not-found':
          throw new Error('User inconnu.');
          break;
        case 'auth/multi-factor-auth-required': {
          // TODO: To debug
          /*const error = new Error('2fa required');
          // @ts-ignore
          error.twoFARequired = true;
          const resolver = getMultiFactorResolver(auth, e);
          setResolver(resolver);
          console.log('---', e, resolver);
          console.error(e);
          if (resolver.hints[0].factorId ===
            PhoneMultiFactorGenerator.FACTOR_ID) {
            const phoneInfoOptions = {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            };
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            // Send SMS verification code
            const recaptchaVerifier = new RecaptchaVerifier(
              'otp-confirmation', { size: 'invisible' }, auth);
            return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
              .then(function (verificationId) {
                console.log('PLEASE VERIF 2F', verificationId);
                setVerifCode(verificationId);
                setOpen2FAPopin(true);
              })
              .finally(() => {
                recaptchaVerifier._reset()
              });
          } else {
            // Unsupported second factor.
            console.log('Unsupported second factor');
          }*/
        }
          break;
        default:
          throw new Error(e.message);
          break;
      }
    });
}

export async function handleRegister(referrerCode, navigate, provider) {
  await provider(referrerCode)

  navigate(routes.registerSuccessProvider.path);
}
