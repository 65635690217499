import * as React from 'react';
import { Typography } from '@mui/material';
import { defaultSubTextColor, defaultTitleColor, secondaryTitleColor } from '../../styles';
import ContainerWithLine from '../../components/ContainerWithLine';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import Box from '@mui/material/Box';
import BoxElement from '../Homepage/HowItWorksSection/BoxElement';
import Grid2 from '@mui/material/Unstable_Grid2';
import imageHowItWorks1 from '../Homepage/assets/HowItWorks1.svg';
import imageHowItWorks2 from '../Homepage/assets/HowItWorks2.svg';
import imageWaveComputer from './assets/waveComputer.png';

function HowItWorksSection() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Grid2
          container
          md={8}
          sx={{
            paddingTop: { xs: 1, md: 2 },
            paddingBottom: { xs: 0, md: 2 },
          }}
        >
          <Grid2>
            <TypographyUnderlined
              variant="h2"
              fontWeight={800}
              fontSize={'3em'}
              lineHeight="1.35em"
              color={secondaryTitleColor}
              wrapperSX={{
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >Démarrer sur Divyz</TypographyUnderlined>
            <Typography variant="body1" color={secondaryTitleColor} paddingTop={3} paddingLeft={2}>3 étapes en moins de 5 mn pour rejoindre la communauté des investisseurs Divyz</Typography>

          </Grid2>
        </Grid2>
      </ContainerWithLine>
        <ContainerWithLine maxWidth="lg" sx={{ pb: 4, pl: { xs: 2, md: 4 } }}>
          <Grid2
            container
            alignContent="center"
            alignItems="stretch"
            justifyContent="center"
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            <BoxElement
              md={4}
              bgColor="transparent"
              heading={<Typography color={secondaryTitleColor} fontWeight={800} fontSize="2.25em" marginY={2}>1</Typography>}
              image={imageWaveComputer}
            >
              <Typography variant="body1" fontSize="1.5em" component="h4" fontWeight={800} textAlign="left" color={defaultTitleColor}>
                Créez votre compte sur Divyz
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="left" mt={6} color={defaultSubTextColor}>
                Inscription en quelques clics. Créez votre compte et envoyez toutes les informations nécessaires.
              </Typography>
            </BoxElement>
            <BoxElement
              md={4}
              bgColor="transparent"
              heading={<Typography color={secondaryTitleColor} fontWeight={800} fontSize="2.25em" marginY={2}>3</Typography>}
              image={imageHowItWorks1}
            >
              <Typography variant="body1" fontSize="1.5em" component="h4" fontWeight={800} textAlign="left" color={defaultTitleColor}>
                Participez au projet immobilier
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="left" mt={6} color={defaultSubTextColor}>
                Vous pouvez participer sur les projets en cours de collecte d’investissement.
              </Typography>
            </BoxElement>
            <BoxElement
              md={4}
              bgColor="transparent"
              heading={<Typography color={secondaryTitleColor} fontWeight={800} fontSize="2.25em" marginY={2}>2</Typography>}
              image={imageHowItWorks2}
            >
              <Typography variant="body1" fontSize="1.5em" component="h4" fontWeight={800} textAlign="left" color={defaultTitleColor}>
                Touchez vos loyers
              </Typography>
              <Typography variant="body2" fontSize="1em" component="p" textAlign="left" mt={6} color={defaultSubTextColor}>
                Une fois le projet validé, recevez directement sur votre compte bancaire tous les mois les loyers partagés avec la foncière.
              </Typography>
            </BoxElement>
          </Grid2>
        </ContainerWithLine>
    </Box>
  )
}

export default HowItWorksSection
