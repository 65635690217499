import { Field, Formik } from 'formik';
import { useAuthValue } from '../../../contexts/AuthContext';
import { cardPayment, bankWirePayment, payByBankPayment, bridgeApiPayment } from '../../../actions/wallet';
import React from 'react';
import { Form } from 'react-bootstrap';
import paymentBitcoinImage from '../Checkout/images/payment/Bitcoin.png';

function Deposit({ setErrorMessage }) {
  const { currentUserData, refreshBalance } = useAuthValue();

  async function handleSubmit({ amount, paymentMethod }, { setSubmitting, setFieldError }) {
    // When button submits form and form is in the process of submitting, submit button is disabled
    setSubmitting(true);

    console.log(paymentMethod);
    try {
      switch (paymentMethod) {
        case 'payByBank':
          await payByBankPayment(amount);
          refreshBalance();
          break;
        case 'card':
          await cardPayment(amount);
          refreshBalance();
          break;
        case 'bankwire':
          await bridgeApiPayment(amount)
            .then((res) => {
              console.log('bridgeapi bankwire data to treat', res);
            });
          refreshBalance();
          break;
        default:
          alert('Not supported yet');
          break;
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
      setSubmitting(false);
    }

    // TODO: Put that on the success page instead
    // refreshBalance();
    // await currentUser.reload();
    // setSubmitting(false);
  }

  return (
    <div className="mt-5">
      <h5>Dépôt</h5>
      <Formik
        initialValues={{
          amount: 0,
          paymentMethod: 'card',
        }}
        validate={values => {
          const errors = {};
          console.log('vv', values);
          if (['card'].includes(values.paymentMethod) && !values.amount) {
            errors.amount = 'Required';
          }

          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>

            <Form.Group controlId="payment_method">
              <div className="payment_method">
                <h2>Mode de paiement</h2>
                <ul>
                  <li>
                    <label>
                      <span><Field type="radio" id="card" name="paymentMethod" value="card" /> Carte bancaire</span>
                      <span><img src={paymentBitcoinImage} alt="paiement par carte" /></span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <span><Field type="radio" id="card" name="paymentMethod" value="payByBank" /> Pay By bank</span>
                      <span><img src={paymentBitcoinImage} alt="pay by bank" /></span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <span><Field type="radio" id="card" name="paymentMethod" value="bankwire" /> BridgeAPI - Virement bancaire</span>
                      <span><img src={paymentBitcoinImage} alt="paiement par virement" /></span>
                    </label>
                  </li>
                </ul>
              </div>
              {touched.paymentMethod && errors.paymentMethod ? (
                <span className="text-danger">{errors.paymentMethod}</span>
              ) : null}
            </Form.Group>
            <input
              type="number"
              name="amount"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.amount && touched.amount && errors.amount}
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default Deposit;
