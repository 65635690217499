import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import styled from 'styled-components';
import AbstractIcon from './AbstractIcon';

const OkIcon = styled.span`
  svg {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb),var(--bs-text-opacity))!important;
  }
`;

function Icon({ size }) {
  return (
    <AbstractIcon
      size={size}
      Icon={FaCheckCircle}
      Wrapper={OkIcon}
      />
  )
}

export default Icon
