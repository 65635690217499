import React from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '../routes';
import { useAuthValue } from '../contexts/AuthContext';
import { isAdmin } from '../utils/helpers';

function RequireAuth({ children, adminOnly = false }: { children: React.Element, adminOnly?: boolean }) {
  const { isLoading, isSuccess, errorAuth, error, currentUser } = useAuthValue();

  console.log('||||', isLoading, isSuccess, errorAuth, error, adminOnly)
  console.log('adminOnly', adminOnly)


  if (isLoading) {
    return <>Loading...</>
  }

  if (!isSuccess) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={routes.login.path} />;
  }

  if (adminOnly && !isAdmin(currentUser)) {
    console.log('not admin', currentUser)
    return <Navigate to={routes.dashboard.path} />;
  }
  console.log('ADMINONLYMFFFFFFF', adminOnly)


  return children;
}

export default RequireAuth;
