import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useFirstPrismicDocument } from '@prismicio/react';
import BreadCrumb from './BreadCrumb';
import Infos from './tabs/Infos';
import Renting from './tabs/Renting';
import Simulator from './tabs/Simulator';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper';
import Image from 'react-bootstrap/Image';
import hero1Image from './images/properties01.png';
import hero2Image from './images/properties02.jpg';
import hero3Image from './images/properties03.jpg';
import { client } from '../../../prismicClient';

// import './styles/main.css';
// import './styles/tabs.css';
import Button from '../../../components/Button/Button';
import FinancialSummary from './tabs/FinancialSummary';
import SkeletonPropertyDetails from './SkeletonPropertyDetails';

function PropertyDetails() {
  console.log(client)
  const document = useFirstPrismicDocument({ client });

  const data = document[0]?.data;

  console.log('PSSD', data)
  if (!data) {
    return <SkeletonPropertyDetails />
  }

  const mainSwiper = (
    <Swiper
      loop={true}
      spaceBetween={10}
      slidesPerView={3}
      freeMode={true}
      // onSwiper={setThumbsSwiper}
      watchSlidesProgress={true}
    >
      <SwiperSlide>
        <Image src={hero1Image} />
      </SwiperSlide>
      <SwiperSlide>
        <Image src={hero2Image} />
      </SwiperSlide>
      <SwiperSlide>
        <Image src={hero3Image} />
      </SwiperSlide>
    </Swiper>
  );

  return (
    <>
      <section className="head_main_section">
        <Container fluid>
          <BreadCrumb city={data.city} type={data.type} />
          <div className="row main">
            <div className="col-md-6 p-0">
              <div className="main_left_slider">
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y, Thumbs]}
                  loop={true}
                  spaceBetween={10}
                  autoplay={{ delay: 2000 }}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  thumbs={{
                    // swiper: thumbsSwiper
                  }}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <Image src={hero1Image} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image src={hero2Image} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image src={hero3Image} />
                  </SwiperSlide>
                </Swiper>
                {mainSwiper}
              </div>
            </div>
            <div className="col-md-6">
              <div className="main_sec_rightside">
                <div className="head_text_wrap">
                  <p>{data.type}</p>
                  <h3>{data.address}</h3>
                  <h5>{data.postalCode} {data.city}</h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Prix du bien</p>
                      <h2>950000€</h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Valeur de la pierre</p>
                      <h2>20000€</h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Loyer</p>
                      <h2>6075€</h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Taux de rentabilité</p>
                      <h2>6,20%</h2>
                    </div>
                  </div>
                </div>
                <Button variant="tertiary">
                  J'investis maintenant
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="tab_section">
        <Container fluid>
          <Tabs defaultActiveKey="infos">
            <Tab eventKey="infos" title="Infos">
              <Infos data={data} />
            </Tab>
            <Tab eventKey="renting" title="État locatif">
              <Renting data={data} />
            </Tab>
            <Tab eventKey="financialSummary" title="Analyse financière">
              <FinancialSummary data={data} />
            </Tab>
            <Tab eventKey="simulateur" title="Simulateur">
              <Simulator data={data} />
            </Tab>
          </Tabs>
        </Container>
      </section>
    </>
  );
}

export default PropertyDetails;
