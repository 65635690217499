import React, { useState } from "react";
import { styled } from '@mui/system';
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputText from "../../components/common/InputText";
import SocialCards from "../../components/common/SocialCards/SocialCards";
import Heading from "../../components/common/Heading/Heading";
import { Formik } from "formik";
import * as Yup from "yup";
import { Opacity } from "@mui/icons-material";
import imageCopyIcon from "../../assets/images/copyIcon.png";
import imageDollarIcon from '../../assets/images/dollar-icon.png';
import imageFacebookIcon from "../../assets/images/facebookIcon.png";
import imageTwitterIcon from "../../assets/images/twitterIcon.png";
import imageWhatsappIcon from "../../assets/images/whatsappIcon.png";
import ReferralCard from './ReferralCard';

export const validationSchema = Yup.object({
  email1: Yup.string()
    // .required("Email 1 is Required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    ),
  email2: Yup.string()
    // .required("Email 2 is Required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    ),
  email3: Yup.string()
    // .required("Email 3 is Required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    ),
});

const Referral = ({ currentUserData, referralCodeUrl, onSubmit }) => {
  const [copyText, setCopyText] = useState("Copy link");

  const handleCopyClick = async () => {
    try {
      setCopyText("Copied");
      setTimeout(() => {
        setCopyText("Copy link");
      }, 2000);
      if (
        typeof navigator !== "undefined" &&
        navigator.clipboard &&
        navigator.clipboard.writeText
      ) {
        await navigator.clipboard.writeText(referralCodeUrl);
        console.log("Text copied to clipboard");
      } else if (typeof document !== "undefined" && document.execCommand) {
        const textArea = document.createElement("textarea");
        textArea.value = referralCodeUrl;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        console.log("Text copied to clipboard");
      } else {
        console.log("Clipboard API not available");
      }
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const Referral = styled('div')({
    paddingTop: '5rem'
  })

  const ReferralSatusGrid = styled(Grid)({
    '@media only screen and (max-width: 767px)': {
      display: 'none'
    },
    '@media only screen and (max-width: 1200px)': {
      display: 'none'
    }
  })

  const CopyContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: '3rem'
  })
  const DetailsTable = styled('div')({
    position: 'relative',
    '@media only screen and (max-width: 767px)': {
      overflowX: 'auto'
    }
  });

  const ReferralTableHeading = styled('div')({
    fontSize: '1.6rem',
    lineHeight: '2rem',
    fontWeight: 700,
    padding: '2.5rem',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media only screen and (max-width: 767px)': {
      padding: '2.5rem 1.5rem',
      fontSize: '1.4rem',
    }
  });

  const TextCenter = styled('p')({
    textAlign: 'center',
  });

  const ReferralPara = styled('p')({
    fontSize: '2.5rem',
    lineHeight: '3.7rem',
    color: 'var(--dark)',
    fontWeight: 500,
  });

  const DollarIcon = styled('img')({
    display: 'inline',
    width: '3rem',
    height: '3rem',
    objectFit: 'contain',
    verticalAlign: 'sub',
  });

  const ParaText1 = styled('p')({
    fontSize: '2.4rem',
    lineHeight: '3.3rem',
    paddingTop: '4rem',
    fontWeight: 800,
    letterSpacing: '-0.02em',
  });

  const InviteText = styled('p')({
    fontSize: '1.6rem',
    lineHeight: '2.6rem',
    fontWeight: 400,
    letterSpacing: '0.02em',
    paddingTop: '3rem',
  });

  const EmailInput = styled('div')({
    maxWidth: '65.8rem',
    '@media only screen and (max-width: 767px)': {
      width: '100%'
    }
  });

  const SubmitButton = styled('div')({
    display: 'flex',
    gap: '1rem',
    paddingTop: '4rem',
    '@media only screen and (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      button : {
        width: '100%'
      }
    }
  });

  const ParaText = styled('p')({
    fontSize: '2.4rem',
    lineHeight: '3.3rem',
    paddingTop: '6rem',
    fontWeight: 800,
    letterSpacing: '-0.02em',
  });

  const CopyDivider = styled('div')({
    width: 'auto',
    height: '2.8rem',
    border: '1px solid rgba(198, 200, 215, 0.5)',
    margin: '0 1.8rem',
    '@media only screen and (max-width: 767px)':{
      margin: '0 0.8rem'
    }
  });

  const CopyLinkText = styled('p')({
    color: 'var(--secondary)',
  });

  const CopyLink = styled('div')({
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    fontSize: '1.6rem',
    fontWeight: 400,
    cursor: 'pointer',
  });

  const CopyPara = styled('div')({
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    fontSize: '1.6rem',
    fontWeight: '400',
  });

  const InviteEmails = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    marginTop: '3rem'
  })

  return (
    <Referral>
      <Heading />
      <ReferralPara>
        Faites découvrir Hivest à vos proches et recevez 10% de leurs loyers
        mensuels pendant une année!
        <DollarIcon
          src={imageDollarIcon}
          alt=""
        />
      </ReferralPara>
      <ParaText1>Comment parrainer?</ParaText1>
      <InviteText>
        Invitez vos amis et vos proches par email:
      </InviteText>
      <Formik
        initialValues={{
          email1: "",
          email2: "",
          email3: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleChange,
          values,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          handleBlur,
          touched,
        }) => (
          <InviteEmails>
            <EmailInput>
              <InputText
                label="Email du filleul #1"
                name="email1"
                value={values?.email1}
                error={errors?.email1 && touched?.email1}
                touched={touched.email1}
                onChange={(e) => {
                  setFieldValue("email1", e?.target?.value);
                }}
              />
            </EmailInput>
            <EmailInput>
              <InputText
                label="Email du filleul #2"
                name="email2"
                value={values?.email2}
                error={errors?.email2 && touched?.email2}
                touched={touched.email2}
                onChange={(e) => {
                  setFieldValue("email2", e?.target?.value);
                }}
              />
            </EmailInput>
            <EmailInput>
              <InputText
                label="Email du filleul #3"
                name="email3"
                value={values?.email3}
                error={errors?.email3 && touched?.email3}
                touched={touched.email3}
                onChange={(e) => {
                  setFieldValue("email3", e?.target?.value);
                }}
              />
            </EmailInput>
            <SubmitButton>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "var(--secondary)",
                  borderRadius: "50px",
                  color: "var(--white)",
                  padding: "10px",
                  width: "258px",
                  height: "58px",
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: "1.6rem",
                  lineHeight: "2.2rem",
                  fontFamily: "var(--font-Manrope)",
                  fontWeight: "700",
                  letterSpacing: "0.01em",
                  "&:hover, &:focus": {
                    backgroundColor: "var(--dark)",
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                  },
                }}
              >
                Invitez
              </Button>

              <Button
                // variant="contained"
                sx={{
                  backgroundColor: "var(--white)",
                  borderRadius: "50px",
                  color: "var(--secondary)",
                  padding: "10px",
                  width: "258px",
                  height: "58px",
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: "1.6rem",
                  lineHeight: "2.2rem",
                  fontFamily: "var(--font-Manrope)",
                  fontWeight: "700",
                  letterSpacing: "0.01em",
                  "&:hover, &:focus": {
                    backgroundColor: "var(--dark)",
                    color: "var(--white)",
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                  },
                }}
              >
                Voir l’aperçu de l’email
              </Button>
            </SubmitButton>
          </InviteEmails>
        )}
      </Formik>
      <ParaText>
        Partagez votre lien sur les réseaux sociaux
      </ParaText>
      <Box sx={{ paddingTop: "40px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <SocialCards
              cardType="socialCard"
              cardImage={imageFacebookIcon}
              socialColor="#4676ed"
              socialName="Facebook"
              width="16px"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <SocialCards
              cardType="socialCard"
              cardImage={imageTwitterIcon}
              socialColor="#1DA1F2"
              socialName="Twitter"
              width="36.6px"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <SocialCards
              cardType="socialCard"
              cardImage={imageWhatsappIcon}
              socialColor="#90E970"
              socialName="Whatsapp"
              width="30px"
            />
          </Grid>
        </Grid>
      </Box>
      <div>
        <ParaText>Lien de parrainage</ParaText>
      </div>
      <CopyContent>
        <CopyPara>
          <p>{referralCodeUrl}</p>
          <CopyDivider></CopyDivider>
        </CopyPara>
        <CopyLink onClick={handleCopyClick}>
          <img src={imageCopyIcon} alt="copy" />
          <CopyLinkText>{copyText}</CopyLinkText>
        </CopyLink>
      </CopyContent>
      <div>
        <ParaText> Vos filleuls</ParaText>
      </div>
      {currentUserData.referrals.map((referral) => {
        return (
          <ReferralCard
            key={referral.id}
            referral={referral}
            currentUserData={currentUserData}
          />
        );
      })}
      <DetailsTable>
        <Box sx={{ paddingTop: "35px" }}>
          <Grid container>
            <Grid xs={3} sm={2} md={2} lg={2}>
              <ReferralTableHeading className="referralTableHeading">
                <p>Date</p>
              </ReferralTableHeading>
            </Grid>
            <Grid xs={7} sm={6} md={6} lg={6}>
              <ReferralTableHeading className="referralTableHeading">
                <p>Nom</p>
              </ReferralTableHeading>
            </Grid>
            <ReferralSatusGrid
              xs={2}
              sm={2}
              md={2}
              lg={2}
            >
              <ReferralTableHeading className="referralTableHeading">
                <TextCenter>Statut</TextCenter>
              </ReferralTableHeading>
            </ReferralSatusGrid>
            <Grid xs={2} sm={2} md={2} lg={2}>
              <ReferralTableHeading className="referralTableHeading">
                <TextCenter>Gain</TextCenter>
              </ReferralTableHeading>
            </Grid>
          </Grid>
        </Box>
        <ReferralCard />
      </DetailsTable>
    </Referral>
  );
};

export default Referral;
