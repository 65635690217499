export const AccordionData = [
  {
    eventName: "Event name",
    description:
      "Suspendisse sit amet congue nibh. Nunc eleifend orci eget malesuada feugiat. Fusce et rhoncus turpis. Etiam suscipit nisi pellentesque, vulputate velit a, congue magna. Aenean vel enim risus. Mauris pellentesque quam non arcu porta,eget sollicitudin leo luctus.",
    date: "07/02/2023",
  },
  {
    eventName: "Event name",
    description:
      "Suspendisse sit amet congue nibh. Nunc eleifend orci eget malesuada feugiat. Fusce et rhoncus turpis. Etiam suscipit nisi pellentesque, vulputate velit a, congue magna. Aenean vel enim risus. Mauris pellentesque quam non arcu porta,eget sollicitudin leo luctus.",
    date: "07/02/2023",
  },
  {
    eventName: "Event name",
    description:
      "Suspendisse sit amet congue nibh. Nunc eleifend orci eget malesuada feugiat. Fusce et rhoncus turpis. Etiam suscipit nisi pellentesque, vulputate velit a, congue magna. Aenean vel enim risus. Mauris pellentesque quam non arcu porta,eget sollicitudin leo luctus.",
    date: "07/02/2023",
  },
  {
    eventName: "Event name",
    description:
      "Suspendisse sit amet congue nibh. Nunc eleifend orci eget malesuada feugiat. Fusce et rhoncus turpis. Etiam suscipit nisi pellentesque, vulputate velit a, congue magna. Aenean vel enim risus. Mauris pellentesque quam non arcu porta,eget sollicitudin leo luctus.",
    date: "07/02/2023",
  },
];
