import React from 'react';
import styled from 'styled-components';
import AbstractIcon from './AbstractIcon';
import { FaExclamationCircle } from 'react-icons/fa';

const WarningIcon = styled.span`
  svg {
    color: #ec9a3c!important;
  }
`;

function Icon({ size }) {
  return (
    <AbstractIcon
      size={size}
      Icon={FaExclamationCircle}
      Wrapper={WarningIcon}
    />
  )
}

export default Icon
