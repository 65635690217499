import * as Yup from 'yup';
import { required } from './messages';

const KYCSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2)
    .max(100)
    .required(required),
  lastName: Yup.string()
    .min(2)
    .max(100)
    .required(required),
  title: Yup.string()
    .required(required),
  adresse: Yup.object().shape({
    street: Yup.string()
      .max(256)
      .required(required),
    postCode: Yup.string()
      .max(10)
      .required(required),
    city: Yup.string()
      .max(140)
      .required(required),
    country: Yup.string()
      .max(3)
      .required(required),
  }),
  nationality: Yup.string()
    .max(3)
    .required(required),
  birth: Yup.object().shape({
    city: Yup.string()
      .max(140)
      .required(required),
    date: Yup
      .string()
      .matches(/^[12]\d{3}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])$/)
      .required(required),
    Country: Yup.string()
      .max(3)
      .required(required),
  }),
});

export default KYCSchema;
