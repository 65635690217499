import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper';

// import './styles/main.css';
// import './styles/tabs.css';
import Button from '../../../components/Button/Button';
import Skeleton from 'react-loading-skeleton';

function SkeletonPropertyDetails() {
  const mainSwiper = (
    <Swiper
      loop={true}
      spaceBetween={10}
      slidesPerView={3}
      freeMode={true}
      // onSwiper={setThumbsSwiper}
      watchSlidesProgress={true}
    >
      <SwiperSlide>
        <Skeleton height={'124px'} width={"100%"} />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton height={'124px'} width={"100%"} />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton height={'124px'} width={"100%"} />
      </SwiperSlide>
    </Swiper>
  );

  return (
    <>
      <section className="head_main_section">
        <Container fluid>
          {/*<BreadCrumb city={data.city} type={data.type} />*/}
          <div className="row main">
            <div className="col-md-6 p-0">
              <div className="main_left_slider">
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y, Thumbs]}
                  loop={true}
                  spaceBetween={10}
                  autoplay={{ delay: 2000 }}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  thumbs={{
                    // swiper: thumbsSwiper
                  }}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <Skeleton height={'400px'} width={"100%"} />
                    {/*<Image src={hero1Image} />*/}
                  </SwiperSlide>
                  <SwiperSlide>
                    <Skeleton height={'400px'} width={"100%"} />
                    {/*<Image src={hero2Image} />*/}
                  </SwiperSlide>
                  <SwiperSlide>
                    <Skeleton height={'400px'} width={"100%"} />
                    {/*<Image src={hero3Image} />*/}
                  </SwiperSlide>
                </Swiper>
                {mainSwiper}
              </div>
            </div>
            <div className="col-md-6">
              <div className="main_sec_rightside">
                <div className="head_text_wrap">
                  <p><Skeleton width={`50px`} /></p>
                  <h3><Skeleton width={`200px`} /></h3>
                  <h5><Skeleton width={`100px`} /></h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Prix du bien</p>
                      <h2><Skeleton width={`100px`} /></h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Valeur de la pierre</p>
                      <h2><Skeleton width={`150px`} /></h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Loyer</p>
                      <h2><Skeleton width={`100px`} /></h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Taux de rentabilité Brut</p>
                      <h2><Skeleton width={`75px`} /></h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="price_text">
                      <p>Taux de rentabilité Net</p>
                      <h2><Skeleton width={`75px`} /></h2>
                    </div>
                  </div>
                </div>
                <Button variant="primary" disabled>
                  J'investis maintenant
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="tab_section">
        <Container fluid>
          <Tabs defaultActiveKey="infos">
            <Tab eventKey="infos" title="Infos">
              {/*<Infos data={data} />*/}
            </Tab>
          </Tabs>
        </Container>
      </section>
    </>
  );
}

export default SkeletonPropertyDetails;
