import React from 'react';
import { Col, Row } from 'react-bootstrap';

function Messages() {
  return (
    <Row>
      <Col md={12} xs={12}>
        <div className="head_box">
          <p className="box_heading">Coming soon...</p>
        </div>
      </Col>
    </Row>
  );
}

export default Messages;
