import { maxLength, required } from './schemas/messages';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

export const errorMessageRender = (error) => {
  return <div className="invalid-feedback">{error}</div>;
};

let renderCount = 0;

export const validateUserName = (value) => {
  let error;
  if (!value) {
    error = required;
  } else if (value.length > 12) {
    error = maxLength;
  }
  return error;
};

export const validateName = (value, s, a) => {
  let error;
  if (!value) {
    error = required;
  } else if (value.length > 12) {
    error = maxLength;
  }
  return error;
};

export const validateEmail = (value) => {
  let error;
  if (!value) {
    error = required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

export const validateMobileNumber = (value) => {
  let error;
  if (value.length > 12) {
    error = maxLength;
  }
  return error;
};

export const validatePassword = (value) => {
  let error;
  if (!value) {
    error = required;
  }
  return error;
};

export const validateDateOfBirth = (value) => {
  let error;
  if (!value) {
    error = required;
  } else if (
    !/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/i.test(
      value,
    )
  ) {
    error = 'Please use the following format MM/DD/YYYY';
  }
  return error;
};

export const validateUrl = (value) => {
  try {
    if (!value) {
      return '';
    }
    return Boolean(new URL(value));
  }
  catch(e) {
    return 'Please enter a valid url';
  }
}

export const onSubmitKYC = ({ currentUser, currentUserData, navigate, nextStep, setErrorMessage }, callbackFunction) => (values, { setSubmitting }) => {
  console.log('VALUES', values, currentUserData.kycLastStep);
    const userDoc = doc(db, 'users', currentUser.uid);

    console.log('vv2', userDoc, {
      kyc: {
        ...currentUserData.kyc,
        ...values,
      },
      kycLastStep: currentUserData.kycLastStep >= nextStep.step ? currentUserData.kycLastStep : nextStep.step,
    })
      updateDoc(userDoc, {
        kyc: {
          ...currentUserData.kyc,
          ...values,
        },
        kycLastStep: currentUserData.kycLastStep >= nextStep.step ? currentUserData.kycLastStep : nextStep.step,
      })
      .then(() => {
        console.log('before callback');
        if (callbackFunction) {
          return callbackFunction(values);
        }

        return Promise.resolve()
      })
      .then(() => {
        console.log('after callback', nextStep);
        setSubmitting(false);
        navigate(nextStep.path);
      })
      .catch((e) => {
        console.log('ERORR', e);
        setSubmitting(false);
        setErrorMessage(e.message);
      });
};
