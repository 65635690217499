import { Col, Row, Table } from 'react-bootstrap';
import React from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { client } from '../../../prismicClient';
import { generatePath, Link } from 'react-router-dom';
import { routes } from '../../../routes';
import styled from 'styled-components';
import ProjectDetail from './ProjectDetail';

function Projects() {
  const documents = useAllPrismicDocumentsByType('property', { client });

  console.log(documents);
  const documentRender = documents.length > 0 && documents[0] && documents[0].map((document) => {
    console.log(document)

    return <ProjectDetail key={document.id} document={document} />
  });

  return (
    <Row>
      <Col md={12}>
        Can only launch the blockchain + kyc green status projects
        <Table bordered>
          <thead>
          <tr>
            <th>#</th>
            <th>Property name</th>
            <th>Price/token</th>
            <th>BLOCKCHAIN?</th>
            <th>KYC?</th>
          </tr>
          </thead>
          <tbody>
            {documentRender}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default Projects;
