import { Alert, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './styles/error.css';
import Button from '../../components/Button/Button';
import { routes } from '../../routes';
import { useEffect, useState } from 'react';
import { handleVerifyEmail } from '../../firebase';

function VerifyEmail({ oobCode }) {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!oobCode) {
      return;
    }

    handleVerifyEmail(oobCode)
      .then(() => {
        setMessage('Email vérifiée avec succès.')
      })
      .catch((err) => {
        setErrorMessage(`Une erreur est survenue lors de vérification de votre adresse e-mail, ${err.message}`)
      })
  }, [oobCode])

  return (
    <section className="error_wrap">
      <Container fluid>
        <h2 className="heading"><span></span> Vérification d'email</h2>
        <Row>
          {message && <Alert variant="success">{message}</Alert>}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

          <div className="error_text_box">
            <div className="error_text_wrap">
              <p>Merci d'avoir verifier votre email! Vous pouvez maintenant vous connecter avec vos identifiants. </p>
              <Button variant="primary" onClick={() => navigate(routes.login.path)} className="error_primary">
                Se connecter
              </Button>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default VerifyEmail;
