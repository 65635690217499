import * as React from 'react';
import { Typography } from '@mui/material';
import { defaultSubTextColor, defaultTitleColor, secondaryTitleColor } from '../../../styles';
import ContainerWithLine from '../../../components/ContainerWithLine';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Box from '@mui/material/Box';
import BoxElement from './BoxElement';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomButton from '../../../components/CustomButton/CustomButton';
import imageHivestLogoHightlighted from '../../../assets/images/logo.svg';
import SuccessListElement from './SuccessListElement';
import ErrorListElement from './ErrorListElement';

type AdvantagesProps = { needsDiagonaleBackground?: boolean };
function AdvantagesSection({ needsDiagonaleBackground = true }: AdvantagesProps) {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg" sx={{ pb: 0 }}>
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Grid2 container paddingTop={4} spacing={4} md={10} paddingBottom={8}>
          <Grid2>
            <TypographyUnderlined
              variant="h2"
              fontWeight={800}
              fontSize={'3em'}
              lineHeight="1.35em"
              color={secondaryTitleColor}
              wrapperSX={{
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >Les avantages du partage de loyers avec Divyz</TypographyUnderlined>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
      <BoxWithDiagonaleBackground padding={0} deg={-6} offsetTop={0} offsetBottom={100} overrideColor={needsDiagonaleBackground ? null : 'transparent'}>
        <ContainerWithLine maxWidth="lg" sx={{
          pb: { xs: 8, md: 24 },
          pl: { xs: 2, md: 4 }
        }}>
          <Grid2
            container
            alignContent="center"
            alignItems="stretch"
            justifyContent="center"
            paddingTop={{ sx: 8, md: 12 }}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}

          >
            <BoxElement
              md={3}
              sm={4}
              xs={12}
              rentability={2}
              title="Livret A"
            >
              <SuccessListElement description="liquidite" />
              <SuccessListElement description="pas de minimum" />
              <ErrorListElement description="Faible rendement" />
              <ErrorListElement description="plafond à 22 950" />
              <ErrorListElement description="non transférable" />
            </BoxElement>
            <BoxElement
              md={3}
              sm={4}
              xs={12}
              rentability={4}
              title="SCPI"
            >
              <SuccessListElement description="liquidite" />
              <SuccessListElement description="simple" />
              <ErrorListElement description="pas de choix" />
              <ErrorListElement description="aucun contrôle" />
              <ErrorListElement description="long terme" />
            </BoxElement>
            <BoxElement
              md={3}
              sm={4}
              xs={12}
              rentability={10}
              title={<img src={imageHivestLogoHightlighted} width={225} alt="Divyz" />}
              highlight
            >
              <SuccessListElement description="vous choisissez" />
              <SuccessListElement description="bons rendements" />
              <SuccessListElement description="à partir de 1000€" />
              <SuccessListElement description="liquidité" />
              <SuccessListElement description="sans engagement" />
            </BoxElement>
            <BoxElement
              md={3}
              sm={4}
              xs={12}
              rentability="4"
              title="Crowdfunding"
            >
              <SuccessListElement description="simple" />
              <SuccessListElement description="rendement garanti" />
              <ErrorListElement description="12/18 mois maximum" />
              <ErrorListElement description="aucune garantie" />
              <ErrorListElement description="typologie des projets" />
            </BoxElement>
          </Grid2>
        </ContainerWithLine>
      </BoxWithDiagonaleBackground>

    </Box>
  )
}

export default AdvantagesSection
