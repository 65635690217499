import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Heading from "../../components/common/Heading/Heading";
import CommonFooter from "../../components/common/CommonFooter/CommonFooter";
import { ChartData } from "../../utils/ChartData";
import imageBriefCase from "../../assets/images/Briefcase.png";
import imageMoney from "../../assets/images/Money.png";
import imageRealEstate from "../../assets/images/Real_estate.png";

import { styled } from '@mui/system';
Chart.register(CategoryScale);

const Accueil = () => {
  const chartData = {
    datasets: [
      {
        label: "Name of the Project",
        data: ChartData.map((data) => {
          return data.id;
        }),
        backgroundColor: ["#242831", "#6F7486", "#5333ED", "#2CD4D9"],
        cutout: "80%",
      },
    ],
  };

  const textCenter = {
    id: "textcenter",
    beforeDatasetsDraw(chart, args, pluginOption) {
      const { ctx } = chart;
      ctx.save();
      ctx.textAlign = "center";
      ctx.justifyContent = "center";
      const fontSize = 30;
      const fontWeight = 700;
      ctx.font = `${fontWeight} ${fontSize}px Manrope`;
      const text = "Montant total \nde vos briques\n3300€";
      const lines = text.split("\n");
      const lineHeight = 40;
      const padding = 15; // Adjust the padding value as per your requirement

      const availableWidth = chart.width - padding * 2;
      const x = padding + availableWidth / 2;

      let y = chart.getDatasetMeta(0).data[0].y - 25;

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (line.includes("3300")) {
          const parts = line.split("3300€");
          ctx.fillText(parts[0], x, y);
          const xPos = x + ctx.measureText(parts[0]).width;
          ctx.fillStyle = "#2CD4D9";
          ctx.fillText("3300€", xPos, y);
          const nextXPos = xPos + ctx.measureText("3300€").width;
          ctx.fillStyle = "#4A5064";
          ctx.fillText(parts[1], nextXPos, y);
        } else {
          ctx.fillText(line, x, y);
        }
        y += lineHeight;
      }
    },
  };

  const AccueilContainer = styled('div')`
  .accueil {
    padding-top: 5rem;
  }

  .accueilCard {
    background: var(--white);
    box-shadow: 40px 40px 100px rgba(220, 225, 237, 0.5);
    padding: 4rem 4rem 3rem;
    height: 100%;
    transition: 0.4s;

    &:hover,
    &:focus {
      transform: translateY(-10px);
    }
  }

  .accueilCard img {
    max-width: 4.8rem;
    max-height: 4rem;
    object-fit: contain;
    margin-bottom: 3rem;
  }

  .accueilCard p {
    color: var(--purple);
    font-weight: 800;
    letter-spacing: -0.02em;
    margin-bottom: 2rem;
  }

  .accueilCard h2 {
    font-size: 3.6rem;
    line-height: 4.6rem;
    color: var(--labelColor);
    letter-spacing: 0.02em;
    margin: 0;
    font-weight: 400;
  }

  .chartContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10rem;
    margin-top: 8rem;
  }

  .chart {
    width: 350px;
    height: 350px;
  }

  .projectDetails {
    display: none;
  }

  @media (max-width: 827px) {
    .chart {
      width: 316px;
      height: 316px;
    }
  }

  @media (max-width: 429px) {
    .chart {
      margin-top: 1rem;
      line-height: 4.8rem;
    }

    .chartContainer {
      padding-bottom: 1.6rem;
    }

    .projectContainer {
      margin-top: 5.6rem;
      padding-bottom: 4.6rem;
    }

    .projectDetails {
      margin-top: 1.2rem;
      display: flex;
      justify-content: space-between;
      padding: 1.2rem;
      background: #ffffff;
      border: 1px solid rgba(198, 200, 215, 0.5);
      box-shadow: 5px 5px 40px rgba(220, 225, 237, 0.4);
      border-radius: 0.5rem;
      height: 50px;
      align-items: center;
    }

    .projectPara {
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 2rem;
      color: #4a5064;
      line-height: 2rem;
    }

    .projectPrice {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 2rem;
      color: var(--secondary);
    }
  }
`;

  return (
    <AccueilContainer>
      <div className="accueil">
        <Heading />
        <div className="hivestRevenues">
          <div className="chartContainer">
            <div className="chart">
              <Doughnut
                data={chartData}
                options={{
                  elements: {
                    arc: {
                      borderWidth: 0,
                    },
                  },
                  plugins: {
                    cutoutPercentage: 0,
                    tooltip: {
                      backgroundColor: '#ffffff',
                      padding: 20,
                      bodyColor: '#4A5064',
                      position: 'nearest',
                      borderColor: 'rgba(198, 200, 215, 0.5)',
                      boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
                      borderWidth: 1,
                      bodyFontSize: '1.6rem',
                      bodyLineHeight: '2.2rem',
                      bodyFontWeight: '700',
                    },
                  },
                }}
                plugins={[textCenter]}
              />
            </div>
          </div>
          <div className="projectContainer">
            {ChartData.map((value, index) => {
              return (
                <div className="projectDetails">
                  <p className="projectPara">{value.projectName}</p>
                  <p
                    className="projectPrice"
                    style={{
                      color: [chartData?.datasets[0]?.backgroundColor[index]],
                    }}
                  >
                    {value.price}
                  </p>
                </div>
              );
            })}
          </div>
          <Box>
            <Grid container spacing={'20px'}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="accueilCard">
                  <img src={imageMoney} alt="" />
                  <p>Revenus perçus</p>
                  <h2>268€</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="accueilCard">
                  <img src={imageRealEstate} alt="" />
                  <p>Vos propriétés</p>
                  <h2>3</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="accueilCard">
                  <img src={imageBriefCase} alt="" />
                  <p>Valeur de vos briques</p>
                  <h2>3900€</h2>
                </div>
              </Grid>
            </Grid>
          </Box>
          <CommonFooter />
        </div>
      </div>
    </AccueilContainer>
  );
};

export default Accueil;
