import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from '@mui/system';
import { TextField, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { RoomData } from "../../../utils/DataRoom";
const DataRoom = () => {

  const PropertyTabContent = styled('div')({
    padding: '5rem 0',
    h2: {
      color: 'var(--primary)',
      marginBottom: '5rem',
      fontWeight: '800',
    },
    iframe: {
      border: 'none'
    },
    p: {
      fontSize: '1.6rem',
      lineHeight: '2.6rem',
      color: '#6f7486',
      letterSpacing: '0.02em',
      '@media (maxWidth: 480px)': {
        fontSize: '1.4rem',
      }
    }
  })

   const TableValue = styled('div')({
    fontSize: '1.6rem',
    lineHeight: '2rem',
    fontWeight: 600,
    color: 'var(--primary)',
    padding: '1.3rem 2rem',
    borderRight: '1px solid rgba(198, 200, 215, 0.5)',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (maxWidth: 480px)': {
      padding: '1.3rem 1rem',
    },
    svg: {
      maxWidth: '2.4rem',
      marginRight: '1.3rem',
      verticalAlign: 'top',
    }
  })

  const DataHeadContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '5rem',
   '@media (maxWidth: 480px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
   },
  h2: {
    margin: 0,
   }
  })

  const SearchForm = styled('form')({
    position: 'relative',
    '@media (maxWidth: 480px)': {
       marginTop: '3rem',
    },
    '@media (maxWidth: 430px)': {
      width: '100% !important'
    },
    div: {
    '@media (maxWidth: 430px)': {
      width: '100% !important',
     }
    }
  })

  const SearchButton = styled(Button)({
    position: 'absolute !important',
    left: 0,
    background: 'transparent !important',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none !important',
    color: '#6f7486 !important',
    height: 45,
    padding: '0 !important',
    justifyContent: 'flex-start !important',
    minWidth: 'auto !important',
    '&:hover, &:focus, &:focus-within, &:focus-visible': {
      background: 'transparent !important',
      transition: 'none !important',
      animation: 'none !important',
    },
  });

  const SearchImage = styled(SearchIcon)({
  width: '3rem !important',
  height: '3rem !important',
  '@media (maxWidth: 430px)': {
     width: '100% !important',
  }
  })

  const DataRoomBox = styled(Box)({
    background: '#ffffff',
    border: '1px solid rgba(198, 200, 215, 0.5)',
    boxShadow: '5px 5px 40px rgba(220, 225, 237, 0.4)',
    borderRadius: '5px',
    marginBottom: '6px',
    ':nth-of-type(odd)': {
      background: '#f4fafa'
    },
    p: {
    fontSize: '1.1rem',
  }
  })

  const PdfIcon = styled('img')({
    maxWidth: '4rem',
    objectFit: 'contain',
    verticalAlign: 'middle',
    marginRight: '1rem',
    '@media (maxWidth: 480px)': {
       maxWidth: '2.5rem',
    }
  })

  const DataLabelValue = styled(Grid)({
    '@media (maxWidth: 899px)': {
      display: 'none',
    }
  })

  const CloudIcon = styled('img')({
    maxWidth: '4rem',
    objectFit: 'contain',
    verticalAlign: 'middle',
    '@media (maxWidth: 480px)': {
       maxWidth: '2.5rem',
    }
  })

  return (
    <TabPanel value="6" sx={{ padding: "0px" }}>
      <PropertyTabContent>
        <DataHeadContent>
          <h2>Data room</h2>
          <SearchForm>
            <TextField
              label="Search"
              type="text"
              variant="outlined"
              sx={{
                backgroundColor: "transparent",
                border: "none !important",
                borderBottom: "1px solid #B8CCE0 !important",
                borderRadius: "0 !important",
                padding: "15px!important",
                height: "45px !important",
                label: {
                  fontSize: "1.6rem",
                  lineHeight: "2.8rem",
                  fontWeight: "500",
                  color: "#4A5064",
                  opacity: "0.5",
                  fontFamily: "var(--font-Manrope)",
                  padding: "0 !important",
                  left: "22px",
                  top: "-6px",
                },
                input: {
                  backgroundColor: "transparent",
                  padding: "0 0 0 22px !important",
                  height: "auto !important",
                  fontSize: "1.6rem",
                  lineHeight: "2.8rem",
                  fontWeight: "500",
                },
                fieldset: {
                  border: "none !important",
                  borderRadius: "5px !important",
                  padding: "15px !important",
                  height: "58px !important",
                },
                "&:hover fieldset": {
                  border: "none !important",
                  borderRadius: "5px",
                },
                "&:focus-within fieldset, &:focus-visible fieldset": {
                  border: "none !important",
                },
                "&:focus label, &:focus-within label, &:focus-visible label": {
                  color: "var(--secondary)",
                  opacity: "1",
                },
              }}
            />
            <SearchButton type="submit" disableRipple>
              <SearchImage />
            </SearchButton>
          </SearchForm>
        </DataHeadContent>
        {RoomData.map((item, i) => {
          return (
            <DataRoomBox key={i}>
              <Grid container>
                <Grid item xs={9} sm={9} md={8} lg={8}>
                  <TableValue>
                    <p>
                      <PdfIcon
                        src="/images/picture-pdf.png"
                        alt=""
                      />
                      {item.imageName}
                    </p>
                  </TableValue>
                </Grid>
                <DataLabelValue
                  item
                  xs={0}
                  sm={0}
                  md={2}
                  lg={2}
                  sx={{ textAlign: "center" }}
                >
                  <TableValue>
                    <p>{item.value}</p>
                  </TableValue>
                </DataLabelValue>

                <Grid item xs={3} sm={3} md={2} lg={2} sx={{ textAlign: "center" }}>
                  <TableValue style={{border: 'none'}}>
                    <p>
                      <a href="#">
                        <CloudIcon
                          src="/images/Cloud-download.png"
                          alt=""
                        />
                      </a>
                    </p>
                  </TableValue>
                </Grid>
              </Grid>
            </DataRoomBox>
          );
        })}
      </PropertyTabContent>
    </TabPanel>
  );
};

export default DataRoom;
