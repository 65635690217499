import { styled } from '@mui/system';
import mainBackground from '../../assets/images/main-bck.png';

export const GlobalStyles = styled('div')({
  '@import': "url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap')",
  ':root': {
    '--white': '#ffffff',
    '--off-white': 'rgba(255, 255, 255, 0.25)',
    '--black': '#000000',
    '--primary': '#4a5064',
    '--secondary': '#2cd4d9',
    '--light-blue': '#b8cce0',
    '--light-grey': '#83899c',
    '--grey': '#a6a9af',
    '--dark-rock-blue': '#242831',
    '--dark-green': '#353d46',
    '--dark': '#423a61',
    '--inputBck': '#f6f6f9',
    '--labelColor': '#6f7486',
    '--purple': '#5333ed',
    '--font-Manrope': '"Manrope", sans-serif',
  },
  '*': {
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    textDecoration: 'none',
    listStyle: 'none',
    WebkitFontSmoothing: 'subpixel-antialiased',
    WebkitTextStroke: '1px transparent',
    fontFamily: 'var(--font-Manrope) !important',
  },
  html: {
    fontSize: '62.5%',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
  },
  body: {
    background: 'var(--white)',
    color: 'var(--primary)',
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
    letterSpacing: '0.02em',
    fontFamily: 'var(--font-Manrope)',
    fontWeight: 400,
    fontStyle: 'normal',
    overflowX: 'hidden',
  },
  'body.mobileMenuActive': {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  h1: {
    position: 'relative',
    fontFamily: 'var(--font-Manrope)',
    fontStyle: 'normal',
    fontWeight: 800,
    color: 'var(--dark)',
    margin: '0 0 3.2rem',
    letterSpacing: '-0.02em',
    fontSize: '4.5rem',
    lineHeight: '5.8rem',
  },
  h2: {
    position: 'relative',
    fontFamily: 'var(--font-Manrope)',
    fontStyle: 'normal',
    fontWeight: 800,
    color: 'var(--dark)',
    margin: '0 0 3.2rem',
    letterSpacing: '-0.02em',
    fontSize: '3.2rem',
    lineHeight: '4rem',
  },
  h3: {
    position: 'relative',
    fontFamily: 'var(--font-Manrope)',
    fontStyle: 'normal',
    fontWeight: 800,
    color: 'var(--dark)',
    margin: '0 0 3.2rem',
    letterSpacing: '-0.02em',
    fontSize: '2.4rem',
    lineHeight: '3.4rem',
  },
  h4: {
    position: 'relative',
    fontFamily: 'var(--font-Manrope)',
    fontStyle: 'normal',
    fontWeight: 800,
    color: 'var(--dark)',
    margin: '0 0 3.2rem',
    letterSpacing: '-0.02em',
    fontSize: '1.8rem',
    lineHeight: '2.6rem',
  },
  h5: {
    position: 'relative',
    fontFamily: 'var(--font-Manrope)',
    fontStyle: 'normal',
    fontWeight: 800,
    color: 'var(--dark)',
    margin: '0 0 3.2rem',
    letterSpacing: '-0.02em',
    fontSize: '2rem',
    lineHeight: '2.4rem',
  },
  h6: {
    position: 'relative',
    fontFamily: 'var(--font-Manrope)',
    fontStyle: 'normal',
    fontWeight: 800,
    color: 'var(--dark)',
    margin: '0 0 3.2rem',
    letterSpacing: '-0.02em',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  },
  '@media (max-width: 767px)': {
    h1: {
      fontSize: '4.2rem',
    },
  },
  input: {
    height: '100% !important',
  },
  label: {
    paddingTop: '1.2rem !important',
  },
});


export const Site = styled('div')`
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
flex-direction: column;
height: 100%;
min-height: 100vh;
.mainContent {
  flex: 1;
  position: relative;
  min-height: 60rem;
  ::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(${mainBackground}) 0 0 no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  max-height: 60rem;
  background-position: bottom;
  pointer-events: none;
  z-index: -1;
}
}

.dashedBorder {
  position: relative;
  border-left: 1px dashed var(--light-blue);
}
.dashedBorder.bottom::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  border-bottom: 1px dashed var(--light-blue);
}
.container {
  width: 100%;
  max-width: 120rem;
  margin: auto;
  padding: 0 3.8rem;
}
@media (max-width: 767px) {
  .container {
    padding: 0 3.4rem;
  }
  .dashedBorder {
    border-left: none;
  }
  .dashedBorder.bottom::after {
    display: none;
  }
}
`

export const SiteContent = styled('div')`
padding-bottom: 48rem;
`

export const UserContainer = styled('div')`
.userContent,
.userContent .info {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.userContent .userImg {
  position: absolute;
  left: -4rem;
  width: 5.8rem;
  height: 5.8rem;
  border-radius: 100%;
}
.userContent .info {
  width: 20.6rem;
  height: 4.4rem;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: 1px solid rgba(198, 200, 215, 0.5);
  box-shadow: 5px 5px 40px rgba(220, 225, 237, 0.4);
  border-radius: 5px;
}
.userContent .info img {
  width: 2.3rem;
  height: 2.3rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 1rem;
}

.menuItemHasChildren::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 24px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--secondary);
  clear: both;
  transition: all 0.15s ease-in-out;
}

.menuItemHasChildren:hover::after {
  border-top: none;
  border-bottom: 6px solid var(--secondary);
  transition: none;
}

.userContent:hover .subMenu {
  visibility: visible;
  opacity: 1;
}
.userContent::after {
  /* background-color: white; */
  display: none;
}
@media (max-width: 1023px) {
    .userContent {
        display: none;
    }
    .menuItemHasChildren::after {
        right: 0;
    }
}
`

export const SiteNavContainer = styled('div')`
.siteNav,
.siteNav .mainMneu {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.siteNav ul li {
  position: relative;
  margin-right: 10.4rem;
  padding: 1.5rem 0;
  cursor: pointer;
}
.siteNav ul li:hover,
.siteNav ul li:focus {
  color: var(--secondary);
}
.siteNav .subMenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: var(--white);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
  width: 20rem;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.siteNav .subMenu li {
  display: block;
  width: 100%;
  padding: 1.5rem;
  color: var(--primary);
}
.siteNav .subMenu li:hover,
.siteNav .subMenu li:focus {
  color: var(--secondary);
}
.siteNav ul li:hover .subMenu,
.siteNav ul li:focus .subMenu {
  visibility: visible;
  opacity: 1;
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .siteNav ul li {
    margin-right: 7.4rem;
  }
}
@media (max-width: 1023px) {
  .siteNav,
  .siteNav .mainMneu {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .siteNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--white);
    padding: 3.4rem;
    margin-top: 9.6rem;
    transform: translateX(-100%);
    transition: 0.4s;
    z-index: 1000;
    overflow-y: auto;
  }
  .siteNav ul li {
    width: 100%;
    margin: 0;
  }
  .siteNav .subMenu {
    position: relative;
    left: auto;
    top: auto;
    background: var(--white);
    box-shadow: unset;
    border-radius: 0;
    box-sizing: border-box;
    width: 100%;
    z-index: 99;
    visibility: visible;
    opacity: 1;
    transition: 0.4s;
    transition: all 0.3s ease-in-out;
    margin-top: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .siteNav ul li {
    margin-right: 4.4rem;
  }
  .siteNav ul li:last-child {
    margin-right: 0;
  }
}
`
export const DashBoardContainer = styled('div')`
.container {
  width: 100%;
  max-width: 120rem;
  margin: auto;
  padding: 0 3.8rem;
}
.dashedBorder {
  position: relative;
  border-left: 1px dotted var(--light-blue);
}
.dashedBorder.bottom::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  border-bottom: 1px dashed var(--light-blue);
}
@media (max-width: 767px) {
  .container {
    padding: 0 3.4rem;
  }
  .dashedBorder {
    border-left: none;
  }
  .dashedBorder.bottom::after {
    display: none;
  }
}
`

export const InnerHeaderContainer = styled('div')`
 .innerHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 4rem 0;
}
.siteLogo img {
  max-width: 16.5rem;
}
.mobileMenu {
  position: relative;
  display: none;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  padding: 10px 5px;
}
.mobileMenu span {
  width: 3rem;
  height: 3px;
  background-color: var(--primary);
  margin: 0 0 5px;
  display: block;
  position: relative;
}
@media (max-width: 1023px) {
    .innerHeader {
    padding: 4rem 0 2rem;
  }
  .mobileMenu {
    display: block;
    position: absolute;
    right: 2rem;
    top: 3.5rem;
  }
  .siteLogo{
    width:100%;
    display: flex;
    justify-content:space-between;
    align-items:flex-end;
  }
}
`

export const HeaderContainer = styled('div')`
  .header {
    position: relative;
  }
  
  .header.no-border {
    border: none!important;
  }
  
@media (max-width: 1023px) {
  .header {
    border-bottom: 1px dashed var(--light-blue);
  }
}
`
export const MobileMenu = styled('div')`
  .mobileMenuActive span:first-child {
    transform: rotate(45deg);
    top: 8px;
  }
  .mobileMenuActive span:nth-of-type(2) {
    opacity: 0;
  }
  .mobileMenuActive span:last-child {
    transform: rotate(-45deg);
    top: -7px;
  }

@media (max-width: 1023px) {
    .mobileMenuActive .siteNav {
    transform: translateX(0);
  }
  .mobileMenuActive{
    display: none;
  }
}
`;
