import * as React from 'react';
import Button from '@mui/material/Button';

import {
  buttonOutlinedBackgroundColor,
  buttonOutlinedColor,
  buttonPrimaryBackgroundColor,
  buttonPrimaryBackgroundHoverColor,
  buttonPrimaryColor,
  buttonPrimaryDisabledBackgroundColor,
  buttonPrimaryDisabledColor,
  buttonSecondaryBackgroundColor,
  buttonSecondaryColor,
  buttonSecondaryDisabledBackgroundColor,
  buttonSecondaryDisabledColor,
  buttonSecondaryHoverBackgroundColor,
  buttonSecondaryHoverColor,
  buttonTextBackgroundColor,
  buttonTextColor,
  buttonTextHoverColor,
} from './styles';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import globalThemeOptions from '../../styles';
import { common } from '@mui/material/colors';

type CustomButtomProps = {
  height?: string | number,
  maxWidth?: string | number,
  width?: string | number | any,
  mx?: string | number,
  my?: string | number,
  boxShadow?: string | number,
  padding?: string,
}

function CustomButton({ ...props }) {
  const sx = [{
    borderRadius: 30,
    textTransform: 'none',
    padding: props.padding ?? '0.75em 4em',
    fontSize: '1.6rem',
    fontWeight: 700,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'transparent',
    mx: props.mx ?? 1,
    my: props.my ?? 0,
    maxWidth: props.maxWidth,
    width: props.width,
    '&:hover': { backgroundColor: buttonTextBackgroundColor },
    '&:visited': { color: common.white },
  },
    props.height && {
      height: props.height,
    },
    props.variant === 'text' && {
      background: buttonTextBackgroundColor,
      color: buttonTextColor,
      '&:hover': { color: buttonTextHoverColor },
      '&:visited': { color: buttonTextColor },
      '&.Mui-disabled': {
        backgroundColor: buttonPrimaryDisabledBackgroundColor,
        color: buttonPrimaryDisabledColor,
      },
    },
    props.variant === 'contained' && props.color === 'primary' && {
      background: buttonPrimaryBackgroundColor,
      color: buttonPrimaryColor,
      '&:hover': { backgroundColor: buttonPrimaryBackgroundHoverColor },
      '&:visited': { color: buttonPrimaryColor },
      '&.Mui-disabled': {
        backgroundColor: buttonPrimaryDisabledBackgroundColor,
        color: buttonPrimaryDisabledColor,
      },
    },
    props.variant === 'contained' && props.color === 'secondary' && {
      background: buttonSecondaryBackgroundColor,
      color: buttonSecondaryColor,
      '&:hover': {
        backgroundColor: buttonSecondaryHoverBackgroundColor,
        color: buttonSecondaryHoverColor,
        boxShadow: 0,
      },
      '&:visited': {
        color: buttonSecondaryColor,
      },
      '&.Mui-disabled': {
        backgroundColor: buttonSecondaryDisabledBackgroundColor,
        color: buttonSecondaryDisabledColor,
        boxShadow: props.boxShadow ?? 1,
      },
    },
    props.size === 'large' && {
      padding: '1.25em 3em',
    },
    props.variant === 'outlined' && {
      background: 'transparent',
      color: buttonOutlinedColor,
      borderColor: buttonOutlinedColor,
      '&:hover': {
        backgroundColor: 'transparent',
        color: buttonOutlinedColor,
        boxShadow: 0,
        borderColor: buttonOutlinedColor,
      },
      '&:visited': {
        color: buttonOutlinedColor,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: buttonOutlinedColor,
        borderColor: buttonOutlinedColor,
        boxShadow: 0,
      },
    },
    props.variant === 'outlined' && props.color === 'secondary' && {
      color: globalThemeOptions.palette.secondary.main,
      borderColor: globalThemeOptions.palette.secondary.main,
      '&:hover': {
        backgroundColor: 'transparent',
        color: globalThemeOptions.palette.secondary.main,
        boxShadow: 1,
        borderWidth: 2,
        borderColor: globalThemeOptions.palette.secondary.main,
      },
      '&:visited': {
        color: globalThemeOptions.palette.secondary.main,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: globalThemeOptions.palette.secondary.main,
        borderColor: globalThemeOptions.palette.secondary.main,
        boxShadow: 0,
      },
    },
    props.endIcon && {
      '> .MuiButton-endIcon': {
        color: buttonPrimaryBackgroundColor,
      },
    },
  ]

  return (
    // @ts-ignore
    <Button sx={sx} {...props} >
      {props.children}
    </Button>
  )
}

export default CustomButton as ExtendButtonBase<ButtonTypeMap<CustomButtomProps>>;
