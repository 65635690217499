import { TabPanel } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from '@mui/system';
import { AccordionData } from "../../../utils/AccordionData";
const Actu = () => {
  const [expandedItems, setExpandedItems] = useState(
    Array(AccordionData.length).fill(true)
  );

  const handleAccordionChange = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  const PropertyTabContent = styled('div')({
    padding: '5rem 0',
    h2: {
      color: 'var(--primary)',
      marginBottom: '5rem',
      fontWeight: '800',
    },
    iframe: {
      border: 'none'
    },
    p: {
      fontSize: '1.6rem',
      lineHeight: '2.6rem',
      color: '#6f7486',
      letterSpacing: '0.02em',
      '@media (max-width: 480px)': {
        fontSize: '1.4rem',
      }
    }
  })

  const AccordianActuContent = styled('div')`
      position: relative;
      padding-left: 5rem;
      .accordianBox {
      position: relative;
      background: transparent;
      box-shadow: none !important;
      padding: 0;
      padding-bottom: 30px;
      border-radius: 0 !important;
      border: none !important;
      margin: 0 !important;
    }
    .accordianBox .accContent {
      background: #ffffff;
      box-shadow: 40px 40px 100px rgba(220, 225, 237, 0.5);
      padding: 28px 40px 18px;
      padding-bottom: 30px;
    }
    .accordianBox .accContent::before {
      content: "";
      position: absolute;
      left: -5rem;
      bottom: 0;
      width: auto;
      height: 100%;
      border-left: 1px dashed var(--light-blue);
      pointer-events: none;
      z-index: 1;
    }
    .accordianBox:first-child .accContent::before,
    .accordianBox:last-child .accContent::before {
      height: 50%;
    }
    .accordianBox:last-child .accContent::before {
      bottom: auto;
      top: 0;
    }
    .accordianBox .accContent::after {
      content: "";
      position: absolute;
      left: -5rem;
      top: 50%;
      transform: translateY(-50%);
      width: 5rem;
      height: auto;
      border-top: 1px dashed var(--light-blue);
      pointer-events: none;
      z-index: 1;
    }
    .accordianBox .accContent .accCircle {
      position: absolute;
      left: -5.9rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.8rem;
      height: 1.8rem;
      background: #e2dfee;
      border-radius: 50%;
      pointer-events: none;
      z-index: 2;
    }
    .accordianBox .accordianTitle {
      font-size: 2rem;
      line-height: 2.7rem;
      font-family: var(--font-Manrope);
      font-weight: 800;
      color: #5333ed;
      letter-spacing: -0.02em;
      margin: 0;
    }

    .accordianBox .accordianSummary,
    .accordianBox .accordianDetails {
      padding: 0 !important;
    }
    .accordianBox .accordianDate {
      display: flex;
      align-items: center;
      margin-top: 2.6rem;
    }
    .accordianBox .accordianDate span {
      margin-left: 1rem;
      line-height: normal;
    }
    .accordianBox .accContent:hover,
    .accordianBox .accContent:focus {
      background: #f4fafa;
    }
    .accordianBox .accContent:hover .accCircle,
    .accordianBox .accContent:focus .accCircle {
      background: var(--secondary);
    }
      @media (max-width: 480px) {
        padding-left: 3rem;
        .accordianBox .accContent::before,
        .accordianBox .accContent::after {
          left: -3rem;
        }
        .accordianBox .accContent::after {
          width: 3rem;
        }
        .accordianBox .accContent .accCircle {
          left: -3.9rem;
        }
        .accordianBox .accContent {
          padding: 28px 20px 18px;
        }
      }
  `

  const AccordianSummary = styled(AccordionSummary)({
    padding: '0 !important',
  })

  const AccordianTitle = styled(Typography)({
    fontSize: '2rem',
    lineHeight: '2.7rem',    
    fontFamily: 'var(--font-Manrope)',
    fontWeight: 800,
    color: '#5333ed',
    letterSpacing: '-0.02em',
    margin: 0,
  })

  const AccordianDetails = styled(AccordionDetails)({
    padding: '0 !important',
  })

  const AccordianDate = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginTop: '2.6rem',
    span: {
      marginLeft: '1rem',
      lineHeight: 'normal',
    }
  })

  return (
    <TabPanel value="5" sx={{ padding: "0px" }}>
      <PropertyTabContent>
        <h2>Actu</h2>
        <AccordianActuContent>
          {AccordionData.map((item, index) => (
            <Accordion key={index} className='accordianBox'>
              <div className='accContent'>
                <div className='accCircle'></div>
                <AccordianSummary
                  expandIcon={
                    <span style={{ color: "var(--secondary)" }}>
                      {expandedItems[index] ? "Hide" : "Show"}
                    </span>
                  }
                  onClick={() => handleAccordionChange(index)}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <AccordianTitle style={{color: '#5333ed','font-size':'2rem'}}>
                    {item.eventName}
                  </AccordianTitle>
                </AccordianSummary>
                {expandedItems[index] && (
                  <AccordianDetails>
                    <Typography>
                      {item.description}
                      <AccordianDate>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_1_3)">
                            <path
                              d="M20.7158 3H19.7158V1H17.7158V3H7.71582V1H5.71582V3H4.71582C3.61582 3 2.71582 3.9 2.71582 5V21C2.71582 22.1 3.61582 23 4.71582 23H20.7158C21.8158 23 22.7158 22.1 22.7158 21V5C22.7158 3.9 21.8158 3 20.7158 3ZM20.7158 21H4.71582V10H20.7158V21ZM20.7158 8H4.71582V5H20.7158V8Z"
                              fill="#6F7486"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_3">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0.71582)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>{item.date}</span>
                      </AccordianDate>
                    </Typography>
                  </AccordianDetails>
                )}
              </div>
            </Accordion>
          ))}
        </AccordianActuContent>
      </PropertyTabContent>
    </TabPanel>
  );
};

export default Actu;
