import { Typography } from '@mui/material';
import { buttonPrimaryBackgroundColor } from '../../components/CustomButton/styles';
import React from 'react';
import YouTube from 'react-youtube';
import ContainerWithLine from '../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import { secondaryTitleColor } from '../../styles';
import videoImage from './assets/video.png';
import styled from 'styled-components';

const YoutubeWrapper = styled(YouTube)`
  width: 100%;
  position: relative;
  margin-top: 3rem;

  iframe{
    height: 100vh;
    width: 100%;
  }
`

function Hero() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">

        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Typography variant="subtitle1" sx={{
          color: buttonPrimaryBackgroundColor,
        }}>Fonctionnement</Typography>
        <TypographyUnderlined variant="h2" fontWeight={800} fontSize={'3em'} color={secondaryTitleColor} wrapperSX={{
          paddingY: (theme) => theme.spacing(2),
        }}>Comment ça marche ?</TypographyUnderlined>
        <Typography variant="body1" color={secondaryTitleColor}>Augmentez vos revenus grâce au partage de loyers avec des foncières.</Typography>
        {/*<Box component="img" src={videoImage} alt="video" sx={{ marginY: 4, width: '100%' }} />*/}
        <YoutubeWrapper videoId={'9RKU9RDUuec'} opts={{
          playerVars: {
            controls: 0,
            rel: 0,
          }
        }} />

      </ContainerWithLine>
    </Box>
  )
}

export default Hero
