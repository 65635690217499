import { useLocalStorage } from 'react-use';

function ReferralWrapper({ children }) {
  const referrerKeyName = 'referrer';
  const [referrerCode] = useLocalStorage(referrerKeyName);

  return children(referrerCode);
}

export default ReferralWrapper;
