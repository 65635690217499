import React, { useEffect, useState } from 'react';
import paymentBitcoinImage from './images/payment/Bitcoin.png';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import './styles/invest.css';
import Button from '../../../components/Button/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { invest, preInvest } from '../../../actions/property';
import { cardPayment } from '../../../actions/wallet';
import { useAuthValue } from '../../../contexts/AuthContext';
import { routes } from '../../../routes';
import humanFormat from 'human-format';
import { calculateCashflow, getStepInvestment } from '../../../utils/helpers';

// Schema for yup
Yup.addMethod(Yup.number, 'isStep1000', function (errorMessage) {
  return this.test(`is-step-1000`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value > 0 && Number.isInteger(value / getStepInvestment())) ||
      createError({ path, message: errorMessage })
    );
  });
});

function Checkout() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { refreshBalance, currentUser, currentUserData } = useAuthValue();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!state || !state?.data) {
    return <span>An error occured, thanks to go back to the <Link to={-1}>previous page</Link></span>;
  }
  const { data, propertyDetails, uid } = state;

  const validationSchema = Yup
    .object().shape({
      amount: Yup.number()
        .isStep1000(`*Le montant doit être un multiple de ${getStepInvestment()}`)
        .min(getStepInvestment(), '*Le montant ne peut être inférieur du montant d\'une pierre')
        .max(data.objective_price, '*Le montant ne peut être supérieur à l\'objectif de financement')
        .required('*Le montant est requis'),
    });

  console.log('---', state);

  async function handleSubmit({ amount, paymentMethod }, { setSubmitting, setFieldError }) {
    // When button submits form and form is in the process of submitting, submit button is disabled
    setSubmitting(true);

    console.log(paymentMethod)
    switch (paymentMethod) {
      case 'wallet':
        await invest(data.blockchain_address, amount);
        refreshBalance();
        navigate(routes.checkoutSuccess.path);
        break;
      case 'preinvest':
        await preInvest(uid, amount);
        break;
      case 'card':
        await cardPayment(amount);
        break;
      default:
        alert('Not supported yet');
        break;
    }


    // TODO: Put that on the success page instead
    // refreshBalance();
    // await currentUser.reload();
    // setSubmitting(false);
  }

  console.log('DATA PASSED TO CHECKOUT', data, propertyDetails);

  return (
    <section className="invest_main">
      <Container fluid>
        <h2 className="heading"><span></span> Combien investir ?</h2>
        <div className="invest_border">
          <Row>
            <div className="col-md-6 right_border">
              <div className="left_wrap">
                <h2 className="second_heading d-md-block d-none">Bien sélectionné</h2>
                <div className="left_top">
                  <div className="top_leftpart">
                    <h4>{data.type}</h4>
                    <h2>{data.address}</h2>
                    <p>{data.postalCode} {data.city}</p>
                  </div>
                  <div className="top_rightpart">
                    <h4>Valeur de la pierre</h4>
                    <h2>1000€</h2>
                  </div>
                </div>
                <div className="left_img">
                  <img src={data.featured_image.url} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_wrap">
                <h2 className="second_heading">Investissement et rentabilité</h2>
                <Formik
                  initialValues={{ amount: '1000', paymentMethod: 'preinvest' }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {/* Callback function containing Formik state and helpers that handle common form actions */}
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit} className="mx-auto">
                      {message && <Alert variant="success">{message}</Alert>}
                      {touched.amount && errors.amount ? (
                        <span className="text-danger">{errors.amount}</span>
                      ) : null}
                      {/*{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}*/}
                      <Form.Group controlId="amount">
                        <Row className="infos">
                          <Col md={6}>
                            <div className="amount">
                              <h4>Montant investi</h4>
                              <p>
                                <Form.Control
                                  type="number"
                                  /* This name property is used to access the value of the form element via values.nameOfElement */
                                  name="amount"
                                  placeholder="1 000"
                                  /* Set onChange to handleChange */
                                  onChange={handleChange}
                                  /* Set onBlur to handleBlur */
                                  onBlur={handleBlur}
                                  /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                                  value={values.amount}
                                  /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
                                  className={`${touched.amount && errors.amount ? 'error' : null} form-control`}
                                  aria-describedby="amountHelp"
                                />
                                <span>€</span>
                              </p>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="objective">
                              <h4>Objectif de financement</h4>
                              <b>{data.objective_price.toLocaleString('fr')} €</b>
                            </div>
                          </Col>
                        </Row>
                        <Row className="infos">
                          <div className="revenus">
                            <Col md={6}>
                              <div className="month">
                                <h4>Revenus par mois</h4>
                                <p>{humanFormat(calculateCashflow(data.price, data.objective_price, data.cashflow_redistributed, values.amount).perMonth, { decimals: 2 })} €</p>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="year">
                                <h4>Revenus par an</h4>
                                <p>{humanFormat(calculateCashflow(data.price, data.objective_price, data.cashflow_redistributed, values.amount).perYear, { decimals: 2 })} €</p>
                              </div>
                            </Col>
                          </div>
                        </Row>
                      </Form.Group>

                      {!currentUserData?.status?.KYCApproved ?
                        <Alert variant="danger">Votre compte n'a pas encore été validé, merci de remplir <Link
                          to={routes.kyc.path}>votre vérification d'identité</Link> avant d'investir sur un projet</Alert> :
                        <>
                          <Form.Group controlId="payment_method">
                            <div className="payment_method">
                              <h2>Mode de paiement</h2>
                              <ul>
                                <li><label>
                            <span><Field type="radio" id="wallet" name="paymentMethod"
                                         value="wallet" />  Portefeuille Divyz</span>
                                  <span><img src={paymentBitcoinImage} alt="payment bitcoin" /></span>
                                </label></li>
                              <li>
                                <label>
                                <span><Field type="radio" id="card" name="paymentMethod" value="card" /> Carte bancaire</span>
                                <span><img src={paymentBitcoinImage} alt="paiement par carte" /></span>
                              </label>
                              </li>


                                {/*
                                <label><li>
                            <span><Field type="radio" id="transfer" name="paymentMethod"
                                         value="transfer" disabled /> Virement bancaire</span>
                              <span><img src={paymentBitcoinImage} /></span>
                            </label></li>
                            <li><label>
                      <span><Field type="radio" id="crypto" name="paymentMethod"
                                   value="bitcoin" disabled />Portefeuille Cryptomonnaie</span>
                              <span><img src={paymentBitcoinImage} /></span>
                            </label></li>*/}
                              </ul>
                            </div>
                            {touched.paymentMethod && errors.paymentMethod ? (
                              <span className="text-danger">{errors.paymentMethod}</span>
                            ) : null}
                          </Form.Group>
                          <div className="invest_wrapper">
                            <Button variant="tertiary" disabled={isSubmitting} type="submit">
                              J’investis maintenant
                            </Button>
                          </div>
                        </>
                      }
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default Checkout;
