import * as React from 'react';
import { secondaryTitleColor } from '../../../styles';
import ContainerWithLine from '../../../components/ContainerWithLine';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import BoxWithDiagonaleBackground from '../../../components/BoxWithDiagonaleBackground';
import Box from '@mui/material/Box';
import BoxElement from './BoxElement';
import moneyGrowthIcon from '../assets/moneyGrowthIcon.png';
import cupIcon from '../assets/cupIcon.png';
import shadedCircleIcon from '../assets/shadedCircleIcon.png';
import targetIcon from '../assets/targetIcon.png';
import Grid2 from '@mui/material/Unstable_Grid2';

function OurValueSection() {
  return (
    <Box>
      <BoxWithDiagonaleBackground padding={0} deg={-6} offsetTop={170} offsetBottom={100}>
        <ContainerWithLine maxWidth="lg" sx={{
          pb: { xs: 10, md: 20 },
          pl: { xs: 2, md: 4 },
          pt: { xs: 2, md: 4 },
        }}>

          {/* @ts-ignore */}
          <ContainerWithLine position="top" sx={{
            marginLeft: -3,
            paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
          }}>
          </ContainerWithLine>

          <TypographyUnderlined variant="h2" fontWeight={800} color={secondaryTitleColor} wrapperSX={{
            paddingY: (theme) => theme.spacing(2),
          }}>Nos valeurs</TypographyUnderlined>

          <Grid2 container>
            <BoxElement icon={targetIcon} title="Simplicité">
              Nous souhaitons démocratiser l’investissement immobilier et le rendre accessible au plus grand nombre.
              Ainsi, nous tenons à ce que notre plateforme soit simple d'usage, rapide avec un langage intelligible de
              tous.
            </BoxElement>
            <BoxElement icon={shadedCircleIcon} title="Transparence">
              La transparence est au cœur de notre démarche pour bâtir une relation de confiance avec nos membres. Les
              informations concernant nos frais et nos engagements resteront accessibles
              à tous.
            </BoxElement>
            <BoxElement icon={cupIcon} title="Excellence">
              L’excellence de Divyz se traduit par une rigueur dans la gestion quotidienne de nos projets, une
              amélioration continue de notre plateforme qui repose sur une technologie de pointe et la recherche
              constante de la performance pour satisfaire nos clients.
            </BoxElement>
            <BoxElement icon={moneyGrowthIcon} title="Engagement">
              Nous tenons à ce que notre croissance s’inscrive dans une perspective durable et respectueuse des normes
              environnementales.
            </BoxElement>
          </Grid2>
        </ContainerWithLine>
      </BoxWithDiagonaleBackground>
    </Box>
  )
}

export default OurValueSection
