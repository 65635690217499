import React from 'react';
import { Alert, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import './styles/error.css';
import Button from '../../../components/Button/Button';
import { routes } from '../../../routes';
import { useEffect, useState } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';

function CheckoutError() {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (query.has('errorMessage')) {
      setErrorMessage(query.get('errorMessage'));
    }
  }, [])

  const { width, height } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="error_wrap">
      <Container fluid>
        <h2 className="heading"><span></span> Une erreur est survenue lors de votre transaction!</h2>
        <Row>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {/*{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}*/}

          <div className="error_text_box">
            <div className="error_text_wrap">
              <p>Une erreur est survenue lors de votre transaction. Nos équipes ont reçu une alerte concernant ce problème nous vous contacterons dans les plus brefs délais. </p>
                <Button variant="primary" onClick={() => navigate(routes.propertyList.path)} className="error_primary">
                  Retour à la liste des biens
                </Button>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default CheckoutError;
