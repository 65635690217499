import Box from '@mui/material/Box';
import * as React from 'react';
import ContainerWithLine from '../../components/ContainerWithLine';
import { defaultSubTextColor, defaultTitleColor, secondaryTitleColor } from '../../styles';
import Grid2 from '@mui/material/Unstable_Grid2';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import imageMarketPlace from './assets/marketplace.png';

function MarketPlaceSection() {
  const routes = useCustomRouterContext()
  const navigate = useNavigate();

  return (
    <Box>
      <ContainerWithLine maxWidth="lg" sx={{ pb: 0 }}>
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(2),
        }}>
        </ContainerWithLine>
        <Grid2 container paddingTop={4} spacing={0} md={12} paddingBottom={8}>
          <Grid2 md={6} paddingX={5}>
            <Box component="img" src={imageMarketPlace} alt="Market place" width="100%" />
          </Grid2>
          <Grid2 md={6}>
            <TypographyUnderlined
              fontWeight={800}
              lineHeight="1.35em"
              fontSize="3em"
              color={secondaryTitleColor}
              wrapperSX={{
                marginBottom: 4,
              }}
            >Besoin de vos fonds avant la fin du projet ?</TypographyUnderlined>
            <Typography
              variant="h5"
              color={defaultSubTextColor}
              fontWeight={800}
              fontSize={"1em"}
              sx={{ marginY: 8 }}>Tous les mois, pendant une semaine, nous proposons aux détenteurs de contrat de partage de les céder aux membres de la communauté au prix d'achat initial afin de faire profitez à un membre qui a loupé le projet de finalement pouvoir y participer plus tard.</Typography>
            <Typography
              color={defaultTitleColor}
              fontWeight={800}
              fontSize={"1.35em"}
              sx={{ marginBottom: 2 }}>Comment ça marche?</Typography>

            <Typography
              color={defaultSubTextColor}
              fontWeight={400}
              fontSize={"1em"}
              sx={{ marginBottom: 8 }}>Vous souhaitez récupérer vos fonds avant la fin de la période de partage de loyer. Vous proposez à la communauté Divyz de racheter vos parts au prix d'achat initial. Vous recevez vos fonds en 48h une fois qu'un membre à décidé de racheter votre contrat.</Typography>

            <Typography
              color={defaultSubTextColor}
              fontWeight={400}
              fontSize={"1em"}
              sx={{ marginBottom: 8 }}>Divyz regarde aussi les offres des membres et peux vous proposer de racheter votre contrat de partage de loyers.</Typography>

            <CustomButton color="primary" variant="contained" onClick={() => navigate(routes.register.path)}>Créez votre compte</CustomButton>

          </Grid2>
        </Grid2>
      </ContainerWithLine>
    </Box>
  )
}

export default MarketPlaceSection;
