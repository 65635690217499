import React from 'react';
import styled from 'styled-components';
import AbstractIcon from './AbstractIcon';
import { FaTimesCircle } from 'react-icons/fa';

const DangerIcon = styled.span`
  svg {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb),var(--bs-text-opacity))!important;
  }
`;

function Icon({ size }) {
  return (
    <AbstractIcon
      size={size}
      Icon={FaTimesCircle}
      Wrapper={DangerIcon}
    />
  )
}

export default Icon
