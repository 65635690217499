import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import noPreviewImage from '../../assets/no-preview.png';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Wrapper = styled.div`
  width: 100%;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
    margin-left: 5px;
  }
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
`;
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  flexDirection: 'column',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  padding: 2,
};
const labelStyle = {
  fontSize: '10px',
  color: '#8d8c8c',
};

function trimLabel(label) {
  if (!label) {
    return ''
  }
  if (label.length > 10) {
    return label.substring(0, 10) + '...';
  }

  return label;
}

function MyDropzone({ setFieldValue, values, name, label }) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    onDrop: acceptedFiles => {
      setFieldValue(name, acceptedFiles[0]);
      console.log(acceptedFiles)

      if (acceptedFiles[0].type.startsWith('image')) {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file),
        })));
      } else {
        setFiles([{
          name: acceptedFiles[0].name,
          preview: noPreviewImage,
        }]);
      }
      console.log(files)
    },
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.bmp'],
    },
  });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <p style={labelStyle}>{trimLabel(file.name)}</p>
    </div>
  ));

  return (
    <Wrapper>
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>{label}</p>
      </Container>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </Wrapper>
  );
}

export default MyDropzone;
