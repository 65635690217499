import React from 'react';
import { Link, useParams } from 'react-router-dom';
import KYCIndividualStep1 from '../KYCAdmin/AdminKYCStep1IndividualPage';
import { routes } from '../../../routes';

function ProjectKYC() {
  const { propertyId } = useParams();

  return (
    <div>
      <div>Retourner a l'<Link to={routes.admin.path}>Admin</Link></div>
      <h1>Project KYC </h1>
      <p>Property ID: {propertyId}</p>
      <KYCIndividualStep1 />
    </div>
  );
}

export default ProjectKYC;
