import * as Yup from 'yup';
import { required } from './messages';

const KYCSchema = Yup.object().shape({
  nationality: Yup.string()
    .max(3)
    .required(required),
});

export default KYCSchema;
