import Header from '../../components/Header/Header';
import WhySection from './WhySection/WhySection';
import WeDoAllForYou from '../../components/WeDoAllForYou/WeDoAllForYou';
import TalkToSalesSection from './TalkToSalesSection/TalkToSalesSection';
import Footer from '../Homepage/Footer/Footer';
import * as React from 'react';
import Hero from './Hero';
import HowItWorksSection from './HowItWorksSection';
import StepByStepSection from './StepByStepSection/StepByStepSection';
import AdvantagesSection from './AdvantagesSection';
import InvestFromX from './InvestFromX';
import MarketPlaceSection from './MarketPlaceSection';

function HowItWorksPage() {
  return (
    <>
      <Hero />
      <HowItWorksSection />
      <StepByStepSection />
      <WhySection />
      <AdvantagesSection />
      <InvestFromX amount={1000} />
      <MarketPlaceSection />
      <WeDoAllForYou />
      <TalkToSalesSection />
    </>
  );
}

export default HowItWorksPage;
