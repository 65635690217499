import * as React from 'react';
import HeroSection from './HeroSection';
import WhySection from './WhySection/WhySection';
import HowToJoin from './HowToJoinSection/HowToJoin';
import HowItWorks from './HowItWorksSection/HowItWorks';
import WeDoAllForYouSection from './WeDoAllForYouSection';
import Advantages from './AdvantagesSection/Advantages';
import FAQSection from './FAQSection/FAQSection';
import ProjectFeatureSection from './ProjectFeaturedSection/ProjectFeatureSection';
import TalkToSalesSection from './TalkToSalesSection/TalkToSalesSection';

function Homepage() {
  return (
    <>
      <HeroSection />
      <WhySection />
      <HowToJoin />
      <HowItWorks />
      <WeDoAllForYouSection />
      <Advantages />
      <FAQSection />
      <ProjectFeatureSection />
      <TalkToSalesSection />
    </>
  )
}

export default Homepage
