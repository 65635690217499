import { Formik } from 'formik';
import { withdraw } from '../../../actions/wallet';
import { useAuthValue } from '../../../contexts/AuthContext';
import { Alert, FormGroup, FormSelect } from 'react-bootstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';

function Withdraw({ setErrorMessage, setSuccessMessage }) {
  const { currentUserData, refreshBalance } = useAuthValue();

  return (
    <div className="mt-5">
      <h5>Retrait</h5>
      <Formik
        initialValues={{ amountToWithdraw: 0 }}
        validate={values => {
          const errors = {};
          if (!values.amountToWithdraw) {
            errors.amountToWithdraw = 'Required';
          }

          return errors;
        }}
        onSubmit={({ amountToWithdraw }, { setSubmitting }) => {
          withdraw({ amount: amountToWithdraw })
            .then(() => {
              setSuccessMessage('Retrait effectué avec succès');
              refreshBalance();
              setSubmitting(false);
            })
            .catch((err) => {
              setErrorMessage(err.message);
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <input
                type="number"
                name="amountToWithdraw"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <div><Link to={routes.ibans.path}>Ajouter votre IBAN</Link></div>
            {errors.amountToWithdraw && touched.amountToWithdraw && errors.amountToWithdraw}
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default Withdraw;
