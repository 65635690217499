import { Field, useFormikContext } from 'formik';
import CountryField from './CountryField';
import DateOfBirthField from './DateOfBirthField';
import React from 'react';
import { Col, FormGroup, FormSelect, Row } from 'react-bootstrap';
import { errorMessage } from '../../utils/helpers';

// Messages
const required = 'This field is required';
const maxLength = 'Your input exceed maximum length';

let renderCount = 0;

const validateUserName = (value) => {
  let error;
  if (!value) {
    error = required;
  } else if (value.length > 12) {
    error = maxLength;
  }
  return error;
};

const validateName = (value, s, a) => {
  console.log('VALIDATE', value, s, a)
  let error;
  if (!value) {
    error = required;
  } else if (value.length > 12) {
    error = maxLength;
  }
  return error;
};

const validateEmail = (value) => {
  let error;
  if (!value) {
    error = required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

const validateMobileNumber = (value) => {
  let error;
  if (value.length > 12) {
    error = maxLength;
  }
  return error;
};

const validatePassword = (value) => {
  let error;
  if (!value) {
    error = required;
  }
  return error;
};

const validateDateOfBirth = (value) => {
  let error;
  if (!value) {
    error = required;
  } else if (
    !/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/i.test(
      value,
    )
  ) {
    error = 'Please use the following format MM/DD/YYYY';
  }
  return error;
};

function KYCIndividual() {
  const { setFieldValue, initialValues, touched, errors, values } = useFormikContext();

  console.log(touched, errors, values)
  return (
    <div className="mt-4">
      <Row className="mb-3">
        <Col sm={2}>
          <FormGroup>
          <label htmlFor="firstName">Titre</label>
          <FormSelect
            aria-label="title"
            onChange={e => setFieldValue('title', e.target.value)}
          >
            <option>Titre</option>
            <option value="M">Homme</option>
            <option value="F">Femme</option>
            <option value="U">Autre</option>
          </FormSelect>
          </FormGroup>
        </Col>
        <Col sm={5}>
          <FormGroup>
            <label htmlFor="firstName">First Name</label>
            <Field
              name="firstName"
              type="text"
              className={`form-control ${
                touched.firstName && errors.firstName ? 'is-invalid' : ''
              }`}
              validate={validateName}
            />
            {touched.firstName && errors.firstName && errorMessage(errors.firstName)}
          </FormGroup>
        </Col>
        <Col sm={5}>
          <FormGroup>
            <label htmlFor="lastName">Last Name</label>
            <Field
              name="lastName"
              type="text"
              className={`form-control ${
                touched.lastName && errors.lastName ? 'is-invalid' : ''
              }`}
              validate={validateName}
            />
            {touched.lastName && errors.lastName && errorMessage(errors.lastName)}
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <FormGroup>
            <label htmlFor="street">Adresse</label>

            <Field
              className={`form-control ${
                touched.address?.street && errors.address?.street ? 'is-invalid' : ''
              }`}
              type="text"
              placeholder="Adresse"
              name="address.street"
              // validate={validateUserName}
            />
            {touched.address?.street && errors.address?.street && errorMessage(errors.address?.street)}
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="city">Ville</label>
            <Field
              className={`form-control ${
                touched.address?.city && errors.address?.city ? 'is-invalid' : ''
              }`}
              type="text"
              placeholder="Ville"
              name="address.city"
              // validate={validateUserName}
            />
            {touched.address?.city && errors.address?.city && errorMessage(errors.address?.city)}
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="postCode">Code postal</label>
            <Field
              type="text"
              placeholder="Code postal"
              name="address.postCode"
              className={`form-control ${
                touched.address?.postCode && errors.address?.postCode ? 'is-invalid' : ''
              }`}
              // validate={validateUserName}
            />
            {touched.address?.postCode && errors.address?.postCode && errorMessage(errors.address?.postCode)}
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="country">Pays</label>
            <Field
              type="select"
              name="address.country"
              placeholder="Pays"
              component={CountryField}
              defaultValue={initialValues.address?.country}
              className={` ${
                touched.address?.country && errors.address?.country ? 'is-invalid' : ''
              }`}
              // validate={validateEmail}
            />
            {touched.address?.country && errors.address?.country && errorMessage(errors.address?.country)}
          </FormGroup>
        </Col>
      </Row>
      <Row className="my-3 fw-bold">Naissance</Row>
      <Row className="mb-3">
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="birth.date">Date de naissance</label>
            <Field
              component={DateOfBirthField}
              className={`form-control ${
                touched.birth?.date && errors.birth?.date ? 'is-invalid' : ''
              }`}
              placeholder="Date de naissance"
              name="birth.date"
              // validate={validateDateOfBirth}
            />
            {errors.birth?.date &&
              touched.birth?.date &&
              errorMessage(errors.birth?.date)}
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="city">Ville de naissance</label>
            <Field
              type="text"
              placeholder="Code postal"
              name="birth.city"
              className={`form-control ${
                touched.birth?.city && errors.birth?.city ? 'is-invalid' : ''
              }`}
              // validate={validateUserName}
            />
            {touched.birth?.city && errors.birth?.city && errorMessage(errors.birth?.city)}
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="country">Pays de naissance</label>
            <Field
              type="select"
              name="birth.Country"
              placeholder="Pays"
              component={CountryField}
              defaultValue={initialValues.birth?.Country}
              className={` ${
                touched.birth?.Country && errors.birth?.Country ? 'is-invalid' : ''
              }`}
              // validate={validateEmail}
            />
            {touched.birth?.Country && errors.birth?.Country && errorMessage(errors.birth?.Country)}
          </FormGroup>
        </Col>
      </Row>


      <Row className="my-3 fw-bold">Plus d'informations!</Row>
      <Row className="mb-3">
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="nationality">Nationalité</label>
            <Field
              type="select"
              name="nationality"
              placeholder="Nationalité"
              component={CountryField}
              defaultValue={initialValues.nationality}
              className={` ${
                touched.nationality && errors.nationality ? 'is-invalid' : ''
              }`}
              // validate={validateEmail}
            />
            {errors.nationality &&
              touched.nationality &&
              errorMessage(errors.nationality)}
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="city">Ville de naissance</label>
            <Field
              type="text"
              placeholder="Code postal"
              name="birth.city"
              className={`form-control ${
                touched.birth?.city && errors.birth?.city ? 'is-invalid' : ''
              }`}
              // validate={validateUserName}
            />
            {touched.birth?.city && errors.birth?.city && errorMessage(errors.birth?.city)}
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label htmlFor="country">Pays de naissance</label>
            <Field
              type="select"
              name="birth.Country"
              placeholder="Pays"
              component={CountryField}
              defaultValue={initialValues.birth?.Country}
              className={` ${
                touched.birth?.Country && errors.birth?.Country ? 'is-invalid' : ''
              }`}
              // validate={validateEmail}
            />
            {touched.birth?.Country && errors.birth?.Country && errorMessage(errors.birth?.Country)}
          </FormGroup>
        </Col>
      </Row>
      <div className="form-group">
        <button className="btn btn-primary" type="submit">
          Submit
        </button>
      </div>
    </div>
  );
}

export default KYCIndividual;
