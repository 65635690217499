import { Tab } from 'react-bootstrap';

function FinancialSummary(data) {
  return (
    <>
      <h2 className="heading"><span></span> Analyse financière</h2>
      <div className="financial_table">
        <div className="table-responsive main_table">
          <table className="table table-borderless ">
            <thead>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>DÉBUT BAIL</td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Chambre 1</td>
              <td>Étudiant</td>
              <td className="green">À jour</td>
              <td>-</td>
              <td>50 €</td>
              <td>675 €</td>
              <td>Meublé</td>
              <td>03/09/2021</td>
              <td>31/08/2022</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Chambre 2</td>
              <td>Salarié CDI</td>
              <td className="red">À encaisser</td>
              <td>625 €</td>
              <td>50 €</td>
              <td>675 €</td>
              <td>Meublé</td>
              <td>03/09/2021</td>
              <td>31/08/2022</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Chambre 3</td>
              <td>-</td>
              <td>Non occupé</td>
              <td>625 €</td>
              <td>50 €</td>
              <td>675 €</td>
              <td>Meublé</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="table_inner_box">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                    <tr>
                      <td>Nb de locataires</td>
                      <td>9</td>
                    </tr>
                    <tr>
                      <td>Type de baux</td>
                      <td>Individuels</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="table_inner_box">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                    <tr>
                      <td>Loyer total estimé CC</td>
                      <td>6075 € /mois</td>
                    </tr>
                    <tr>
                      <td>Loyer total actuel CC</td>
                      <td>6075 € /mois</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinancialSummary
