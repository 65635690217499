import { Formik } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from 'firebase/auth';
import { useState } from 'react';
import { Alert, Box, Link, Modal, Typography } from '@mui/material';
import CustomButton from '@hivest/integration/src/components/CustomButton/CustomButton';
import TextInput from '@hivest/integration/src/components/Forms/TextInput';
import { buttonPrimaryBackgroundColor } from '@hivest/integration/src/components/CustomButton/styles';
import { useCustomRouterContext } from '../../contexts/CustomRouterContext';
import CustomLink from '../../components/CustomLink/CustomLink';

type LoginFormProps = {
  confirmVerificationCode: any,
  onSubmit: any,
  validationSchema: any,
};

function LoginForm({ confirmVerificationCode, onSubmit, validationSchema }: LoginFormProps) {
  const navigate = useNavigate();
  const routes = useCustomRouterContext();
  const [open2FAPopin, setOpen2FAPopin] = useState(false);
  const [resolver, setResolver] = useState();
  const [verifCode, setVerifCode] = useState();
  const [confirmationCode, setConfirmationCode] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClose2FAPopin = () => {
    setOpen2FAPopin(false);
  };

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit({ resolver, setResolver, setErrorMessage, setVerifCode, setOpen2FAPopin })}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

            <TextInput
              fullWidth
              name="email"
              value={values.email}
              onChange={handleChange}
              disabled={isSubmitting}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              label="Adresse email"
              placeholder=""
            />

            <TextInput
              fullWidth
              label="Mot de passe"
              showHidePasswordField
              name="password"
              value={values.password}
              onChange={handleChange}
              disabled={isSubmitting}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              placeholder="******"
            />
            <CustomLink
              to={routes.forgotPassword.path}
            >Mot de passe oublié?</CustomLink>
            <CustomButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              mx={0}
              boxShadow="none"
              disabled={isSubmitting}
              my={4}
            >S'identifier</CustomButton>
            </>
          </form>
        )}
      </Formik>

      <Modal
        open={open2FAPopin}
        onClose={handleClose2FAPopin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            2-Factor Authentication
          </Typography>
          <div id="otp-confirmation"></div>
          <input
            autoFocus
            // margin="dense"
            id="code"
            // label="Confirmation code"
            type="text"
            // variant="outlined"
            // fullWidth
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />

          <CustomButton
            color="primary"
            type="submit"
            // disabled={isSubmitting}
            // onClick={confirmVerificationCode} // TODO: To fix
          >
            Confirm code
          </CustomButton>
        </Box>
      </Modal>
    </>
  );
}

export default LoginForm;
