import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import PersonalInfosSchema from './schemas/PersonalInfosSchema';
import { Alert, Col, FormGroup, FormSelect, Row } from 'react-bootstrap';
import KYCIndividual from '../../../components/KYC/KYCIndividual';
import CountryField from '../../../components/KYC/CountryField';
import DateOfBirthField from '../../../components/KYC/DateOfBirthField';
import { errorMessageRender, onSubmitAdminKYC, validateName } from './helpers';
import { useAuthValue } from '../../../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../routes';
import { kycIndividualRegister } from '../../../actions/user';

function AdminKYCStep1IndividualPage() {
  const [errorMessage, setErrorMessage] = useState(null)
  const navigate = useNavigate()
  const { type, propertyId } = useParams()
  const { currentUser, currentUserData } = useAuthValue()
  console.log(currentUser, currentUserData)
  return (
    <>
      <Formik
        initialValues={{
          title: '',
          firstName: '',
          lastName: '',
          adresse: {
            street: '',
            postCode: '',
            city: '',
            country: 'FRA',
          },
          nationality: 'FRA',
          birth: {
            date: '',
            city: '',
            Country: 'FRA'
          },
          ...currentUserData.kyc
        }}
        validationSchema={PersonalInfosSchema}
        onSubmit={onSubmitAdminKYC({
          propertyId,
          currentUserData,
          currentUser,
          navigate,
          setErrorMessage,
          nextStep: { path: generatePath(routes.adminKycCompanyInfo.path, { type: 'company', propertyId }), step: 1 }
        })}
      >
        {({
          values,
          errors,
          touched,
          isValidating,
          setFieldValue,
          initialValues,
          handleSubmit,
          isSubmitting
        }) => (
          <div className="container">
            {console.log(errors)}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Col sm={12}>
              <Form onSubmit={handleSubmit}>
                <div className="mt-4">
                  <Row className="mb-3">
                    <Col sm={2}>
                      <FormGroup>
                        <label htmlFor="firstName">Titre</label>
                        <FormSelect
                          aria-label="title"
                          onChange={e => setFieldValue('title', e.target.value)}
                          defaultValue={values.title}
                          disabled={isSubmitting}
                          className={touched.title && errors.title ? 'is-invalid' : ''}
                        >
                          <option>Titre</option>
                          <option value="M">Homme</option>
                          <option value="F">Femme</option>
                          <option value="U">Autre</option>
                        </FormSelect>
                        {touched.title && errors.title && errorMessageRender(errors.title)}
                      </FormGroup>
                    </Col>
                    <Col sm={5}>
                      <FormGroup>
                        <label htmlFor="firstName">First Name</label>
                        <Field
                          name="firstName"
                          type="text"
                          disabled={isSubmitting}
                          className={`form-control ${
                            touched.firstName && errors.firstName ? 'is-invalid' : ''
                          }`}
                          validate={validateName}
                        />
                        {touched.firstName && errors.firstName && errorMessageRender(errors.firstName)}
                      </FormGroup>
                    </Col>
                    <Col sm={5}>
                      <FormGroup>
                        <label htmlFor="lastName">Last Name</label>
                        <Field
                          name="lastName"
                          type="text"
                          disabled={isSubmitting}
                          className={`form-control ${
                            touched.lastName && errors.lastName ? 'is-invalid' : ''
                          }`}
                          validate={validateName}
                        />
                        {touched.lastName && errors.lastName && errorMessageRender(errors.lastName)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <FormGroup>
                        <label htmlFor="street">Adresse</label>

                        <Field
                          className={`form-control ${
                            touched.adresse?.street && errors.adresse?.street ? 'is-invalid' : ''
                          }`}
                          disabled={isSubmitting}
                          type="text"
                          placeholder="Adresse"
                          name="adresse.street"
                          // validate={validateUserName}
                        />
                        {touched.adresse?.street && errors.adresse?.street && errorMessageRender(errors.adresse?.street)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="city">Ville</label>
                        <Field
                          className={`form-control ${
                            touched.adresse?.city && errors.adresse?.city ? 'is-invalid' : ''
                          }`}
                          disabled={isSubmitting}
                          type="text"
                          placeholder="Ville"
                          name="adresse.city"
                          // validate={validateUserName}
                        />
                        {touched.adresse?.city && errors.adresse?.city && errorMessageRender(errors.adresse?.city)}
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="postCode">Code postal</label>
                        <Field
                          type="text"
                          disabled={isSubmitting}
                          placeholder="Code postal"
                          name="adresse.postCode"
                          className={`form-control ${
                            touched.adresse?.postCode && errors.adresse?.postCode ? 'is-invalid' : ''
                          }`}
                          // validate={validateUserName}
                        />
                        {touched.adresse?.postCode && errors.adresse?.postCode && errorMessageRender(errors.adresse?.postCode)}
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="country">Pays</label>
                        <Field
                          type="select"
                          name="adresse.country"
                          placeholder="Pays"
                          disabled={isSubmitting}
                          component={CountryField}
                          defaultValue={initialValues.adresse?.country}
                          className={` ${
                            touched.adresse?.country && errors.adresse?.country ? 'is-invalid' : ''
                          }`}
                        />
                        {touched.adresse?.country && errors.adresse?.country && errorMessageRender(errors.adresse?.country)}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="birth.date">Date de naissance</label>
                        <Field
                          component={DateOfBirthField}
                          disabled={isSubmitting}
                          className={`form-control ${
                            touched.birth?.date && errors.birth?.date ? 'is-invalid' : ''
                          }`}
                          placeholder="Date de naissance"
                          name="birth.date"
                          // validate={validateDateOfBirth}
                        />
                        {errors.birth?.date &&
                          touched.birth?.date &&
                          errorMessageRender(errors.birth?.date)}
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="city">Ville de naissance</label>
                        <Field
                          type="text"
                          placeholder="Ville de naissance"
                          name="birth.city"
                          disabled={isSubmitting}
                          className={`form-control ${
                            touched.birth?.city && errors.birth?.city ? 'is-invalid' : ''
                          }`}
                          // validate={validateUserName}
                        />
                        {touched.birth?.city && errors.birth?.city && errorMessageRender(errors.birth?.city)}
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="country">Pays de naissance</label>
                        <Field
                          type="select"
                          name="birth.Country"
                          placeholder="Pays"
                          component={CountryField}
                          disabled={isSubmitting}
                          defaultValue={initialValues.birth?.Country}
                          className={` ${
                            touched.birth?.Country && errors.birth?.Country ? 'is-invalid' : ''
                          }`}
                          // validate={validateEmail}
                        />
                        {touched.birth?.Country && errors.birth?.Country && errorMessageRender(errors.birth?.Country)}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="nationality">Nationalité</label>
                        <Field
                          type="select"
                          name="nationality"
                          placeholder="Nationalité"
                          component={CountryField}
                          disabled={isSubmitting}
                          defaultValue={initialValues.nationality}
                          className={` ${
                            touched.nationality && errors.nationality ? 'is-invalid' : ''
                          }`}
                          // validate={validateEmail}
                        />
                        {errors.nationality &&
                          touched.nationality &&
                          errorMessageRender(errors.nationality)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="form-group float-end">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Suivant
                    </button>
                  </div>
                </div>
              </Form>
            </Col>
          </div>
        )}
      </Formik>
    </>
  );
}

export default AdminKYCStep1IndividualPage;
