import * as React from 'react';
import Box from '@mui/material/Box';
import ContainerWithLine from '../../../components/ContainerWithLine';
import Grid2 from '@mui/material/Unstable_Grid2';
import TypographyUnderlined from '../../../components/TypographyUnderlined';
import { defaultSubTextColor, defaultTitleColor, secondaryTitleColor } from '../../../styles';
import imageHowItWorks1 from '../../Homepage/assets/HowItWorks1.svg';
import imageFinancial2 from '../../Homepage/assets/HowItWorks2.svg';
import imageContract3 from '../assets/diamondContract.png';
import imageWave4 from '../assets/waveComputer.png';
import imageSpread5 from '../assets/spreadMoney.png';
import imageBanCheck6 from '../assets/bankCheck.png';
import imageBanCheck7 from '../assets/signature.png';
import imageBanCheck8 from '../assets/moneyCalendar.png';
import imageBanCheck9 from '../assets/OwnHouse.png';
import imageBanCheck10 from '../assets/bricksStack.png';
import imageBanCheck11 from '../assets/moneyGrowth.png';
import snakeLineImage from '../assets/snakeLine.png';
import BoxElement from './BoxElement';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { Link } from 'react-router-dom';
import { useCustomRouterContext } from '../../../contexts/CustomRouterContext';

function StepByStepSection() {
  const routes = useCustomRouterContext();

  return (
    <Box
    sx={{
      // backgroundImage: `url(${snakeLineImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPositionY: '230px',
    }}>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>
        </ContainerWithLine>
        <Grid2
          container
          md={8}
          sx={{
            paddingTop: { xs: 1, md: 2 },
            paddingBottom: { xs: 0, md: 2 },
          }}
        >
          <Grid2>
            <TypographyUnderlined
              variant="h2"
              fontWeight={800}
              fontSize={'3em'}
              lineHeight="1.35em"
              color={secondaryTitleColor}
              wrapperSX={{
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >Les étapes d’un projet d’investissement Divyz</TypographyUnderlined>
          </Grid2>
        </Grid2>
      </ContainerWithLine>
      <ContainerWithLine maxWidth="lg" sx={{
        pb: 4,
      }}>
        <Grid2
          container
          // display="grid"
          // alignItems="start"
          // gridTemplateColumns="repeat(9, 12.5%)"
          // gridTemplateRows="repeat(8, 220px)"
          // sx={{
          //   gridGap: 0,

        // alignContent="center"
          alignItems="baseline"
          // justifyContent="center"
          flexWrap={{ xs: 'wrap', md: 'wrap' }}
        >
          <BoxElement
            gridProps={{
              md: 3,
          }}
            prefix={"1."}
            title={"Sélection du bien immobilier"}
            description={"Les experts en immobilier de Divyz sélectionnent le bien selon plusieurs critères pour maximiser le potentiel de rentabilité."}
            image={imageHowItWorks1}
            />
          <BoxElement
            gridProps={{
              md: 3,
          }}
            prefix={"2."}
            title={"Dossier financier"}
            description={"L’équipe Divyz définit le montage financier qui permet de maximiser la rentabilité du projet"}
            image={imageFinancial2}
            />
          <BoxElement
            gridProps={{
              md: 3,
          }}
            prefix={"3."}
            title={"Création du smartcontract"}
            description={"Le projet est enregistré sur la blockchain Divyz pour garantir la sécurité et la transparence du projet"}
            image={imageContract3}
            />
          <BoxElement
            gridProps={{
              md: 3,
          }}
            prefix={"4."}
            title={"Mise en ligne du projet sur Divyz"}
            description={"Le projet est mis en ligne sur le site Divyz avec toutes informations relatives au bien (% de rendement visé, localisation, point positifs de la région etc)"}
            image={imageWave4}
            />
          <BoxElement
            gridProps={{
              md: 3,
              lgOffset: 2,
              mdOffset: 1,
              smOffset: 0,
              marginTop: 12,
            }}
            prefix={"5."}
            title={"Ouverture du projet aux membres Divyz"}
            description={"L’ouverture du projet permet aux membres Divyz de contribuer à son investissement à partir de 1000€. Vous pouvez acheter une ou plusieurs parts en seulement quelques clics"}
            image={imageSpread5}
            />
          <BoxElement
            gridProps={{
              md: 3,
              marginTop: 12,
            }}
            prefix={"6."}
            title={"Recherche du financement"}
            description={"Tous nos projets sont financés à l’aide d’un emprunt bancaire pour profiter de l’effet de levier. Les experts Divyz recherchent et finalisent l’emprunt bancaire à hauteur de 50% du montant du bien."}
            image={imageBanCheck6}
            />
          <BoxElement
            gridProps={{
              md: 3,
              marginTop: 12,
            }}
            prefix={"7."}
            title={"Signature de l'acte authentique"}
            description={"L’acte est authentique est signé après la validation de l’ensemble des étapes projet par les parties prenantes (emprunt bancaire, notaire, achat de briques par les investisseurs)"}
            image={imageBanCheck7}
            />
          <BoxElement
            gridProps={{
              md: 3,
              marginTop: 4,
            }}
            prefix={"8."}
            title={"Distribution des revenus locatifs tous les mois"}
            description={"Chaque mois, vous recevrez des revenus locatifs au prorata de votre investissement sur le projet"}
            image={imageBanCheck8}
            />
          <BoxElement
            gridProps={{
              md: 3,
              marginTop: 4,
            }}
            prefix={"9."}
            title={"Gestion quotidienne du bien immobilier"}
            description={"Divyz s'occupe de la gestion locative du bien et vous décharge de toute contrainte."}
            image={imageBanCheck9}
            />
          <BoxElement
            gridProps={{
              md: 3,
              marginTop: 4,
            }}
            prefix={"10."}
            title={"Cession de votre contrat"}
            description={"une fois par mois, pendant une semaine, vous pouvez proposer à la communauté Divyz de racheter votre part de contrat."}
            image={imageBanCheck10}
            />
          <BoxElement
            gridProps={{
              md: 3,
              marginTop: 4,
            }}
            prefix={"11."}
            title={"Revente du bien et distribution de la plus value"}
            description={"Lorsque le bien immobilier est vendu par la foncière, vous percevez votre part de la plus value au prorata de votre investissement sur le projet (si plus value constatée)"}
            image={imageBanCheck11}
            />
        </Grid2>
        <Box sx={{ textAlign: 'center', marginY: 2 }}>
          <Link to={routes.register.path}>
            <CustomButton color="primary" variant="contained">Créer votre compte</CustomButton>
          </Link>
        </Box>
      </ContainerWithLine>
    </Box>
  )
}

export default StepByStepSection;
