import * as React from 'react';
import errorCross from '../assets/errorCross.svg';
import AbstractListElement from './AbstractListElement';

function ErrorListElement({ description }: {description: string }) {
  return (
    <AbstractListElement icon={errorCross} description={description} />
  );
}

export default ErrorListElement
