import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, FormGroup, FormSelect, Row } from 'react-bootstrap';
import CountryField from '../../../components/KYC/CountryField';
import DateOfBirthField from '../../../components/KYC/DateOfBirthField';
import { errorMessageRender, onSubmitKYC } from './helpers';
import BirthSchema from './schemas/BirthSchema';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../../contexts/AuthContext';

function KYCStep2Page() {
  const navigate = useNavigate()
  const { currentUser, currentUserData } = useAuthValue()

  return (
    <>
      <Formik
        initialValues={{
          birth: {
            date: '',
            city: '',
            Country: 'FRA'
          },
          ...currentUserData.kyc
        }}r
        validationSchema={BirthSchema}
        onSubmit={onSubmitKYC({ currentUserData, currentUser, navigate, nextStep: { path: routes.kycIndividualStep3.path, step: 2 } })}
      >
        {({
          values,
          errors,
          touched,
          isValidating,
          setFieldValue,
          initialValues,
          handleSubmit
        }) => (
          <div className="container">
            {console.log(values, errors, touched)}
            <Col sm={12}>
              <Form onSubmit={handleSubmit}>
                <div className="mt-4">
                  <Row className="my-3 fw-bold">Naissance</Row>
                  <Row className="mb-3">
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="birth.date">Date de naissance</label>
                        <Field
                          component={DateOfBirthField}
                          className={`form-control ${
                            touched.birth?.date && errors.birth?.date ? 'is-invalid' : ''
                          }`}
                          placeholder="Date de naissance"
                          name="birth.date"
                          // validate={validateDateOfBirth}
                        />
                        {errors.birth?.date &&
                          touched.birth?.date &&
                          errorMessageRender(errors.birth?.date)}
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="city">Ville de naissance</label>
                        <Field
                          type="text"
                          placeholder="Ville de naissance"
                          name="birth.city"
                          className={`form-control ${
                            touched.birth?.city && errors.birth?.city ? 'is-invalid' : ''
                          }`}
                          // validate={validateUserName}
                        />
                        {touched.birth?.city && errors.birth?.city && errorMessageRender(errors.birth?.city)}
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="country">Pays de naissance</label>
                        <Field
                          type="select"
                          name="birth.Country"
                          placeholder="Pays"
                          component={CountryField}
                          defaultValue={initialValues.birth?.Country}
                          className={` ${
                            touched.birth?.Country && errors.birth?.Country ? 'is-invalid' : ''
                          }`}
                          // validate={validateEmail}
                        />
                        {touched.birth?.Country && errors.birth?.Country && errorMessageRender(errors.birth?.Country)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="form-group float-end">
                    <button className="btn btn-primary" type="submit">
                      Suivant
                    </button>
                  </div>
                </div>
              </Form>
            </Col>
          </div>
        )}
      </Formik>
    </>
  );
}

export default KYCStep2Page;
