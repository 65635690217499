import { useNavigate } from 'react-router-dom';
import { handleResetPassword } from '../../firebase';
import { routes } from '../../routes';
import { useEffect } from 'react';
import ResetPasswordForm from '@hivest/integration/src/pages/ResetPassword/ResetPasswordForm';
import resetPasswordValidator from '../../validators/ResetPasswordValidator';

function ResetPasswordLogic({ oobCode }) {
  const navigate = useNavigate();

  const onSubmit = ({ setErrorMessage, setSuccessMessage }) => ({ password }, {
    setSubmitting,
    resetForm,
    setFieldError
  }) => {
    // When button submits form and form is in the process of submitting, submit button is disabled
    setSuccessMessage(null);
    setErrorMessage(null);
    setSubmitting(true);

    handleResetPassword(oobCode, password)
      .then(async () => {
        console.log('login reset password');
        setSuccessMessage('Mot de passe réinitialisé avec succès.');
        navigate(routes.login.path, { replace: true });
      })
      .catch((err) => {
        setErrorMessage(`Une erreur est survenue lors de de la réinitialisation du mot de passe, ${err.message}`);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <ResetPasswordForm
      onSubmit={onSubmit}
      validationSchema={resetPasswordValidator}
    />
  );
}

export default ResetPasswordLogic;
