import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { common } from '@mui/material/colors';
import { Stack } from '@mui/material';
import ContainerWithLine from '../../../components/ContainerWithLine';
import Box from '@mui/material/Box';

// @ts-ignore
function BasicCard({ bgColor, image, children, heading, ...props }) {
  return (
    <Grid2
      alignContent="center"
      margin={2}
      padding={0}
      sx={{
        borderRadius: 0,
        color: common.white,
        backgroundColor: bgColor,
      }}
      {...props}
    >
      {heading}
      <ContainerWithLine position="top">
        <Box my={6}>
          <img src={image} alt="image1" width={90} />
        </Box>
        {children}
      </ContainerWithLine>
    </Grid2>
  );
}

export default BasicCard;
