import React from 'react';
import { Col, FormGroup, FormSelect, Row } from 'react-bootstrap';
import MyDropzone from '../../../components/Dropzone/Dropzone';
import { get } from '../../../utils/helpers';
import { useFormikContext } from 'formik';

const documentsTypes = [
  {
    type: 'company',
    id: 7,
    name: 'KBIS',
    bothSides: false,
    primary: true,
  },
  {
    type: 'individual',
    id: 0,
    name: 'Carte d\'identité',
    bothSides: true,
    primary: true,
  },
  {
    type: 'individual',
    id: 1,
    name: 'Preuve de domicile',
    primary: false,
  },
  {
    id: 2,
    name: 'Scan de votre IBAN',
    primary: false,
  },
  {
    type: 'individual',
    id: 3,
    name: 'Passeport (Union européenne)',
    primary: true,
  },
  {
    type: 'individual',
    id: 4,
    name: 'Passeport (Hors union européenne)',
    primary: true,
  },
  {
    type: 'individual',
    id: 5,
    name: 'Titre de séjour',
    bothSides: true,
    primary: true,
  },
  {
    type: 'individual',
    id: 11,
    name: 'Permis de conduire',
    bothSides: true,
    primary: true,
  },
  {
    type: 'company',
    id: 12,
    primary: true,
    name: 'Status de société',
  },
  {
    type: 'company',
    id: 21,
    primary: true,
    name: 'Registre des bénéficiaires effectifs type DBE-S1',
  },
  {
    type: 'individual',
    id: 13,
    name: 'Selfie',
    primary: false,
  },
];

function getDocumentById(id) {
  return documentsTypes.find((doc) => doc.id.toString() === id);
}

function AdminKYCUploadField({ namePrefix, index, type, bothSides, primary, overrideOptions = [] }) {
  const { values, setFieldValue } = useFormikContext()

  function generateName(index, name) {
    return `${namePrefix}[${index}][${name}]`;
  }


  console.log('props', namePrefix, index, type, bothSides, primary)
  console.log('get', get(generateName(index, 'documentType'), values), generateName(index, 'documentType'), values)

  return (
    <Row key={index}>
      <Col sm={12}>
        <FormGroup>
          <FormSelect
            name={generateName(index, 'documentType')}
            onChange={(e) => {
              setFieldValue(generateName(index, 'documentType'), e.target.value);
            }}
          >
            <option value="">Choisissez un document</option>
            {documentsTypes
              .filter((doc) => overrideOptions.length > 0 ? overrideOptions.includes(doc.id) : ((primary ? doc.primary === primary : true) && doc.type === type))
              .map((doc) => (
                <option key={doc.id} value={doc.id} disabled={values.documents?.map((dc) => dc && dc.documentType).includes(doc.id.toString())}>
                  {doc.name}
                </option>
              ))}
          </FormSelect>
        </FormGroup>
      </Col>
      <Col sm={12}>
        <div className="d-flex flex-row justify-content-between mt-2">
          {get(generateName(index, 'documentType'), values) ?
            <>
              <MyDropzone
                name={`${generateName(index, 'files')}[0]`}
                label={`Ajouter une photo de votre ${getDocumentById(get(generateName(index, 'documentType'), values)).name} ${get(generateName(index, 'documentType'), values) && getDocumentById(get(generateName(index, 'documentType'), values)).bothSides ? '(recto)' : ''}`}
                setFieldValue={setFieldValue}
                values={values}
              />
              {get(generateName(index, 'documentType'), values) && getDocumentById(get(generateName(index, 'documentType'), values)).bothSides &&
                <MyDropzone
                  name={`${generateName(index, 'files')}[1]`}
                  label={`Ajouter une photo de votre ${getDocumentById(get(generateName(index, 'documentType'), values)).name} ${get(generateName(index, 'documentType'), values) && getDocumentById(get(generateName(index, 'documentType'), values)).bothSides && '(verso)'}`}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              }
            </>
            : null}
        </div>
      </Col>
    </Row>
  );
}

export default AdminKYCUploadField;
