import Header from '../../components/Header/Header';
import Footer from '../Homepage/Footer/Footer';
import * as React from 'react';
import Hero from './Hero';
import ProjectFeatureSection from './ProjectFeaturedSection/ProjectFeatureSection';
import AdvantagesSection from './AdvantagesSection';
import TalkToSalesSection from '../Fees/TalkToSalesSection/TalkToSalesSection';

function ProjectsPage() {
  return (
    <>
      <Hero />
      <ProjectFeatureSection />
      <AdvantagesSection />
      <TalkToSalesSection />
    </>
  );
}

export default ProjectsPage;
