import React from 'react';
import { Col, Row, Form, Container } from 'react-bootstrap';
import { useAuthValue } from '../../../contexts/AuthContext';
import Referral from '@hivest/integration/src/pages/Referral/Referral';
import { inviteReferral } from '../../../actions/user';

function ReferralWrapper() {
  const { currentUserData, currentUser } = useAuthValue()

  async function inviteSubmit(values, { setSubmitting }) {
    setSubmitting(true)

    console.log(values)
    const emails = [values.email1, values.email2, values.email3].filter((email) => email !== '')
    await inviteReferral(currentUser.uid, emails)

    setSubmitting(false)
  }

  const referralCodeUrl = `https://${window.location.host}/?referrer=${currentUserData.referralCode}`
  return (
    <Referral currentUserData={currentUserData} referralCodeUrl={referralCodeUrl} onSubmit={inviteSubmit} />
  );
}

export default ReferralWrapper;
