import * as React from 'react'
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Box from '@mui/material/Box';
import logoBackground from './assets/login-background.svg';

function LoginLayout() {
  return (
    <>
      <Header isMinimalMenu={true} />
      <Box sx={{
        backgroundImage: `url(${logoBackground})`,
        backgroundSize: 'cover',
        height: '110vh',
        marginTop: { xs: 2, sm: 0 },
      }}>
        <Outlet />
      </Box>
    </>
  )
}

export default LoginLayout;
