import { Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './styles/error.css';
import Button from '../../components/Button/Button';
import { routes } from '../../routes';

function Error() {
  const navigate = useNavigate();

  return (
    <section className="error_wrap">
      <Container fluid>
        <h2 className="heading"><span></span> Oups</h2>
        <Row>
          <div className="error_text_box">
            <div className="error_text_wrap">
              <h3 className="error_heading">Erreur 404</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. </p>
                <Button variant="primary" onClick={() => navigate(routes.homepage.path)} className="error_primary">
                  Retour à la page d’accueil
                </Button>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default Error;
