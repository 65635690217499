import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from 'react-router-dom';
import { Link, LinkProps } from '@mui/material';
import { buttonPrimaryBackgroundColor } from '../CustomButton/styles';
import React from 'react';

type CustomLinkProps = RouterLinkProps & LinkProps;
function CustomLink(props: CustomLinkProps) {
  return (
    <Link
      component={RouterLink}
      to={props.to}
      color={buttonPrimaryBackgroundColor}
      fontSize="1em"
      fontWeight={400}
      marginY={1}
      sx={{
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
        ...props.sx
    }}
    >{props.children}</Link>
  )
}

export default CustomLink;
