import { auth } from '../firebase';
import { uploadFile } from './files';

export async function postRegister(data) {
  const tokenId = await auth.currentUser.getIdToken(true);

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/postRegister`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenId}`,
    },
    body: JSON.stringify(data),
  }).then(res => res.json())
    .then(res => console.log(res));
}

export async function kycIndividualRegister() {
  const tokenId = await auth.currentUser.getIdToken(true);

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/lemonway/kyc/individual`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenId}`,
    },
    // body: JSON.stringify(data)
  }).then(res => res.json())
    .then((res) => {
      if (res.type === 'error') {
        throw new Error(res.message);
      }

      return res;
    })
    .then(res => console.log(res));
}
export async function inviteReferral(userId, emails) {
  const tokenId = await auth.currentUser.getIdToken(true);

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/${userId}/referrals`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenId}`,
    },
    // body: JSON.stringify(data)
  }).then(res => res.json())
    .then((res) => {
      if (res.type === 'error') {
        throw new Error(res.message);
      }

      return res;
    })
    .then(res => console.log(res));
}

export async function kycCompanyRegister() {
  const tokenId = await auth.currentUser.getIdToken(true);

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/lemonway/kyc/company`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenId}`,
    },
    // body: JSON.stringify(data)
  }).then(res => res.json())

    .then((res) => {
      if (res.type === 'error') {
        throw new Error(res.message);
      }

      return res;
    })
    .then(res => console.log(res));
}

export const kycIndividualUploadDocuments = (documents, prefix = 'kyc') => async () => {
  console.log('data', documents);
  const tokenId = await auth.currentUser.getIdToken(true);
  try {
    for await (const document of documents) {
      console.log('docawaited', document);
      await uploadFile(`${prefix}/${auth.currentUser.uid}/doc-${document.documentType}.pdf`, document.file, document.documentType);
    }

    return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/lemonway/${prefix}/documents`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
      },
    }).then(res => res.json())

      .then((res) => {
        if (res.type === 'error') {
          throw new Error(res.message);
        }

        return res;
      })
      .then(res => console.log(res));
  } catch (e) {
    console.error('error', e);
    throw new Error(e.message)
  }
};
