import * as React from 'react';
import { defaultSubTextColor, defaultTitleColor, secondaryTitleColor } from '../../styles';
import ContainerWithLine from '../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import CustomList from '../../components/CustomList/CustomList';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import Avatar from '@mui/material/Avatar';
import { Stack, Typography } from '@mui/material';

function TeamSection() {
  return (
    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>

          <TypographyUnderlined variant="h2" fontWeight={800} color={secondaryTitleColor} wrapperSX={{
            paddingY: (theme) => theme.spacing(2),
          }}>L'équipe</TypographyUnderlined>

          <Stack direction="row" spacing={4} marginTop={6}>
            <Avatar sx={{
              width: { lg: 200, md: 150, sm: 100, xs: 75 },
              height: { lg: 200, md: 150, sm: 100, xs: 75 },
            }} />
            <Avatar sx={{
              width: { lg: 200, md: 150, sm: 100, xs: 75 },
              height: { lg: 200, md: 150, sm: 100, xs: 75 },
            }} />
          </Stack>

          <Box sx={{ marginTop: 10 }}>
          <Typography color={defaultSubTextColor} fontSize="1em" paragraph>Divyz est l’histoire de deux entrepreneurs, Arnaud et Sébastien, passionnés d’immobilier et dotés d’une expertise en plateforme servicielle dans le digital et d’un réseau immobilier puissant qui leur donne accès à des projets off-market à haut potentiel.
            <br/>
            <br/>
            A la suite de nombreuses expériences immobilières fructueuses et la difficulté partagée par plusieurs personnes désireuses d’investir dans la pierre mais dans l’incapacité d’y accéder pour plusieurs raisons (difficulté d'accès à l’emprunt bancaire, non connaissance des critères de chasse pour des opérations immobilières rentables, manque de temps pour la gestion locative et ses imprévus etc), les fondateurs de Divyz décident d’unir leurs forces et mettre à profit leurs expertises et leurs réseaux à travers une plateforme d’investissement immobilier fractionné qui se doit d’être simple d’usage, sécurisée grâce à une technologie de pointe et avec la promesse d’une rentabilité optimale.</Typography>
          </Box>
          {/*// mandatory to fix the line position */}
          <br/>
        </ContainerWithLine>
      </ContainerWithLine>
    </Box>
  )
}

export default TeamSection
