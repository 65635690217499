import { TextField } from "@mui/material";
import React from "react";
import { ErrorMessage, useField } from "formik";
const InputText = ({ label, name, onChange, error, touched }) => {
  

  const [field] = useField(name);
  return (
    <div>
      <TextField
        label={label}
        error={!!error && touched}
        helperText={error}
        type="text"
        name={name}
        {...field}
        onChange={(e) => {
          onChange(e);
        }}
        fullWidth
        sx={{
          backgroundColor: "var(--inputBck)",
          border: "none !important",
          borderRadius: "5px !important",
          padding: "15px !important",
          height: "58px !important",
          label: {
            fontSize: "1.6rem",
            lineHeight: "2.8rem",
            fontWeight: "500",
            color: "var(--labelColor)",
            opacity: "0.5",
            fontFamily: "var(--font-Manrope)",
            padding: "0 !important",
          },
          input: {
            backgroundColor: "var(--inputBck)",
            padding: "0 !important",
            height: "auto !important",
            fontSize: "1.6rem",
            lineHeight: "2.8rem",
            fontWeight: "500",
          },
          fieldset: {
            border: "none !important",
            borderRadius: "5px !important",
            padding: "15px !important",
            height: "58px !important",
          },
          "&:hover fieldset": {
            border: "none !important",
            borderRadius: "5px",
          },
          "&:focus-within fieldset, &:focus-visible fieldset": {
            border: "none !important",
          },
          "&:focus label, &:focus-within label, &:focus-visible label": {
            color: "var(--secondary)",
            opacity: "1",
          },
        }}
      />
      {error && <ErrorMessage name={field.name} component="div"  style={{color:"red"}}/>}
    </div>
  );
};

export default InputText;
