import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

import './button.css'

function Button({ children, disabled, variant, type, className, active, size, onClick, style }) {
  return (
    <BootstrapButton variant={variant} type={type} disabled={disabled} className={className} active={active} size={size} onClick={onClick} style={style}>
      {children}
    </BootstrapButton>
  )
}

export default Button
