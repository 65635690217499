import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomButton from '../../components/CustomButton/CustomButton';
import { lineColor, secondaryTitleColor } from '../../styles';
import { common } from '@mui/material/colors';
import LoginSocialButton from '../../components/LoginSocialButton/LoginSocialButton';
import { buttonTextColor } from '../../components/CustomButton/styles';

type ForgotPasswordProps = {
  children: React.ReactNode,
}
function ForgotPassword({ children }: ForgotPasswordProps) {
  return (
    <Grid2 container>
      <Grid2 mdOffset={4} md={4}>
        <Box
          sx={{
            background: common.white,
            padding: { xs: 2, md: 4 },
            boxShadow: '40px 40px 100px rgba(220, 225, 237, 0.5)',
          }}>
          <Typography variant="h3" color={secondaryTitleColor} fontWeight={500} mb={4} sx={{
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}>Vous avez oublié votre mot de passe?</Typography>
          {children}
        </Box>
      </Grid2>
    </Grid2>
  )
}

export default ForgotPassword
