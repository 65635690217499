import humanFormat from 'human-format';
import Logo from '../../components/Logo';
import avatarImg from '../../images/avatar.png';
import { Container, Image, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// import ReactPlaceholder from 'react-placeholder';
// import "react-placeholder/lib/reactPlaceholder.css";
import './styles/header.css';
import { useAuthValue } from '../../contexts/AuthContext';
import { routes } from '../../routes';
import { logout } from '../../firebase';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaBars } from 'react-icons/fa';

import styled from 'styled-components';
import DropdownProfile from '../AuthenticatedLayout/DropdownProfile';

const Heading = styled.h2`
   font-weight: 700;
   font-size: 60px;
   line-height: 72px;
   padding-left: 27px;
   margin: 100px 0px 80px;
  
   span {
    width: 45px;
    height: 45px;
    background-color: #0F3A8C;
    display: inline-block;
  }
`;

function Header() {
  const { balance, currentUser } = useAuthValue();
  const navigate = useNavigate();
  console.log('ha', balance);
  const balanceFormatted = balance ? humanFormat(parseInt(balance), {
        maxDecimals: 2,
      }) :
      <Skeleton width={`50px`} style={{ marginLeft: '5px', marginRight: '5px' }} />
  ;

  const handleLogOut = () => {
    return logout().then(() => navigate(routes.login.path, { replace: true }));
  };

  return (
    <>
      <section className="navigation">
        <Navbar collapseOnSelect expand="md">
          <Container fluid>
            <Navbar.Brand>
              <Logo />
            </Navbar.Brand>
            <Navbar.Toggle className="navbar-toggler" type="button" data-bs-toggle="collapse"
                           data-bs-target="#collapsibleNavbar">
              <FaBars />
            </Navbar.Toggle>

            <Navbar.Collapse id="collapsibleNavbar">
              <Nav>
                <Nav.Link as={NavLink} to={routes.howItWorks.path}>
                  Comment ça fonctionne ?
                </Nav.Link>
                <Nav.Link as={NavLink} to={routes.advantages.path}>
                  Nos avantages
                </Nav.Link>
                <Nav.Link as={NavLink} to={routes.propertyList.path}>
                  Liste des propriétés
                </Nav.Link>
                {/*<Nav.Link as={NavLink} to={routes.aboutUs.path}>*/}
                {/*  À propos*/}
                {/*</Nav.Link>*/}

              </Nav>
              <Nav.Item style={{ display: 'inline-flex' }}>
                Balance: {balanceFormatted} €
              </Nav.Item>
              <Nav.Item>
                 <DropdownProfile />
              </Nav.Item>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </section>

      <Container fluid>
        <Row>
          <Heading><span></span> Bonjour {currentUser.displayName} 👋</Heading>
        </Row>
      </Container>
    </>
  );
}

export default Header;
