import LoomProvider from './blockchain/LoomProvider'
import FirebaseProvider from './firebase/FirebaseProvider'

const providerList = {
  blockchain: LoomProvider,
  firebase: LoomProvider
  // firebase: FirebaseProvider
}

function getProviderClass(name) {
  return providerList[name]
}

export default getProviderClass;
