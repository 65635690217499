import * as React from 'react';
import { defaultSubTextColor, defaultTitleColor, secondaryTitleColor } from '../../styles';
import ContainerWithLine from '../../components/ContainerWithLine';
import Box from '@mui/material/Box';
import CustomList from '../../components/CustomList/CustomList';
import TypographyUnderlined from '../../components/TypographyUnderlined';
import Avatar from '@mui/material/Avatar';
import { Stack, Typography } from '@mui/material';

function OurVisionSection() {
  return (

    <Box>
      <ContainerWithLine maxWidth="lg">
        {/* @ts-ignore */}
        <ContainerWithLine position="top" sx={{
          marginLeft: -3,
          paddingTop: (theme: { spacing: (arg0: number) => any; }) => theme.spacing(4),
        }}>

          <TypographyUnderlined variant="h3" fontWeight={800} color={secondaryTitleColor} wrapperSX={{
            paddingY: (theme) => theme.spacing(2),
          }}>Notre devise: le succès ne se rêve pas, il se construit à plusieurs</TypographyUnderlined>

          <Box sx={{ marginTop: 10 }}>
            <Typography color={defaultSubTextColor} fontSize="1em" paragraph>Le modèle Divyz repose sur le RBF (Revenue Based Financing) qui consiste à partager chaque mois les revenus locatifs aux investisseurs du projet au prorata de leurs investissements.
<br/>
<br/>
              La plus value à la revente sera également partagée à tous les investisseurs du projet dans le cas ou la vente du bien était supérieure à son prix d'achat.</Typography>
          </Box>
          {/*// mandatory to fix the line position */}
          <br/>
        </ContainerWithLine>
      </ContainerWithLine>
    </Box>
  )
}

export default OurVisionSection
