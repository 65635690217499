import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, FormGroup, Row } from 'react-bootstrap';
import CountryField from '../../../components/KYC/CountryField';
import { errorMessageRender, onSubmitKYC } from './helpers';
import MoreInfosSchema from './schemas/MoreInfosSchema';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../../contexts/AuthContext';
import { kycIndividualRegister } from '../../../actions/user';

function KYCStep3Page() {
  const navigate = useNavigate();
  const { currentUser, currentUserData } = useAuthValue();

  return (
    <>
      <Formik
        initialValues={
          {
            nationality: 'FRA',
            ...currentUserData.kyc
          }
        }
        validationSchema={MoreInfosSchema}
        onSubmit={onSubmitKYC({
            currentUserData,
            currentUser,
            navigate,
            nextStep: { path: routes.kycCommonConfirmation.path, step: 3 },
          }, kycIndividualRegister)}
      >
        {({
          values,
          errors,
          touched,
          isValidating,
          setFieldValue,
          initialValues,
        }) => (
          <div className="container">
            <Col sm={12}>
              <Form>
                <div className="mt-4">
                  <Row className="my-3 fw-bold">Plus d'informations!</Row>
                  <Row className="mb-3">
                    <Col sm={4}>
                      <FormGroup>
                        <label htmlFor="nationality">Nationalité</label>
                        <Field
                          type="select"
                          name="nationality"
                          placeholder="Nationalité"
                          component={CountryField}
                          defaultValue={initialValues.nationality}
                          className={` ${
                            touched.nationality && errors.nationality ? 'is-invalid' : ''
                          }`}
                          // validate={validateEmail}
                        />
                        {errors.nationality &&
                          touched.nationality &&
                          errorMessageRender(errors.nationality)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="form-group float-end">
                    <button className="btn btn-primary" type="submit">
                      Suivant
                    </button>
                  </div>
                </div>
              </Form>
            </Col>
          </div>
        )}
      </Formik>
    </>
  );
}

export default KYCStep3Page;
