import {Col, Container, Row} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';

import './styles/advantages.css';
import Button from '../../components/Button/Button';
import { routes } from '../../routes';
import BackgroundedDiv from '../../components/BackgroundedDiv/BackgroundedDiv';
import Image from 'react-bootstrap/Image';
import RESEAU_IMG from '../../assets/images/reseaux-pro.jpg';
import ANALYSE_IMG from '../../assets/images/analyse.jpg';

function Advantages() {
  const navigate = useNavigate();

  return (
    <section className="advantages_wrap">
      <BackgroundedDiv className="py-5">
        <Container fluid>
          <Col md={12} style={{ textAlign: 'center', height: 200, paddingTop: 50 }}>
            <h1>Nos avantages</h1>
          </Col>
        </Container>
      </BackgroundedDiv>
      <Container fluid>
        <Row className="py-5">
          <Col md={{span: 8, order: 'first'}} xs={{span: 12, order: 'last'}}>
            <h3>Un <span className="txt_blue">réseau professionnel</span> expérimenté</h3>
            <p>
              Divyz dispose d'un réseau de professionnel de l'immobilier dans plusieurs pays d'Europe et plus particulièrement en France.
            </p>
            <p>C'est une des raison pour laquelle nous lançons la plate-forme sur le marché français en premier, mais nous ne nous arrêterons pas en si bon chemin et rapidement
              les investisseurs de la plate-forme pourrons participer à des projets à l'étranger en toute sécurité.</p>
            <p>Soyez près, nous ouvrirons prochainement de nombreux pays réputés pour leurs atouts en matière d'investissement locatif.</p>
          </Col>
          <Col md={4}>
            <Image src={RESEAU_IMG} roundedCircle width={300} height={300} />
          </Col>
        </Row>
        <Row className="py-5">
          <Col md={{span: 4, order: 'first'}}>
            <Image src={ANALYSE_IMG} roundedCircle />
          </Col>
          <Col md={8}>
            <h3><span className="txt_blue">Analyse</span> détaillée des biens</h3>
            <p>
              Pour bien choisir il faut comprendre le contexte autour d'un investissement. Sur la fiche d'une propriété, vous disposez
              de tous les détails nécessaire pour faire votre choix sur l'investissement.
            </p>
            <p>
              Notre analyse ne s'arrête pas uniquement à la propriété. Nous nous efforçons d'afficher le plus d'informations possibles
              sur la ville, la population, les commodités et tout autre information qui nous semble utile pour vous aider à choisir
              l'investissement qui vous correspond.
            </p>
          </Col>
        </Row>
        <Row className="py-5">
          <Col md={{span: 8, order: 'first'}} xs={{span: 12, order: 'last'}}>
            <h3><span className="txt_blue">La Blockchain</span> pour garantir la transparence</h3>
            <p>
              Que vous soyez un jeune investisseur ou un expert, Divyz ce doit de vous garantir une transparence totale sur la gestion au
              quotidien des propriétés dans lesquelles vous investissez.<br/>
              Pour ce faire, les comptes bancaires et toutes les transactions financières liées à une propriété sont enregistrées sur la Blockchain
              et nous permet ainsi de garantir la vérité des informations liées sans pouvoir tricher.
            </p>
          </Col>
          <Col md={4}>
            <Image src="https://images.squarespace-cdn.com/content/v1/5aa97ac8372b96325bb9ad66/1630611849206-KMH9WJKZZVLDP8O8VNU6/BiTA_blockchain_illustration.png?format=300w" />
          </Col>
        </Row>
        <Row className="py-5">
          <Col md={4}>
            <Image src="https://www.blochome.com/thumbs/44e1d2d0-a460-11ec-93ce-1f81dbce44ff.png" />
          </Col>
          <Col md={8}>
            <h3>Nos <span className="txt_blue">Reportages terrains</span> pour rester informé</h3>
            <p>
              Avec toute cette transparence sur la gestion, nos frais et l'environnement dans lequel vous investissez, il
              nous parait evident que vous donner l'opportunité de visiter ou voir vos investissements en vidéo était l'élément
              indispensable pour faire de Divyz la seule plate-forme vous offrant ce niveau d'informations.
            </p>
          </Col>
        </Row>
      </Container>
      <BackgroundedDiv className="py-5">
        <Container fluid>
          <Row className="justify-content-md-center py-5">
            <Col md={4} style={{ textAlign: 'center' }}>
              <h3>Démarrer sur Divyz en <span className="txt_blue">3 étapes</span></h3>
              <p>Notre solution unique permet à chacun de se constituer un patrimoine, en quelques clics, à partir de 1000€.
                Placer son épargne devient enfin simple et vraiment rémunérateur.
              </p>
            </Col>
          </Row>
          <Row className="steps">
            <Col md={4} style={{ textAlign: 'center' }}>
              <h3>1.</h3>
              <b>Inscrivez-vous en quelques clics</b>
              <p>Lors de la création de votre compte, nous vous demandons de fournir une copie de votre pièce d'identité (CNI ou passeport en cours de validité).</p>
            </Col>
            <Col md={4} style={{ textAlign: 'center' }}>
              <h3>2.</h3>
              <b>Nous validons votre compte</b>
              <p>Pour cela, notre partenaire bancaire vérifie la validité de vos documents d’identité. Cette étape peut prendre quelques jours.</p>
            </Col>
            <Col md={4} style={{ textAlign: 'center' }}>
              <h3>3.</h3>
              <b>Créditez votre portefeuille Divyz</b>
              <p>Pour pouvoir investir sur nos biens, vous devez disposer de fonds sur votre portefeuille Divyz. Pensez à créditer votre portefeuille Hivest.</p>
            </Col>
          </Row>

          <Row className="justify-content-md-center py-4">
            <Col md={6} style={{ textAlign: 'center' }}>
              <h3>Tout est ok, vous pouvez acheter vos premières pierres !</h3>
              <p>Repérez les prochaines mises en vente sur notre site, et choisissez les biens qui vous intéressent.
                Lors de la collecte, vous pourrez acheter une ou plusieurs pierres.</p>
              <br/>
            </Col>
          </Row>
          <Row className="justify-content-center pt-3 pb-5">
            <Col md={{span: 6, offset: 2}}>
              <Link to={routes.register.path}>
                <Button variant="primary">Je construis mon patrimoine immobilier rentable</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </BackgroundedDiv>

    </section>
  );
}

export default Advantages;
