import humanFormat from 'human-format';
import Logo from '../../components/Logo';
import avatarImg from '../../images/avatar.png';
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// import ReactPlaceholder from 'react-placeholder';
// import "react-placeholder/lib/reactPlaceholder.css";
import './styles/header.css';
import { useAuthValue } from '../../contexts/AuthContext';
import { routes } from '../../routes';
import { logout } from '../../firebase';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaBars } from 'react-icons/fa';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownProfile from './DropdownProfile';

function Header() {
  const { balance, currentUser } = useAuthValue();
  const navigate = useNavigate();
  console.log('ha', balance);
  const balanceFormatted = balance ? humanFormat(parseInt(balance), {
        maxDecimals: 2,
      }) :
      <Skeleton width={`50px`} style={{ marginLeft: '5px', marginRight: '5px' }} />
  ;

  const handleLogOut = () => {
    return logout().then(() => navigate(routes.login.path, { replace: true }));
  };

  return (
    <section className="navigation">
      <Navbar collapseOnSelect expand="md">
        <Container fluid>
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle className="navbar-toggler" type="button" data-bs-toggle="collapse"
                         data-bs-target="#collapsibleNavbar">
            <FaBars />
          </Navbar.Toggle>

          <Navbar.Collapse id="collapsibleNavbar">
            <Nav>
              <Nav.Link as={NavLink} to={routes.howItWorks.path}>
                Comment ça fonctionne ?
              </Nav.Link>
              <Nav.Link as={NavLink} to={routes.advantages.path}>
                Nos avantages
              </Nav.Link>
              <Nav.Link as={NavLink} to={routes.propertyList.path}>
                Liste des propriétés
              </Nav.Link>
              <Nav.Link className="d-block d-sm-none" as={NavLink} to={routes.profile.path}>Profile</Nav.Link>
              <Nav.Link className="d-block d-sm-none" as={NavLink} to={routes.propertyList.path}>Liste des propriétés</Nav.Link>
              <Nav.Link className="d-block d-sm-none" as={NavLink} to={routes.dashboard.path}>Dashboard</Nav.Link>
              <Nav.Link className="d-block d-sm-none" as={NavLink} to="#" onClick={() => handleLogOut()}>Logout</Nav.Link>

            </Nav>
            <Nav.Item style={{ display: 'inline-flex' }}>
              Balance: {balanceFormatted} €
            </Nav.Item>
            <Nav.Item>
              <DropdownProfile />
            </Nav.Item>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
}

export default Header;
